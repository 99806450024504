import './wp-map.scss';

import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import Vue, { PropOptions } from 'vue';
import GlobalService, {
	ArenaSector,
	ArenaSeance,
	Cart,
	IArenaService,
	IMetricsService,
	AppSettings,
	ArenaTariff,
	ArenaSeat,
	ArenaSymbols,
	MetricsSymbols,
	ArenaCartItem,
	ActionResult,
	ArenaSeatsList,
	ErrorTypes,
	ArenaTariffOption,
} from 'web-platform-core-ui';
import { Prop } from 'vue/types/options';
import { Ref, Watch } from 'vue-property-decorator';
import { MetricsEvents } from './../../../metrics-events-symbols';
import { DefaultProps } from 'vue/types/options';

import { PanZoom } from 'panzoom';
import panzoom from 'panzoom';
import BaseUILocalizationDictionary from './../../../Localization/base-ui-localization-terms';

const WpMapProps = Vue.extend({
	props: {
		sector: {
			type: Object,
			required: true,
		} as PropOptions<ArenaSector>,
		seance: {
			type: Object,
			required: true,
		} as PropOptions<ArenaSeance>,
		text: String,
		ArenaPosition: Number,
		Cart: Object as Prop<Cart>,
	},
});

@Component({
	template: require('./wp-map.html'),
})
export default class WpMapClass extends mixins<WpBaseComponent<WpMapDictionary>, DefaultProps>(WpBaseComponent, WpMapProps) {
	// #region Properties
	@Ref('rows') Rows!: HTMLElement;
	@Ref('map') Map!: HTMLElement;
	@Ref('wrapper') Wrapper!: HTMLElement;
	@Ref('container') Container!: HTMLElement;
	private arenaService!: IArenaService;
	private metricsService!: IMetricsService;
	private canvas!: HTMLCanvasElement;
	private seatSize = 48;
	private seatBorder = 8;
	private context?: CanvasRenderingContext2D | null;
	private RulerEnabled = true;
	private computedRowHeight = '100px';
	// Use theese to "nudge" seats left or right
	private SeatOffsetX = 1;
	private SeatOffsetY = 1;
	Settings!: AppSettings;
	isDragging = false;
	Debug = false;
	PanZoom!: PanZoom;
	isPanning = false;
	tariffs: ArenaTariff[] = [];
	Colors: string[] = [
		'#E53935',
		'#D81B60',
		'#8E24AA',
		'#311B92',
		'#3949AB',
		'#1E88E5',
		'#00ACC1',
		'#00897B',
		'#2E7D32',
		'#7CB342',
		'#EF6C00',
		'#5D4037',
	];
	PlaceUnderPointer: ArenaSeat | null | undefined = null;
	Loading = true;
	UILoading = false;
	MinZoom = 0.3;
	DialogOptions = false;
	ArenaTariffOptions: ArenaTariffOption[] = [];
	TempTariff: any = null;
	TempSeat: any = null;
	Transform: any = null;
	// #endregion

	// #region Hooks
	async created() {
		this.Settings = GlobalService.GetSettings<AppSettings>();
		this.arenaService = this.GlobalService.Get<IArenaService>(ArenaSymbols.ArenaService);
		this.metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
	}
	async mounted() {
		await this.getSeatsAsync();
		this.createCanvas();

		this.Cart.CartChanged.Subscribe(async (sender: Cart, e: any) => {
			this.Loading = this.UILoading = true;
			await this.getSeatsAsync();
			this.Render();
			this.Loading = this.UILoading = false;
		});
		this.Loading = false;
	}
	// #endregion

	// #region Watchers
	@Watch('sector')
	async SectorChanged() {
		this.Loading = true;
		await this.getSeatsAsync();
		this.createCanvas();
		this.Loading = false;
	}
	// #endregion

	private async getSeatsAsync() {
		const SEATS = await this.arenaService.GetSeatsAsync(this.seance.Id, this.sector.SectorCode);

		if (SEATS.Success && SEATS.Data) {
			this.Seats = SEATS.Data;
			this.tariffs = SEATS.Data.Tariffs.sort((a: any, b: any) => a.Price - b.Price);
		}
	}

	createCanvas() {
		// Remove any existing canvas
		if (this.canvas && this.Wrapper) this.Wrapper.removeChild(this.canvas);
		this.canvas = document.createElement('canvas');
		this.canvas.onpointerup = this.CanvasEventPointerUp;
		this.canvas.width = this.Width;
		this.canvas.height = this.Height;
		this.Wrapper.appendChild(this.canvas);
		this.context = this.canvas.getContext('2d');

		this.PanZoom = panzoom(this.canvas, {
			maxZoom: 1,
			minZoom: this.MinZoom,
			bounds: true,
			boundsPadding: 0.1,
			initialZoom: this.MinZoom,
			initialX: 0,
			initialY: 0,
		});

		this.Transform = this.PanZoom.getTransform();
		let initialX = (this.Container.clientWidth - 32) * 0.5 - this.canvas.clientWidth * this.Transform.scale * 0.5;
		let initialY = this.Container.clientHeight * 0.5 - this.canvas.clientHeight * this.Transform.scale * 0.5;
		this.PanZoom.moveBy(initialX, initialY, true);

		this.PanZoom.on('pan', this.UpdateVisuals);
		this.PanZoom.on('zoom', this.UpdateVisuals);
		this.PanZoom.on('panstart', () => {
			this.isPanning = true;
		});
		this.PanZoom.on('panend', () => {
			this.isPanning = false;
		});

		// Look if there is a seat under mouse position
		this.canvas.addEventListener('mousemove', (event: MouseEvent) => {
			this.PlaceUnderPointer = this.PlaceOverlap(event);
		});
		// Don't show place under mouse if mouse left the canvas
		this.canvas.addEventListener('pointerleave', () => {
			this.PlaceUnderPointer = undefined;
		});

		this.UpdateVisuals();
		this.Render();
	}

	private UpdateVisuals() {
		this.Transform = this.PanZoom.getTransform();
		this.computedRowHeight = `${this.seatSize * this.Transform.scale}px`;
		if (this.RulerEnabled) this.Rows.style.top = this.Transform.y + 'px';
	}

	private Overlap(click: any, target: any): boolean {
		if (
			this.RectOverlap(
				click.x,
				click.y,
				(target.X - this.SeatOffsetX) * this.seatSize,
				(target.Y - this.SeatOffsetY) * this.seatSize,
				this.seatSize
			)
		)
			return true;
		return false;
	}

	private RectOverlap(x1: number, y1: number, x2: number, y2: number, w: number, h?: number): boolean {
		// If only width specified - we working with a square
		if (h === undefined) h = w;
		// Horizontal check
		if (x1 >= x2 && x1 <= x2 + w) {
			// Vertical check
			if (y1 >= y2 && y1 <= y2 + h) return true;
		}
		return false;
	}

	private PlaceOverlap(event: MouseEvent | PointerEvent) {
		const CLIENT_REC = this.canvas.getBoundingClientRect();
		const X = event.clientX,
			Y = event.clientY;
		this.Transform = this.PanZoom.getTransform();
		let scale = this.Transform.scale;
		const location = {
			x: (X - CLIENT_REC.left) / scale,
			y: (Y - CLIENT_REC.top) / scale,
		};
		return this.Places.find((place) => this.Overlap(location, place) && (place.IsFree || this.isSelected(place)));
	}

	async ReservateSeat(seat: ArenaSeat, option?: ArenaTariffOption): Promise<ActionResult<ArenaCartItem>> {
		if (this.Cart.Items.length >= this.Settings.CartMaxItems) {
			this._notificationService.Error('Ошибка', 'Корзина заполнена! Максимальное кол-во товаров в корзине: ' + this.Settings.CartMaxItems);
			return ActionResult.Failed(ErrorTypes.CartItemsConflict);
		}

		this.Loading = this.UILoading = true;
		let cartItem = new ArenaCartItem(this.arenaService);

		cartItem.Seat = seat;
		cartItem.OrderId = this.Cart.OrderId;
		cartItem.Sector = this.sector;
		cartItem.Seance = this.seance;
		cartItem.Tariff = this.Tariff(seat)!;
		cartItem.SibId = this.Settings.SibId;
		if (option) cartItem.Option = option;

		let reservate = await cartItem.ReservateItem();

		if (reservate.Success) {
			this.Cart.OrderId = reservate.Data.Id;
			let add = this.Cart.AddItem(cartItem);
			this.metricsService.RegistrateEvent(MetricsEvents.ArenaSeatReservate, cartItem.Seance.Id);
			if (!add.Success) this._notificationService.Error('Ошибка', add.ErrorMessage ? add.ErrorMessage : this.Terms.InternalError);

			this.Render();
			this.Loading = this.UILoading = false;
			return ActionResult.SuccessData(cartItem);
		}

		this.Loading = this.UILoading = false;
		return ActionResult.Failed(ErrorTypes.InternalError);
	}

	async SelectOption(seat: ArenaSeat, option: ArenaTariffOption) {
		this.DialogOptions = false;
		await this.ReservateSeat(seat, option);
	}

	Zoom(ammount: number = 1.1) {
		this.Transform = this.PanZoom.getTransform();
		this.PanZoom.zoomTo(
			this.canvas.clientWidth * this.Transform.scale * 0.5 + this.Transform.x,
			this.canvas.clientHeight * this.Transform.scale * 0.5 + this.Transform.y,
			ammount
		);
	}
	Tariff(place: ArenaSeat) {
		return this.tariffs.find((tariff) => tariff.TariffCode === place.TariffCode);
	}
	Color(num: number) {
		return this.Colors[num % this.Colors.length];
	}
	Price(place: ArenaSeat) {
		const TARIFF = this.Tariff(place);
		if (TARIFF) {
			if (TARIFF.Options && TARIFF.Options.length > 1) {
				const PRICES = TARIFF.Options.map((option) => option.Price).sort();
				const MIN = this.$data._formatPrice(Math.min(...PRICES));
				const MAX = this.$data._formatPrice(Math.max(...PRICES));
				return `${this.Terms.GenericFrom} ${MIN} ${this.Terms.GenericTo} ${MAX}`;
			}
			return this.$data._formatPrice(TARIFF.Price);
		}
		return '';
	}
	LineName(row: number) {
		return this.Places.find((place) => place.Y === row)?.LineName;
	}
	isSelected(seat: ArenaSeat) {
		const SEAT = (this.Cart.Items as ArenaCartItem[]).find((item) => item.Seat.PlaceCode === seat.PlaceCode);
		if (SEAT) return true;
		return false;
	}

	// #region Pointer Event Handlers & Minimap click events
	private async CanvasEventPointerUp(event: PointerEvent) {
		// If pointer hasn't moved since pointer down event...
		if (!this.isPanning) {
			// Look for a seat under pointer
			const SEAT = this.PlaceOverlap(event);
			if (SEAT) {
				this.TempSeat = SEAT;
				const TARIFF = this.Tariff(SEAT);

				// If this seat is already in the cart...
				let arenaCartItem = (this.Cart.Items as ArenaCartItem[]).find((item) => item.Seat.PlaceCode === SEAT.PlaceCode);

				if (!arenaCartItem) {
					if (TARIFF?.Options && TARIFF?.Options?.length != 0) {
						// Если у тарифа есть варианты (Например льготный, детский, взрослый)
						this.TempTariff = TARIFF;
						this.ArenaTariffOptions = TARIFF?.Options || [];
						this.DialogOptions = true;
						return;
					} else {
						// Если у тарифа нет опций - зарезервировать место и добавить в корзину
						let reservate = await this.ReservateSeat(SEAT);
						if (reservate.Success && reservate.Data != null) {
							let cartItem = reservate.Data;
							this.metricsService.RegistrateEvent(MetricsEvents.ArenaSeatReservate, cartItem.Seance?.Id);
						} else {
							console.error(reservate);
							this._notificationService.Error('Error', 'Не удалось зарезервировать место');
						}
					}
				} else {
					// Удалить место из корзины
					this.Loading = true;
					let remove = await this.Cart.RemoveItem(arenaCartItem.OrderItemId!.toString());
					if (!remove.Success) this._notificationService.Error('Ошибка', 'Не удалось убрать место из корзины');
					await this.getSeatsAsync();
					this.Render();
					this.Loading = false;
				}
			}
		}
	}
	MinimapClick(event: any) {
		this.PanZoom.smoothMoveTo(-event.x, -event.y);
	}
	MinimapMove(event: any) {
		this.PanZoom.moveTo(-event.x, -event.y);
	}
	MinimapWheel(event: any) {
		this.PanZoom.zoomTo(this.Container.clientWidth * 0.5, this.Container.clientHeight * 0.5, 1 - event.delta * 0.1);
	}
	// #endregion

	// #region Getters/Setters
	get UprightText() {
		return { 'writing-mode': 'vertical-lr', 'text-orientation': 'upright' };
	}
	get Width(): number {
		return (this.SeatMaxX + 1) * this.seatSize;
	}
	get Height(): number {
		return (this.SeatMaxY + 1) * this.seatSize;
	}
	get Places(): ArenaSeat[] {
		return this.Seats.Places;
	}
	get SeatMaxX(): number {
		return Math.max(...this.Places.map((p) => p.X));
	}
	get SeatMaxY(): number {
		return Math.max(...this.Places.map((p) => p.Y));
	}
	private _seats: ArenaSeatsList = new ArenaSeatsList();
	get Seats(): ArenaSeatsList {
		return this.$data._seats;
	}
	set Seats(seats: ArenaSeatsList) {
		this.$data._seats = seats;
	}
	get MaxRows(): number {
		if (this.Seats && this.Places.length) return Math.max(...this.Places.map((place) => place.Y));
		return 0;
	}
	get StylePlaceUnderPointer() {
		if (!this.PlaceUnderPointer) return {};
		this.Transform = this.PanZoom.getTransform();
		let x = this.Transform.x;
		let y = this.Transform.y;
		let scale = this.Transform.scale;
		return {
			left: `${(this.PlaceUnderPointer.X - this.SeatOffsetX) * this.seatSize * scale + x}px`,
			top: `${(this.PlaceUnderPointer.Y - this.SeatOffsetY) * this.seatSize * scale + y}px`,
		};
	}
	get MinimapEnabled() {
		if (this.Settings && this.Settings.Minimap !== undefined) return this.Settings.Minimap;
		return true;
	}
	// #endregion

	// MAIN RENDER FUNCTION
	private Render() {
		if (this.context) {
			if (this.Debug) console.time('Render time:');
			let context = this.context;
			context.clearRect(0, 0, this.Width, this.Height);
			context.font = `${this.seatSize * 0.5}px serif`;
			context.textAlign = 'center';
			context.textBaseline = 'middle';
			context.strokeStyle = '#22F';
			// DRAW PLACES
			this.Places.forEach((place) => {
				// Draw rectangles
				if (this.Debug) {
					context.strokeStyle = '#000';
					context.strokeRect(
						(place.X - this.SeatOffsetX) * this.seatSize,
						(place.Y - this.SeatOffsetY) * this.seatSize,
						this.seatSize,
						this.seatSize
					);
				}

				context.strokeStyle = '#FFF';

				let seatCart = (this.Cart.Items as ArenaCartItem[]).find((item) => item.Seat.PlaceCode === place.PlaceCode);
				let isSelected = seatCart ? true : false;

				// Switch colors for circles
				if (isSelected) {
					// If seat is selected
					context.fillStyle = '#000';
				} else if (place.IsFree) {
					context.fillStyle = this.Color(place.TariffCode);
				} else {
					context.strokeStyle = '#000';
					context.fillStyle = '#EFEFEF';
				}

				context.lineWidth = this.seatBorder;
				context.beginPath();
				context.arc(
					(place.X - this.SeatOffsetX) * this.seatSize + this.seatSize * 0.5,
					(place.Y - this.SeatOffsetY) * this.seatSize + this.seatSize * 0.5,
					(this.seatSize - this.seatBorder) / 2,
					0,
					2 * Math.PI,
					false
				);
				context.closePath();

				// Draw circle border
				if (this.Debug) context.stroke();

				// Fill in circle with color
				context.fill();

				if (place.IsFree || this.isSelected(place)) {
					context.fillStyle = '#FFF';
					context.fillText(
						place.PlaceName,
						(place.X - this.SeatOffsetX) * this.seatSize + this.seatSize * 0.5,
						(place.Y - this.SeatOffsetX) * this.seatSize + this.seatSize * 0.5 + 1
					);
				}
			});
			if (this.Debug) console.timeEnd('Render time:');
		}
	}
}

export enum ArenaPosition {
	Bottom = 1,
	Top = 2,
	Left = 3,
	Right = 4,
}

export class WpMapDictionary extends BaseUILocalizationDictionary {
	GenericFrom = 'от';
	GenericTo = 'до';
	row = 'Ряд';
	seat = 'Место';
	GenericOccupied = 'Занято';
	GenericPicked = 'Выбрано';
}
