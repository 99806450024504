import { injectable } from 'inversify';
import ActionResult from './../Common/action-result';
import ITariffCapacityProvider from './i-tariff-capacity-provider';
import { RestClient } from 'typed-rest-client';
import GlobalService from '../Global/global-service';
import { ErrorTypes } from '../Common/error-types';
import TariffCapacity from './tariff-capacity';
import { AppSettings } from '../Global/app-settings';

@injectable()
export default class TariffCapacityProvider implements ITariffCapacityProvider {
    private _settings: AppSettings;
    // Don't forget to change it back to trfsrv.moipass.ru
    protected readonly _apiUrl: string;

    constructor() {
        this._settings = GlobalService.GetSettings();
        this._apiUrl = this._settings.IsDevVersion ? 'https://trfsrv-test.moipass.ru' : 'https://trfsrv.moipass.ru/';
    }

    async GetCapacityAsync(tariffId: number, date: string, seanceId: string): Promise<ActionResult<TariffCapacity>> {
        let address = this._settings.IsDevVersion ? this._settings.ServerUrl : 'https://trfsrv.moipass.ru/';

        let client: RestClient = new RestClient('wp-app', address, [], {
            headers: {
                "SessionId": this._settings.SessionId
            }
        });

        let response = await client.get<AjaxResult>(`/api/tariffs/capacity?id=${tariffId}&date=${date}&seanceId=${seanceId}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError);

        if (response.result != null && response.result.Capacity != null &&
            response.result.Count != null && response.result.ZoneId != null) {
            let capacity = new TariffCapacity();

            capacity.Capacity = response.result.Capacity;
            capacity.Count = response.result.Count;
            capacity.ZoneId = response.result.ZoneId;
            capacity.SeanceId = seanceId;
            capacity.BindedZoneIds = response.result!.BindedZoneIds;
            
            console.log(response.result);
            console.log(capacity);

            return ActionResult.SuccessData(capacity);
        }

        return ActionResult.Success();
    };

    /*
     * Запросить нагрузку (Сколько осталось билетов например) на сеансы тарифа одним запросом
     * 
     * Пример ответа:
     * ID сеанса: { Кол-во купленных билетов, Макс. кол-во билетов, ID зоны, Результат операции, Сообщение об ошибке}
     * {
     * 274252: {Count: 0, Capacity: 10, ZoneId: '2', Result: 0, Message: null},
     * 673425: {Count: 0, Capacity: 10, ZoneId: '2', Result: 0, Message: null},
     * 673432: {Count: 0, Capacity: 10, ZoneId: '2', Result: 0, Message: null}
     * }
     */
    async GetTariffsAllCapacities(id: number, date: string): Promise<ActionResult> {
        let client: RestClient = new RestClient('wp-app', this._apiUrl, [], {
            headers: {
                "SessionId": this._settings.SessionId
            }});
        let response = await client.get<AjaxResult>(`/api/tariffs/all/capacities?id=${id}&date=${date}`);
        return response.result != null ? ActionResult.SuccessData(response.result) : ActionResult.Failed(ErrorTypes.InternalError);
    }

}

class AjaxResult {
    Result: number = 0;
    Message: string = '';
    Capacity?: number;
    Count?: number;
    ZoneId?: string;
    BindedZoneIds: Array<string> = [];
}
