import { ActionResult, ErrorTypes } from '../Common';
import { injectable, inject } from 'inversify';
import {
    IdentitySymbols, IContext
} from './../Identity';
import { RestClient } from 'typed-rest-client';
import { SaleProvider, PaymentForm } from './../Sale'

@injectable()
export default class FreeSaleProvider extends SaleProvider {

    constructor(@inject(IdentitySymbols.Context) context: IContext) {
        super(context)
    }

    async GetPaymentFormAsync(id: number): Promise<ActionResult<PaymentForm>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [],
            this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders);

        let response = await client.get<AjaxResult>(`/free/pay?id=${id}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError);

        return ActionResult.SuccessData<PaymentForm>(new PaymentForm());
    }
}


class AjaxResult {
    Result: number = 0;
    Message: string = '';
}


