import Vue from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';
import {
    INotificationService, NotificationSymbols,
    IInstructorsService, InstructorsSymbols, InstructorsBundlesGroupsLessons, BaseSystemLocalizationDictionary
} from 'web-platform-core-ui';
import { DefaultProps } from 'vue/types/options';
import { VueConstructor } from 'vue/types/umd';

export const wpInstructorsBundlesLessonsProp = Vue.extend({
    props: {
        bundles: Array,
        dates: Array,
        categoryName: String
    }
})
@Component({
    template: require("./wp-instructors-bundles-lessons.html")
})

export default class WpInstructorsBundlesLessons extends mixins<WpBaseComponent<WpInstructorsBundlesLessonsDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, wpInstructorsBundlesLessonsProp, Vue) {
    private _price: number = 0;
    protected _notificationService!: INotificationService;
    private _instructorsService!: IInstructorsService;

    @PropSync('bundlesBought', { type: Array }) BundlesBought!: Array<InstructorsBundlesGroupsLessons>;

    Bundles!: Array<InstructorsBundlesGroupsLessons>;
    Dates!: Array<string>;
    BundlesAndDatesLoaded: boolean = false;
    CategoryName: string = '';
    Price: number = 0;

    GroupAmount: number = 1;

    GroupMin: number = 1;
    GroupMax: number = 10;
    get CanGroupDecrease() {
        return this.GroupAmount > this.GroupMin;
    }
    get CanGroupIncrease() {
        if (this.Bundle !== undefined) {
            return this.GroupAmount < this.GroupMax && this.GroupAmount < this.Bundle.Lessons[0].FreeMembers;
        } else {
            return this.GroupAmount < this.GroupMax;
        }
    }
    GroupDecrease(): void {
        if (this.GroupAmount > this.GroupMin) {
            this.GroupAmount--;
        }
        this.Price = this.GroupAmount * this._price;
    }
    GroupIncrease(): void {
        if (this.Bundle !== undefined) {
            if (this.GroupAmount < this.GroupMax && this.GroupAmount < this.Bundle.Lessons[0].FreeMembers) {
                this.GroupAmount++;
            }
        } else {
            if (this.GroupAmount < this.GroupMax) {
                this.GroupAmount++;
            }
        }
        this.Price = this.GroupAmount * this._price * (this.Bundle != undefined ? this.Bundle.Lessons.length : 1);
    }

    async mounted() {
        this.Bundles = await this.$props.bundles;
        this.Dates = await this.$props.dates;
        this.BundlesAndDatesLoaded = true;
        this.CategoryName = await this.$props.categoryName;
        if (this.Bundle !== undefined && this.Bundle.Lessons[0].FreeMembers < this.GroupMax)
            this.GroupMax = this.Bundle.Lessons[0].FreeMembers;

        this._instructorsService = this.GlobalService.Get<IInstructorsService>(InstructorsSymbols.InstructorsService);
        this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
    }

    BundlesByDates(date: string) {
        let periodDates = date.split('-');
        let minDate = periodDates[0].trim();
        let maxDate = periodDates[1].trim();
        let result = this.Bundles.filter(x => this.DateHandler.Parse(x.MinDate, 'YYYY-MM-DDTHH:mm:SS').Format('DD.MM.YYYY') == minDate &&
            this.DateHandler.Parse(x.MaxDate, 'YYYY-MM-DDTHH:mm:SS').Format('DD.MM.YYYY') == maxDate); 

        return result;
    }

    Bundle?: InstructorsBundlesGroupsLessons;
    BundleLoaded: boolean = false;
    LessonPriceLoaded: boolean = false;
    LessonTariffAvailable: boolean = false;

    PeopleAmountDialog: boolean = false;

    async ShowBundlePurchaseDialog(bundle: InstructorsBundlesGroupsLessons) {
        this.LessonPriceLoaded = false;
        this.LessonTariffAvailable = false;
        if (!this.IsBought(bundle)) {
            this.Bundle = this.Bundles?.find(x => x.BundleId === bundle.BundleId);
            this.BundleLoaded = true;
            this.PeopleAmountDialog = true;

            if (this.Bundle != null) {
                let price = await this._instructorsService.GetGroupLessonPriceAsync(this.Bundle.Lessons[0]);

                if (price.Success && price.Data !== null && price.Data !== undefined) {
                    this._price = price.Data.PriceForOne;
                    this.LessonTariffAvailable = true;
                    this.Price = this.GroupAmount * this._price * this.Bundle.Lessons.length;
                } 
            } else {
                this._notificationService.Error("Ошибка", "Занятие не найдено");
            }
        }
        this.LessonPriceLoaded = true;
    }

    FormatDate(dateString: string): string {
        return this.DateHandler.Parse(dateString, 'YYYY-MM-DD').Format('DD.MM.YYYY');
    }
    FormatTime(dateString: string): string {
        return this.DateHandler.Parse(dateString, 'HH:mm:SS').Format('HH:mm');
    }

    PurchaseBundle(): void {
        this.$emit('purchaseBundle', this.Bundle, this.GroupAmount, this._price * (this.Bundle != undefined ? this.Bundle.Lessons.length : 1));
        this.PeopleAmountDialog = false;
    }
    IsBought(bundle: InstructorsBundlesGroupsLessons): boolean {
        let boughtLesson = this.BundlesBought.find(x => x.BundleId === bundle.BundleId);
        if (boughtLesson !== undefined)
            return true
        return false
    }
}

export class WpInstructorsBundlesLessonsDictionary extends BaseSystemLocalizationDictionary {
    Vacant: string = '';
    GuestsAmmount: string = '';
}