import { EcomServicesList, Brand, EcomProduct } from 'web-platform-core-ui/src/Metrics/i-ecom-data';
import { IEcomData, Tariff, TariffCapacity, TariffSale, TariffSeance, TariffsBundle } from "web-platform-core-ui";

export class EcomTariffsHelper implements IEcomData {
    private _items: TariffItem[];

    constructor(items: TariffItem[]) {
        this._items = items;
    }

    public GetEcomData(eventId: Symbol): EcomServicesList {
        /** Получение списка услуг для ecommerce */
        let result = new EcomServicesList();
        result.impressions

        this._items.forEach((item: TariffItem) => {
            let tariff;
            let bundle;
            if(!item.ItemAvailable || !item.Price || !item.Show) return;

            if(item.Type === TariffItemType.Tariff) {
                tariff = item.Data as Tariff;

                result.impressions.push({
                    id: tariff.TariffCode.toString(),
                    name: tariff.Name,
                    list: tariff.GroupName,
                    brand: Brand.ISDS,
                    quantity: 1,
                    variant: EcomProduct.GetProductVariant(item.Seance, tariff.OptionName),
                    price: item.Price
                });
            } else {
                item.BundleItems.forEach(bundleItem => {
                    result.impressions.push({
                        id: bundleItem.tariff!.TariffCode.toString(),
                        name: bundleItem.tariff!.Name,
                        list: bundleItem.tariff!.GroupName,
                        brand: Brand.ISDS,
                        quantity: 1,
                        variant: EcomProduct.GetProductVariant(item.Seance, bundleItem.tariff!.OptionName),
                        price: bundleItem.tariffPrice
                    });
                });
            };
        });
        
        return result;
    };
}

export class TariffItem {
	Id: string = '';
	Type: TariffItemType = 1;
	Data?: Tariff | TariffsBundle;
	ZIndex: string = '50';
	ItemLoading: boolean = true;
	ItemAvailable: boolean = false;
	Seance?: TariffSeance;
	Show: boolean = true;
	SeanceId: string = '';
	Price?: number;
    BundleItems: Array<TariffBundleItem> = [];
}

export enum TariffItemType {
	Tariff = 1,
	Bundle = 2,
}

export class TariffBundleItem {
    capacity?: TariffCapacity;
    maxCount?: number;
    sale?: TariffSale;
    tariff?: any;
    tariffPrice?: number;
    useCapacity?: boolean;
};