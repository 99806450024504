export default class NewsArticle {
    // This is required so TS allows accesing arrays with strings
    [index: string]: any;
    Id: number = 0;
    Title: string = "";
    Content: string = "";
    Preview: string = "";
    ImageLink?: string;
    Date?: Date;
    HideDate: number = 0;
    Author: string = "";
}