import { injectable, inject } from 'inversify';

import IInstructorsService from './i-instructors-service';
import InstructorsPlace from './instructors-places';
import { ActionResult, BaseFilter } from './../Common';
import SkillType from './skill-type';
import InstructorTariffCategory from './instructor-tariff-category';
import { IInstructorsProvider, PersonalLessonsFilter, GroupLessonsFilter, InstructorsGroupLesson, InstructorsGroupLessonPrice, InstructorsBundlesGroupsLessons, InstructorCategory } from './';
import { InstructorsSymbols } from './symbols';
import Instructor from './instructor';
import InstructorsPersonalLesson from './instructors-personal-lesson';
import GlobalService from './../Global/global-service';
import { AppSettings } from './../Global/app-settings';
import InstructorsPersonalLessonPrice from './instructors-personal-lesson-price';
import { Tariff } from './../Tariffs';
import IDataProvider from '../Cms/i-data-provider';
import { CmsSymbols } from '../Cms';
import ILocalizationService from '../Localization/i-localization-service';
import { LocalizationSymbols } from '../Localization/symbols';
import InstructorsLessonsLocation from './instructors-lessons-location';
import { WpCorePrefixes } from '..';

@injectable()
export default class InstructorsService implements IInstructorsService {
    private readonly _settings: AppSettings;
    protected readonly _provider: IInstructorsProvider;
    protected readonly _cmsProvider: IDataProvider;
    private readonly _localizationService: ILocalizationService;

    constructor(@inject(InstructorsSymbols.InstructorsProvider) provider: IInstructorsProvider,
        @inject(CmsSymbols.DataProvider) cmsProvider: IDataProvider,
        @inject(LocalizationSymbols.LocalizationService) localizationService: ILocalizationService) {
        this._provider = provider;
        this._cmsProvider = cmsProvider;
        this._localizationService = localizationService;
        this._settings = GlobalService.GetSettings<AppSettings>();
    }
    async GetPlacesAsync(): Promise<ActionResult<Array<InstructorsPlace>>> {
        let result = await this._provider.GetPlacesAsync();

        if (this._settings.PlaceId !== undefined) {
            result.Data = result.Data?.filter(place => place.Id == this._settings.PlaceId);
        }

        return result;
    }

    async GetInstructorsTariffsAsync<T extends Tariff>(): Promise<ActionResult<Array<T>>> {
        return await this._provider.GetInstructorsTariffsAsync<T>();;
    }
    async GetSkillAsync(placeId: number): Promise<ActionResult<Array<SkillType>>> {
        let skills = await this._provider.GetSkillAsync(placeId);

        if (this._settings.Skills !== undefined && this._settings.Skills.length > 0) {
            skills.Data = skills.Data?.filter(x => this._settings.Skills.indexOf(x.Id) !== -1);
        }

        return skills;
    }
    async GetInstructorsTariffCategoriesAsync(): Promise<ActionResult<Array<InstructorTariffCategory>>> {
        let categories = await this._provider.GetInstructorsTariffCategoriesAsync();

        if (this._settings.Categories !== undefined && this._settings.Categories.length > 0) {
            categories.Data = categories.Data?.filter(x => this._settings.Categories.indexOf(x.Id.toString()) !== -1);
        }

        return categories;
    }

    async GetInstructorsAsync(placeId: number): Promise<ActionResult<Array<Instructor>>> {
        return await this._provider.GetInstructorsAsync(placeId);
    }
    async GetPersonalLessonsAsync(filter: PersonalLessonsFilter): Promise<ActionResult<Array<InstructorsPersonalLesson>>> {
        let lessons = await this._provider.GetPersonalLessonsAsync(filter);

        if (this._settings.LessonsTariffsId !== undefined && this._settings.LessonsTariffsId.length > 0) {
            lessons.Data = lessons.Data?.filter(x => this._settings.LessonsTariffsId.indexOf(x.TariffId) !== -1);
        }

        return lessons
    }
    async GetPersonalLessonPriceAsync(lesson: InstructorsPersonalLesson): Promise<ActionResult<InstructorsPersonalLessonPrice>> {
        return await this._provider.GetPersonalLessonPriceAsync(lesson);
    }
    async GetGroupLessonsAsync(filter: GroupLessonsFilter): Promise<ActionResult<Array<InstructorsGroupLesson>>> {
        let lessons = await this._provider.GetGroupLessonsAsync(filter);

        if (this._settings.GroupLessonsTariffsId !== undefined && this._settings.GroupLessonsTariffsId.length > 0) {
            lessons.Data = lessons.Data?.filter(x => this._settings.GroupLessonsTariffsId.indexOf(x.TariffId) !== -1);
        }

        return lessons
    }
    async GetGroupLessonPriceAsync(lesson: InstructorsGroupLesson): Promise<ActionResult<InstructorsGroupLessonPrice>> {
        return await this._provider.GetGroupLessonPriceAsync(lesson);
    }

    async GetBundlesGroupLessonsAsync(filter: GroupLessonsFilter): Promise<ActionResult<Array<InstructorsBundlesGroupsLessons>>> {
        return await this._provider.GetBundlesLessonsAsync(filter);
    }

    async GetLessonsLocationsAsync(): Promise<ActionResult<Array<InstructorsLessonsLocation>>> {
        let result = new Array<InstructorsLessonsLocation>();
        let locations = await this._cmsProvider.GetEntitiesAsync<InstructorsLessonsLocation>(WpCorePrefixes.InstructorsLessonsLocation, new BaseFilter(), '',
            this._localizationService.CurrentLocalization);

        if (locations.Data != null) {
            result = locations.Data.Entities;

            if (this._settings.LessonsLocation != null) {
                result = result.filter(x => x.LocationId == this._settings.LessonsLocation);
            }
        }

        return ActionResult.SuccessData(result);
    }

    async GetInstructorsCategoriesAsync(placeId: number): Promise<ActionResult<Array<InstructorCategory>>> {
        return await this._provider.GetInstructorsCategoriesAsync(placeId);
    }
}