export default class PersonIsdLoyaltyData {
    AddressId?: number;
    Birthdate: string | null = '';
    City: string = '';
    ConfirmCodeId: number = 0;
    Email?: string;
    FirstName: string = '';
    Gender?: number = 0;
    LastName: string = '';
    PersId: number = 0;
    Phone: string = '';
    SecondName: string = '';
    TNodId: number = 0;

    Id?: number = 0;
    LoyaltyId?: number = 0;
};