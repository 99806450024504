import { MetricType } from './metric-type';

export default class MetricCounter {
	Id: number = 0;
	SiteId: number = 0;
	Name: string = '';
	Description: string = '';
	Type: MetricType = MetricType.YandexCounter;
	CounterId: string = '';
	Ecommerce: boolean = false;
}
