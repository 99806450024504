import { ArenaPosition } from './arena-position';
import ArenaSector from './arena-sector';

export default class ArenaSectorsList {
    HavePlan: boolean = true;
    PlanImage: string = '';
    ImageWidth: number = 0;
    ImageHeight: number = 0;
    ArenaText: string = '�����';
    ArenaPosition: ArenaPosition = ArenaPosition.Bottom;
    Sectors: Array<ArenaSector> = [];
}