import { injectable } from 'inversify';

import ITariffProvider from './i-tariff-provider';
import Tariff from './tariff';
import ActionResult from '../Common/action-result';
import { RestClient } from 'typed-rest-client';
import GlobalService from './../Global/global-service';
import { ErrorTypes } from './../Common/error-types';
import TariffSale from './tariff-sale';
import TariffsBundle from './tariffs-bundle';
import CardAcceptableTariffs from './card-acceptable-tariffs';
import { AppSettings } from '../Global/app-settings';

@injectable()
export default class TariffProvider implements ITariffProvider {
    _settings: AppSettings;

    // Don't forget to change it back to trfsrv.moipass.ru
    protected readonly _apiUrl: string;

    private GetClient(): RestClient {
        return new RestClient("wp-app", this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "moduleId": this._settings.SiteId,
                "SessionId": this._settings.SessionId
            }
        });
    };

    constructor() {
        this._settings = GlobalService.GetSettings();
        this._apiUrl = this._settings.IsDevVersion ? 'https://trfsrv-test.moipass.ru' : 'https://trfsrv.moipass.ru/';
    }

    async GetCardAcceptableTariffsAsync(card: string, userId: string, cardType?: number): Promise<ActionResult<CardAcceptableTariffs>> {
        let client: RestClient = this.GetClient();

        let response = await client.get<AjaxResult<CardAcceptableTariffs>>(`/websale/api/tariffs/card?mediaNum=${card}&userId=${userId}&mediaType=${cardType ?? "" }`);

        if (response.result != null && response.result.Result != 0) {
            if (response.result.Result == 17)
                return ActionResult.FailedData(ErrorTypes.MediaCardIsBanned, response.result?.Data, undefined);

            if (response.result.Result == 2)
                return ActionResult.FailedData(ErrorTypes.MediaCardInProcess, response.result?.Data, undefined);

            if (response.result.Result == 9)
                return ActionResult.FailedData(ErrorTypes.MediaCardIsLocked, response.result?.Data, undefined);

            if (response.result.Result == 10)
                return ActionResult.FailedData(ErrorTypes.MediaCardNotFound, response.result?.Data, undefined);

            if (response.result.Result == 11)
                return ActionResult.FailedData(ErrorTypes.MediaCardExpired, response.result?.Data, undefined);

            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data, response.result.Message);
        };

        return ActionResult.SuccessData<CardAcceptableTariffs>(response.result?.Data);
    };

    async GetTariffsAsync<T extends Tariff>(): Promise<ActionResult<Array<T>>> {
        let client: RestClient = this.GetClient();

        let response = await client.get<AjaxResult<Array<T>>>(`/websale/api/tariffs`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data, response.result?.Message);

        return ActionResult.SuccessData<Array<T>>(response.result?.Data);
    };

    async GetTariffsBundlesAsync(): Promise<ActionResult<Array<TariffsBundle>>> {
        let client: RestClient = this.GetClient();

        let response = await client.get<AjaxResult<Array<TariffsBundle>>>(`/websale/api/tariffsbundles`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<Array<TariffsBundle>>(response.result?.Data);
    };

    async GetAvailableTariffsAsync(date: string): Promise<ActionResult<Array<Tariff>>> {
        let client: RestClient = this.GetClient();

        let response = await client.get<AjaxResult<Array<Tariff>>>(`/websale/api/tariffs/available?Date=${date}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<Array<Tariff>>(response.result?.Data);
    };

    async GetTariffSalesAsync(date: string, tariffs: Array<number>): Promise<ActionResult<Array<TariffSale>>> {
        let client: RestClient = this.GetClient();

        let tariffsString = '';
        for (let i = 0; i < tariffs.length; i++) {
            tariffsString += '&tariffsId=' + tariffs[i];
        };
        let response = await client.get<AjaxResult<Array<TariffSale>>>(`/websale/api/tariffs/sales?date=${date}${tariffsString}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<Array<TariffSale>>(response.result?.Data);
    };

    async GetSingleTariffSalesAsync(date: string, tariffId: number, seanceId?: string): Promise<ActionResult<TariffSale>> {
        let address = GlobalService.GetSettings().IsDevVersion ? 'https://trfsrv-test.moipass.ru' : 'https://trfsrv.moipass.ru';

        let client: RestClient = new RestClient('wp-app', address, [], {
            headers: {
                "SessionId": this._settings.SessionId
            }
        });

        let response = await client.get<AjaxResult<TariffSale>>(`/api/tariffs/${tariffId}/sales?date=${date}&seanceId=${seanceId != null ? seanceId : ''}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<TariffSale>(response.result?.Data);
    };

    async GetTariffPrice(date: string, tariffId: number, seanceId?: string): Promise<ActionResult> {
        let address = GlobalService.GetSettings().IsDevVersion ? 'https://trfsrv-test.moipass.ru/' : 'https://trfsrv.moipass.ru/';

        let client: RestClient = new RestClient('wp-app', address, [], {
            headers: {
                "SessionId": this._settings.SessionId
            }
        });

        let seanceParam = seanceId != null ? `&seanceId=${seanceId}` : ``;
        let response = await client.get<AjaxResult<any>>(`/api/tariffs/price?id=${tariffId}&date=${date}${seanceParam}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data, response.result?.Message);

        return ActionResult.SuccessData(response.result?.Data);
    };

    // ����� ������ - http://redmine.isd.su/issues/6843
    // ������� ��������:
    // https://trfsrv-test.moipass.ru/api/tariffs/all/prices?id=1493&date=15.02.2022
    // https://trfsrv-test.moipass.ru/api/tariffs/all/capacities?id=1493&date=15.02.2022

    /*
     * ��������� ���� �� ������ ������ ����� ��������
     * 
     * ������ ������:
     * ID ������: { ����, ��������� �������� (0 - ��, !0 - ������), ��������� �� ������ }
     * {
     * 673432: { Data: 100, Result: 0, Message: null },
     * 673425: { Data: 120, Result: 0, Message: null },
     * 274252: { Data: 100, Result: 0, Message: null }
     * }
     */
    async GetTariffsAllPrices(id: number, date: string): Promise<ActionResult> {
        let client: RestClient = new RestClient('wp-app', this._apiUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "SessionId": this._settings.SessionId
            }
        });
        let response = await client.get<AjaxResult<any>>(`/api/tariffs/all/prices?id=${id}&date=${date}`);
        return response.result != null ? ActionResult.SuccessData(response.result) : ActionResult.Failed(ErrorTypes.InternalError);
    };
};

class AjaxResult<T> {
    Result: number = 0;
    Message: string = '';
    Data?: T;
};
