import { WpEventArgs } from '../Common';
import SoldClubContract from './sold-club-contract';

export default class SoldContractChangedEventArgs extends WpEventArgs {
    OldContract: SoldClubContract;
    NewContract: SoldClubContract;

    constructor(oldContract: SoldClubContract, newContract: SoldClubContract) {
        super();

        this.OldContract = oldContract;
        this.NewContract = newContract;
    }
}