import './wp-isd-loyalty-children.scss';

import Vue from 'vue';
import WpBaseComponent from '../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import BaseUILocalizationDictionary from '../../../Localization/base-ui-localization-terms';
import { DefaultProps } from 'vue/types/options';
import { VueConstructor } from 'vue/types/umd';
import { wpIsdLoyaltyProps } from '../wp-isd-loyalty';
import { ILoyaltyProvider, LoyaltySymbols, IsdUserLoyalty, PersonIsdLoyaltyData } from 'web-platform-core-ui';

@Component({
	template: require('./wp-isd-loyalty-children.html'),
})
export default class WpIsdLoyaltyChildren extends mixins<WpBaseComponent<WpIsdLoyaltyChildrenDictionary>, DefaultProps, VueConstructor>(
	WpBaseComponent,
	wpIsdLoyaltyProps,
	Vue
) {
	private _provider!: ILoyaltyProvider;
	public IsdUserLoyalty!: IsdUserLoyalty;

	public Children: Array<PersonIsdLoyaltyData> = [];
	public AddChildModal: boolean = false;
	public RemoveChildConfirmModal: boolean | Child = false;
	public AddChildFormValid: boolean = false;
	public RulesRequired!: Array<Function>;
	public Genders!: Array<any>;

	public UIDisabled: boolean = true;
	public ChildToRemove: Child = new Child();

	public Yesterday: string = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().substr(0, 10);
	public DateFormated: string = this.Yesterday.split('-').reverse().join('.');

	Today = new Date();
	public ChildFirstName: string = '';
	public ChildLastName: string = '';
	public ChildSecondName: string = '';
	public ChildGender: number = 0;
	public ChildBirthdate: string | null = null;
	public ChildPersId: number = 0;

	created(): void {
		this._provider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);
		// Подгружаем правила и полы вместе с локализацией
		this.RulesRequired = [(v: any) => !!v || this.Terms.FormFieldRequired];
		this.Genders = [
			{ text: this.Terms.GenderMale, value: 1 },
			{ text: this.Terms.GenderFemale, value: 2 },
		];
		// Следим за переменной this.ChildBirthdate, выполняем метод UpdateFormatDate при изменении её.
		//this.$watch('ChildBirthdate', this.UpdateFormatDate);
	}

	async mounted(): Promise<void> {
		let result = await this._provider.GetIsdUserLoyaltyAsync();
		if (result.Success && result.Data != undefined) this.IsdUserLoyalty = result.Data;
		await this.UpdateChildren();
	}

	async UpdateChildren(): Promise<void> {
		try {
			// Отключаем кнопки на время
			this.UIDisabled = true;
			// Получаем детей массивом
			this.Children = (await this._provider.GetChildrenAsync()).Data ?? [];
		} catch (e) {
			// Couldn't update children list
			console.error(e);
		} finally {
			// Включаем кнопки обратно
			this.UIDisabled = false;
		}
	}
	async AddChild(): Promise<void> {
		try {
			this.UIDisabled = true;
			// Попробуем проверить валидна ли форма или нет (например есть ли пустые обязательные поля)
			// TS ругается что у формы нет метода validate(), поэтому так странно и выглядит код ниже
			let isFormValid = (this.$refs.AddChildModalForm as Vue & { validate: () => boolean }).validate();
			// Если форма валидна
			if (isFormValid && this.ChildBirthdate) {
				// У DateHandler какие-то шенаниганы по поводу того, что он просто так не спарсит дату
				// потому что у него она хранится как внутренняя переменная или что-то типа того.
				// Дичь. Надо бы поправить.
				let birthday = this.DateHandler.ParseToDate(this.ChildBirthdate, 'DD.MM.YYYY');
				// А в таком формате дату рождения принимает СЕРВЕР (Что тоже немного дико. Зачем знать ДАТУ РОЖДЕНИЯ В ЧАСАХ (А ТО И В МИНУТАХ)?)
				let birthdayFull = this.DateHandler.Parse(birthday).Format('YYYY-MM-DDT12:00:00');

				let child = {
					LoyaltyId: this.IsdUserLoyalty.Id,
					FirstName: this.ChildFirstName.trim(),
					LastName: this.ChildLastName.trim(),
					SecondName: this.ChildSecondName.trim(),
					Birthdate: birthdayFull,
					Gender: this.ChildGender,
				};

				await this._provider.AddChildAsync(child);
				await this.UpdateChildren();
				this.ClearForm();
				this.AddChildModal = false;
			}
		} catch (e) {
			// Couldn't create a new child :(
			console.error(e);
		} finally {
			this.UIDisabled = false;
		}
	}
	async UpdateChild(): Promise<void> {
		try {
			if (!this.ChildBirthdate) return;
			this.UIDisabled = true;
			//this.DateHandler.ParseToDate(this.ChildBirthdate, 'YYYY-MM-DD');
			//let Birthdate = this.DateHandler.Parse(this.ChildBirthdate, 'YYYY-MM-DD').Format('YYYY-MM-DDT12:00:00');
			let Birthdate = this.DateHandler.Parse(this.ChildBirthdate, 'DD.MM.YYYY').Format('YYYY-MM-DDT12:00:00');
			let child = {
				PersonId: this.ChildPersId,
				TNodId: this.IsdUserLoyalty.TNodId,
				LoyaltyId: this.IsdUserLoyalty.Id,
				FirstName: this.ChildFirstName.trim(),
				LastName: this.ChildLastName.trim(),
				SecondName: this.ChildSecondName.trim(),
				Birthdate: Birthdate,
				Gender: this.ChildGender,
			};
			await this._provider.UpdateChildAsync(child);
			await this.UpdateChildren();
			this.AddChildModal = false;
		} catch (e) {
			// Couldn't update child
			console.error(e);
		} finally {
			this.UIDisabled = false;
		}
	}
	async RemoveChild(child: any): Promise<void> {
		try {
			this.UIDisabled = true;
			// Удаляем ребёнка
			await this._provider.RemoveChildAsync(this.IsdUserLoyalty.Id, child.PersId);
			// После удаления - обновим список детей
			await this.UpdateChildren();
		} catch (e) {
			// Couldn't remove child
			console.error(e);
		} finally {
			this.UIDisabled = false;
			this.RemoveChildConfirmModal = false;
		}
	}
	ClearForm(): void {
		this.ChildFirstName = '';
		this.ChildLastName = '';
		this.ChildSecondName = '';
		this.ChildGender = 0;
		this.ChildPersId = 0;
		this.ChildBirthdate = null;
	}
	EditChild(child: any): void {
		this.AddChildModal = true;
		this.ChildFirstName = child.FirstName;
		this.ChildLastName = child.LastName;
		this.ChildSecondName = child.SecondName;
		this.ChildGender = child.Gender;
		this.ChildPersId = child.PersId;
		//this.ChildBirthdate = new Date(child.Birthdate).toISOString().substr(0, 10);
		this.ChildBirthdate = this.DateHandler.Parse(new Date(child.Birthdate)).Format('DD.MM.YYYY');
	}
}

class Child {
	AddressId?: number;
	Birthdate: string = '';
	City?: string;
	ConfirmCodeId?: number;
	FirstName: string = '';
	Gender: number = 1;
	LastName: string = '';
	PersId: number = 0;
	SecondName?: string;
	TNodId: number = 0;
}

export class WpIsdLoyaltyChildrenDictionary extends BaseUILocalizationDictionary {
	GenericChildren: string = 'Дети';
	GenericChildData: string = 'Данные ребёнка';
	GenericConfirm: string = 'Вы уверены?';

	GenderMale: string = '♂ Мужской';
	GenderFemale: string = '♀ Женский';

	LastName: string = 'Фамилия';
	FirstName: string = 'Имя';
	SecondName: string = 'Отчество';
}
