import { ActionResult, ErrorTypes } from '../Common';
import { RestClient } from 'typed-rest-client';
import GlobalService, { IdentitySymbols, IContext } from './..';
import { AppSettings } from './../Global/app-settings';


export default class LuzhnikFeedbackProvider {
    private _context: IContext;
    private _settings: AppSettings;

    constructor(context: IContext) {
        this._context = context;
        this._settings = GlobalService.GetSettings();
    }

    async SendAsync(topic: string, method: string, message: string, email: string | null = null, phone: string | null = null, name: string | null = null): Promise<ActionResult> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "moduleId": this._settings.SiteId,
            }
        });

        let response = await client.create(`/luzhniki/feedback/`,
            {
                Phone: phone ?? this._context.CurrentIdentity.Phone,
                Email: email ?? this._context.CurrentIdentity.Username,
                Category: topic,
                ContactWay: method,
                Message: message,
                Name: name
            });

        if (response.statusCode != 200)
            return ActionResult.Failed(ErrorTypes.InternalError);

        return ActionResult.Success();
    }
}
