import Vue from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, { AppSettings, BaseSystemLocalizationDictionary } from 'web-platform-core-ui';
import { PropSync, Watch } from 'vue-property-decorator';
import moment from 'moment';

export const wpDatepickerProps = Vue.extend({
	props: {
		disabledweekdays: Array,
		forceselectrange: Number,
	},
});

@Component
export default class WpInstructorsDatepickerClass extends mixins<WpBaseComponent<WpInstructorsDatepickerDictionary>>(
	WpBaseComponent,
	wpDatepickerProps,
	Vue
) {
	@PropSync('settings', { type: Object }) Settings!: AppSettings;

	private Today: Date = new Date();

	DisabledWeekdays: Array<number> = [];
	ForceSelectRange: number = 0;

	SessionDateRangeModal: boolean = false;
	SessionDateRangePicker: any = [];
	SessionDateRange: string = '';
	SessionDateRangeSub: string = '';
	SessionDatesVisible: boolean = true;

	ShowButtonVisible: boolean = true;

	DateRangeSub: string = '';
	DateRangeTitle: string = '';
	HaveForcedDate: boolean = false;

	MinDate: string = '';
	MaxDate: string = '';
	MinDateDefault: string = '';
	MaxDateDefault: string = '';

	@Watch('Settings')
	OnSettingsChange(val: AppSettings, _oldVal: AppSettings): void {
		if (val !== undefined) this.ChangeSettings(val);
	}

	created(): void {
		this.MinDate = this.DateHandler.Parse(this.Today).Format('YYYY-MM-DD');
		this.MaxDate = this.DateHandler.Parse(this.Today).Add('months', 1).Format('YYYY-MM-DD');
		this.MinDateDefault = this.DateHandler.Parse(this.Today).Format('YYYY-MM-DD');
		this.MaxDateDefault = this.DateHandler.Parse(this.Today).Add('days', 30).Format('YYYY-MM-DD');
	}

	protected HandleMinDate() {
		if (this.Settings.Mindate !== undefined && this.Settings.Mindate != '') {
			let minDate = this.DateHandler.ParseToDate(this.Settings.Mindate, 'DD.MM.YYYY');
			if (this.Today.getTime() > minDate.getTime()) {
				this.MinDate = this.MinDateDefault = this.DateHandler.Parse(this.Today).Format('YYYY-MM-DD');
				// Выбрать минимальную дату как текущее начало
				this.SessionDateRangePicker[0] = this.MinDate;
			}
		}
	}

	get FormatSelectedDate(): string {
		let from = this.SortDates[0];
		let fromFormat = '';
		if (from) fromFormat = new Date(from).toLocaleDateString('ru-RU');

		let to = this.SortDates[1];
		let toFormat = '';
		if (to) toFormat = new Date(to).toLocaleDateString('ru-RU');

		return `${fromFormat} ${toFormat ? '- ' + toFormat : ''}`;
	}

	get DatesArray(): Array<string> {
		let datesArray = [];
		let firstDate = moment(this.SessionDateRangePicker[0], 'YYYY-MM-DD');

		if (this.SessionDateRangePicker.length == 2) {
			let lastDate = moment(this.SessionDateRangePicker[1], 'YYYY-MM-DD');

			while (firstDate <= lastDate) {
				datesArray.push(firstDate.format('YYYY-MM-DD'));
				firstDate = firstDate.add(1, 'days');
			}
		} else if (this.SessionDateRangePicker.length == 1) {
			datesArray.push(firstDate.format('YYYY-MM-DD'));
		}
		return datesArray;
	}

	get DatesChoosen(): string {
		if (this.SessionDateRangePicker[0] == undefined || this.SessionDateRangePicker[1] == undefined) return '';
		let firstDate = this.DateHandler.Parse(this.SessionDateRangePicker[0], 'YYYY-MM-DD').Format('DD.MM.YYYY');
		let lastDate = this.DateHandler.Parse(this.SessionDateRangePicker[1], 'YYYY-MM-DD').Format('DD.MM.YYYY');
		return firstDate + ' - ' + lastDate;
	}

	AllowedDates(val: string): boolean {
		let date = this.DateHandler.Parse(val, 'YYYY-MM-DD');
		if (this.DisabledWeekdays.length !== 0) if (this.DisabledWeekdays.indexOf(date.Weekday()) > -1) return false;

		if (this.Settings.Disableddates != undefined && this.Settings.Disableddates.length > 0)
			return this.Settings.Disableddates.indexOf(date.Format('DD.MM.YYYY')) === -1;

		return true;
	}

	async ChangeSettings(_settings: AppSettings): Promise<void> {
		this.Settings = GlobalService.GetSettings<AppSettings>();
		this.SessionDatesVisible = !this.Settings.Nocalendar;

		if (this.Settings.Maxdate !== undefined && this.Settings.Maxdate != '') {
			this.MaxDate = this.MaxDateDefault = this.DateHandler.Parse(this.Settings.Maxdate, 'DD.MM.YYYY').Format('YYYY-MM-DD');
		}

		if (this.Settings.Forcedate !== undefined) {
			let forcedate = this.DateHandler.Parse(this.Settings.Forcedate, 'DD.MM.YYYY');
			let forcedateTime = forcedate.Time();

			let mindate = this.DateHandler.Parse(this.MinDate, 'YYYY-MM-DD');
			let mindateTime = mindate.Time();

			let maxdate = this.DateHandler.Parse(this.MaxDate, 'YYYY-MM-DD');
			let maxdateTime = maxdate.Time();

			forcedate =
				mindateTime > forcedateTime
					? this.DateHandler.Parse(this.MinDate, 'YYYY-MM-DD')
					: this.DateHandler.Parse(this.Settings.Forcedate, 'DD.MM.YYYY');
			forcedate =
				forcedateTime > maxdateTime
					? this.DateHandler.Parse(this.MaxDate, 'YYYY-MM-DD')
					: this.DateHandler.Parse(this.Settings.Forcedate, 'DD.MM.YYYY');

			//this.SessionDateRange = forcedate.format('DD.MM.YYYY');
			this.SessionDateRange = forcedate.Format('DD.MM.YYYY');
			this.SessionDateRangePicker = [forcedate.Format('YYYY-MM-DD')];

			if (this.$props.forceselectrange !== undefined) {
				let newDate = forcedate;
				//newDate.add('days', this.$props.forceselectrange);
				newDate.Add('days', this.$props.forceselectrange);
				//this.SessionDateRangePicker.push(newDate.format('YYYY-MM-DD'));
				this.SessionDateRangePicker.push(newDate.Format('YYYY-MM-DD'));

				this.$emit('datesSet', this.SessionDateRangePicker);
				this.SetDateRange();
			} else {
				this.$emit('datesSet', this.SessionDateRangePicker);
			}
			this.HaveForcedDate = true;
		}

		if (this.Settings.Forcedate == undefined && this.Settings.Nocalendar === true) {
			let d = this.DateHandler.Parse(new Date());
			this.SessionDateRange = d.Format('DD.MM.YYYY');
			this.SessionDateRangePicker = [d.Format('YYYY-MM-DD')];
		}

		this.HandleMinDate();
	}

	async mounted(): Promise<void> {
		let disabledweekdays = await this.$props.disabledweekdays;
		if (disabledweekdays !== undefined) this.DisabledWeekdays = disabledweekdays;

		let forceselectrange = await this.$props.forceselectrange;
		if (forceselectrange !== undefined) this.ForceSelectRange = forceselectrange;
	}

	ClearDates(): void {
		this.SessionDateRangePicker = [];
		this.MaxDate = this.MaxDateDefault;
		this.MinDate = this.MinDateDefault;
		this.$emit('datesSet', this.SessionDateRangePicker);
	}

	get SortDates() {
		return this.SessionDateRangePicker ? this.SessionDateRangePicker.sort() : [];
	}

	SortChoosenDates(): void {
		if (this.SessionDateRangePicker.length > 1) {
			let fd = this.DateHandler.ParseToDate(this.SessionDateRangePicker[0], 'YYYY-MM-DD');
			let ld = this.DateHandler.ParseToDate(this.SessionDateRangePicker[1], 'YYYY-MM-DD');

			// Friendly reminder - you can compare two dates with >, <, >= or <=
			// However you CANNOT use ==, ===, != or !== with Date objects.
			if (fd > ld) {
				this.SessionDateRangePicker[0] = this.DateHandler.Parse(ld).Format('YYYY-MM-DD');
				this.SessionDateRangePicker[1] = this.DateHandler.Parse(fd).Format('YYYY-MM-DD');
			}
		}
	}

	SetDateRange(): void {
		this.SessionDateRangeModal = false;
		if (this.SessionDateRangePicker.length == 2) {
			this.SessionDateRange = this.FormatDate(this.SessionDateRangePicker[0]) + ' − ' + this.FormatDate(this.SessionDateRangePicker[1]);
		} else if (this.SessionDateRangePicker.length == 1) {
			this.SessionDateRange = this.FormatDate(this.SessionDateRangePicker[0]);
		}
		this.$emit('datesSet', this.SessionDateRangePicker);
	}

	FormatDate(dateString: string): string {
		return this.DateHandler.Parse(dateString, 'YYYY-MM-DD').Format('DD.MM.YYYY');
	}

	FormatTime(dateString: string): string {
		return this.DateHandler.Parse(dateString, 'HH:mm:SS').Format('HH:mm');
	}

	DateSelected(dates: Array<string>): void {
		if (this.HaveForcedDate == true && this.ForceSelectRange === 0) {
			dates.shift();
			this.HaveForcedDate = false;
		}

		this.SortChoosenDates();

		let firstDate = this.DateHandler.Parse(dates[0], 'YYYY-MM-DD');

		if (this.ForceSelectRange !== 0) {
			if (dates.length === 2) dates.pop();
			let newDate = firstDate;
			newDate.Add('days', this.ForceSelectRange);
			dates.push(newDate.Format('YYYY-MM-DD'));
		}

		if (dates.length == 1) {
			let maxdate = this.DateHandler.Parse(dates[0], 'YYYY-MM-DD').Add('days', 7);

			if (this.Settings.Maxdate !== undefined) {
				let settingsMaxdate = this.DateHandler.Parse(this.Settings.Maxdate, 'DD.MM.YYYY');
				maxdate = maxdate.Time() > settingsMaxdate.Time() ? settingsMaxdate : maxdate;
			} else {
				let defaultMaxdate = this.DateHandler.Parse(new Date()).Add('days', 30);
				maxdate = maxdate.Time() > defaultMaxdate.Time() ? defaultMaxdate : maxdate;
			}

			this.MaxDate = maxdate.Format('YYYY-MM-DD');
			let mindate = this.DateHandler.Parse(dates[0], 'YYYY-MM-DD').Subtract('days', 7);

			if (this.Settings.Mindate !== undefined) {
				let settingsMindate = this.DateHandler.Parse(this.Settings.Mindate, 'DD.MM.YYYY');

				let currentDate = this.DateHandler.Parse(new Date());
				if (currentDate.Time() > settingsMindate.Time()) settingsMindate = currentDate;

				if (settingsMindate > mindate) mindate = settingsMindate;
			} else {
				let defaultMindate = this.DateHandler.Parse(new Date());
				mindate = mindate.Time() < defaultMindate.Time() ? defaultMindate : mindate;
			}

			this.MinDate = mindate.Format('YYYY-MM-DD');
		} else {
			if (this.Settings.Maxdate !== undefined) {
				this.MaxDate = this.MaxDateDefault = this.DateHandler.Parse(this.Settings.Maxdate, 'DD.MM.YYYY').Format('YYYY-MM-DD');
			} else {
				this.MaxDate = this.MaxDateDefault = this.DateHandler.Parse(new Date()).Add('days', 30).Format('YYYY-MM-DD');
			}

			if (this.Settings.Mindate !== undefined) {
				let mindate = this.DateHandler.Parse(this.Settings.Mindate, 'DD.MM.YYYY');
				let currentDate = this.DateHandler.Parse(new Date());
				if (mindate.Time() < currentDate.Time()) mindate = currentDate;

				this.MinDate = mindate.Format('YYYY-MM-DD');
			} else {
				this.MinDate = this.MinDateDefault = this.DateHandler.Parse(new Date()).Format('YYYY-MM-DD');
			}
		}
	}
}

export class WpInstructorsDatepickerDictionary extends BaseSystemLocalizationDictionary {
	PeriodOfStudy: string = '';
}
