import { TariffsBundle } from 'web-platform-core-ui';
import { EcomBaseProducts, Brand, EcomProduct, EcomRemoveFromCart } from 'web-platform-core-ui/src/Metrics/i-ecom-data';
import Vue from 'vue';
import { Prop, DefaultProps } from "vue/types/options";
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { formatPrice } from 'web-platform-core-ui/src/Utility/format';
import BundleCartItem from 'web-platform-core-ui/src/Tariffs/bundle-cart-item';
import { MetricsSymbols, IMetricsService, BaseSystemLocalizationDictionary } from 'web-platform-core-ui';
import { MetricsEvents } from '../../../metrics-events-symbols';
import { VueConstructor } from 'vue/types/umd';

export const wpCartBundleProps = Vue.extend({
    props: {
        CartItem: Object as Prop<BundleCartItem>,
        disabled: Boolean
    }
})

@Component({  
    template: require("./wp-cart-bundle.html")
})
export default class WpCartBundle extends mixins<WpBaseComponent<WpCartBundleDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, wpCartBundleProps, Vue) {
    _formatPrice = formatPrice;
    RemoveItem() {
        let metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);

        let productsList: any = {};
        let tmpList: EcomRemoveFromCart = new EcomRemoveFromCart();
        tmpList.remove = new EcomBaseProducts();
        this.ReduceCountEcom(tmpList.remove);
        console.log('На кнопку trash bin в корзине', tmpList);

        productsList.GetEcomData = (): EcomRemoveFromCart => {
            return tmpList;
		};        

        metricsService.RegistrateEvent(MetricsEvents.IncartRemove, this.CartItem?.MetricsId, productsList);

        this.$emit('RemoveItem', this.CartItem.TempId);
    }

    /**
     * Метод парсинга данных удаленного бандла из корзины для метрики ecommerce.
     * @param tmpList - массив, который нужно заполнить данными удаляемого объекта.
     */
    ReduceCountEcom(tmpList: EcomBaseProducts) {
        const bundle: TariffsBundle =  this.CartItem._bundle;

        bundle.Tariffs.forEach(bundle => {            
            tmpList.products.push({
                id: bundle.TariffCode.toString(),
                name: bundle.Name,
                list: bundle.GroupName,
                brand: Brand.ISDS,
                quantity: 1,
                variant: EcomProduct.GetProductVariant(this.CartItem._seance, bundle.OptionName),
            });
        });
    };
}

export class WpCartBundleDictionary extends BaseSystemLocalizationDictionary {
    // No words here... yet...
}