import { injectable, inject } from 'inversify';
import ActionResult from './../Common/action-result';
import ITariffProvider from './i-tariff-provider';
import { TariffSymbols } from './symbols';
import { IdentitySymbols } from './../Identity/symbols';
import TariffService from './tariff-service';
import IContext from './../Identity/i-context';
import { ErrorTypes } from '../Common/error-types';
import { MLogger } from '../Global/m-logger';


@injectable()
export default class TariffServiceCached extends TariffService {
    protected _logger: MLogger;
    protected _processed: any = [];
    private _statCached: number = 0;
    private _statError: number = 0;

    public Cache: any = [];

    constructor(@inject(TariffSymbols.TariffProvider) provider: ITariffProvider, @inject(IdentitySymbols.Context) context: IContext) {
        super(provider, context);
        this._logger = new MLogger(0);
    };

    // Override this metod with new stuff
    async GetTariffPrice(date: string, tariffId: number, seanceId?: string): Promise<ActionResult> {
        // Form an ID type of a string to access the _cache array
        let stringId = `${tariffId}_${date}_${(seanceId || '0')}`;
        // Get the price from _cache
        let cachedPrice = this.Cache[`${stringId}`];

        // If there is something, like 100 or 120
        if (cachedPrice !== undefined) {
            this._logger.say(`${stringId} PRICE ${cachedPrice} found in cache`, 'success');
            return ActionResult.SuccessData(cachedPrice);
        } else {
            // If no price has been cached...
            this._logger.say(`${stringId} PRICE NOT CACHED. Requesting...`, 'error');
            // Send a request
            let result = await this._provider.GetTariffPrice(date, tariffId, seanceId);

            if (result.Success) {
               /* if (result.ErrorType == 1) {
                    // 'Тариф {0} недоступен на эту дату'
                    this._logger.say(`${stringId} PRICE is not available (possibly for this date). Marking as null..`, "warning");
                    this.Cache[`${stringId}`] = null;
                };
            } else {*/
                // Cache result data for future use
                this._logger.say(`${stringId} GOT price ${result.Data}. Saving to cache...`, "info");
                this.Cache[`${stringId}`] = result.Data;
            };

            return result;
        };
    };

    // Новые методы
    async GetTariffsAllPrices(id: number, date: string): Promise<ActionResult> {
        // If id & date already cached
        // SHIELD !null
        if (this._processed[`${id}_${date}`] != null) {
            this._logger.say(`${id}_${date} PRICE already cached! Skipping...`, 'warning')
            return ActionResult.Success();
        };

        // Mark as cached
        this._processed[`${id}_${date}`] = true;
        this._logger.say(`GET PRICE - ${id}_${date}`, 'info');

        // Send request
        let result = await this._provider.GetTariffsAllPrices(id, date);

        // SHIELD null
        if (result.Data == null) return ActionResult.FailedData(ErrorTypes.InternalError);

        this._logger.say(`Found ${Object.keys(result.Data).length} seance(s)`, 'message');

        for (var seanceId in result.Data) {
            let price = result.Data[seanceId].Data;     // Цена. Если 0, то скорее всего была ошибка
            let eRes = result.Data[seanceId].Result;    // Результат операции. Если 0 - то всё ок. Если нет - ошибка
            let eMes = result.Data[seanceId].Message;   // Если результат не 0 - то тут будет сообщение об ошибке

            let stringId = `${id}_${date}_${seanceId}`;

            // Если операция прошла успешно
            if (eRes == 0) {
                if (this.Cache[`${stringId}`] !== undefined) {
                    // Если цена уже кэширована
                    this._logger.say(`${stringId} already cached. Skipping`, 'warning');
                } else {
                    // Если цена не сохранена в кэшэ
                    this._logger.say(`PRICE - ${stringId} - ${price}`, 'work');
                    this.Cache[`${stringId}`] = price;
                    this._statCached++;
                }
            } else {
                this._logger.say(`ERROR - ${stringId} - ${eMes}`, 'error');
                this.Cache[`${stringId}`] = null;
                this._statError++;
            };
        };
        this._logger.say(`Массовое кэширование закончено. Успех: ${this._statCached} - Ошибка: ${this._statError}`);

        return ActionResult.Success();
    };
};
