import './wp-error.scss';

import Vue from 'vue';
import { Prop } from "vue/types/options";
import WpPageBaseComponent from './../wp-page-base-component';
import Component, { mixins } from 'vue-class-component';

@Component({  
    template: require("./wp-error.html")
})
export class WpError extends mixins(WpPageBaseComponent, Vue) {

    RequiredReload: boolean = false;
    async mounted() {
        let requiresReloadOnError = this.$route.matched.some(record => record.meta.requiresReloadOnError);
        if (requiresReloadOnError) {
            this.RequiredReload = true;
        }
    }

    async beforeDestroy() {
        if (this.RequiredReload) {
            window.location.reload();
        }
    }

}
export namespace WpError {
    export enum ErrorTypes {
        False = 0,
        Offline = 1,
        OfflineReload = 2,
        Server = 3
    }
}
export default WpError;