import ArenaTariffFreePlaces from './arena-tariff-free-places';
import ArenaTariffOption from './arena-tariff-option';

export default class ArenaTariff {
    Name: string = '';
    TariffCode: number = 0;
    Price: number = 0;
    FreeCount?: number;
    MinPrice: number = 0;
    MaxPrice: number = 0;
    Options?: Array<ArenaTariffOption>;
    FreePlaces?: Array<ArenaTariffFreePlaces>;
}