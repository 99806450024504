import { EcomAddToCart, EcomRemoveFromCart, IEcomData } from './../Metrics/i-ecom-data';
import { CartItemTypes } from './cart-item-types';
import { ActionResult, WpEventHandler, WpEvent, WpEventArgs } from './../Common';
import { createGuid } from './../Utility';
import ReservationItem from './../Sale/reservation-item';

export default abstract class CartItem implements IEcomData {
	private _tempId: string;

	protected _cartItemChanged: WpEventHandler<WpEventArgs>;

	abstract get Info(): string;
	abstract get Type(): CartItemTypes;
	abstract get Price(): number;
	abstract get Count(): number;
	abstract get Dates(): Array<string>;
	abstract get AdditionalInfo(): any;
	abstract get MetricsId(): any | undefined;

	public get TempId(): string {
		return this._tempId;
	}

	public get CartItemChanged(): WpEvent<WpEventArgs> {
		return this._cartItemChanged;
	}

	abstract get IsCanIncreaseCount(): boolean;
	abstract get IsCanReduceCount(): boolean;
	abstract get IsAvailable(): boolean;

	abstract IncreaseCount(): ActionResult;
	abstract ReduceCount(): ActionResult;

	abstract IsConflictItem(item: CartItem): boolean;
	abstract IsEqualsItem(item: CartItem): boolean;

	abstract CombineItem(item: CartItem): ActionResult;

	abstract GetReservationItem(): Array<ReservationItem>;

	abstract RemoveItem(): Promise<ActionResult>;

	abstract GetEcomData(eventId: Symbol): EcomAddToCart | EcomRemoveFromCart | null;

	abstract IsAssociatedReservationItem(item: ReservationItem): boolean;

	constructor() {
		this._tempId = createGuid();
		this._cartItemChanged = new WpEventHandler<WpEventArgs>();
	}
}
