import Vue from 'vue';
import { Prop, DefaultProps } from "vue/types/options";
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { OrderItem, OrderItemType, BaseSystemLocalizationDictionary } from 'web-platform-core-ui';
import { VueConstructor } from 'vue/types/umd';

export const wpPayConfirmItemProps = Vue.extend({
    props: {
        OrderItem: Object as Prop<OrderItem>,
        removable: {
            type: Boolean,
            required: false,
            default: true
        }
    }
})

@Component({
    template: require("./wp-payconfirm-item.html")
})
export default class WpPayConfirmItem extends mixins<WpBaseComponent<WpPayConfirmItemDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, wpPayConfirmItemProps, Vue) {
    Loading: boolean = false;

    get HasInfo(): boolean {
        return (this.OrderItem.Type == OrderItemType.TariffPersonification ||
            this.OrderItem.Type == OrderItemType.TariffBarcodePesonification ||
            this.OrderItem.Type == OrderItemType.TariffRewritable ||
            this.OrderItem.Type == OrderItemType.TariffRewritablePesonification ||
            this.OrderItem.Type == OrderItemType.ArenaTicket)
            && this.OrderItem.AdditionalInfo != undefined && this.OrderItem.AdditionalInfo.Info != undefined;
    }

    Remove() {
        this.Loading = true;
        this.$emit("ItemRemove", this.OrderItem);
    }
}

export class WpPayConfirmItemDictionary extends BaseSystemLocalizationDictionary {
    GenericDecline: string = 'Отказаться';
    GenericTotalSum: string = 'Сумма';
}