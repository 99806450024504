import IArenaProvider from './i-arena-provider';
import { injectable, inject } from 'inversify';
import { ActionResult, ErrorTypes } from './../Common';
import ArenaSeance from './arena-seance';
import GlobalService from './../Global/global-service';
import { RestClient } from 'typed-rest-client';
import ArenaSectorsList from './arena-sectors-list';
import ArenaTariff from './arena-tariff';
import ArenaSeatsList from './arena-seats-list';
import ArenaCartItem from './arena-cart-item';
import { OrderSale } from './..';
import { IdentitySymbols, IContext } from '../Identity';
import { AppSettings } from '../Global/app-settings';

@injectable()
export default class DefaultArenaProvider implements IArenaProvider {
    private _settings: AppSettings;
    private _context: IContext;

    constructor(@inject(IdentitySymbols.Context) context: IContext) {
        this._context = context;
        this._settings = GlobalService.GetSettings();
    }

    get NotAuthorizationHeaders(): any {
        return {
            headers: {
                "SiteId": this._settings.SiteId,
                "SessionId": this._settings.SessionId,
                "User": this._settings.SessionId,
                "moduleId" : GlobalService.GetSettings().SiteId
            }
        }
    }


    get AuthorizationHeaders(): any {
        return {
            headers: {
                "SiteId": this._settings.SiteId,
                "SessionId": this._settings.SessionId,
                "User": this._context.CurrentIdentity.UserId,
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "moduleId" : GlobalService.GetSettings().SiteId
            }
        }
    }

    async GetSeancesAsync(): Promise<ActionResult<Array<ArenaSeance>>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": GlobalService.GetSettings().SiteId,
                "SessionId": GlobalService.GetSettings().SessionId,
                "moduleId" : GlobalService.GetSettings().SiteId
            }
        });

        let response = await client.get<AjaxResult<Array<ArenaSeance>>>(`/websale/api/arena/seances/`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<Array<ArenaSeance>>(response.result?.Data);
    }

    async GetSectorsAsync(seanceId: number): Promise<ActionResult<ArenaSectorsList>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": GlobalService.GetSettings().SiteId,
                "SessionId": GlobalService.GetSettings().SessionId,
                "moduleId" : GlobalService.GetSettings().SiteId
            }
        });

        let response = await client.get<AjaxResult<ArenaSectorsList>>(`/websale/api/arena/${seanceId}/sectors`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<ArenaSectorsList>(response.result?.Data);
    }

    async GetTariffsAsync(seanceId: number): Promise<ActionResult<Array<ArenaTariff>>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SessionId": GlobalService.GetSettings().SessionId,
                "moduleId" : GlobalService.GetSettings().SiteId
            }
        });

        let response = await client.get<AjaxResult<Array<ArenaTariff>>>(`/trfsrv/api/arena/tariffs?seanceid=${seanceId}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<Array<ArenaTariff>>(response.result?.Data);
    }

    async GetSeatsAsync(seanceId: number, sectorCode: number): Promise<ActionResult<ArenaSeatsList>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": GlobalService.GetSettings().SiteId,
                "SessionId": GlobalService.GetSettings().SessionId,
                "moduleId" : GlobalService.GetSettings().SiteId
            }
        });

        let response = await client.get<AjaxResult<ArenaSeatsList>>(`/websale/api/arena/${seanceId}/${sectorCode}/places`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<ArenaSeatsList>(response.result?.Data);
    }

    async UnreservateSeatAsync(itemId: number): Promise<ActionResult> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], this.NotAuthorizationHeaders);

        let response = await client.get<AjaxResult<any>>(`/websale/api/arena/unreservateplace/${itemId}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError);

        return ActionResult.SuccessData();
    }

    async ReservateSeatAsync(arenaCartItem: ArenaCartItem): Promise<ActionResult<OrderSale>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], this.NotAuthorizationHeaders);

        let data = {
            OptionCode: arenaCartItem.Option?.OptionCode,
            PlaceCode: arenaCartItem.Seat.PlaceCode,
            OrderId: arenaCartItem.OrderId,
            SibId: arenaCartItem.SibId
        };        

        let response = await client.create<AjaxResult<OrderSale>>(`/websale/api/arena/${arenaCartItem.Seance.Id}/${arenaCartItem.Sector.SectorCode}/reservateplace`, data);

        if (response.result != null && response.result.Result != 0) {
            if (response.result.Result == 3) {
                return ActionResult.FailedData(ErrorTypes.SeatIsOccupied);
            }

            return ActionResult.FailedData(ErrorTypes.InternalError);
        }

        return ActionResult.SuccessData<OrderSale>(response.result?.Data);
    }

    async CancelOrderAsync(orderId: number): Promise<ActionResult> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], this.NotAuthorizationHeaders);

        let response = await client.get<AjaxResult<any>>(`/websale/api/order/cancel/${orderId}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError);

        return ActionResult.SuccessData();
    }

}

class AjaxResult<T> {
    Result: number = 0;
    Message: string = '';
    Data?: T;
}