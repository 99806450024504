import { ConnectionType, SiteStatus } from './';

export default class Site {
    Id: number = 0;
    Name: string = '';
    Url: string = '';
    Offset: string = '';
    OrganizationId: number = 0;
    OrganizationName: string = '';
    ConnectionId: number = 0;
    ConnectionType: ConnectionType = ConnectionType.Tariff;
    Status: SiteStatus = SiteStatus.Disabled;
    OrganizationStatus: number = 0;
    PlatformHelpDeskAddress?: string;
    SessionId?: string;
}