import Sorting from './sorting';

export default class BaseFilter {
    Page: number = 1;
    PageCount: number = 10;
    Sorting?: Sorting;
    PlatformId?: number;

    constructor(Page: number = 1, PageCount: number = 10, Sorting?: Sorting, PlatformId?: number) {
        this.Page = Page;
        this.PageCount = PageCount;
        if (Sorting) this.Sorting = Sorting;
        if (PlatformId) this.PlatformId = PlatformId;
    };
};