import OrderItemDiscount from './order-item-discount';
import OrderItemAdditionalInfo from './order-item-additional-info';
import { OrderItemType } from './order-item-type';

export default class OrderItem {
    Id: number = 0;
    Code: number = 0;
    Info: string = '';
    ProductId: string = '';
    ProductName: string = '';
    Price: number = 0;
    TotalSum: number = 0;
    AdditionalInfo?: any; // todo
    Type?: OrderItemType;
    BundleId: string = '';
    BundleName: string = '';
    DiscountsList?: Array<OrderItemDiscount>;
    get Discount(): number {
        return this.Price - this.Discount;
    }
}