import Vue, { VueConstructor } from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { BaseSystemLocalizationDictionary } from 'web-platform-core-ui';
import { DefaultProps } from 'vue/types/options';

export const wpPayConfirmItemProps = Vue.extend({
    props: {
        Bundles: Array,
        removable: {
            type: Boolean,
            required: false,
            default: true
        }
    }
})

@Component({
    template: require("./wp-payconfirm-bundle.html")
})
export default class WpPayConfirmBundle extends mixins<WpBaseComponent<WpPayConfirmBundleDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, wpPayConfirmItemProps, Vue) {
    Loading: boolean = false;

    mounted() {
        this.TotalSum = 0;
        this.TotalPrice = 0;
        this.Bundles.forEach((element: any) => {
            this.TotalSum += element.TotalSum;
            this.TotalPrice += element.Price;
        });
    }
    Remove() {
        this.Loading = true;
        this.$emit("ItemRemove", this.Bundles[0]);
    }

    TotalSum: number = 0;
    TotalPrice: number = 0; 


    @Watch('Bundles')
    OnBundlesChanged(bundles: Array<any>, oldBundles: Array<any>) {
        this.TotalSum = 0;
        this.TotalPrice = 0;
        bundles.forEach((element: any) => {
            this.TotalSum += element.TotalSum;
            this.TotalPrice += element.Price;
        });
    }

}

export class WpPayConfirmBundleDictionary extends BaseSystemLocalizationDictionary {
    GenericDecline: string = 'Отказаться';
    GenericTotalSum: string = 'Сумма';
    Personalization: string = 'Персонализация';
}