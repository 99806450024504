import { ILocalizationService, LocalizationSymbols } from '../Localization';
import { ILoyaltyProvider, LoyaltySymbols, IsdUserLoyalty } from '../Loyalty';
import { IDateHandler, DateHandlerSymbols } from '../Utility';
import { INotificationService, NotificationSymbols } from '../Notification';
import DefaultClubService from './../Club/default-club-service';
import { ActionResult, ErrorTypes } from '../Common';
import ClubContract from './../Club/club-contract';
import { injectable, inject } from 'inversify';
import { IClubProvider, ClubSymbols, SoldClubContract } from './../Club';
import { TennisSymbols } from './symbols';

@injectable()
export default class TennisClubService extends DefaultClubService {

    constructor(@inject(ClubSymbols.ClubProvider) clubProvider: IClubProvider,
        @inject(LocalizationSymbols.LocalizationService) localizationService: ILocalizationService,
        @inject(DateHandlerSymbols.DateHandlerService) dateHandler: IDateHandler,
        @inject(LoyaltySymbols.LoyaltyProvider) loyaltyProvider: ILoyaltyProvider,
        @inject(NotificationSymbols.NotificationService) notificationService: INotificationService) {
        super(clubProvider, localizationService, dateHandler, loyaltyProvider, notificationService);
    }

    async GetContractsAsync(): Promise<ActionResult<Array<ClubContract>>> {
        let result = await super.GetContractsAsync();

        if (!result.Success || this._contracts == null) return result;

        await this._localizationService.TranslateManyAsync(Symbol.for("LTContractAccess"), this._contracts);

        return ActionResult.SuccessData(this._contracts);
    }

    async GetStatsAsync(user: IsdUserLoyalty, personContract: SoldClubContract): Promise<ActionResult<any>> {
        const STATS = await this._clubProvider.GetStatsAsync(
            this._settings.ClubSiteId,
            personContract.Person.TNodId,   //user.TNodId,
            user.Id,
            '01.01.2000',
            new Date().toLocaleDateString('ru-RU'),
            personContract.ID!
        );
        if (STATS.Success && STATS.Data != null)
            return ActionResult.SuccessData(STATS.Data);
        return ActionResult.Failed(ErrorTypes.InternalError);
    };
};