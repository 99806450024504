import { ActionResult, ErrorTypes } from './../Common';
import { injectable } from 'inversify';
import GlobalService from './..';
import { RestClient } from 'typed-rest-client';

import IMetricsProvider from './i-metrics-provider';
import MetricCounter from './metric-counter';

@injectable()
export default class MetricsProvider implements IMetricsProvider {

    async GetSitesCountersAsync(): Promise<ActionResult<Array<MetricCounter>>> {
        let settings = GlobalService.GetSettings();

        let client: RestClient = new RestClient('wp-app', settings.ServerUrl, [], {
            headers: {
                "SiteId": settings.SiteId,
                "SessionId": GlobalService.GetSettings().SessionId,
                "moduleId": settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<Array<MetricCounter>>>(`/websale/api/sites/metrics`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError);

        return ActionResult.SuccessData<Array<MetricCounter>>(response.result?.Data);
    }

}

class AjaxResult<T> {
    Result: number = 0;
    Message: string = '';
    Data?: T;
    ErrorType: string = '';
}
