import WpEvent from './wp-event';
import WpEventArgs from './wp-event-args';

export default class WpEventHandler<T extends WpEventArgs> extends WpEvent<T> {
    Send(sender: any, e: T) {
        this._handlers.forEach(async h => {
            try {
                h(sender, e);
            }
            catch{ }
        });
    }
}