import ISibProvider from './i-sib-provider';
import { injectable } from 'inversify';
import { ActionResult, ErrorTypes } from '../Common';
import { RestClient } from 'typed-rest-client';
import GlobalService, { AppSettings } from './..';

@injectable()
export default class DefaultSibProvider implements ISibProvider {
    protected _settings: AppSettings;

    constructor() {
        this._settings = GlobalService.GetSettings();
    }

    async SearchFanIdByPhoneAsync(phone: string): Promise<ActionResult<string>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SessionId": GlobalService.GetSettings().SessionId
            }
        });
        let response = await client.get<AjaxResult>(`/sib/v2/fan/?phone=${encodeURIComponent(phone)}`);

        if (response.result == null || response.result.Result != 0) {
            return ActionResult.Failed(ErrorTypes.NotFoundData, response.result?.Message);
        }

        return ActionResult.SuccessData(response.result.Data);
    }
}

class AjaxResult {
    Result: number = 0;
    Message: string = '';
    Data: string = '';
}