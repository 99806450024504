import { Brand, EcomAddToCart, EcomBaseProducts, EcomProduct, EcomRemoveFromCart } from './../Metrics/i-ecom-data';
import CartItem from '../Cart/cart-item';
import TariffsBundle from './tariffs-bundle';
import { CartItemTypes } from './../Cart/cart-item-types';
import { ActionResult, ErrorTypes, WpEventArgs } from './../Common';
import { ReservationItemTypes, ReservationItem } from './../Sale';
import * as moment from 'moment';
import { TariffSeance, TariffSale } from '.';
import Tariff, { RentalOption } from './tariff';
import TariffCapacity from './tariff-capacity';


export default class BundleCartItem extends CartItem {

    private _date: string = "";
    private _bundle: TariffsBundle;
    private _price: number = 0;
    private _type: CartItemTypes = CartItemTypes.Bundle;
    private _seance?: TariffSeance;
    private _sales: Array<TariffSale> = [];
    private _capacity: Array<TariffCapacity | undefined> = [];
    private _carNumbers?: Array<string>;
    private _rentalOption?: string[];

    get IsAvailable(): boolean {
        let notValidSale = this._sales.some(x => x.Quota != null && x.LeftCount != null && x.Count + x.ReservedCount < x.Quota);

        let notValidCapacity = this._capacity.some(x => x != null && x.Count + x.ReservedCount < x.Capacity);

        return !notValidSale && !notValidCapacity;
    }
    public get Info(): string {
        return `<strong>${this._bundle.Name}</strong>, ${this._date} ${this._seance != null ? TariffSeance.SeanceTime(this._seance) : ''}`;
    }
    public get Type(): CartItemTypes {
        return this._type;
    }
    public get Price(): number {
        return this._price;
    }
    public get Sales(): Array<TariffSale> {
        return this._sales;
    }
    public get Capacity(): Array<TariffCapacity | undefined> {
        return this._capacity;
    }
    get MetricsId(): any | undefined {
        return this._bundle?.Id;
    }
    /*
    public get Count(): number {
        return 1;
    }
    */
    public get IsCanIncreaseCount(): boolean {
        return false;
    }
    public get IsCanReduceCount(): boolean {
        return false;
    }
    public get Bundle(): TariffsBundle {
        return this._bundle;
    }
    public get Seance(): TariffSeance | undefined {
        return this._seance;
    }
    public get Date(): string {
        return this._date;
    }
    public get Count(): number {
        return this._bundle.Tariffs.length;
    }
    public get Dates(): Array<string> {
        let result = new Array<string>();

        if (this.Date !== undefined) {
            result.push(moment.parseZone(this.Date, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY'));
        }

        return result;
    }
    public get AdditionalInfo(): any {
        return this._bundle;
    }

    constructor(
        bundle: TariffsBundle,
        date: string,
        price: number,
        sales: Array<TariffSale>,
        capacity: Array<TariffCapacity | undefined>,
        seance?: TariffSeance,
        carNumbers?: Array<string>,
        rentalOption?: string[]
    ) {
        super();

        this._date = date;
        this._bundle = bundle;
        this._price = price;
        this._seance = seance;
        this._sales = sales;
        this._capacity = capacity;
        this._carNumbers = carNumbers;
        this._rentalOption = rentalOption;

        this._capacity.forEach(x => {
            if (x != null) {
                x.ReservedCount++;
            }
        });

        this._sales.forEach(x => {
            if (x.Quota != null) {
                x.ReservedCount++;
            }
        });
    }

    public IncreaseCount(): ActionResult {
        return ActionResult.Failed(ErrorTypes.CartError, "Превышено допустимое количество для данного типа услуг");
    }

    public ReduceCount(): ActionResult {
        return ActionResult.Failed(ErrorTypes.CartError, "Достигнуто минимальное возможное количество");
    }

    public IsConflictItem(item: CartItem): boolean {
        return false;
    }

    public IsEqualsItem(item: CartItem): boolean {
        return false;
    }

    public CombineItem(item: CartItem): ActionResult {
        return ActionResult.Failed(ErrorTypes.CartError, "Превышено допустимое количество для данного типа услуг");
    }

    public GetReservationItem(): Array<ReservationItem> {
        let rentalOptions = '';

        if (this._rentalOption != null && this._rentalOption.length > 0) {            
            this._rentalOption.forEach(x => rentalOptions += `${x};`);
        }

        let item = new ReservationItem(
            1,
            ReservationItemTypes.Bundle,
            null,
            this._date,
            this._bundle.Id,
            null,
            null,
            null,
            this._seance?.SeanceId,
            null,
            null,
            null,
            null,
            null,
            null,
            this._carNumbers,
            rentalOptions
        );
        return [item];
    }

    async RemoveItem(): Promise<ActionResult> {
        this._sales.forEach(x => {
            if (x.Quota != null) x.ReservedCount--;
        })

        this._capacity.forEach(x => {
            if (x != undefined) x.ReservedCount--;
        })

        return ActionResult.Success();
    }

    IsAssociatedReservationItem(item: ReservationItem): boolean{
        return item.ItemType == ReservationItemTypes.Bundle &&
               item.Count == 1 &&
               this._bundle.Id == item.BundleId &&
               this._date == item.Date;
    }

    GetEcomData(eventId: Symbol): EcomAddToCart | EcomRemoveFromCart  {
        let removeResult = new EcomRemoveFromCart();
        let result = new EcomAddToCart();	
        result.add = new EcomBaseProducts();
        removeResult.remove = new EcomBaseProducts();

        if(eventId == Symbol.for('incart_plus') || eventId == Symbol.for('service_cart_add')){
            for (let i = 0; i < this.Bundle.Tariffs.length; i++) {
                result?.add?.products.push({
                    id: this.Bundle.Tariffs[i].TariffCode.toString(),
                    name: this.Bundle.Tariffs[i].Name,
                    list: this.Bundle.Tariffs[i].GroupName,
                    brand: Brand.ISDS,
                    quantity: 1,
                    variant: EcomProduct.GetProductVariant(this._seance, this.Bundle.Tariffs[i].OptionName),
                    price: this._price
                });
            };
        } else if(eventId == Symbol.for('incart_minus') || eventId == Symbol.for('incart_remove')){
            for (let i = 0; i < this.Bundle.Tariffs.length; i++) {
                removeResult?.remove?.products.push({
                    id: this.Bundle.Tariffs[i].TariffCode.toString(),
                    name: this.Bundle.Tariffs[i].Name,
                    list: this.Bundle.Tariffs[i].GroupName,
                    brand: Brand.ISDS,
                    quantity: 1,
                    variant: EcomProduct.GetProductVariant(this._seance, this.Bundle.Tariffs[i].OptionName)
                });
            };

            return removeResult;
        }

        return result;
    }
}