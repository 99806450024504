import MarketBill from './market-bill';
import { AccessToken, IIdentity } from '../Identity';
import MarketBillInitializeResult from './market-bill-initialize-result';

export default class MarketIdentity implements IIdentity {
    private _userId: string;
    private _accessToken: string;
    private _userBill: MarketBill;

    IsInitialized: boolean = false;

    get AccessToken(): AccessToken {
        let date = new Date(30);

        return new AccessToken(this._accessToken, date);
    }
    get IsAuthentificated(): boolean {
        return true;
    }
    get Username(): string {
        return this._userBill.Email[0];
    }
    get UserId(): string {
        return this._userId;
    }
    get Phone() {
        return this._userBill != null && this._userBill?.Phone != null && this._userBill?.Phone.length > 0 ? this._userBill?.Phone[0] : undefined;
    }

    get Email() {
        return this._userBill.Email[0];
    }

    constructor(result: MarketBillInitializeResult) {
        this._accessToken = result.Token;
        this._userId = result.UserId;
        this._userBill = result.Bill;
    }
}