import { PaymentSystemType } from './payment-system-type';

export default class LockOrderInfo {
    Id: number = 0;
    Email?: string;
    UserPhone: string = '';
    UserFirstName: string = '';
    UserLastName: string = '';
    UserSecondName: string = '';
    Comments: string = '';
    PaymentId: number = 0;
    PaymentType: PaymentSystemType = PaymentSystemType.Test;
}