import { WpEventHandler, WpEvent } from '../Common';

import WpNotification from './wp-notification';
import { NotificationTypes } from './notification-types';
import INotificationService from './i-notification-service';
import NotificationEventArgs from './notification-event-args';
import { injectable } from 'inversify';

@injectable()
export default class DefaultNotificationService implements INotificationService {
    private _notificationSended: WpEventHandler<NotificationEventArgs> =
        new WpEventHandler<NotificationEventArgs>();

    get NotificationSended(): WpEvent<NotificationEventArgs> {
        return this._notificationSended;
    }

    Success(title: string, message: string) : void {
        let notification = new WpNotification();

        notification.Title = title;
        notification.Message = message;
        notification.Type = NotificationTypes.Success;
        let args = new NotificationEventArgs(notification);

        this._notificationSended.Send(this, args);
    }
    Error(title: string, message: string): void {
        let notification = new WpNotification();

        notification.Title = title;
        notification.Message = message;
        notification.Type = NotificationTypes.Error;
        let args = new NotificationEventArgs(notification);

        this._notificationSended.Send(this, args);
    }

    SendSystemNotification<T>(processKey: string, data?: T): void {
        let notification = new WpNotification<T>();
        
        notification.AdditionalData = data;
        notification.SystemProcessKey = processKey;
        notification.Type = NotificationTypes.System;
        let args = new NotificationEventArgs(notification);
        
        this._notificationSended.Send(this, args);
    }
}