import { ReservationItemTypes } from './reservation-item-types';

export default class ReservationItem {
    public TariffId: number;
    public Count: number;
    public Date: string;
    public BundleId: number;
    public LessonId: number;
    public SeanceId?: string | null;
    public ItemType: ReservationItemTypes;
    public MediaNum: string;
    public LessonsBundleId: string;
    public MarketBillId: number;
    public LessonCoachId?: number | null;
    public LessonLocationId?: number | null;
    public ClubContractId?: number | null;
    public PersonContractId?: number | null;
    public ReplenishmentSum?: number | null;
    public CarNumbers?: Array<string> | null;
    public RentalOptionId?: string;
    public MediaType?: number | null;

    constructor(
        count: number,
        itemType: ReservationItemTypes,
        tariffId?: number | null,
        date?: string | null,
        bundleId?: number | null,
        lessonId?: number | null,
        mediaNum?: string | null,
        lessonsBundleId?: string | null,
        seanceId?: string | null,
        marketBillId: number | null | undefined = undefined,
        lessonCoachId: number | null | undefined = undefined,
        lessonLocationId: number | null | undefined = undefined,
        contractId: number | null | undefined = undefined,
        personContractId: number | null | undefined = undefined,
        replenishmentSum: number | null | undefined = undefined,
        carNumbers: Array<string> | null | undefined = undefined,
        rentalOptionId?: string,
        mediaType: number | null | undefined = undefined
    ) {
        this.Count = count;
        this.ItemType = itemType;
        this.TariffId = tariffId != null ? tariffId : 0;
        this.Date = date == null ? '' : date;
        this.BundleId = bundleId != null ? bundleId : 0;
        this.LessonId = lessonId != null ? lessonId : 0;
        this.MediaNum = mediaNum != null ? mediaNum : '';
        this.LessonsBundleId = lessonsBundleId != null ? lessonsBundleId : '';
        this.SeanceId = seanceId;
        this.MarketBillId = marketBillId != null ? marketBillId : 0;
        this.LessonCoachId = lessonCoachId;
        this.LessonLocationId = lessonLocationId;
        this.ClubContractId = contractId;
        this.PersonContractId = personContractId;
        this.ReplenishmentSum = replenishmentSum;
        this.CarNumbers = carNumbers;
        this.RentalOptionId = rentalOptionId;
        this.MediaType = mediaType;
    }
}