import { injectable, inject } from 'inversify';

import IDataProvider from './i-data-provider';
import { BaseFilter, FiltringResult, ActionResult } from './../Common';
import { LocalizationSymbols, ILocalizationService } from './../Localization';
import ICmsService from './i-cms-service';
import { CmsSymbols } from './symbols';

@injectable()
export default class CmsService implements ICmsService {
	private _provider: IDataProvider;
	private _localizationService: ILocalizationService;
	private _cache = new Map<string, ActionResult<FiltringResult<any>>>();

	constructor(
		@inject(CmsSymbols.DataProvider) provider: IDataProvider,
		@inject(LocalizationSymbols.LocalizationService) localizationService: ILocalizationService
	) {
		this._provider = provider;
		this._localizationService = localizationService;
	}

	async GetEntitiesAsync<T>(prefix: symbol, filter: BaseFilter, query: string): Promise<ActionResult<FiltringResult<T>>> {
		let key = `${prefix.toString()}_${JSON.stringify(filter)}_${query}`;
		let item = this._cache.get(key);
		if (item) return item;
		let result = await this._provider.GetEntitiesAsync<T>(prefix, filter, query, this._localizationService.CurrentLocalization);
		this._cache.set(key, result);
		return result;
	}
	async FindEntityAsync<T>(prefix: symbol, id: number): Promise<ActionResult<T>> {
		let result = await this._provider.FindEntityAsync<T>(prefix, id, this._localizationService.CurrentLocalization);

		return result;
	}
}
