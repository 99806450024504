import { Prop } from 'vue-property-decorator';
import WpBaseComponent from './../../../wp-base-component';
import Component from 'vue-class-component';
import GlobalService, { AppSettings, CustomSeanceTitle, IMetricsService, MetricsSymbols, TariffSeance } from 'web-platform-core-ui';

@Component({
	template: require('./wp-tariffs-filter-seance.html'),
})
export default class WpTariffsFilterSeance extends WpBaseComponent {
	@Prop()
	seances?: Array<TariffSeance>;
	@Prop()
	date?: string;

	protected _metricsService!: IMetricsService;

	settings!: AppSettings;
	customSeanceTitles: CustomSeanceTitle[] = [];
	SelectedSeance?: TariffSeance;

	created() {
		this.settings = GlobalService.GetSettings<AppSettings>();
		this.customSeanceTitles = this.settings.CustomSeanceTitles;
		// Сервис по работе с метрикой
		this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
	}

	SeanceTime(seance: TariffSeance): string | undefined {
		return TariffSeance.SeanceTime(seance);
	}

	IsSeanceOver(seance: TariffSeance) {
		let selectedDate = this.DateHandler.Parse(this.date, 'DD.MM.YYYY').ParseToDate();
		let set = seance.SeanceValue.split('-');
		let startSet = set[1].split(':');
		let hours = parseInt(startSet[0]);
		let minutes = parseInt(startSet[1]);
		let offset = parseInt(this.settings.CurrentSite!.Offset.split(':')[0]);
		let now = new Date();
		now.setHours(new Date().getUTCHours() + offset);
		let compareDate = new Date(selectedDate);
		compareDate.setHours(hours);
		compareDate.setMinutes(minutes);
		return compareDate < now;
	}

	SelectSeance(seance: TariffSeance) {
		this.$emit('SelectSeance', seance);
	}

	// https://redmine.moipass.ru/issues/12916
	customSeanceTitleAvailable(seance: TariffSeance) {
		if (!this.date) return false;
		let day = new Date(this.date.split('.').reverse().join('/')).getDay();
		let find = this.customSeanceTitles.find((s) => s.seanceId === parseInt(seance.SeanceId));
		if (find) {
			if (Array.isArray(find.dates) && find.dates.indexOf(this.date) != -1) {
				return true;
			}
			if (Array.isArray(find.days) && find.days.indexOf(day) != -1) {
				return true;
			}
			return false;
		} else {
			return false;
		}
	}
	customSeanceTitle(seance: TariffSeance) {
		let find = this.customSeanceTitles.find((s) => s.seanceId === parseInt(seance.SeanceId));
		if (find) {
			return find.text.replace('script', '');
		}
		return false;
	}
	customSeanceColor(seance: TariffSeance) {
		let find = this.customSeanceTitles.find((s) => s.seanceId === parseInt(seance.SeanceId));
		if (find && find.color) {
			return { color: find.color };
		}
		return null;
	}
}
