import './wp-cart.scss';

import Vue, { VueConstructor } from 'vue';
import { Prop, DefaultProps } from 'vue/types/options';
import WpBaseComponent from './../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
	Cart,
	CartItem,
	IMetricsService,
	MetricsSymbols,
	BaseSystemLocalizationDictionary,
	INotificationService,
	NotificationSymbols,
	AppSettings,
TariffCartItem,
CartItemTypes,
} from 'web-platform-core-ui';
import { formatPrice } from 'web-platform-core-ui/src/Utility/format';
import { MetricsEvents } from '../../metrics-events-symbols';

export const wpCartProps = Vue.extend({
	props: {
		Cart: Object as Prop<Cart>,
		Type: Number,
	},
});

@Component({
	template: require('./wp-cart.html'),
})
export default class WpCart extends mixins<WpBaseComponent<WpCartDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, wpCartProps, Vue) {
	Settings!: AppSettings;
	// Enable notifications/sygnals to send to other parts of the program
	protected _notificationService!: INotificationService;
	UILoading = false;

	_formatPrice = formatPrice;
	_metricsService!: IMetricsService;

	CartModal: boolean = false;

	async created() {
		this.Settings = GlobalService.GetSettings<AppSettings>();
		this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
		// Get notification service up and ready
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
	}

	async mounted() {
		this.$parent.$on('ExternalCart', this.OpenCart);
	}

	get Items(): Array<CartItem> {
		this._notificationService.SendSystemNotification('CartContent', this.Cart.Items);
		return this.Cart.Items;
	}

	OpenCart(): void {
		this.CartModal = true;
		this._metricsService.RegistrateEvent(MetricsEvents.WidgetOpenCart);
	}

	Pay(): void {
		this.CartModal = false;
		this._metricsService.RegistrateEvent(MetricsEvents.IncartToPurchase);
		this.$emit('CartReservation');
	}

	RemoveItem(itemId: string): void {
		let cartItem = this.Cart.Items.find((x: { TempId: string }) => x.TempId == itemId);

		this.Cart.RemoveItem(itemId);
		this.$emit('CartHaveItems', this.Cart.Items.length > 0);

		this._metricsService.RegistrateEvent(MetricsEvents.IncartRemove, cartItem?.MetricsId, cartItem);
	}

	async ClearCart(): Promise<void> {
		this.UILoading = true;
		this._metricsService.RegistrateEvent(MetricsEvents.IncartCancel, null, this.Cart);
		// This method clears cart's content
		await this.Cart.Clear();
		this.$emit('CartHaveItems', this.Cart.Items.length > 0);
		// Close cart after clearing it (Optional)
		this.CloseCart();
		this.UILoading = false;
	}

	CloseCart() {
		this.CartModal = false;
		this._metricsService.RegistrateEvent(MetricsEvents.WidgeCloseCart);
	}

	get isBtnCheckoutDisabled() {
		const TOTALCOUNT = this.Items.map((i) => i.Count).reduce((a, b) => a + b, 0);
		const MINCART = Math.max(this.Items.length, TOTALCOUNT) < this.Settings.CartMinItems;
		return this.isEmpty || this.UILoading || MINCART;
	}

	get isEmpty() {
		return this.Items.length === 0;
	}
}

export class WpCartDictionary extends BaseSystemLocalizationDictionary {
	GenericCart: string = '';
	GenericClearCart: string = '';
	GenericTotal: string = '';
	GenericCheckout: string = '';
}
