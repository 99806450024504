export default class TariffSale {
    TariffId: number = 0;
    Date: string = '';
    Count: number = 0;
    SeanceId?: string;
    Quota?: number;
    ReservedCount: number = 0;

    get LeftCount(): number | undefined {
        if (this.Quota != null) {
            let result = (this.Quota - this.Count) - this.ReservedCount;
            return result < 0 ? 0 : result;
        }

        return undefined;
    }
}