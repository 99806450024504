/* Компонент загрузки взвешиваний и заплывов
 * http://redmine.isd.su/issues/6922
 */
import Vue from 'vue';

import Component, { mixins } from 'vue-class-component';
import  WpPageBaseComponent from './../../wp-page-base-component';
import { ActionResult, OrderServiceSymbols, LuzhnikiOrderService, ErrorTypes, INotificationService, NotificationSymbols } from 'web-platform-core-ui';
import { isNumber } from 'util';
import { Ref } from 'vue-property-decorator';

@Component({
    template: require("./luzh-bio.html")
})
export default class LuzhBio extends mixins(WpPageBaseComponent, Vue) {
    // Protected is accesible from within the class and extending classes
    protected _userData!: UserData;
    protected ErrorNoData: boolean = false;
    // Private are only accesible from within the class
    private _service!: LuzhnikiOrderService;
    _notificationService!: INotificationService;

    _bioRequested!: boolean;

    DataTableItems: any = [];
    LastKnownHeight?: number = 0;

    // UI data
    Loading: boolean = true;
    BioLoading: boolean = false;
    UpdatingHeight: boolean = false;
    HeightValid: boolean = false;
    Height: string = '';

    @Ref('HeightForm') HeightForm!: HTMLFormElement;

    get HeightRules() {
        return [
            (v: string) => v !== '' || 'Поле нельзя оставить пустым',
            (v: string) => (v && /^[0-9]*?$/.test(v.trim())) || 'Неверный формат',
            (v: string) => (v && Number.parseInt(v) >= 40 && Number.parseInt(v) <= 260) || 'Допустимые значения от 40 до 260'
        ];
    }

    Genders: Array<string> = [
        '',
        'Мужской', // ♂
        'Женский' // ♀
    ];

    ActivityTypes: Array<any> = [
        { text: 'Взвешивание', value: 1 },
        { text: 'Заплывы', value: 2 },
    ];

    ActivityType: number = 1;

    DateFrom: string = '';
    DateFromFormatted: string = '';
    DateFromMenu: boolean = false;
    DateTo: string = '';
    DateToFormatted: string = '';
    DateToMenu: boolean = false;
    DateYearAgo!: string;
    DateYearFromNow!: string;

    DataTableHeaders!: Array<any>;

    DataTableHeadersModelWeight: Array<any> = [
        {
            text: 'Дата',
            align: 'center',
            sortable: false,
            value: 'formattedDate',
        }, {
            text: 'Вес (кг)',
            align: 'center',
            sortable: false,
            value: 'weight'
        }, {
            text: 'Жир (%)',
            align: 'center',
            sortable: false,
            value: 'fat_percent'
        }, {
            text: 'Жир (кг)',
            align: 'center',
            sortable: false,
            value: 'fat_kg'
        }, {
            text: 'Вода (литры)',
            align: 'center',
            sortable: false,
            value: 'water'
        }, {
            text: 'Скелетная мускулатура (кг)',
            align: 'center',
            sortable: false,
            value: 'smm'
        }, {
            text: 'Баланс жидкости',
            align: 'center',
            sortable: false,
            value: 'icw'
        }, {
            text: 'Индекс массы тела',
            align: 'center',
            sortable: false,
            value: 'bmi'
        }, {
            text: 'Белок (кг)',
            align: 'center',
            sortable: false,
            value: 'protein'
        }, {
            text: 'Минералы (кг)',
            align: 'center',
            sortable: false,
            value: 'mineral'
        }, {
            text: 'Базальный метоболизм (ккал)',
            align: 'center',
            sortable: false,
            value: 'bmr'
        }, {
            text: 'Жир, окружающий внутренние органы брюшной полости (см²)',
            align: 'center',
            sortable: false,
            value: 'vfa'
        }, {
            text: 'Оценка (от 1 до 100)',
            align: 'center',
            sortable: false,
            value: 'tot_score'
        }
    ];

    DataTableHeadersModelSwim: Array<any> = [
        {
            text: 'Дата',
            align: 'center',
            sortable: false,
            value: 'formattedDate'
        }, {
            text: 'Начало',
            align: 'center',
            sortable: false,
            value: 'formattedStarted'
        }, {
            text: 'Финиш',
            align: 'center',
            sortable: false,
            value: 'formattedFinished'
        }, {
            text: 'Длительность',
            align: 'center',
            sortable: false,
            value: 'difference'
        }, {
            text: 'Дистанция',
            align: 'center',
            sortable: false,
            value: 'distance'
        }
    ];

    created(): void {
        this._bioRequested = false;
        this._service = this.GlobalService.Get<LuzhnikiOrderService>(OrderServiceSymbols.OrderService);
        this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);

        // Calculate the date year from now
        this.DateYearAgo = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString();
        this.DateYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString();

        this.$watch("DateFrom", function () {
            this.DateFromFormatted = this.DateFrom.split('-').reverse().join('.');
        });

        this.$watch("DateTo", function () {
            this.DateToFormatted = this.DateTo.split('-').reverse().join('.');
        });
    };

    async mounted(): Promise<void> {
        await this.GetData();
        if (this._userData && this._userData.Height) this.Height = `${this._userData.Height}`;
        this.Loading = false;
    };

    Increment() {
        if (this._userData!.Height && this._userData!.Height < 260)
            this._userData!.Height++; 

        if (!this._userData!.Height)
            this._userData!.Height = 51;
    };

    Decrement() {
        if (this._userData!.Height && this._userData!.Height < 50)
            this._userData!.Height--; 

        if (!this._userData!.Height)
            this._userData!.Height = 50;
    }

    async HeighFormSubmit(e: any) {
        e.preventDefault();
        await this.UpdateHeight();
    }



    async GetData(): Promise<ActionResult> {
        let that = this;
        this.Loading = true;
        this.ErrorNoData = false;
        let userData = await this._service.GetData();

        setTimeout(function () {
            that.Loading = false;
        }, 1000);

        if (userData.Success) {
            this._userData = userData.Data!;
            return ActionResult.Success();
        } else {
            this.ErrorNoData = true;
            return ActionResult.Failed(ErrorTypes.InternalError);
        };
    };

    async UpdateHeight(): Promise<void> {
        if (!this.HeightForm.validate())
            return;


        let newHeight = Number.parseInt(this.Height.trim());
        // Если последний изветный рост отличается от того, что в поле
        if (newHeight != this._userData!.Height) {
            this._userData.Height = newHeight;
            this.UpdatingHeight = true;
            let result = await this._service.UpdateHeight(this._userData.Height || 0);
            if (result.Success) {
                this._notificationService.Success("Успех", 'Рост успеншо обновлён');
            } else {
                this._notificationService.Error('Ошибка', 'Не удалось обновить рост');
            }
        }

        this.UpdatingHeight = false;
    };

    async GetBio(): Promise<ActionResult> {
        this._bioRequested = true;
        this.BioLoading = true;
        if (this.DateToFormatted) this.DateToFormatted;
        let result = await this._service.GetBio(this.ActivityType, this.DateFromFormatted, this.DateToFormatted ? this.DateToFormatted + " 23:59" : "");

        if (this.ActivityType == 1) {
            // Change Data Table headers accordingly
            this.DataTableHeaders = this.DataTableHeadersModelWeight;
            let WeightDataArray: Array<WeightData> = [];

            result.Data.Data!.forEach((r: any) => {
                WeightDataArray.push(new WeightData(r.Date, r.Data));
            });

            this.DataTableItems = WeightDataArray;

        } else {
            // Change Data Table headers accordingly
            this.DataTableHeaders = this.DataTableHeadersModelSwim;
            let SwimDataArray: Array<SwimData> = [];

            result.Data.Data!.forEach((r: any) => {
                SwimDataArray.push(new SwimData(r.Date, r.Data.started, r.Data.finished, r.Data.distance));
            });

            this.DataTableItems = SwimDataArray;
        };

        this.BioLoading = false;

        return ActionResult.Success();
    };
};

class UserData {
    Gender!: number;
    FullYears!: number;
    Height?: number;
};

class WeightData {
    protected _data: JSON;
    date: Date;
    formattedDate: string;

    fat_percent!: number;   //процент жира, %
    fat_kg!: number;        //количество жира в кг
    weight!: number;        //вес, КГ
    water!: number;         //количество воды, литры
    smm!: number;           //скелетная мускулатура, кг
    icw!: number;           //баланс жидкости в организме, кг
    bmi!: number;           //ИМТ
    protein!: number;       //количество белка (кг)
    mineral!: number;       //количество минералов (кг)
    bmr!: number;           //базальный метоболизм, ккал
    vfa!: number;           //показатель жира, окружающего внутренние органы в брюшной полости, кг
    tot_score!: number;     //общая оценка, от 1 до 100

    constructor(date: string, data: any) {
        this._data = data;
        this.date = new Date(date.replace("Z", ""));

        this.formattedDate = this.date.toLocaleTimeString('ru-RU') + " " + this.date.toLocaleDateString('ru-RU');

        this.bmi = data.bmi.value;
        this.bmr = data.bmr.value;
        this.fat_kg = data.fat_kg.value;
        this.fat_percent = data.fat_percent.value;
        this.icw = data.icw.value;
        this.mineral = data.mineral.value;
        this.protein = data.protein.value;
        this.smm = data.smm.value;
        this.tot_score = data.tot_score.value;
        this.vfa = data.vfa.value;
        this.water = data.water.value;
        this.weight = data.weight.value;
    };
};

class SwimData {
    // Data that comes from the server
    started!: Date;                 //время начало заплыва
    finished!: Date;                //время окончания
    distance?: number | string;     //пройденное расстояние
    rounds?: Array<any>;            //массив времен на каждый круг.
    round_started?: string;
    changed?: string;
    state?: number;

    // Calculated fields
    difference: any = 0;
    formattedDate: string;
    formattedStarted: string;
    formattedFinished: string;

    date!: Date;

    constructor(date: string, started: string, finished: string, distance?: number, rounds?: Array<any>) {
        // Удалить Z - индикатор того, что дату надо создавать с учётом зоны, иначе прибавится 3 часа если время Московское
        this.date = new Date(date.replace("Z", ""));
        this.started = new Date(started.replace("Z", ""));
        this.finished = new Date(finished.replace("Z", ""));

        this.distance = distance || "-";
        this.rounds = rounds || [];

        this.formattedDate = this.started.toLocaleDateString('ru-RU');
        this.formattedStarted = this.started.toLocaleTimeString('ru-RU');
        this.formattedFinished = this.finished.toLocaleTimeString('ru-RU');

        // Calculating difference
        let miliseconds = this.finished.getTime() - this.started.getTime();
        let seconds = Math.floor(miliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        // Get time difference in H:m:s format
        this.difference = hours + ":" + (minutes - hours * 60) + ":" + (seconds - minutes * 60);
    };
};