import ISDLoyaltyQuestionnaire from './isd-loyalty-questionnaire';

export default class IsdLoyaltyProgram {
    OrganizationId: number = 0;
    SavePhone: boolean = false;
    LoyaltyProgramId: number = 0;
    RuleAddress: string = '';
    ProgramName: string = '';
    EnableRegisterNewUser: boolean = false;
    Questionnaire?: ISDLoyaltyQuestionnaire;
}