import { injectable, inject, id } from 'inversify';

import PhoneConfirmation from './phone-confirm-code';
import UserPhoneConfirmBan from './user-phone-confirm-ban';
import IPhoneConfirmationProvider from './i-phone-confirmation-provider';

import { PhoneConfirmationSymbols } from './symbols';

import { ActionResult, FiltringResult } from './../Common';
import { IPhoneConfirmationService, PhoneConfirmCode, } from './';
import { IIdentity, IdentitySymbols, IContext } from '../Identity';
import { IProfileProvider, ProfileSymbols } from '../Profile';


@injectable()
export default class PhoneConfirmationService implements IPhoneConfirmationService {
    private readonly _provider: IPhoneConfirmationProvider;
    private readonly _context: IContext;
    private readonly _profileProvider: IProfileProvider;

    constructor(@inject(PhoneConfirmationSymbols.PhoneConfirmationProvider) provider: IPhoneConfirmationProvider,
        @inject(IdentitySymbols.Context) context: IContext,
        @inject(ProfileSymbols.ProfileProvider) profileProvider: IProfileProvider) {

        this._provider = provider;
        this._context = context;
        this._profileProvider = profileProvider;
    }

    async GetCurrentBanAsync(): Promise<ActionResult<UserPhoneConfirmBan>> {
        return await this._provider.GetCurrentBanAsync();
    }
    async GetCurrentCodeAsync(): Promise<ActionResult<PhoneConfirmCode>> {
        return await this._provider.GetCurrentCodeAsync();
    }
    async SendCodeAsync(phone: string): Promise<ActionResult<PhoneConfirmCode>> {
        return await this._provider.SendCodeAsync(phone);
    }
    async ConfirmCodeAsync(code: string): Promise<ActionResult<PhoneConfirmCode>> {
        let confirmResult = await this._provider.ConfirmCodeAsync(code);

        if (confirmResult.Success && this._context.CurrentIdentity != null &&
            this._context.CurrentIdentity.IsAuthentificated && confirmResult.Data != null) {
            await this._profileProvider.SetPhoneAsync(confirmResult.Data.Phone);
            this._context.CurrentIdentity.Phone = confirmResult.Data.Phone;
        }

        return confirmResult;
    }
    async CodeLeftTimeAsync(codeId: number): Promise<ActionResult<number>> {
        return await this._provider.CodeLeftTimeAsync(codeId);
    }
    async CancelCodeAsync(codeId: number): Promise<ActionResult<number>> {
        return await this._provider.CancelCodeAsync(codeId);
    }
}