import MarketBase from './market-base';
import MarketTariffService  from './market-tariff-service';

export default class MarketRule extends MarketBase {
    Quantity: Array<number> = [];
    Price: Array<number> = [];
    Service: Array<MarketTariffService> = [];
    Duration: Array<string> = [];
    State: Array<number> = [];
    Name: string = "";
    IsProduct: boolean = false;
    ClientType: Array<number> = [];
}