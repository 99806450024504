import { ActionResult, ErrorTypes } from './../Common';
import { injectable, inject } from 'inversify';
import {
    IdentitySymbols, IContext
} from './../Identity';
import { RestClient } from 'typed-rest-client';
import SaleProvider from './sale-provider';
import OrderSale from './order-sale';
import ReservationData from './reservation-data';

@injectable()
export default class BenefitSaleProvider extends SaleProvider {

    constructor(@inject(IdentitySymbols.Context) context: IContext) {
        super(context)
    }

    get NotAuthorizationHeaders(): any {
        return {
            headers: {
                "SiteId": this._settings.SiteId,
                "SessionId": this._settings.SessionId,
                "User": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        }
    }

    get AuthorizationHeaders(): any {
        return {
            headers: {
                "SiteId": this._settings.SiteId,
                "SessionId": this._settings.SessionId,
                "User": this._context.CurrentIdentity.UserId,
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "moduleId": this._settings.SiteId
            }
        }
    }

    async ReservateAsync(data: ReservationData): Promise<ActionResult<OrderSale>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [],
            this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders);

        let response = await client.create<AjaxResult<OrderSale>>(`/free/reservate`, data);

        if (response.result != null && response.result.Result != 0) {
            if (response.result.Result == 6)
                return ActionResult.Failed(ErrorTypes.TariffNotAvailableForSale);

            if (response.result.Result == 7)
                return ActionResult.Failed(ErrorTypes.QuotaExceededForTariff);

            if (response.result.ErrorType == "MediaCardExistSoTypeTariff")
                return ActionResult.Failed(ErrorTypes.CardWithSoTypeTariff);

            if (response.result.ErrorType == "RewriteTariffNotAvailable")
                return ActionResult.Failed(ErrorTypes.RewriteTariffNotAvailable);

            if (response.result.ErrorType == "InstructorsLessonBlocked" || response.result.ErrorType == "InstructorsLessonNotAvailable")
                return ActionResult.Failed(ErrorTypes.LessonNotAvailable);

            return ActionResult.FailedData(ErrorTypes.InternalError);
        }

        return ActionResult.SuccessData<OrderSale>(response.result?.Data);
    }
}


class AjaxResult<T> {
    Result: number = 0;
    Message: string = '';
    Data?: T;
    ErrorType: string = '';
}


