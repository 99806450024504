import { WpEventArgs } from './../Common'
import { IIdentity } from './'

export default class IdentityChangedEventArgs extends WpEventArgs {
    Identity: IIdentity;

    constructor(identity: IIdentity) {
        super();
        this.Identity = identity;
    }
}