














import Component from 'vue-class-component';
import WpBaseComponent from '../wp-base-component';
import GlobalService, {
    AppSettings,
} from 'web-platform-core-ui';
import BaseUILocalizationDictionary from './../Localization/base-ui-localization-terms';
import { Prop, Watch } from 'vue-property-decorator';

@Component
export default class WpDisclaimer extends WpBaseComponent<WpDisclaimerDictionary> {
    Settings!: AppSettings;

    @Prop() elevation?: string;

    created() {
        this.Settings = GlobalService.GetSettings<AppSettings>();
    }

    Dismiss(action: Function) {
        localStorage?.setItem("WpDisclaimerDismissed", "true");
        action();
    }

    get WpDisclaimerDismissed() {
        return localStorage?.getItem("WpDisclaimerDismissed");
    }
}

export class WpDisclaimerDictionary extends BaseUILocalizationDictionary {
    Header = "Политика обработки персональных данных";
    Text = "При оплате через наш виджет, ваши персональные данные обрабатываются в соответствии с действующим законодательством. Мы гарантируем их защиту и используем только для выполнения заказа и предоставления услуг";
}
