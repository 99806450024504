import { IIdentity, AccessToken } from './..';

export default class RHIdentity implements IIdentity {
    private _token: string;
    private _phone: string;

    Username: string;
    UserId: string;
    PersonId: number;

    get AccessToken(): AccessToken {
        var date = new Date();
        date.setDate(date.getDate() + 7);

        return new AccessToken(this._token, date);
    }
    get IsAuthentificated(): boolean {
        return true;
    }

    get Phone() {
        return this._phone;
    }

    set Phone(val: string) {
        this._phone = val;
    }


    constructor(email: string, userId: string, personId: number, token: string, phone: string) {
        this.Username = email;
        this.UserId = userId;
        this.PersonId = personId;
        this._token = token;
        this._phone = phone;
    }
}