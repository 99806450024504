import { WpEventArgs } from './../Common'
import { IsdUserLoyalty } from './'

export default class LoyaltyChangedEventArgs extends WpEventArgs {
    IsdUserLoyalty: IsdUserLoyalty;

    constructor(userLoyalty: IsdUserLoyalty) {
        super();
        this.IsdUserLoyalty = userLoyalty;
    }
}