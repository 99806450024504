import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WpPageBaseComponent from './../wp-page-base-component';
import { Emit } from 'vue-property-decorator';
import {
    NewsArticle, CmsSymbols, ICmsService,
    WpCorePrefixes, BaseFilter, Sorting,
    SortingMode
} from 'web-platform-core-ui';

@Component({
    template: require("./wp-main.html")
})
export default class WpMain extends mixins(WpPageBaseComponent, Vue) {

    @Emit('ToggleDrawer')
    ToggleDrawer(): void {
    }

    NewsList: Array<NewsArticle> = [];

    async mounted() {
        let service = this.GlobalService.Get<ICmsService>(CmsSymbols.CmsService);

        let filter = new BaseFilter();
        filter.Page = 1;
        filter.PageCount = 3;
        filter.Sorting = new Sorting();
        filter.Sorting.Member = "Date";
        filter.Sorting.Mode = SortingMode.DESC;

        let result = await service.GetEntitiesAsync<NewsArticle>(WpCorePrefixes.News, filter, '');

        if (result.Success && result.Data != null) {
            this.NewsList = result.Data.Entities;
        }
    }

    AppGoBack() {
        // @ts-ignore
        closeApplication("");
    }
} 