import './wp-news-item.scss';

import Vue from 'vue';
import { Prop } from "vue/types/options";
import WpBaseComponent from './../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { NewsArticle, IMetricsService, MetricsSymbols } from 'web-platform-core-ui';
import { MetricsEvents } from '../metrics-events-symbols';

export const wpNewsItemProps = Vue.extend({
    props: {
        Entity: Object as Prop<NewsArticle>
    }
})

@Component({
    template: require("./wp-news-item.html")
})
export default class WpNewsItem extends mixins(WpBaseComponent, wpNewsItemProps, Vue) {
    ArticleDate?: string = '';
    mounted() {
        this.ArticleDate = this.DateHandler.Parse(this.$props.Entity.Date, 'YYYY-MM-DDTHH:mm:SS').Format('DD MMMM YYYY');

        let metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
        metricsService.RegistrateEvent(MetricsEvents.NewsItem, this.Entity?.Id);
    }
} 