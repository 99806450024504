import { injectable, inject } from 'inversify';

import { ActionResult } from '../Common';
import {  IContext, IdentitySymbols } from '../Identity';
import { SyntechSymbols } from './symbols';
import ISyntechProvider from './i-syntech-provider';
import ISyntechService from './i-syntech-service';
import ParkingSessionInfo from './parking-session-info';
import Tariff from '../Tariffs/tariff';

@injectable()
export default class DefaultSyntechService implements ISyntechService {
    protected readonly _context: IContext;
    protected readonly _provider: ISyntechProvider;

    constructor(@inject(SyntechSymbols.SyntechProvider) provider: ISyntechProvider,
        @inject(IdentitySymbols.Context) context: IContext) {
        this._provider = provider;
        this._context = context;
    }

    async GetSessionInfoAsync(ticket: string): Promise<ActionResult<ParkingSessionInfo>> {
        return await this._provider.GetSessionInfoAsync(ticket);
    }

    async GetTariffsAsync(): Promise<ActionResult<Array<Tariff>>> {
        let result = await this._provider.GetTariffsAsync();

        return result;
    };
}