import Vue from 'vue';
import './wp-instructors-list.scss';
import WpBaseComponent from '../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';
import { Instructor, IInstructorsService, InstructorsSymbols, BaseFilter, WpCorePrefixes, ICmsService, CmsSymbols, BaseSystemLocalizationDictionary } from 'web-platform-core-ui';
import { DefaultProps, Prop } from 'vue/types/options';

const WpInstructorsListProps = Vue.extend({
    props: {
        // Value & input are necessary for v-model to work on the component attributes
        value: {
            type: Object as Prop<Instructor>,
            default: null,
            required: false
        },
        input: {
            type: Function,
            default: () => { },
        },
        // Derived props
        placeid: {
            type: Object,
            default: 0,
            required: false
        }
    }
})

@Component({
    template: require("./wp-instructors-list.html")
})
export default class WpInstructorsList extends mixins<WpBaseComponent<WpInstructorsListDictionary>, DefaultProps>(WpBaseComponent, WpInstructorsListProps) {
    private _instructorsService!: IInstructorsService;
    private _cmsService!: ICmsService;

    @Ref('select')
    protected select!: HTMLElement; // Maybe it's Vue or VueComponent?

    Instructor: Instructor | null = null;
    Instructors: Array<Instructor> = [];
    InstructorsLoaded: boolean = false;
    InstructorMore: Instructor | null = null;

    created(): void {
        this._instructorsService = this.GlobalService.Get<IInstructorsService>(InstructorsSymbols.InstructorsService);
        this._cmsService = this.GlobalService.Get<ICmsService>(CmsSymbols.CmsService);
    };

    async mounted(): Promise<void> {
        if (this.placeid && this.placeid.Id)
            await this.LoadInstructors(this.placeid.Id);
    };

    @Watch("placeid")
    async PlaceChanged(): Promise<void> {
        if (this.placeid.Id)
            await this.LoadInstructors(this.placeid.Id);
    };

    @Watch("Instructor")
    InstructorChanged(): void {
        this.$emit("input", this.Instructor);
    };

    Select(i: Instructor) {
        this.Instructor = i;
        this.select.blur();
    };

    async LoadInstructors(placeid: number): Promise<void> {
        this.InstructorsLoaded = false;
        let query = await this._instructorsService.GetInstructorsAsync(placeid);
        if (query.Success && query.Data != null) this.Instructors = query.Data.sort((x, y) =>
        {
            if (x.Name == y.Name) return 0;
            else if (x.Name < y.Name) return -1;
            else return 1;
        });

        let filter = new BaseFilter(1, 300);
        // Запросим у CMS доп инфу по инструкторам
        let moreInfo = await this._cmsService.GetEntitiesAsync(WpCorePrefixes.Intructors, filter, '');
        if (moreInfo.Success && moreInfo.Data && +moreInfo.Data.TotalCount != 0) {
            let entities = moreInfo.Data.Entities as Array<InstructorAdditionalInfo>;
            this.Instructors.forEach((i: Instructor) => {
                let info = entities.find((e: InstructorAdditionalInfo) => i.Code == e.Code);
                if (info) {
                    if (info.Name) i.Name = info.Name;
                    if (info.ImageLink) i.ImageLink = info.ImageLink;
                    if (info.Preview) i.Preview = info.Preview;
                    if (info.Description) i.Description = info.Description;
                };
            });
        };
        this.InstructorsLoaded = true;
    };
};

class InstructorAdditionalInfo {
    Id!: number;
    Code!: number;
    Description?: string;
    ImageLink?: string;
    Name?: string;
    PlatformEntities?: any | Array<any> | string;
    Preview?: string;
};

export class WpInstructorsListDictionary extends BaseSystemLocalizationDictionary {
    Instructor: string = "Инструктор";
    InstructorsLoading: string = "Загрузка инструкторов…";
};