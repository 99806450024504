import './wp-instructors.scss';

import Vue, { VueConstructor } from 'vue';
import { Prop, DefaultProps } from 'vue/types/options';
import WpBaseComponent from './../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
    ActionResult, AppSettings,

    InstructorsSymbols, IInstructorsService, GroupLessonsFilter,
    InstructorsGroupLesson, InstructorTariffCategory,
    SkillType, InstructorsPlace, InstructorsBundlesGroupsLessons,

    INotificationService, NotificationSymbols,

    CartItem, Cart, CartItemTypes, Tariff, InstructorsBundlesCartItem, BaseSystemLocalizationDictionary,
    WpCorePrefixes, BaseFilter, ICmsService, CmsSymbols, InstructorsLessonsLocation,
} from 'web-platform-core-ui';


export const wpInstructorsProp = Vue.extend({
    props: {
        Cart: Object as Prop<Cart>,
    }
})

@Component({
    template: require("./wp-instructors-bundles.html")
})
export default class WpInstructorsBundles extends mixins<WpBaseComponent<WpInstructorsBundlesDictionary>,DefaultProps,VueConstructor>(WpBaseComponent, wpInstructorsProp, Vue) {
    private _allTariffs: Array<Tariff> = [];
    private _instructorsService!: IInstructorsService;
    protected _notificationService!: INotificationService;

    Settings!: AppSettings;
    DatepickerSettings: any = {};

    SelectedPlace!: InstructorsPlace;

    TariffId: number = 0;
    Tariffs: Array<Tariff> = [];
    TariffLoaded: boolean = false;
    TariffVisible: boolean = true;
    NoTariffs: boolean = false;

    Skill: number = 0;
    Skills: Array<SkillType> = [];
    SkillsLoaded: boolean = false;
    SkillsVisible: boolean = true;
    NoSkills: boolean = false;

    get Category(): string {
        if (this.Categories.length !== 0) {
            let categoryById = this.Categories.filter(x => x.Id == parseInt(this.CategoryId));
            if (categoryById.length !== 0) {
                return categoryById[0].Name;
            }
            else {
                return '';
            }
        }
        else {
            return '';
        }
    }
    CategoryId: string = '';
    Categories: Array<InstructorTariffCategory> = [];
    CategoriesLoaded: boolean = false;
    CategoriesVisible: boolean = true;
    NoCategories: boolean = false;

    LocationId: number = 0;
    Locations: Array<InstructorsLessonsLocation> = [];
    LocationsLoaded: boolean = false;
    LocationsVisible: boolean = true;
    NoLocations: boolean = false;

    ShowButtonVisible: boolean = true;

    Filter?: GroupLessonsFilter;
    FilterLoading = false;
    LessonsLoaded = false;
    Bundles?: Array<InstructorsBundlesGroupsLessons>;

    NoLessons: boolean = false;

    Dates: Array<string> = [];
    DatesVisible: boolean = true;
    DatesArray: Array<string> = [];

    FilterComplete: boolean = false;

    Datepicker: boolean = false;
    StartDatepicker(): void {
        this.DatepickerSettings = this.Settings;
        this.Datepicker = true;
    }

    async mounted() {
        this._instructorsService = this.GlobalService.Get<IInstructorsService>(InstructorsSymbols.InstructorsService);
        this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
        let cmsService = this.GlobalService.Get<ICmsService>(CmsSymbols.CmsService);

        this.Settings = GlobalService.GetSettings<AppSettings>();
        this.StartDatepicker();

        if (this.Settings.NoSkills) {
            this.NoSkills = true;
        }
        if (this.Settings.NoCategories) {
            this.NoCategories = true;
        }
        if (this.Settings.NoTariffs) {
            this.NoTariffs = true;
        }
        if (this.Settings.NoLocations) {
            this.NoLocations = true;
        }

        let places = await this._instructorsService.GetPlacesAsync();

        if (!places.Success || places.Data == undefined || places.Data.length == 0) {
            this._notificationService.Error("Ошибка", places.ErrorMessage ? places.ErrorMessage : "Непредвиденная ошибка");
            return;
        }
        if ('PlaceId' in this.Settings) {
            let place = places.Data.filter(x => x.Id == this.Settings?.PlaceId);
            if (place.length !== 0) {
                this.SelectedPlace = place[0];
            }
            else {
                this.SelectedPlace = places.Data[0];
            }
        }
        else {
            this.SelectedPlace = places.Data[0];
        }

        let Skills = await this._instructorsService.GetSkillAsync(this.SelectedPlace.Id);
        if (Skills.Data !== undefined && Skills.Data.length >= 1) {
            await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.InstructorsSkillsNames, Skills.Data);

            let translateSkill = await cmsService.GetEntitiesAsync<SkillType>(WpCorePrefixes.InstructorsSkillsNames, new BaseFilter(), '');

            if (translateSkill.Success && translateSkill.Data != null) {
                Skills.Data.forEach(x => {
                    let skill = translateSkill.Data?.Entities.find(sc => sc.Code == x.Id);
                    if (skill != null) x.Name = skill.Name;
                })
            }

            this.Skills = Skills.Data.sort((x, y) => {
                if (x.Name == y.Name) return 0;
                if (x.Name < y.Name) return -1;
                else return 1;
            });

            if (Skills.Data.length === 1) {
                this.Skill = this.Skills[0].Id;
                this.SkillsVisible = false;
            }
        }
        this.SkillsLoaded = true;

        let Categories = await this._instructorsService.GetInstructorsTariffCategoriesAsync();
        if (Categories.Data !== undefined && Categories.Data.length >= 1) {
            await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.InstructorsTariffOptionNames, Categories.Data);

            let translateCategories = await cmsService.GetEntitiesAsync<InstructorTariffCategory>(WpCorePrefixes.InstructorsTariffOptionNames, new BaseFilter(), '');

            if (translateCategories.Success && translateCategories.Data != null) {
                Categories.Data.forEach(x => {
                    let category = translateCategories.Data?.Entities.find(sc => sc.Code == x.Id);
                    if (category != null) x.Name = category.Name;
                })
            }

            this.Categories = Categories.Data.sort((x, y) => {
                if (x.Name == y.Name) return 0;
                if (x.Name < y.Name) return -1;
                else return 1;
            });

            if (this.Categories.length === 1) {
                this.CategoryId = this.Categories[0].Id.toString();
                this.CategoriesVisible = false;
            }
        }
        this.CategoriesLoaded = true;

        let locations = await this._instructorsService.GetLessonsLocationsAsync();
        if (locations.Data !== undefined && locations.Data.length >= 1) {
            this.Locations = locations.Data.sort((x, y) => {
                if (x.Name == y.Name) return 0;
                if (x.Name < y.Name) return -1;
                else return 1;
            });

            if (this.Locations.length === 1) {
                this.LocationId = this.Locations[0].LocationId;
                this.LocationsVisible = false;
            }
        }
        this.LocationsLoaded = true;

        let tariffs = await this._instructorsService.GetInstructorsTariffsAsync();
        if (tariffs.Data !== undefined && tariffs.Data.length >= 1) {
            await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.Tariff, tariffs.Data);

            this._allTariffs = tariffs.Data;

            if (this.Settings.BundlesLessonsTariffsId !== undefined && this.Settings.BundlesLessonsTariffsId.length > 0) {
                this._allTariffs = this._allTariffs.filter(x => this.Settings.BundlesLessonsTariffsId.indexOf(x.Id) !== -1)
            }
        }
        this.TariffLoaded = true;

        this.ResetTariffs();
        this.ResetFilterResults();

        //if (this.FilterComplete) {
        //    this.ShowButtonVisible = false;
        //    await this.GetGroupLessonsAsync();
        //}
    }

    FormatDate(dateString: string): string {
        return this.DateHandler.Parse(dateString, 'YYYY-MM-DD').Format('DD.MM.YYYY');
    }
    FormatTime(dateString: string): string {
        return this.DateHandler.Parse(dateString, 'HH:mm:SS').Format('HH:mm');
    }
    SkillChanged() {
        this.ResetTariffs();
        this.ResetFilterResults();
    }

    CategoryChanged() {
        this.ResetTariffs();
        this.ResetFilterResults();
    }

    TariffChanged(tariffCode: number) {
        this.TariffId = tariffCode;
        this.ResetFilterResults();
    }

    ResetFilterResults(): void {
        this.NoLessons = false;
        this.Bundles = [];
        this.LessonsLoaded = false;

        this.FilterComplete =
            this.TariffLoaded && this.SkillsLoaded && this.CategoriesLoaded && this.LocationsLoaded &&
            (this.Skill !== 0 || this.NoSkills) &&
            (this.CategoryId !== '' || this.NoCategories) &&
            (this.LocationId !== 0 || this.NoLocations) &&
            (this.TariffId !== 0 || this.NoTariffs) &&
            this.Dates.length >= 1;
    }


    ResetTariffs() {
        if (this._allTariffs !== undefined) {
            this.Tariffs =
                this.CategoryId !== '' ?
                    this._allTariffs.filter(x =>
                        this.Settings.GroupLessonsSkillsTariffs[`${this.Skill}`] !== undefined ? this.Settings.GroupLessonsSkillsTariffs[`${this.Skill}`].indexOf(x.Id) !== -1 : true)
                        .filter(x => x.OptionId === this.CategoryId).sort((x, y) => {
                            if (x.Name == y.Name) return 0;
                            if (x.Name < y.Name) return -1;
                            else return 1;
                        })
                    :
                    this._allTariffs.filter(x =>
                        this.Settings.GroupLessonsSkillsTariffs != null &&
                            this.Settings.GroupLessonsSkillsTariffs[`${this.Skill}`] !== undefined ? this.Settings.GroupLessonsSkillsTariffs[`${this.Skill}`].indexOf(x.Id) !== -1 : true)
                        .sort((x, y) => {
                            if (x.Name == y.Name) return 0;
                            if (x.Name < y.Name) return -1;
                            else return 1;
                        })

        }

        if (this.Tariffs.length == 1) {
            this.TariffId = this.Tariffs[0].TariffCode;
        } else {
            this.TariffId = 0;
        }
    }

    AddToCart(bundle: InstructorsBundlesGroupsLessons, amount: number, price: number): void {
        let cartItem = new InstructorsBundlesCartItem(bundle, amount, price, this.Cart);
        let result = this.Cart.AddItem(cartItem);

        if (!result.Success) {
            this._notificationService.Error("Ошибка", result.ErrorMessage ? result.ErrorMessage : "Непредвиденная ошибка");
        }
        else {
            var cartItems = this.Cart.SimpleCart();
            parent.postMessage({ status: 'addedtocart', data: cartItems }, '*');
        }
    }

    get BundlesBought(): Array<CartItem> {
        let bundlesBought: Array<CartItem> = [];
        this.Cart.Items.forEach((element: CartItem) => {
            if (element.Type == CartItemTypes.InstructorsBundleLessons)
                bundlesBought.push(element);
        });
        return bundlesBought;
    }

    async GetBundlesLessonsAsync(): Promise<ActionResult<Array<InstructorsBundlesGroupsLessons>>> {
        this.LessonsLoaded = false;
        this.FilterLoading = true;
        let dateFrom = this.DateHandler.Parse(this.Dates[0], 'YYYY-MM-DD').Format('DD.MM.YYYY');
        let dateTo = this.Dates.length == 2 ? this.DateHandler.Parse(this.Dates[1], 'YYYY-MM-DD').Format('DD.MM.YYYY') : dateFrom;

        let skill = this.Skills.find(x => x.Id == this.Skill);
        let filter = new GroupLessonsFilter(this.SelectedPlace.Id, skill !== undefined ? skill.Name : '', this.CategoryId,
            dateFrom, dateTo, this.TariffId == 0 ? null : this.TariffId, null, this.Settings?.CoachId, this.LocationId == 0 ? '' : this.LocationId.toString());

        let filterResult = await this._instructorsService.GetBundlesGroupLessonsAsync(filter);

        this.FilterLoading = false;
        this.LessonsLoaded = true;
        if (filterResult.Success && filterResult.Data !== undefined) {
            this.Bundles = filterResult.Data;
        }

        let datesArray = new Array<string>();
        let _this = this;
        this.Bundles?.forEach(bundle => {
            let minDate = _this.DateHandler.Parse(bundle.MinDate, 'YYYY-MM-DDTHH:mm:SS').Format('DD.MM.YYYY');
            let maxDate = _this.DateHandler.Parse(bundle.MaxDate, 'YYYY-MM-DDTHH:mm:SS').Format('DD.MM.YYYY');
            var bundelPeriod = `${minDate} - ${maxDate}`;
            if (!datesArray.some(x => x == bundelPeriod)) {
                datesArray.push(bundelPeriod);
            }
        });

        this.DatesArray = datesArray;

        if (this.DatesArray.length == 0) {
            this.NoLessons = true;
        }

        return filterResult;
    }

    DatesSet(dates: Array<string>): void {
        this.Dates = dates;
        this.ResetFilterResults();
    }
}

export class WpInstructorsBundlesDictionary extends BaseSystemLocalizationDictionary {
    Discipline: string = '';
    DisciplinesLoading: string = '';
    Category: string = '';
    CategoriesLoading: string = '';
    Program: string = '';
    ProgramsLoading: string = '';
    DialogBtnShow: string = '';
    NoLessonsAvailable: string = '';
    Location: string = '';
    LocationsLoading: string = '';
}