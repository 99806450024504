import { ILocalizationService, Localization } from 'web-platform-core-ui';
import wpRuLocalizationComponentsDictionary, { BaseDictionaryRu } from './Ru/localization-dictionary-ru';
import wpEnLocalizationComponentsDictionary, { BaseDictionaryEn } from './En/localization-dictionary-en';


const loadStaticLocaliztionDictionaries = function (localizationService: ILocalizationService) {

    // RUSSIAN
    for (let i = 0; i < wpRuLocalizationComponentsDictionary.length; i++) {
        let x = wpRuLocalizationComponentsDictionary[i];
        x.Terms.AccessDenied = BaseDictionaryRu.AccessDenied;
        x.Terms.InternalError = BaseDictionaryRu.InternalError;

        x.Terms.GenericYes = BaseDictionaryRu.GenericYes;
        x.Terms.GenericNo = BaseDictionaryRu.GenericNo;
        x.Terms.GenericBack = BaseDictionaryRu.GenericBack;
        x.Terms.GenericNext = BaseDictionaryRu.GenericNext;
        x.Terms.GenericChange = BaseDictionaryRu.GenericChange;
        x.Terms.GenericClose = BaseDictionaryRu.GenericClose;
        x.Terms.GenericError = BaseDictionaryRu.GenericError;
        x.Terms.GenericPleaseWait = BaseDictionaryRu.GenericPleaseWait;
        x.Terms.GenericClear = BaseDictionaryRu.GenericClear;
        x.Terms.GenericCancel = BaseDictionaryRu.GenericCancel;
        x.Terms.GenericOK = BaseDictionaryRu.GenericOK;        
        x.Terms.GenericLogIn = BaseDictionaryRu.GenericLogIn;
        x.Terms.GenericAdd = BaseDictionaryRu.GenericAdd;
        x.Terms.GenericEdit = BaseDictionaryRu.GenericEdit;
        x.Terms.GenericUpdate = BaseDictionaryRu.GenericUpdate;
        x.Terms.GenericApply = BaseDictionaryRu.GenericApply;
        x.Terms.GenericSelect = BaseDictionaryRu.GenericSelect;

        x.Terms.GenericPrice = BaseDictionaryRu.GenericPrice;
        x.Terms.GenericBuy = BaseDictionaryRu.GenericBuy;
        x.Terms.GenericFrom = BaseDictionaryRu.GenericFrom;
        x.Terms.GenericTo = BaseDictionaryRu.GenericTo;

        x.Terms.FormFieldRequired = BaseDictionaryRu.FormFieldRequired;

        localizationService.AddDictionary(Localization.RU, x);
    }

    // ENGLISH
    for (let i = 0; i < wpEnLocalizationComponentsDictionary.length; i++) {
        let x = wpEnLocalizationComponentsDictionary[i];
        x.Terms.AccessDenied = BaseDictionaryEn.AccessDenied;
        x.Terms.InternalError = BaseDictionaryEn.InternalError;

        x.Terms.GenericYes = BaseDictionaryEn.GenericYes;
        x.Terms.GenericNo = BaseDictionaryEn.GenericNo;
        x.Terms.GenericBack = BaseDictionaryEn.GenericBack;
        x.Terms.GenericNext = BaseDictionaryEn.GenericNext;
        x.Terms.GenericChange = BaseDictionaryEn.GenericChange;
        x.Terms.GenericClose = BaseDictionaryEn.GenericClose;
        x.Terms.GenericError = BaseDictionaryEn.GenericError;
        x.Terms.GenericPleaseWait = BaseDictionaryEn.GenericPleaseWait;
        x.Terms.GenericClear = BaseDictionaryEn.GenericClear;
        x.Terms.GenericCancel = BaseDictionaryEn.GenericCancel;
        x.Terms.GenericOK = BaseDictionaryEn.GenericOK;
        x.Terms.GenericLogIn = BaseDictionaryEn.GenericLogIn;
        x.Terms.GenericAdd = BaseDictionaryEn.GenericAdd;
        x.Terms.GenericEdit = BaseDictionaryEn.GenericEdit;
        x.Terms.GenericUpdate = BaseDictionaryEn.GenericUpdate;
        x.Terms.GenericApply = BaseDictionaryEn.GenericApply;
        x.Terms.GenericSelect = BaseDictionaryEn.GenericSelect;

        x.Terms.GenericPrice = BaseDictionaryEn.GenericPrice;
        x.Terms.GenericBuy = BaseDictionaryEn.GenericBuy;
        x.Terms.GenericFrom = BaseDictionaryEn.GenericFrom;
        x.Terms.GenericTo = BaseDictionaryEn.GenericTo;

        x.Terms.FormFieldRequired = BaseDictionaryEn.FormFieldRequired;

        localizationService.AddDictionary(Localization.EN, x);
    }
}

export default loadStaticLocaliztionDictionaries;