import { EcomAddToCart } from './../Metrics/i-ecom-data';
import CartItem from './../Cart/cart-item';
import { CartItemTypes } from './../Cart/cart-item-types';
import { ActionResult, ErrorTypes, WpEventArgs } from './../Common';
import { ReservationItemTypes, ReservationItem } from './../Sale';
import * as moment from 'moment';
import { Cart, createGuid } from './..';
import InstructorsBundlesGroupsLessons from './instructors-bundles-groups-lessons';


export default class InstructorsBundlesCartItem extends CartItem {
    private _maxCount: number = 10;
    private _price: number = 0;
    private _beginTime: string = "";
    private _endTime: string = "";
    private _maxDate: string = "";
    private _minDate: string = "";
    private _count: number;
    private _cart: Cart;
    private _bundle: InstructorsBundlesGroupsLessons;
    private _type: CartItemTypes = CartItemTypes.InstructorsBundleLessons;

    get IsAvailable(): boolean {
        return true;
    }
    public get Info(): string {
        return `<strong>${this._minDate} - ${this._maxDate}</strong> ${this._bundle.Lessons[0].TariffName} с ${this._beginTime} до ${this._endTime} (${this._count}) чел.`;
    }
    public get Type(): CartItemTypes {
        return this._type;
    }
    public get Price(): number {
        return this._count * this._price;
    }
    public get Count(): number {
        return this._count;
    }
    public get IsCanIncreaseCount(): boolean {
        return this._count < this._maxCount && this._cart.LeftCount > 0; 
    }
    public get IsCanReduceCount(): boolean {
        return this._count > 1 ;
    }
    public get Bundle(): InstructorsBundlesGroupsLessons {
        return this._bundle;
    }
    get MetricsId(): any | undefined {
        if (this._bundle == null || this._bundle.Lessons == null ||
            this._bundle.Lessons.length == 0)
            return undefined;

        return this._bundle?.Lessons[0]?.TariffId;
    }
    public get Dates(): Array<string> {
        let result = new Array<string>();

        this.Bundle.Lessons.forEach(lesson => {
            if (lesson.Date !== undefined) {
                result.push(moment.parseZone(lesson.Date, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY'));
            }
        })

        return result;
    }

    public get AdditionalInfo(): any {
        return this._bundle;
    }

    constructor(bundle: InstructorsBundlesGroupsLessons, count: number, price: number, cart: Cart) {
        super();        

        this._beginTime = moment.parseZone(bundle.Lessons[0].BeginTime, "HH:mm:SS").format("HH:mm");
        this._endTime = moment.parseZone(bundle.Lessons[0].EndTime, "HH:mm:SS").format("HH:mm");
        this._minDate = moment.parseZone(bundle.MinDate, "YYYY-MM-DD").format("DD.MM.YYYY");
        this._maxDate = moment.parseZone(bundle.MaxDate, "YYYY-MM-DD").format("DD.MM.YYYY");

        this._bundle = bundle;
        this._count = count;
        this._price = price;
        this._cart = cart;
        if (bundle.Lessons[0].FreeMembers < this._maxCount)
            this._maxCount = bundle.Lessons[0].FreeMembers;
    }

    public IncreaseCount(): ActionResult {
        if (this._count < this._maxCount && this._cart.LeftCount > 0) {
            this._count++;

            this._cartItemChanged.Send(this, WpEventArgs.Empty);

            return ActionResult.Success();
        }
        else
            return ActionResult.Failed(ErrorTypes.CartError, "Превышено допустимое количество для данного типа услуг");
    }

    public ReduceCount(): ActionResult {
        if (this._count >= 2) {
            this._count--;

            this._cartItemChanged.Send(this, WpEventArgs.Empty);

            return ActionResult.Success();
        } else
            return ActionResult.Failed(ErrorTypes.CartError, "Достигнуто минимальное возможное количество");
    }

    public IsConflictItem(item: CartItem): boolean {
        return false;
    }

    public IsEqualsItem(item: CartItem): boolean {
        if (item.Type != CartItemTypes.InstructorsBundleLessons) return false;

        let cartItem = item as InstructorsBundlesCartItem;

        return cartItem != null && cartItem.Bundle.BundleId === this._bundle.BundleId;
    }

    public CombineItem(item: CartItem): ActionResult {
        let cartItem = item as InstructorsBundlesCartItem;

        if (this._count + cartItem.Count > this._maxCount || this._cart.LeftCount < this._count + cartItem.Count)
            return ActionResult.Failed(ErrorTypes.CartError, "Превышено допустимое количество для данного типа услуг");

        this._count = this._count + cartItem.Count;

        this._cartItemChanged.Send(this, WpEventArgs.Empty);

        return ActionResult.Success();
    }

    public GetReservationItem(): Array<ReservationItem> {
        let result = new Array<ReservationItem>();

        for (let i = 1; i <= this._count; i++) {
            let reservationBundleId = createGuid();

            this.Bundle.Lessons.forEach(lesson => {
                result.push(new ReservationItem(1, ReservationItemTypes.BundleLesson,
                    lesson.TariffId, moment.parseZone(lesson.Date).format("DD.MM.YYYY"), null, lesson.Id, null, reservationBundleId));
            });
        }

        return result;
    }

    async RemoveItem(): Promise<ActionResult> {
        return ActionResult.Success();
    }

    IsAssociatedReservationItem(item: ReservationItem): boolean{
        this.Bundle.Lessons.forEach(lesson => {
                let date = moment.parseZone(lesson.Date).format("DD.MM.YYYY");
                if(item.ItemType == ReservationItemTypes.BundleLesson &&
                    item.Count == 1 &&
                    date == item.Date &&
                    lesson.TariffId ==  item.TariffId &&
                    lesson.Id == item.LessonId)
                    return true;
        });

        return false;
    }

    GetEcomData(eventId: Symbol): EcomAddToCart {
        throw new Error('Not implemented');
    }
}