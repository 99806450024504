import { CartItem, CartItemTypes } from './../Cart';
import { ReservationItem, ActionResult, ReservationItemTypes, EcomAddToCart } from './..';
import ArenaSeance from './arena-seance';
import { ArenaSector, ArenaSymbols, IArenaService } from './';
import ArenaTariff from './arena-tariff';
import ArenaTariffOption from './arena-tariff-option';
import ArenaSeat from './arena-seat';
import { WpEventArgs } from './../Common';
import { ArenaCartItemState } from './arena-cart-item-state';

export default class ArenaCartItem extends CartItem {
    private _type: CartItemTypes = CartItemTypes.ArenaTicket;
    protected _arenaService: IArenaService;

    OrderId?: number;
    OrderItemId?: number;
    SibId?: string;

    Seat: ArenaSeat = new ArenaSeat();
    Seance: ArenaSeance = new ArenaSeance();
    Sector: ArenaSector = new ArenaSector();
    Tariff: ArenaTariff = new ArenaTariff();
    Option?: ArenaTariffOption;

    State: ArenaCartItemState = ArenaCartItemState.Available;

    constructor(arenaService: IArenaService) {
        super();
        this._arenaService = arenaService;
    };

    get Info(): string {
        return `${this.Sector.Name}, ряд ${this.Seat.LineName}, место ${this.Seat.PlaceName} ${this.Option != null ? this.Option.Name : ''}`;
    };

    get Type(): CartItemTypes {
        return this._type;
    };

    get Price(): number {
        return this.Option !== undefined ? this.Option.Price : this.Tariff.Price;
    };

    get Count(): number {
        return 1;
    };

    get IsAvailable(): boolean {
        return true;
    };

    get MetricsId(): any | undefined {
        return this.Seance?.Id;
    };

    get TempId(): string {
        return this.OrderItemId?.toString() || "";
    };

    public get Dates(): Array<string> {
        return [];
    };

    public get AdditionalInfo(): any {
        return {
            seat: this.Seat,
            seance: this.Seance,
            sector: this.Sector,
            tariff: this.Tariff,
            option: this.Option,
            sibid: this.SibId
        };
    };

    get IsCanIncreaseCount(): boolean {
        return false;
    };

    get IsCanReduceCount(): boolean {
        return false;
    };

    IncreaseCount(): ActionResult {
        throw new Error("Method not implemented.");
    };

    ReduceCount(): ActionResult<any> {
        throw new Error("Method not implemented.");
    };

    IsConflictItem(item: CartItem): boolean {
        return item.Type != CartItemTypes.ArenaTicket;
    };

    IsEqualsItem(item: CartItem): boolean {
        return false;
    };

    CombineItem(item: CartItem): ActionResult<any> {
        throw new Error("Method not implemented.");
    };

    GetReservationItem(): ReservationItem[] {
        throw new Error("Method not implemented.");
    };

    async ReservateItem(): Promise<ActionResult> {
        this.State = ArenaCartItemState.Busy;
        let result = await this._arenaService.ReservateSeatAsync(this);
        if (result.Success && result.Data != null) {
            this.OrderId = result.Data.Id;
            let orderItem = result.Data.Items.find(x => x.AdditionalInfo.PlaceCode == this.Seat.PlaceCode);
            this.OrderItemId = orderItem?.Id;
        };
        this.State = ArenaCartItemState.Available;
        return result;
    };

    async RemoveItem(): Promise<ActionResult> {
        if (this.OrderItemId == undefined) return ActionResult.Success();
        this.State = ArenaCartItemState.Busy;
        let result = await this._arenaService.UnreservateSeatAsync(this.OrderItemId);
        this.State = ArenaCartItemState.Available;
        this._cartItemChanged.Send(this, WpEventArgs.Empty);
        return result;
    };

    IsAssociatedReservationItem(item: ReservationItem): boolean {
        return false;
    }

    GetEcomData(eventId: Symbol): EcomAddToCart {
        throw new Error('Not implemented');
    }
};