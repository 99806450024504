import './wp-arena.scss';

import Vue, { VueConstructor } from 'vue';
import WpBaseComponent from './../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { ArenaSeance, ArenaSector, ArenaPosition, Cart, IMetricsService, MetricsSymbols, BaseSystemLocalizationDictionary } from 'web-platform-core-ui';
import { Prop, DefaultProps } from 'vue/types/options';
import { MetricsEvents } from '../../metrics-events-symbols';
import BaseUILocalizationDictionary from './../../Localization/base-ui-localization-terms';
import { Watch } from 'vue-property-decorator';

export const wpArenaProp = Vue.extend({
    props: {
        Cart: Object as Prop<Cart>,
    }
})

@Component({
    template: require("./wp-arena.html")
})
export default class WpArena extends mixins<WpBaseComponent<WpArenaDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, Vue, wpArenaProp) {
    private _metricsService!: IMetricsService;

    Step: WpArenaSaleSteps = WpArenaSaleSteps.Seances;

    ArenaText: string = '';
    ArenaPosition: ArenaPosition = ArenaPosition.Bottom;
    CurrentSeance: ArenaSeance = new ArenaSeance();
    CurrentSector: ArenaSector = new ArenaSector();
    OrderId: number = 0;
    ForcedSeance: boolean = false;
    ForcedSector: boolean = false;

    @Watch('Step')
    NavigationHappened(step: WpArenaSaleSteps, _oldValue: WpArenaSaleSteps): void {
        if (step == 2 && this.ForcedSector) {
            _oldValue < step ? this.Step++ : this.Step--;
        };
    };

    mounted() {
        this.ArenaText = this.Terms.ArenaText;
        this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
        this._metricsService.RegistrateEvent(MetricsEvents.WidgetToArenaSeances);

        this.Cart.CartChanged.Subscribe((sender: any, e: any) => {
            if (this.Cart.OrderId != null) {
                this.OrderId = this.Cart.OrderId;
            }
        })
        if (this.Cart.OrderId != null)
            this.OrderId = this.Cart.OrderId;
    };

    SeanceSelected(seance: ArenaSeance) {
        this.CurrentSeance = seance;
        this.Step = WpArenaSaleSteps.Sectors;
        this._metricsService.RegistrateEvent(MetricsEvents.WidgetToArenaSectors, this.CurrentSeance?.Id);
    };

    SectorSelected(data: any) {
        this.ArenaText = data.ArenaText;
        this.ArenaPosition = data.ArenaPosition;
        this.CurrentSector = data.Sector;
        this.Step = WpArenaSaleSteps.Seats;
        this._metricsService.RegistrateEvent(MetricsEvents.WidgetToArenaSeats, this.CurrentSector?.SectorCode);
    };

    Back(): void {
        if (this.Step == WpArenaSaleSteps.Sectors) {
            this._metricsService.RegistrateEvent(MetricsEvents.WidgetArenaSectorsBack, this.CurrentSeance?.Id);
        };

        if (this.Step == WpArenaSaleSteps.Seats) {
            this._metricsService.RegistrateEvent(MetricsEvents.WidgetArenaSeatsBack, this.CurrentSector?.SectorCode);
        };

        if (this.Step == WpArenaSaleSteps.Sectors || this.Step == WpArenaSaleSteps.Seats) {
            this.Step--;

            if (this.Step == WpArenaSaleSteps.Seances) {
                this._metricsService.RegistrateEvent(MetricsEvents.WidgetToArenaSeances);
            };
        };
    };

    async CartTimeout() {
        this.Cart.OrderId = undefined;
        this.Cart.Clear();
        this.Cart.Reset();
    };

    ForcedSeanceDetected(seance: ArenaSeance): void {
        this.CurrentSeance = seance;
        this.ForcedSeance = true;
    };

    ForcedSectorDetected(sector: ArenaSector): void {
        this.CurrentSector = sector;
        this.ForcedSector = true;
    };
};

export enum WpArenaSaleSteps {
    Seances = 1,
    Sectors = 2,
    Seats = 3,
};

export class WpArenaDictionary extends BaseUILocalizationDictionary {
    ArenaText: string = '';
};