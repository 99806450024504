export default class NewTelData {
    CallId: string = "";
    ClientNumber: string = ""
    ConfirmationNumber: string = "";
    IsMnp: boolean = false;
    OperatorNameMnp?: string;
    OpertatorName?: string;
    QrCodeUri: string = "";
    RegionName: string = "";
    UserData?: string;
}