import Vue, { VueConstructor } from 'vue';
import { Prop, DefaultProps } from "vue/types/options";
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { OrderItem, PersonificationApiOrderItem, PersonSale, IContext, IdentitySymbols, BaseSystemLocalizationDictionary } from 'web-platform-core-ui';
import { DatePickerType } from 'vuetify';
import { Watch } from 'vue-property-decorator';

export const wpPersonificationItemProps = Vue.extend({
    props: {
        OrderItem: Object as Prop<OrderItem>,
        Personification: Object as Prop<PersonificationApiOrderItem>,
        UserPersons: Array,
        removable: {
            type: Boolean,
            default: true,
            required: false,
        }
    }
})

@Component({
    template: require("./wp-personification-item.html")
})
export default class WpPersonificationItem extends mixins<WpBaseComponent<WpPersonificationItemDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, wpPersonificationItemProps, Vue) {
    private _selectedPerson!: PersonSale;

    IsAuthetificated: boolean = false;
    Loading: boolean = false;
    IsDisabled: boolean = false;
    Yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().substr(0, 10);

    // ///////////////////////////////////\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\
    // WATCH EVERY FIELD TO REMOVE SPACES (Maybe there is a better solution?)
    // Point of interest: v-model.trim doesn't seem to work for v-text-field
    @Watch("Personification.FirstName")
    PFN(): void {
        this.$nextTick(() => {
            this.Personification.FirstName = this.RemoveSpaces(this.Personification.FirstName);
        });
    };
    @Watch("Personification.LastName")
    PLN(): void {
        this.$nextTick(() => {
            this.Personification.LastName = this.RemoveSpaces(this.Personification.LastName);
        });
    };
    @Watch("Personification.SecondName")
    PSN(): void {
        this.$nextTick(() => {
            this.Personification.SecondName = this.RemoveSpaces(this.Personification.SecondName);
        });
    };
    RemoveSpaces(text: string): string {
        return text.replace(/\s+/g, "").trim();
    };
    // \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\/////////////////////////////////
    
    public Genders: Array<any> = [
        { text: 'Мужской', value: "1" },
        { text: 'Женский', value: "2" },
    ];

    get SelectedPerson() {
        return this._selectedPerson;
    };

    set SelectedPerson(personId: any) {
        this._selectedPerson = this.UserPersons.find((x: PersonSale) => (x as PersonSale).PersonId == personId) as PersonSale;

        if (this._selectedPerson === undefined || this._selectedPerson.PersonId == 0) {
            this.Personification.PersonId = this.OrderItem.AdditionalInfo.PersonId;
            
            this.Personification.FirstName = '';
            this.Personification.LastName = '';
            this.Personification.SecondName = '';
            this.Personification.Phone = '';

            this.IsDisabled = false;
        } else {
            this.Personification.PersonId = this._selectedPerson.PersonId;
            this.Personification.FirstName = this._selectedPerson.FirstName;
            this.Personification.LastName = this._selectedPerson.LastName;
            this.Personification.SecondName = this._selectedPerson.SecondName;
            this.Personification.Phone = this._selectedPerson.Phone;

            this.IsDisabled = true;
        };
    };

    get Rules(): any {
        return {
            required: (value: string) => !!value || this.Terms.FieldRequired,
            personname: (value: string) => {
                const pattern = /^[a-zA-Zа-яёА-ЯЁ\-]+( [a-zA-Zа-яёА-ЯЁ\-]+)*$/
                if (value === '') return true;
                return pattern.test(value) || this.Terms.UnacceptableCharacters
            }
        };
    };

    get BirthdateRules() {
        return [
            (v: string) => v !== '' || 'Поле нельзя оставить пустым'
        ];
    };

    get RadioRules() {
        return [
            (v: string) => !!v || 'Выберите один из вариантов'
        ];
    };

    BirthdateMenu: boolean = false;
    Birthdate: string = "";

    get BirthdateFormatted(): string {
        return this.Birthdate != '' ? this.DateHandler.Parse(this.Birthdate, 'YYYY-MM-DD').Format('DD.MM.YYYY') : '';
    };

    UpdateBirthdate(): void {
        if (this.Birthdate !== '') {
            this.Personification!.BirthDate = this.DateHandler.Parse(this.Birthdate, 'YYYY-MM-DD').Format('YYYY-MM-DDT12:00:00');
        } else {
            this.Personification!.BirthDate = null;
        };
    };

    created() {
        let context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
        this.IsAuthetificated = context.CurrentIdentity.IsAuthentificated;
        this.SelectedPerson = this.UserPersons[0] as PersonSale;
    };

    Remove() {
        this.Loading = true;
        this.$emit("ItemRemove", this.OrderItem);
    };
};

export class WpPersonificationItemDictionary extends BaseSystemLocalizationDictionary {
    LabelFirstName: string = 'Имя';
    LabelSecondName: string = 'Отчество';
    LabelLastName: string = 'Фамилия';
    LabelBirthDate: string = 'Дата рождения';
    LabelGender: string = 'Пол';
    FieldRequired: string = 'Необходимо заполнить';
    UnacceptableCharacters: string = 'Введены недопустимые символы';
    PersonifyOn: string = 'Персонифицировать на:';
    GenericDecline: string = 'Отказаться';
    GenericTotalSum: string = 'Сумма';
};