import Member from '../Questionnaire/member';

export default class PersonificationApiOrderItem {
    Id: number = 0;
    PersonId: number = 0;
    FirstName: string = '';
    LastName: string = '';
    SecondName: string = '';
    BirthDate: string = '';
    Email: string = '';
    Phone: string = '';
    Gender: string = '';
    IsdTariffpersonification: Array<Member> = [];


    get IsValid(): boolean {
        let secondnameRequired = this.IsdTariffpersonification.find(x => x.Name == 'SecondName');
        let secondnameEnabled = this.IsdTariffpersonification.some(x => x.Name == 'SecondName');

        let birthdateRequired = this.IsdTariffpersonification.find(x => x.Name == 'Birthdate');
        let birthdateEnabled = this.IsdTariffpersonification.some(x => x.Name == 'Birthdate');

        let genderRequired = this.IsdTariffpersonification.find(x => x.Name == 'Gender');
        let genderEnabled = this.IsdTariffpersonification.some(x => x.Name == 'Gender');

        let regexp: RegExp = /^[a-zA-Zа-яёА-ЯЁ\-]+( [a-zA-Zа-яёА-ЯЁ\-]+)*$/;

        if ((secondnameEnabled && secondnameRequired?.Required) && (birthdateEnabled && birthdateRequired?.Required) && (genderEnabled && genderRequired?.Required)) {
            return regexp.test(this.FirstName) && regexp.test(this.LastName) && regexp.test(this.SecondName) && this.Gender != '' && this.BirthDate != '';
        }

        if ((secondnameEnabled && secondnameRequired?.Required) && (birthdateEnabled && birthdateRequired?.Required) && !(genderEnabled && genderRequired?.Required)) {
            return regexp.test(this.FirstName) && regexp.test(this.LastName) && regexp.test(this.SecondName) && this.BirthDate != '';
        }

        if ((secondnameEnabled && secondnameRequired?.Required) && !(birthdateEnabled && birthdateRequired?.Required) && (genderEnabled && genderRequired?.Required)) {
            return regexp.test(this.FirstName) && regexp.test(this.LastName) && regexp.test(this.SecondName) && this.Gender != '';
        }

        if (!(secondnameEnabled && secondnameRequired?.Required) && (birthdateEnabled && birthdateRequired?.Required) && (genderEnabled && genderRequired?.Required)) {
            return regexp.test(this.FirstName) && regexp.test(this.LastName) && this.Gender != '' && this.BirthDate != '';
        }

        if (!(secondnameEnabled && secondnameRequired?.Required) && !(birthdateEnabled && birthdateRequired?.Required) && (genderEnabled && genderRequired?.Required)) {
            return regexp.test(this.FirstName) && regexp.test(this.LastName) && this.Gender != '';
        }

        if (!(secondnameEnabled && secondnameRequired?.Required) && (birthdateEnabled && birthdateRequired?.Required) && !(genderEnabled && genderRequired?.Required)) {
            return regexp.test(this.FirstName) && regexp.test(this.LastName) && this.BirthDate != '';
        }

        if ((secondnameEnabled && secondnameRequired?.Required) && !(birthdateEnabled && birthdateRequired?.Required) && !(genderEnabled && genderRequired?.Required)) {
            return regexp.test(this.FirstName) && regexp.test(this.LastName) && regexp.test(this.SecondName);
        }
        return regexp.test(this.FirstName) && regexp.test(this.LastName);
    }
}