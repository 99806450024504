import ArenaPolygonPoint from './arena-polygon-point';
import GlobalService from '..';

export default class ArenaSector {
    Id: number = 0;
    SectorCode: number = 0;
    Name: string = '';
    PlacesWithoutNumbers: boolean = false;
    Points: Array<ArenaPolygonPoint> = [];

}