import { ClubCardType } from './club-card-type';

export default class ClubCard {
    ACCOUNT_YES?: boolean;
    /** xs:long */
    ACC_DISCOUNT?: number;

    CARD_FREEZE_LIST?: Array<any>;
    CARD_USED_FREEZE_LIST?: Array<any>;
    CARD_VISIT_LIST?: Array<any>;

    /** xs:boolean */
    CHILD_CARD?: boolean;           // false
    CONTRACT?: number;              // 3480
    CSHI_ID?: number;               // 14541915

    /** xs:long */
    CURRENT_NUMBER?: number;        // 1620
    /** xs:long */
    DESIGN?: number;                // 0
    DISCOUNT?: number;              // 14535818
    /** xs:boolean */
    FIXED_YES?: boolean;            // false
    FREEZE_DAYS_AMOUNT?: number;    // 90
    FREEZE_DAYS_EXTRA?: number;     // 0
    FREEZE_DAYS_REST?: number;      // 90
    FREEZE_END_DATE?: string;       // "0001-01-01T00:00:00"
    FREEZE_YES?: boolean;           // true

    /** xs:long */
    ID?: number;                    // 2985

    ISSUE_DATA?: string;            // "0001-01-01T00:00:00"
    IS_FREEZED?: boolean;           // false

    /** xs:long */
    KIND?: number;                  // 4
    /** xs:string */
    KIND_DESC?: string;             // "������� 1"
    /** xs:string */
    NAME?: string;                  // "(��)�������������� ���"
    /** xs:long */
    NEW_DESIGN?: number;            // 0
    /** xs:string */
    NUMBER?: string;                // "1620"
    OWNER_IS_CHILD?: boolean;       // false
    OWNER_ORGANISATION?: number;    // 0
    OWNER_PERSON?: number;          // 14158733

    /** xs:long */
    SERVICE?: number;               // 0
    SHABLON?: number;               // 14535611
    STATUS?: number;                // 1
    TIC_NUM?: number;               // 0
    /** ClubCardType|xs:string|cctDefault,cctBarcode,cctStripe */
    TYPE?: ClubCardType;            // 1
    VALID_FROM?: string;            // "2022-09-21T00:00:00"
    VALID_TO?: string;              // "2023-09-20T00:00:00"
};
