import ClubCard from './club-card';
import { ContractActualPeriod } from './contract-actual-period';
import { FixedPriceType } from './fixed-price-type';
import { ContractPaymentType } from './contract-payment-type';
import { ContractPeriod } from './contract-period';
import { ContractProcedure } from './contract-procedure';
import { ContractServicePeriod } from './contract-service-period';
import ContractService from './contract-service';

export default class ClubContractData {
    /** xs:boolean */
    ACCOUNT_YES?: boolean;
    /** xs:long */
    ACC_DISCOUNT?: number;
    /** xs:string */
    ACC_DISCOUNT_NAME?: string;
    /** xs:dateTime */
    ACTUAL_DATA?: string;
    /** ContractActualPeriod|xs:string|capAny,capDay,capWeek,capMonth,capQuarter,capSixMonth,capYear,capAllPeriod */
    ACTUAL_PERIOD?: ContractActualPeriod;
    /** xs:long */
    ACTUAL_QUANTITY?: number;
    /** xs:long */
    CARD?: number;
    /** CARD_LIST */
    CARD_LIST?: Array<ClubCard>;
    /** xs:string */
    CARD_LIST_STR?: string;
    /** xs:string */
    CARD_NAME?: string;
    /** xs:double */
    CHECK_ACCOUNT?: number;
    /** xs:long */
    CURRENCY?: number;
    /** xs:long */
    CURRENCY_TYPE?: number;
    /** xs:long */
    CURRENT_NUMBER?: number;
    /** xs:double */
    ENSUR_AMOUNT?: number;
    /** xs:double */
    ENSUR_SUMMA?: number;
    /** xs:double */
    ENTRY_FEE?: number;
    /** xs:double */
    EXTEND_AMOUNT?: number;
    /** xs:double */
    EXTEND_DAYS_AMOUNT?: number;
    /** xs:long */
    EXTEND_DAYS_COUNT?: number;
    /** xs:long */
    EXTEND_DISCOUNT?: number;
    /** xs:string */
    EXTEND_DISCOUNT_NAME?: string;
    /** xs:double */
    EXTEND_PERCENT?: number;
    /** xs:boolean */
    EXTEND_YES?: boolean;
    /** xs:long */
    FAMILY_YES?: number;
    /** FixedPriceType|xs:string|ftpNo,ftpCard,ftpContract */
    FIXED_PRICE_YES?: FixedPriceType;
    /** xs:boolean */
    FIXED_YES?: boolean;
    /** ���� true - �������� ����� ������� �������� */
    FOR_CHILD?: boolean;
    /** xs:long */
    FREEZE?: number;
    /** xs:string */
    FREEZE_NAME?: string;
    /** xs:boolean */
    FREEZE_YES?: boolean;
    /** xs:long */
    ID?: number;
    /** xs:long */
    KIND?: number;
    /** xs:string */
    KIND_DESC?: string;
    /** xs:string */
    NAME?: string;
    /** xs:string */
    NUMBER?: string;
    /** ContractPaymentType|xs:string|cptNow,cptCredit */
    PAYMENT_TYPE?: ContractPaymentType;
    /** ContractPeriod|xs:string|cpAny,cpDay,cpWeek,cpMonth,cpQuarter,cpSixMonth,cpYear,cpFixed */
    PERIOD?: ContractPeriod;
    /** xs:long */
    PERIOD_QUANTITY?: number;
    /** xs:double */
    PRICE?: number;
    /** ContractProcedure|xs:string|cprCard,cprVisit,cprPayment,cprFreeze,cprProcedure,cprSheduke */
    PROCEDURE?: ContractProcedure;
    /** xs:long */
    REPORTFILE?: number;
    /** xs:long */
    SERVICE?: number;
    /** xs:long */
    SERVICE_DISCOUNT?: number;
    /** xs:string */
    SERVICE_DISCOUNT_NAME?: string;
    /** xs:string */
    SERVICE_NAME?: string;
    /** ContractServicePeriod|xs:string|spAny,spWeek,spMonth1,spMonth2,spMonth3,spMonth6,spYear,spDays */
    SERVICE_PERIOD?: ContractServicePeriod;
    /** xs:long */
    SERVICE_PERIOD_DAYS?: number;
    /** xs:long */
    SERVICE_QUANTITY?: number;
    /** xs:boolean */
    SHEDULED?: boolean;
    /** xs:long */
    SYS_STATUS?: number;

    SERVICE_LIST?: Array<ContractService>;
}
