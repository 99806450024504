import Vue from 'vue';
import WpBaseComponent from './../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { INotificationService, NotificationSymbols, NotificationEventArgs } from 'web-platform-core-ui';

export const WpTopBarProp = Vue.extend({
    props: {
        RightButtons: Array,
        DisableBars: Boolean,
        SpaceToCenter: Boolean,
        color: String,
        app: Boolean,
        dark: Boolean,
        height: {
            type: [Number, String],
            default: 56
        },
        elevation: {
            type: [Number, String],
            required: false,
            default: 4
        },
        default: String,
    }
})

@Component({
    template: require("./wp-top-bar.html"),
    mixins: [Vue, WpTopBarProp]
})
export default class WpTopBar extends WpBaseComponent {
    Title: string = "";
    get NeedBack(): boolean {
        return this.RouteBack != '';
    }
    Loading: boolean = false;
    RouteBack: string = '';
    LeftButton: any = null;

    mounted() {
        let _this = this;
        this.$router.beforeEach((to, from, next) => {

            to.meta?.leftButton ? this.LeftButton = to.meta.leftButton : this.LeftButton = null;

            if (to.meta?.title != null) {
                _this.Title = to.meta.title as string;
            }

            if (to.meta?.routeback != null && to.meta.routeback != '') {
                _this.RouteBack = to.meta.routeback as string;
            } else {
                this.RouteBack = '';
            }

            next();
        });

        let notifitcationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
        notifitcationService.NotificationSended.Subscribe((sender: any, e: NotificationEventArgs) => {
            if (e.Data.SystemProcessKey == "WpPageBaseComponentLoading" && e.Data.AdditionalData != null) {
                _this.Loading = e.Data.AdditionalData as boolean
            }
        });
    };

    ToggleDrawer() {
        this.$emit("ToggleDrawer");
    };

    AppGoBack() {
        const _global = (window || global) as any;
        if (_global.androidBackButtonPressed != null) _global.androidBackButtonPressed();
        else this.$router.push({ name: this.RouteBack });
    };
};