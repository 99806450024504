import { BarcodeType } from '../Common';
import SocialNetworkData from './social-network-data';

export default class IsdUserLoyalty {
    Id: number = 0;
    PersId: number = 0;
    TNodId: number = 0;
    FirstName: string = '';
    LastName: string = '';
    SecondName: string = '';
    Gender?: number;
    AddressId?: number;
    City: string = '';

    Phone: string = '';
    Birthdate?: Date;
    Balance: number = 0;
    Discount: string = '';
    MoneyBalance: number = 0;

    ProgramId: number = 0;
    ProgramName: string = '';
    RuleAddress: string = '';

    OrganizationId: number = 0;
    OrganizationSiteUrl: string = '';
    OrganizationName: string = '';
    OrganizationEmail: string = '';
    OrganizationPhone: string = '';

    EmailNotification: boolean = false;
    PushNotification: boolean = false;
    SmsNotification: boolean = false;
    EnableUsingBonuses: boolean = true;
    
    CardNumber: string = '';
    BarcodeType: BarcodeType = BarcodeType.Code128B;

    SocialNetworks: Array<SocialNetworkData> = [];
}