import './wp-bar-chart.scss';

import Vue, { VueConstructor } from 'vue';

import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { DefaultProps } from 'vue/types/options';
import WpBaseComponent from '../../wp-base-component';

export const wpBarChartProps = Vue.extend({
    props: {
        Data: Array
    }
})

@Component({
    template: require("./wp-bar-chart.html")
})
export default class WpBarChart extends mixins<WpBaseComponent, DefaultProps, VueConstructor>(WpBaseComponent, wpBarChartProps, Vue) {
    protected BarHeight: string = '128px';
}

class BarData {
    Id?: number;
    Label!: string;
    Value!: number;
    Width?: number;
}