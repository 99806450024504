import './wp-instructors.scss';

import Vue from 'vue';
import { Prop, DefaultProps } from 'vue/types/options';
import WpBaseComponent from './../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { VueConstructor } from 'vue/types/umd';
import GlobalService, {
	ActionResult,
	AppSettings,
	BaseSystemLocalizationDictionary,
	InstructorsSymbols,
	IInstructorsService,
	PersonalLessonsFilter,
	InstructorsPersonalLesson,
	InstructorsPersonalCartItem,
	InstructorTariffCategory,
	SkillType,
	InstructorsPlace,
	InstructorsLessonsLocation,
	INotificationService,
	NotificationSymbols,
	CartItem,
	Cart,
	CartItemTypes,
	WpCorePrefixes,
	ICmsService,
	CmsSymbols,
	BaseFilter,
	Tariff,
	Instructor,
} from 'web-platform-core-ui';
import WpInstructorsList from './WpInstructorsList/wp-instructors-list';
import { Ref } from 'vue-property-decorator';

Vue.component('wp-instructors-list', WpInstructorsList);

export const wpInstructorsProp = Vue.extend({
	props: {
		Cart: Object as Prop<Cart>,
	},
});

@Component({
	template: require('./wp-instructors.html'),
})
export default class WpInstructors extends mixins<WpBaseComponent<WpInstructorsDictionary>, DefaultProps, VueConstructor>(
	WpBaseComponent,
	wpInstructorsProp,
	Vue
) {
	private _allTariffs: Array<Tariff> = [];
	private _instructorsService!: IInstructorsService;
	protected _notificationService!: INotificationService;
	private _cmsService!: ICmsService;

	Settings!: AppSettings;
	DatepickerSettings: any = {};

	SelectedPlace: InstructorsPlace | null = null;

	Skill: string = '';
	Skills: Array<SkillType> = [];
	SkillsLoaded: boolean = false;
	SkillsVisible: boolean = true;
	NoSkills: boolean = false;

	TariffId: number = 0;
	Tariffs: Array<Tariff> = [];
	TariffLoaded: boolean = false;
	TariffVisible: boolean = true;
	NoTariffs: boolean = false;

	TariffChanged(tariffCode: number) {
		this.TariffId = tariffCode;
		this.ResetFilterResults();
	}

	CategoryId: string = '';
	Categories: Array<InstructorTariffCategory> = [];
	CategoriesLoaded: boolean = false;
	CategoriesVisible: boolean = true;
	NoCategories: boolean = false;

	LocationId: number = 0;
	Locations: Array<InstructorsLessonsLocation> = [];
	LocationsLoaded: boolean = false;
	LocationsVisible: boolean = true;
	NoLocations: boolean = false;

	ShowButtonVisible: boolean = true;

	Filter?: PersonalLessonsFilter;
	FilterLoading = false;
	LessonsLoaded = false;
	Lessons?: Array<InstructorsPersonalLesson>;

	Dates: Array<string> = [];
	DatesVisible: boolean = true;
	Datepicker: boolean = false;

	Instructor: Instructor | null = null;
	NoCoach: boolean = false;

	NoAvailablePlaces: boolean = false;

	@Ref('vselect')
	protected vselect?: any;

	Select(i: Instructor) {
		this.Instructor = i;
		this.vselect.blur();
	}

	//=========== Хуки Vue ===========\\
	created(): void {
		this._instructorsService = this.GlobalService.Get<IInstructorsService>(InstructorsSymbols.InstructorsService);
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
		this._cmsService = this.GlobalService.Get<ICmsService>(CmsSymbols.CmsService);

		this.Settings = GlobalService.GetSettings<AppSettings>();
		if (this.Settings.NoSkills) this.NoSkills = true;
		if (this.Settings.NoCategories) this.NoCategories = true;
		if (this.Settings.NoTariffs) this.NoTariffs = true;
		if (this.Settings.NoLocations) this.NoLocations = true;
		if (this.Settings.NoCoach) this.NoCoach = true;
	}

	async mounted(): Promise<void> {
		this.StartDatepicker();

		await this.LoadPlaces();
		await this.LoadSkills();
		await this.LoadCategories();
		await this.LoadLocations();
		await this.LoadTariffs();

		if (!this.SkillsVisible && !this.CategoriesVisible && !this.Dates && !this.LocationsVisible) {
			await this.GetPersonalLessonsAsync();
			this.ShowButtonVisible = false;
		}

		this.UpdateData();
	}
	//================================\\

	async LoadPlaces(): Promise<void> {
		let query = await this._instructorsService.GetPlacesAsync();

		if (!query.Success || query.Data == undefined)
			return this._notificationService.Error('Ошибка', query.ErrorMessage ? query.ErrorMessage : 'Непредвиденная ошибка');

		if(query.Data.length == 0){
			this.NoAvailablePlaces = true;
			return;
		}			

		if ('PlaceId' in this.Settings) {
			let place = query.Data.filter((x) => x.Id == this.Settings?.PlaceId);
			this.SelectedPlace = place.length !== 0 ? place[0] : query.Data[0];
		} else {
			this.SelectedPlace = query.Data[0];
		}
	}
	async LoadSkills(): Promise<void> {
		let Skills = await this._instructorsService.GetSkillAsync(this.SelectedPlace!.Id);
		if (Skills.Data !== undefined && Skills.Data.length >= 1) {
			await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.InstructorsSkillsNames, Skills.Data);
			let translateSkill = await this._cmsService.GetEntitiesAsync<SkillType>(WpCorePrefixes.InstructorsSkillsNames, new BaseFilter(), '');

			if (translateSkill.Success && translateSkill.Data != null) {
				Skills.Data.forEach((x) => {
					let skill = translateSkill.Data?.Entities.find((sc) => sc.Code == x.Id);
					if (skill != null) x.Name = skill.Name;
				});
			}

			this.Skills = Skills.Data.sort((x, y) => {
				if (x.Name == y.Name) return 0;
				if (x.Name < y.Name) return -1;
				else return 1;
			});

			if (Skills.Data.length === 1) {
				this.Skill = this.Skills[0].Name;
				this.SkillsVisible = false;
			}
		}
		this.SkillsLoaded = true;
	}
	async LoadCategories(): Promise<void> {
		let Categories = await this._instructorsService.GetInstructorsTariffCategoriesAsync();
		if (Categories.Data !== undefined && Categories.Data.length >= 1) {
			await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.InstructorsTariffOptionNames, Categories.Data);

			let translateCategories = await this._cmsService.GetEntitiesAsync<InstructorTariffCategory>(
				WpCorePrefixes.InstructorsTariffOptionNames,
				new BaseFilter(),
				''
			);

			if (translateCategories.Success && translateCategories.Data != null) {
				Categories.Data.forEach((x) => {
					let category = translateCategories.Data?.Entities.find((sc) => sc.Code == x.Id);
					if (category != null) x.Name = category.Name;
				});
			}

			this.Categories = Categories.Data.sort((x, y) => {
				if (x.Name == y.Name) return 0;
				if (x.Name < y.Name) return -1;
				else return 1;
			});

			if (this.Categories.length === 1) {
				this.CategoryId = this.Categories[0].Id.toString();
				this.CategoriesVisible = false;
			}
		}
		this.CategoriesLoaded = true;
	}
	async LoadLocations(): Promise<void> {
		let query = await this._instructorsService.GetLessonsLocationsAsync();
		if (query.Data !== undefined && query.Data.length >= 1) {
			this.Locations = query.Data.sort((x, y) => {
				if (x.Name == y.Name) return 0;
				if (x.Name < y.Name) return -1;
				else return 1;
			});

			if (this.Locations.length === 1) {
				this.LocationId = this.Locations[0].LocationId;
				this.LocationsVisible = false;
			}
		}

		this.LocationsLoaded = true;
	}
	async LoadTariffs(): Promise<void> {
		this.TariffLoaded = false;
		let query = await this._instructorsService.GetInstructorsTariffsAsync();
		if (query.Data !== undefined && query.Data.length >= 1) {
			await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.Tariff, query.Data);
			this._allTariffs = query.Data;

			if (this.Settings.LessonsTariffsId !== undefined && this.Settings.LessonsTariffsId.length > 0)
				this._allTariffs = this._allTariffs.filter((x: Tariff) => this.Settings.LessonsTariffsId.indexOf(x.Id) !== -1);

			/*if (this.Settings.IndividualLessonsTariffsId !== undefined && this.Settings.IndividualLessonsTariffsId.length > 0)
                this._allTariffs = this._allTariffs.filter((x: Tariff) => this.Settings.IndividualLessonsTariffsId.indexOf(x.Id) !== -1)*/
		}
		this.TariffLoaded = true;
	}

	get FilteredSkills() {
		if (this.Instructor) {
			let skills = new Array();
			Object.keys(this.Instructor!.Skills).forEach((key, index) => {
				let name = Object.values(this.Instructor!.Skills)[index];
				let skill = { Id: key, Name: name };
				skills.push(skill);
			});
			return skills;
		}
		return this.Skills;
	}

	get Category(): string {
		if (this.Categories.length !== 0) {
			let categoryById = this.Categories.find((x) => x.Id == parseInt(this.CategoryId));
			if (categoryById !== null && categoryById !== undefined) {
				return categoryById.Name;
			} else {
				return '';
			}
		} else {
			return '';
		}
	}

	get DatesArray(): Array<string> {
		let datesArray = [];
		let firstDate = this.DateHandler.ParseToDate(this.Dates[0], 'YYYY-MM-DD');

		if (this.Dates.length == 2) {
			let lastDate = this.DateHandler.ParseToDate(this.Dates[1], 'YYYY-MM-DD');

			while (firstDate <= lastDate) {
				datesArray.push(this.DateHandler.Parse(firstDate).Format('YYYY-MM-DD'));
				firstDate.setDate(firstDate.getDate() + 1);
			}
		} else if (this.Dates.length == 1) {
			datesArray.push(this.DateHandler.Parse(firstDate).Format('YYYY-MM-DD'));
		}
		return datesArray;
	}

	get FilterComplete(): boolean {
		if (
			this.SkillsLoaded &&
			this.CategoriesLoaded &&
			this.LocationsLoaded &&
			(this.Skill !== '' || this.NoSkills) &&
			(this.CategoryId !== '' || this.NoCategories) &&
			(this.LocationId !== 0 || this.NoLocations) &&
			(this.TariffId !== 0 || this.NoTariffs) &&
			this.Dates.length >= 1
		) {
			return true;
		}
		return false;
	}

	StartDatepicker(): void {
		this.DatepickerSettings = this.Settings;
		this.Datepicker = true;
	}

	UpdateData(): void {
		this.ResetTariffs();
		this.ResetFilterResults();
	}

	FormatDate(dateString: string): string {
		return this.DateHandler.Parse(dateString, 'YYYY-MM-DD').Format('DD.MM.YYYY');
	}

	FormatTime(dateString: string): string {
		return this.DateHandler.Parse(dateString, 'HH:mm:SS').Format('HH:mm');
	}

	ResetFilterResults(): void {
		this.Lessons = [];
		this.LessonsLoaded = false;
	}

	AddToCart(lesson: InstructorsPersonalLesson, amount: number): void {
		let cartItem = new InstructorsPersonalCartItem(lesson, amount, this.Cart);
		let result = this.Cart.AddItem(cartItem);

		if (!result.Success) {
			this._notificationService.Error('Ошибка', result.ErrorMessage ? result.ErrorMessage : 'Непредвиденная ошибка');
		} else {
			var cartItems = this.Cart.SimpleCart();
			parent.postMessage({ status: 'addedtocart', data: cartItems }, '*');
		}
	}

	get LessonsBought(): Array<CartItem> {
		let lessonsBought: Array<CartItem> = [];
		this.Cart.Items.forEach((element: CartItem) => {
			if (element.Type == CartItemTypes.InstructorsPersonalLesson) lessonsBought.push(element);
		});
		return lessonsBought;
	}

	async GetPersonalLessonsAsync(): Promise<ActionResult<Array<InstructorsPersonalLesson>>> {
		this.LessonsLoaded = false;
		this.FilterLoading = true;
		let dateFrom = this.DateHandler.Parse(this.Dates[0], 'YYYY-MM-DD').Format('DD.MM.YYYY');
		let dateTo = this.Dates.length == 2 ? this.DateHandler.Parse(this.Dates[1], 'YYYY-MM-DD').Format('DD.MM.YYYY') : dateFrom;

		let coachid = this.Settings.CoachId || null; // Взять coachid из настроек в JSON
		if (this.Instructor) coachid = this.Instructor.Code; // Переопределить, если выбран коуч в выпадающем списке

		let filter = new PersonalLessonsFilter(
			this.SelectedPlace!.Id,
			this.Skill,
			this.CategoryId,
			dateFrom,
			dateTo,
			coachid,
			this.LocationId == 0 ? '' : this.LocationId.toString(),
			this.TariffId
		);

		let filterResult = await this._instructorsService.GetPersonalLessonsAsync(filter);

		this.FilterLoading = false;
		this.LessonsLoaded = true;
		if (filterResult.Data !== null) {
			this.Lessons = filterResult.Data;
			if (this.Settings.LessonsTariffsId !== undefined && this.Settings.LessonsTariffsId.length > 0 && this.Lessons !== undefined)
				this.Lessons = this.Lessons.filter((x) => this.Settings.LessonsTariffsId.indexOf(x.TariffId) > -1);
		}

		return filterResult;
	}

	DatesSet(dates: Array<string>): void {
		this.Dates = dates;
		this.ResetFilterResults();
	}

	ResetTariffs(): void {
		if (this._allTariffs !== undefined) {
			this.Tariffs =
				this.CategoryId !== ''
					? this._allTariffs
							.filter((x) =>
								this.Settings.PersonalLessonsSkillsTariffs[`${this.Skill}`] != undefined
									? this.Settings.PersonalLessonsSkillsTariffs[`${this.Skill}`].indexOf(x.Id) !== -1
									: true
							)
							.filter((x) => x.OptionId === this.CategoryId)
							.sort((x, y) => {
								if (x.Name == y.Name) return 0;
								if (x.Name < y.Name) return -1;
								else return 1;
							})
					: this._allTariffs
							.filter((x) =>
								this.Settings.PersonalLessonsSkillsTariffs[`${this.Skill}`] !== undefined
									? this.Settings.PersonalLessonsSkillsTariffs[`${this.Skill}`].indexOf(x.Id) !== -1
									: true
							)
							.sort((x, y) => {
								if (x.Name == y.Name) return 0;
								if (x.Name < y.Name) return -1;
								else return 1;
							});
		}

		this.TariffId = this.Tariffs.length == 1 ? this.Tariffs[0].TariffCode : 0;
	}
}

export class WpInstructorsDictionary extends BaseSystemLocalizationDictionary {
	Discipline: string = '';
	DisciplinesLoading: string = '';
	Category: string = '';
	CategoriesLoading: string = '';
	Program: string = '';
	ProgramsLoading: string = '';
	DialogBtnShow: string = '';
	Location: string = '';
	LocationsLoading: string = '';
	Instructor: string = '';
	InstructorsLoading: string = '';
	NoAvailablePlaces: string = '';
}
