export default class ParkingSessionInfo {
    SessionId: string = "";
    State: string = ""
    Cost: number = 0;
    StartTime: string = "";
    CostTime: string = "";
    PaymentTimeout: string = "";
    LeavingTimeout: string = "";
    Duration: string = "";
    PaidSum: number = 0;
}