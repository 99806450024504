import { ClubContractStatus } from './club-contract-status';
import ClubContractData from './club-contract-data';

export default class ClubContract {
    ContractCode: number = 0;
    Data: ClubContractData = new ClubContractData();
    Description: string = '';
    Id: number = 0;
    IsGuestContract: boolean = false; // �������� �������� ��������?
    IsTennis?: number;  // 1 ���� �������� ��������� ����������
    // ---
    LTAccessLK?: number;
    LTAchievements?: number;
    LTAvailableAdditionalServices?: string; // Parsing from JSON results in Array<number> "�������������� ������"
    LTExtend?: number;
    LTFinanceChange?: number;
    LTFinanceRead?: number;
    LTHiddenService?: string; // Parsing from JSON results in Array<number> "�������� ������"
    LTRequest?: number;
    LTSchedule?: number;
    LTServices?: number;
    LTFreeze?: number;
    LTBuy?: number;
    // ---
    Name: string = '';
    PlatformEntities?: string;
    Status: ClubContractStatus = ClubContractStatus.Disabled;
    Type?: number;
    ZIndex?: number;
}