
import './wp-minimap.scss'

import Vue from 'vue';
import WpBaseComponent from './../../../wp-base-component'
import Component, { mixins } from 'vue-class-component'
import { Ref } from 'vue-property-decorator';
import GlobalService, { ArenaSeatsList, AppSettings } from 'web-platform-core-ui';
import { Prop } from 'vue/types/options';
import BaseUILocalizationDictionary from './../../../Localization/base-ui-localization-terms';

const WpMinimapProps = Vue.extend({
    props: {
        seats: Object as Prop<ArenaSeatsList>,
        map: HTMLDivElement,
        width: Number,
        height: Number,
        transform: Object,
        arenaposition: Number,
    }
})

@Component({
    template: require('./wp-minimap.html')
})
export default class WpMinimap extends mixins(WpBaseComponent, WpMinimapProps) {
    Settings!: AppSettings;
    @Ref('root') Root!: HTMLElement;
    context!: CanvasRenderingContext2D | null;
    Colors: string[] = [
        "#E53935",
        "#D81B60",
        "#8E24AA",
        "#311B92",
        "#3949AB",
        "#1E88E5",
        "#00ACC1",
        "#00897B",
        "#2E7D32",
        "#7CB342",
        "#EF6C00",
        "#5D4037",
    ];
    aspect = 0.075;
    seatSize = 48 * this.aspect;
    seatBorder = 8 * this.aspect;
    SeatOffsetX = 1;
    SeatOffsetY = 1;
    panning = false;
    active = true;
    pointerDown = false;

    created() {
        this.Settings = GlobalService.GetSettings<AppSettings>();
        if (this.Settings.MinimapAspect) {
            this.aspect = this.Settings.MinimapAspect;
            this.seatSize = 48 * this.aspect;
            this.seatBorder = 8 * this.aspect;
        };
    };

    mounted() {
        let canvas = document.createElement("canvas");
        canvas.width = this.Width
        canvas.height = this.Height
        this.context = canvas.getContext("2d");
        this.Root.appendChild(canvas);
        this.Render();

        this.Root.addEventListener("pointerdown", () => {
            this.pointerDown = true;
        });

        this.Root.addEventListener("pointerleave", () => {
            this.pointerDown = this.panning = false;
        });

        this.Root.addEventListener("pointerup", (event: PointerEvent) => {
            if (!this.panning) {
                let offsetWidth = this.width * this.aspect / this.transform.scale * .5;
                let offsetHeight = this.height * this.aspect / this.transform.scale * .5;
                this.$emit('minimapclick', {
                    x: (event.offsetX - offsetWidth) / this.aspect * this.transform.scale,
                    y: (event.offsetY - offsetHeight) / this.aspect * this.transform.scale,
                });
            };
            this.pointerDown = this.panning = false;
        });

        this.Root.addEventListener("wheel", event => {
            event.preventDefault();
            this.$emit("minimapwheel", {
                x: event.offsetX * this.aspect * this.transform.scale,
                y: event.offsetY * this.aspect * this.transform.scale,
                delta: event.deltaY / 100
            });
        });

        this.Root.addEventListener("pointermove", event => {
            event.preventDefault();
            if (this.pointerDown) this.panning = true;
            if (this.panning) {
                let offsetWidth = this.width * this.aspect / this.transform.scale * .5;
                let offsetHeight = this.height * this.aspect / this.transform.scale * .5;
                this.$emit('minimapmove', {
                    x: (event.offsetX - offsetWidth) / this.aspect * this.transform.scale,
                    y: (event.offsetY - offsetHeight) / this.aspect * this.transform.scale,
                });
            };
        });

        this.Root.ontouchmove = function (event) {
            event.preventDefault();
        };
    };

    Render() {
        this.seats.Places.forEach((place) => {
            if (this.context) {
                if (place.IsFree) {
                    this.context.fillStyle = this.Color(place.TariffCode);
                } else {
                    this.context.fillStyle = "#EFEFEF";
                };
                this.context.beginPath();
                this.context.arc(
                    (place.X - this.SeatOffsetX) * this.seatSize + this.seatSize * .5,
                    (place.Y - this.SeatOffsetY) * this.seatSize + this.seatSize * .5,
                    (this.seatSize - this.seatBorder) * .5,
                    0,
                    2 * Math.PI,
                    false
                );
                this.context.fill();
                this.context.closePath();
            };
        });
    };

    Color(num: number) {
        return this.Colors[num % this.Colors.length];
    };

    get styleLens() {
        return {
            'left': `${-this.transform.x / this.transform.scale * this.aspect}px`,
            'top': `${-this.transform.y / this.transform.scale * this.aspect}px`,
            'width': `${this.width / this.transform.scale * this.aspect}px`,
            'height': `${this.height / this.transform.scale * this.aspect}px`,
        };
    };
    get styleMinimap() {
        return {
            'width': `${this.seatMaxX * this.seatSize}px`,
            'height': `${this.seatMaxY * this.seatSize}px`,
        };
    };
    get seatMaxX() {
        return Math.max(...this.seats.Places.map(place => place.X));
    };
    get seatMaxY() {
        return Math.max(...this.seats.Places.map(place => place.Y));
    };
    get wrapperWidth() {
        return `${this.width * this.aspect}px`;
    };
    get wrapperHeight() {
        return `${this.height * this.aspect}px`;
    };
    get Width() {
        return Math.max(...this.seats.Places.map(p => p.X + 1)) * this.seatSize;
    };
    get Height() {
        return Math.max(...this.seats.Places.map(p => p.Y + 1)) * this.seatSize;
    };
};

export class WpMinimapDictionary extends BaseUILocalizationDictionary {
    Minimap = "Миникарта"
}