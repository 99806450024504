import { injectable } from 'inversify';

import ActionResult from '../Common/action-result';
import { RestClient } from 'typed-rest-client';
import GlobalService from './../Global/global-service';
import { ErrorTypes } from './../Common/error-types';
import { MarketCompany, MarketSchedule, MarketClient, MarketBill, MarketBillInitializeResult } from '.';
import IMarketProvider from './i-market-provider';
import MarketTariffCartItem from './market-tariff-cart-item';
import { OrderSale } from '../Sale';
import { AppSettings } from './../Global/app-settings';

@injectable()
export default class MarketProvider implements IMarketProvider {
    protected _settings: AppSettings;

    constructor() {
        this._settings = GlobalService.GetSettings();
    }


    async GetRulesAsync(): Promise<ActionResult<MarketCompany>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": GlobalService.GetSettings().SiteId,
                "SessionId": GlobalService.GetSettings().SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<any>>(`/websale/api/market/`);

        if (response == null || response.result == null)
            return ActionResult.FailedData(ErrorTypes.InternalError);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError);

        if (response.result.Data?.Error)
            return ActionResult.FailedData(ErrorTypes.InternalError, new MarketCompany(), response.result.Data?.Message);

        let data: MarketCompany = new MarketCompany();
        if (response.result.Data)
            data = response.result.Data[0] as MarketCompany;

        return ActionResult.SuccessData<MarketCompany>(data);
    }

    async GetRuleScheduleAsync(id: number, date: string): Promise<ActionResult<MarketSchedule>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": GlobalService.GetSettings().SiteId,
                "SessionId": GlobalService.GetSettings().SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<any>>(`/websale/api/market/schedule/${id}/?date=${date}`);

        if (response == null || response.result == null)
            return ActionResult.FailedData(ErrorTypes.InternalError);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError);

        if (response.result.Data?.Error)
            return ActionResult.FailedData(ErrorTypes.InternalError, new MarketSchedule(), response.result.Data?.Message);

        let data: MarketSchedule = new MarketSchedule();
        if (response.result.Data)
            data = response.result.Data as MarketSchedule;

        return ActionResult.SuccessData<MarketSchedule>(data);
    }

    async InitializeByBill(billId: number): Promise<ActionResult<MarketBillInitializeResult>> {
        

        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<any>>(`/market/bill/initialize?id=${billId}`);

        if (response == null || response.result == null || response.result.Data == null)
            return ActionResult.FailedData(ErrorTypes.InternalError);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError);

        let data = response.result.Data as MarketBillInitializeResult;
        if (data == null)
            return ActionResult.FailedData(ErrorTypes.NotFoundData, data, response.result.Data?.Message);

        return ActionResult.SuccessData<MarketBillInitializeResult>(data);
    }

    async UnreservateTariffAsync(itemId: number): Promise<ActionResult> {
        

        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<any>>(`/websale/api/market/unreservatetariff/${itemId}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError);

        return ActionResult.SuccessData();
    }

    async ReservateTariffAsync(marketTariffCartItem: MarketTariffCartItem): Promise<ActionResult<OrderSale>> {
        

        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let data = {
            RuleId: marketTariffCartItem.Rule.id,
            Date: marketTariffCartItem.Rule.Quantity[0] == 1 && marketTariffCartItem.Rule.Duration.length > 0 ? marketTariffCartItem.Period.StartDateTime[0] : null,
            ClientId: marketTariffCartItem.Client.Id,
            OrderId: marketTariffCartItem.OrderId,
            ExternalId: marketTariffCartItem.TempId,
            EmployeeId: marketTariffCartItem.Employee?.id
        };

        let response = await client.create<AjaxResult<OrderSale>>(`/websale/api/market/reservate`, data);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError);

        return ActionResult.SuccessData<OrderSale>(response.result?.Data);
    }

    async GetClientAsync(phone: string): Promise<ActionResult<MarketClient>> {
        

        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<MarketClient>>(`/websale/api/market/client/?phone=${phone}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError);

        return ActionResult.SuccessData(response.result?.Data);
    }
}

class AjaxResult<T> {
    Result: number = 0;
    Message: string = '';
    Data?: T;
}
