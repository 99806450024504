export default class ClubCardVisit {
    AMOUNT?: number;        // 1
    CARD?: number;          // 2985
    CSHI_ID?: number;       // 14543110
    CanReserve?: boolean;   // true
    ID?: number;            // 1116115
    NAME?: string;          // "���� �� ������ Premium (12 ���.)"
    PRICE?: number;         // 0
    REST?: number;          // 1
    Reserved?: number;      // 0
    SERVICE?: number;       // 14535679
    Status?: number;        // 1 - Open; 4 - Closed
    TIC_NUM?: number;       // 0
    VALID_FROM?: string;    // "2022-09-21T00:00:00"
    VALID_TO?: string;      // "2023-09-20T00:00:00"
};