import { TariffSeance } from "../Tariffs";

export interface IEcomData {
	GetEcomData(eventId: symbol, items?: any): EcomServicesList | EcomRemoveFromCart | EcomAddToCart | EcomOrder | null;
}

export class EcomDataBase {
	id?: string;
	name?: string;
	list?: string;
	brand: Brand = Brand.ISDS;
	coupon?: string;
	discount?: number;
	quantity?: number;
	variant?: string;
}

/* Действия */
// Отображение списка товаров при инициализации виджета
export class EcomServicesList {
	impressions: EcomProduct[] = [];
}
// Удаление из корзины
export class EcomRemoveFromCart {
	remove?: EcomBaseProducts;
}
// Добавление товаров в корзину
export class EcomAddToCart {
	add?: EcomProducts;
}
// Заказ. purchase состоит из полей actionField и products
export class EcomOrder {
	purchase?: EcomPurchase;
}
/*******************************/

// Расширенный продукт (к базовому добавлена цена)
export class EcomProduct extends EcomDataBase {
	price?: number | null;

	/** Строка для поля variant в ProductList для Ecommerce */
	static GetProductVariant(seance: TariffSeance | undefined, optionName: string) {
       // Если тариф сеансовый, то variant = Время сеанса & Тип тарифа (optionName), к примеру, 12 00-13 00 Детский
       // иначе - тип тарифа (optionName)
       const seanceTime = seance ? TariffSeance.SeanceTime(seance) : '';
       return `${seanceTime} ${optionName ?? ''}`;
    }
}

// Список расширенных продуктов (используется для добавления в корзину)
export class EcomProducts {
	products: EcomProduct[] = [];
}

// Список базовых продуктов (продукты для удаления)
export class EcomBaseProducts {
	products: EcomDataBase[] = [];
}

// Наполнение объекта purchase
export class EcomPurchase extends EcomProducts {
	actionField?: EcomActionField;
}

// Описание номера заказа для purchase -
export class EcomActionField {
	id: string = "";
}

export enum Brand {
	ISDS = 'ISDS',
}
