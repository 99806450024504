export default class GroupLessonsFilter {
    CategoryId: string = '';
    PlaceId: number = 0;
    Skill: string = '';
    DateFrom: string = '';
    DateTo: string = '';
    TariffCode?: number | null;
    SkillLevelId?: number | null;
    CoachId?: number | null;
    Location?: string | null;
    ContractId?: number | null;

    constructor(placeId: number, skill: string, categoryId: string, dateFrom: string, dateTo: string,
        tariffCode?: number | null, skillLevelId?: number | null, coachId?: number | null, location?: string | null,
        contractId?: number | null) {
        this.PlaceId = placeId;
        this.Skill = skill;
        this.CategoryId = categoryId;
        this.DateFrom = dateFrom;
        this.DateTo = dateTo;
        this.TariffCode = tariffCode;
        this.SkillLevelId = skillLevelId;
        this.CoachId = coachId;
        this.Location = location;
        this.ContractId = contractId;
    }
}