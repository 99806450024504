import { AppSettings } from './../Global/app-settings';

export default class SibAppSettings extends AppSettings {
    RedirectUri: string = ''

    get SibAuthority(): string {
        if (window.location.host.toLowerCase().indexOf('test') == -1 &&
            window.location.host.toLowerCase().indexOf('localhost') == -1) {
            return 'https://oauth.arena-expert.ru/auth_ui/';
        }
        else {
            return 'https://sks-test.arena-expert.ru:9050/';
        }
    }

    get SibClientId(): string {
        if (window.location.host.toLowerCase().indexOf('test') == -1 &&
            window.location.host.toLowerCase().indexOf('localhost') == -1) {
            return 'ISD';
        }
        else {
            return 'isd-test';
        }
    }

    public ParseHashParams(): boolean {
        return super.ParseHashParams();
    }
}