import { ErrorTypes } from './error-types';

export default class ActionResult<T = any> {
    Success: boolean = false;
    ErrorMessage?: string;
    ErrorType?: ErrorTypes;
    Data?: T;

    static Success(): ActionResult {
        return { Success: true };
    };

    static SuccessData<T>(data?: T): ActionResult<T> {
        return { Success: true, Data: data };
    };

    static Failed(errorType: ErrorTypes, errorMessage?: string): ActionResult {
        return { Success: false, ErrorMessage: errorMessage, ErrorType: errorType };
    }

    static FailedData<T>(errorType: ErrorTypes, data?: T, errorMessage?: string): ActionResult<T> {
        return { Success: false, ErrorMessage: errorMessage, ErrorType: errorType, Data: data };        
    }
}