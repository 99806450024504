import './wp-arena-seances.scss';

import Vue from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
    AppSettings, IArenaService, ArenaSymbols, ArenaSeance,
    BaseSystemLocalizationDictionary, INotificationService,
    NotificationSymbols, WpCorePrefixes, IMetricsService, MetricsSymbols
} from 'web-platform-core-ui';
import { MetricsEvents } from '../../../metrics-events-symbols';

@Component({
    template: require("./wp-arena-seances.html")
})
export default class WpArenaSeances extends mixins<WpBaseComponent<WpArenaSeancesDictionary>>(WpBaseComponent, Vue) {
    private _arenaService!: IArenaService;
    protected _notificationService!: INotificationService;
    protected _metricsService!: IMetricsService

    Settings!: AppSettings;
    Seances: Array<ArenaSeance> = [];
    ForcedSeance: ArenaSeance | null = null;
    SeancesDates: Array<string> = [];
    Date: string = '';
    TempListSeances: Array<ArenaSeance> = [];

    async DateSet(date: string): Promise<void> {
        if (this.Date == date)
            return;

        this._metricsService.RegistrateEvent(MetricsEvents.WidgetArenaChangeDate, date);

        this.Date = date;
        this.HandleSeance();
    };

    HandleSeance() {
        this.TempListSeances = [];
        if (this.Date == '') {
            this.TempListSeances = this.Seances;
            return;
        }

        let date = this.DateHandler.Parse(this.Date, "DD.MM.YYYY").Format("YYYY-MM-DD");

        this.Seances.forEach(x => {
            if (x.BeginDate.split('T')[0] == date) this.TempListSeances.push(x);
        });
    }

    created() {
        this._arenaService = this.GlobalService.Get<IArenaService>(ArenaSymbols.ArenaService);
        this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
        this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
    }

    async mounted() {
        this.Settings = GlobalService.GetSettings<AppSettings>();

        let seances = await this._arenaService.GetSeancesAsync();

        if (seances.Success && seances.Data != null) {
            await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.ArenaSeance, seances.Data);
            this.Seances = seances.Data.sort((x, y) => {
                if (x.BeginDate == y.BeginDate) return 0;
                if (x.BeginDate! < y.BeginDate!) return -1
                else return 1;
            });
        } else {
            this._notificationService.Error("Ошибка", seances.ErrorMessage ? seances.ErrorMessage : this.Terms.InternalError);
        };

        this.Seances.forEach(x => {
            let date = x.BeginDate.split('T')[0];
            if (!this.SeancesDates.find(d => d == date))
                this.SeancesDates.push(date);
        })

        // Если установлена настройка forceseanceid - найти сеанс, и если есть - кликнуть на него
        if (this.Settings.ForceSeanceId) {
            this.ForcedSeance = this.Seances.find(seance => seance.Id == this.Settings.ForceSeanceId) || null;
            if (this.ForcedSeance) {
                this.Selected(this.ForcedSeance);
                this.$emit("ForcedSeanceDetected", this.ForcedSeance);
            };
        };

        this.HandleSeance();
    };

    Selected(seance: ArenaSeance) {
        this.$emit("SeanceSelected", seance);
    };
}

export class WpArenaSeancesDictionary extends BaseSystemLocalizationDictionary {
    NoAvailableSeances: string = '';
}