import Vue from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { ISaleService, SaleSymbols, BaseSystemLocalizationDictionary } from 'web-platform-core-ui';

@Component({
    template: require("./wp-payconfirm-promo.html")
})

export default class WpPayConfirmPromo extends mixins<WpBaseComponent<WpPayConfirmPromoDictionary>>(WpBaseComponent, Vue) {

    protected _saleService!: ISaleService;

    CodeLoading: boolean = true;
    Code: string = '';

    async SendCode() {
        this.CodeLoading = true;
        /*
        if (this.Step == WpSaleSteps.PayConfirm) {
            await this._saleService.CancelOrderAsync(this.OrderId);
            this.OrderId = 0;
        }

        await this._saleService.ClearOrderSaleStateAsync();

        this.Step = WpSaleSteps.Instructors;
        this.BackLoading = false;
        */
    }

    async mounted() {
        this._saleService = await this.GlobalService.Get<ISaleService>(SaleSymbols.SaleService);
    }
}

export class WpPayConfirmPromoDictionary extends BaseSystemLocalizationDictionary {
    CallToAction: string = 'Воспользуйтесь промокодом!';
    GenericApply: string = 'Применить';
    GenericSuccess: string = 'Вы применили промокод «ABC123»!';
}