import './wp-news-article.scss';

import Vue from 'vue';
import { Prop } from "vue/types/options";
import Component, { mixins } from 'vue-class-component';
import WpPageBaseComponent from './../wp-page-base-component';
import { NewsArticle, ICmsService, CmsSymbols, WpCorePrefixes } from 'web-platform-core-ui';

export const wpNewsArticleProps = Vue.extend({
    props: {
        ArticleId: Number
    }
})

@Component({
    template: require("./wp-news-article.html")
})
export default class WpNewsArticle extends mixins(WpPageBaseComponent, wpNewsArticleProps, Vue) {
    Article: NewsArticle = new NewsArticle();
    ArticleDate?: string = '';
    Loading: boolean = true;

    async mounted() {

        let service = this.GlobalService.Get<ICmsService>(CmsSymbols.CmsService);

        let result = await service.FindEntityAsync<NewsArticle>(WpCorePrefixes.News, this.$props.ArticleId);

        if (result.Success && result.Data != null) {
            this.Article = result.Data;
            this.ArticleDate = this.DateHandler.Parse(this.Article.Date, 'YYYY-MM-DDTHH:mm:SS').Format('DD MMMM YYYY');
            this.Loading = false;
        }
    }
}