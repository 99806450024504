import './wp-tariffs-datepicker.scss';
import Vue from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, { AppSettings, TariffsCalendarType } from 'web-platform-core-ui';
import { PropSync, Watch } from 'vue-property-decorator';
import BaseUILocalizationDictionary from './../../../Localization/base-ui-localization-terms';

@Component({
	template: require('./wp-tariffs-datepicker.html'),
})
export default class WpTariffsDatepicker extends mixins<WpBaseComponent<WpTariffsDatepickerDictionary>>(WpBaseComponent, Vue) {
	static DateSelected: boolean = false;

	SessionDateRangeModal: boolean = false;
	TariffDatePicker: any;
	SessionDateRange: string = '';
	SessionDateRangeSub: string = '';
	SessionDatesVisible: boolean = true;
	CloseAvailable: boolean = false;
	CalendarType: TariffsCalendarType = TariffsCalendarType.Field;

	ShowButtonVisible: boolean = true;

	DateRangeSub: string = '';
	DateRangeTitle: string = '';

	TimeOffset: string = '';

	MinDate: string = '';
	MaxDate: string = '';
	ForceDate?: Date;

	Today: Date = new Date();

	// This array stores AVAILABLE DATES
	allowedDatesArray: Array<any> = [];
	isDateAllowed(dateStr: string): boolean {
		return this.allowedDatesArray.indexOf(dateStr) != -1;
	}

	AllowedDates(val: string): boolean {
		let date = new Date(new Date(val).setHours(0, 0, 0));

		if (!this.DateHandler.IsValid(date)) {
			console.error('Something went horribly wrong - wrong format?');
			this.EmitUnavailable();
			return false;
		}

		// https://redmine.moipass.ru/issues/12516
		if (this.saleDepthDate && date > this.saleDepthDate) return false;

		// https://redmine.moipass.ru/issues/13030
		let disabledDays = this.Settings.DisabledDays;
		if (disabledDays && disabledDays.indexOf(date.getDay()) !== -1) return false;

		// Если дата уже вчера или раньше, то отрубить её по любому
		// https://redmine.moipass.ru/issues/13377
		if (date < new Date(new Date().setHours(0, 0, 0, 0))) return false;

		let dateStr = this.DateHandler.Parse(date).Format('DD.MM.YYYY');
		if (this.Settings.Disableddates?.length > 0) {
			return this.Settings.Disableddates.indexOf(dateStr) === -1;
		} else {
			return true;
		}
	}

	@PropSync('settings', { type: Object }) Settings!: AppSettings;

	@Watch('Settings', { immediate: true })
	OnSettingsChange(val: AppSettings, _oldVal: AppSettings) {
		if (val !== undefined) this.ChangeSettings();
	}

	created(): void {
		let settings = GlobalService.GetSettings<AppSettings>();
		this.Settings = settings;
		this.TariffDatePicker = this.SessionDateRange = this.DateHandler.Parse(this.Today).Format('YYYY-MM-DD');
		this.CalendarType = settings != null ? settings.TariffsCalendar : TariffsCalendarType.Field;

		// Lets set default min/max dates as now and now+30 days temporaryly
		this.MinDate = this.DateHandler.Parse(this.Today).Format('YYYY-MM-DD');
		this.MaxDate = this.DateHandler.Parse(this.Today).Add('days', 30).Format('YYYY-MM-DD');

		if (this.CalendarType == TariffsCalendarType.Page && !WpTariffsDatepicker.DateSelected) this.SessionDateRangeModal = true;
	}

	get DatesChoosen(): string {
		let date = this.DateHandler.Parse(new Date(this.TariffDatePicker)).Format('DD.MM.YYYY');
		return date;
	}

	// feat: https://redmine.moipass.ru/issues/12516
	get saleDepthDate(): Date | null {
		let days = this.Settings.SaleDepth;
		if (!days || isNaN(days)) return null;
		return this.DateHandler.Parse(this.MinDate, 'YYYY-MM-DD').Add('days', Math.abs(days)).ParseToDate();
	}

	async ChangeSettings() {
		this.SessionDatesVisible = !this.Settings.Nocalendar;
		// Берем Offset из настроек сайта (Например "03:00:00")
		this.TimeOffset = this.Settings.CurrentSite?.Offset || '';
		// Конвертируем в милисекунды
		let msOffset = this.DateHandler.ConvertToMilliseconds(this.TimeOffset);
		// Создаём новую дату, с учетом сдвига (Offset)
		let nowDate = new Date();
		var currentDateOffset = Date.UTC(
			nowDate.getUTCFullYear(),
			nowDate.getUTCMonth(),
			nowDate.getUTCDate(),
			nowDate.getUTCHours(),
			nowDate.getUTCMinutes() + nowDate.getTimezoneOffset() + msOffset / 1000 / 60,
			nowDate.getUTCSeconds()
		);
		this.Today = new Date(currentDateOffset);
		/* Логика:
		 * -Создаем новую дату (new Date() )
		 * -getUTCHours например возвращает UTC+0000 часы. Т.е. если в Москве 10 (UTC+03:00), то UTCHours вернет 7
		 *
		 * В итоге:
		 * В Москве 11.01.2022 - 10:50
		 * В Аляске 10.01.2022 - 22:50
		 *
		 * Мы в Аляске. let d = new Date();
		 * console.log( d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds() );
		 * вернет 2022 0 11 7 45 7
		 *
		 * Date.UTC( d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds() )
		 * вернет 1641887799000
		 *
		 * new Date(1641887799000) создаст дату и отнимет у неё 9 часов, что нормально, т.к. мы их отняли ранее
		 * вуаля, мы получили new Date() с таймзоной this.TimeOffset
		 */

		/* --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- */

		// This may be unnecessary
		if (this.Settings.Forcedate == undefined && this.Settings.Nocalendar != undefined) {
			this.selectDate(new Date());
		}

		// Новые мин/макс даты с учётом сдвига
		let currentDate = new Date(currentDateOffset);
		this.MinDate = this.DateHandler.Parse(currentDate).Format('YYYY-MM-DD');
		this.MaxDate = this.DateHandler.Parse(currentDate).Add('days', 30).Format('YYYY-MM-DD');

		if (this.Settings.Maxdate) {
			let maxDateString = this.Settings.Maxdate.split('.').reverse().join('-');
			let maxdate = new Date(maxDateString);
			this.MaxDate = this.DateHandler.Parse(new Date(maxDateString)).Format('YYYY-MM-DD');

			if (maxdate.getTime() < currentDate.getTime()) {
				this.selectDate(maxdate);
			}
		}

		if (this.Settings.Mindate) {
			let mindate = new Date(this.Settings.Mindate.split('.').reverse().join('-'));
			this.MinDate = this.DateHandler.Parse(new Date(mindate)).Format('YYYY-MM-DD');
			if (mindate.getTime() > currentDate.getTime()) this.selectDate(mindate);
		}

		// <- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -> \\
		if (this.Settings.Forcedate) {
			if (this.forcedate && this.DateHandler.IsValid(this.forcedate)) {
				let min = new Date(this.MinDate).getTime();
				let fdt = this.forcedate.getTime();

				if (fdt < min) {
					this.selectDate(new Date(this.MinDate));
				} else {
					this.selectDate(new Date(this.forcedate));
				}
			} else {
				console.error('Force date is invalid');
				this.EmitUnavailable();
			}
		}

		this.HandleMinDate();
		this.HandleForceDate();
		this.HandleDisabledDates();
		this.HandleMaxDate();

		// <- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -> \\
		// Scan ALL AVAILABLE dates from MinDate to MaxDate and add allowed dates to an array
		// Regenerate the array. This works even in older versions of ECMA
		this.allowedDatesArray.length = 0;
		let that = this;

		let between = this.DateHandler.DatesBetween([new Date(this.MinDate), new Date(this.MaxDate)], false, true, true);
		between.forEach(function (d: Date) {
			let date = that.DateHandler.Parse(d).Format('YYYY-MM-DD');
			if (that.AllowedDates(date)) that.allowedDatesArray.push(date);
		});
		if (this.forcedate) {
			let fd = this.DateHandler.Parse(this.forcedate).Format('YYYY-MM-DD');
			this.allowedDatesArray.push(fd);
		}
		// <- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -> \\

		if (this.allowedDatesArray.length === 0) this.$emit('NotAvailable');
		this.$emit('dateSet', this.SessionDateRange);
	}

	ValiDate(date: string) {
		let valid = true;
		if (date == undefined) valid = false;
		if (date.includes('/')) valid = false;
		if (date.includes('-')) valid = false;
		if (!valid) console.error(`Date isn't valid: ${date}`);
		return valid;
	}

	// If TODAY > MINDATE then MINDATE IS TODAY
	HandleMinDate(): void {
		// If MINDATE exists
		if (this.Settings.Mindate) {
			if (!this.ValiDate(this.Settings.Mindate)) return this.EmitUnavailable();

			// Create a Date object from it
			let minDate = new Date(this.Settings.Mindate.split('.').reverse().join('/'));
			// If created Date is valid
			if (this.DateHandler.IsValid(minDate)) {
				// And if today > mindate
				if (this.Today.getTime() > minDate.getTime()) {
					// mindate = today
					this.MinDate = this.DateHandler.Parse(this.Today).Format('YYYY-MM-DD');
				}
			}
		}
	}

	HandleMaxDate(): void {
		if (this.Settings.Maxdate) {
			let maxDate = this.DateHandler.ParseToDate(this.Settings.Maxdate, 'DD.MM.YYYY');
			if (this.DateHandler.IsValid(maxDate)) {
				// Если сегодня перешло за максимальную дату - отключить календарь
				if (
					this.Today.getDate() > maxDate.getDate() &&
					this.Today.getMonth() >= maxDate.getMonth() &&
					this.Today.getFullYear() >= maxDate.getFullYear()
				) {
					this.EmitUnavailable();
				}
			}
			if (this.Settings.Mindate) {
				let minDate = new Date(this.Settings.Mindate.split('.').reverse().join('/'));
				if (minDate.getTime() > maxDate.getTime()) {
					this.EmitUnavailable();
				}
			}
			this.MaxDate = this.DateHandler.Parse(this.Settings.Maxdate, 'DD.MM.YYYY').Format('YYYY-MM-DD');
		}
	}
	get forcedate() {
		return this.Settings.Forcedate ? this.DateHandler.ParseToDate(this.Settings.Forcedate, 'DD.MM.YYYY') : undefined;
	}
	// Set DatePicker date to TODAY or FORCEDATE, depending on which is bigger
	HandleForceDate(): void {
		// If we have forcedate setting in a format of "12.09.2019"
		if (this.forcedate) {
			// If "mindate" setting is present
			if (this.Settings.Mindate) {
				// Create a date from it
				let minDate = new Date(this.Settings.Mindate.split('.').reverse().join('/'));
				// If created date is valid
				if (this.DateHandler.IsValid(minDate)) {
					// and mindate is above forcedate - make forcedate equal to mindate
					if (minDate.getTime() > this.forcedate.getTime()) this.ForceDate = minDate;
				} else {
					console.error('mindate invalid');
					this.EmitUnavailable();
				}
			}

			if (this.DateHandler.IsValid(this.forcedate)) {
				// If today is further than forcedate and forcedate is valid
				if (this.Today.getTime() > this.forcedate.getTime()) {
					// Set current date in date picker to TODAY
					this.selectDate(this.Today);
				} else {
					// Set current date in date picker to FORCEDATE
					this.selectDate(this.forcedate);
				}
			}
		}
	}

	selectDate(date: Date) {
		this.TariffDatePicker = this.DateHandler.Parse(date).Format('YYYY-MM-DD');
		this.SessionDateRange = this.DateHandler.Parse(date).Format('DD.MM.YYYY');
	}

	// Hande disabled dates
	HandleDisabledDates(): void {
		if (this.Settings.Disableddates) {
			let disableddates;

			// Made redundant in appsettings on 13.02.2024 - array check no longer needed
			if (!Array.isArray(this.Settings.Disableddates)) {
				disableddates = new Array(this.Settings.Disableddates);
			} else {
				disableddates = this.Settings.Disableddates;
			}

			disableddates.forEach((date: string) => {
				let d = this.DateHandler.Parse(date, 'DD.MM.YYYY').Format('YYYY-MM-DD');
				if (d == this.TariffDatePicker) {
					let tomorrow = this.DateHandler.Parse(date, 'DD.MM.YYYY').Add('days', 1).ParseToDate();
					this.selectDate(tomorrow);
				}
			});
		}
	}

	ClearDates(): void {
		this.selectDate(new Date(this.MinDate));
	}

	SetDateRange(): void {
		this.CloseAvailable = true;
		WpTariffsDatepicker.DateSelected = true;
		this.SessionDateRangeModal = false;
		this.SessionDateRange = this.DateHandler.Parse(new Date(this.TariffDatePicker)).Format('DD.MM.YYYY');
		this.$emit('dateSet', this.SessionDateRange);
	}

	private EmitUnavailable() {
		this.allowedDatesArray.length = 0;
		this.$emit('NotAvailable');
	}
}

export class WpTariffsDatepickerDictionary extends BaseUILocalizationDictionary {
	TextBoxLabel: string = '';
	TitleText: string = '';
	DialogBtnClean: string = '';
	DialogBtnOk: string = '';
	DialogBtnCancel: string = '';
}
