export default class InstructorsGroupLesson {
    BeginTime?: string;
    BundleId?: number | null;
    Date?: string;
    EndTime?: string;
    ExistToContract: boolean = false;
    FreeMembers: number = 0;
    Id: number = 0;
    InstructorId: number = 0;
    MaxPersonsCount: number = 0;
    PlaceId: number = 0;
    SkillTypeId: number = 0;
    SkillType: string = '';
    TariffId: number = 0;
    TariffName: string = '';
    LocationId: number = 0;
};