import { AppSettings } from './../Global/app-settings';

export default class RHAppSettings extends AppSettings {
    PersonId: number = 0;

    Email: string = "";
    FirstName: string = "";
    LastName: string = "";
    SecondName: string = "";
    Phone: string = "";
    Sign: string = "";
    Gender?: number;

    public ParseHashParams(ignoreWindowName: boolean = false): boolean {
        let result = super.ParseHashParams(ignoreWindowName);

        if (!result) return result; 

        this.Email = this._hashParams.email;
        this.FirstName = this._hashParams.firstName;
        this.LastName = this._hashParams.lastName;
        this.SecondName = this._hashParams.secondName;
        this.Phone = this._hashParams.phone;
        this.Sign = this._hashParams.sign;
        if (this.PlaceId === undefined) {
            this.PlaceId = 5;
        }

        if ('gender' in this._hashParams) {
            this.Gender = this._hashParams.gender;
        }

        this.CartMaxItems = 50;

        return true;
    }
}