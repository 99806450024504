export enum ReservationItemTypes {
    Tariff = 1,
    Bundle = 2,
    PersonalLesson = 3,
    RewritableTariff = 4,
    GroupLesson = 5,
    BundleLesson = 6,
    MarketBill = 7,
    NewClubContract = 8,
    ContractAdditionalService = 9,
    AccountReplenishment = 10,
    SyntechParking = 11,
    ContractAdditionalPackage = 12,
}