import IProfileService from './i-profile-service';
import UserProfile from './user-profile';
import { ActionResult } from './../Common';
import IProfileProvider from './i-profile-provider';
import { ProfileSymbols } from './symbols';
import { inject, injectable } from 'inversify';


@injectable()
export default class DefaultProfileService implements IProfileService {
    private _profileProvider: IProfileProvider;

    constructor(@inject(ProfileSymbols.ProfileProvider) profileProvider: IProfileProvider) {
        this._profileProvider = profileProvider;
    }

    async GetProfileAsync(): Promise<ActionResult<UserProfile>> {
        return await this._profileProvider.GetProfileAsync();
    }
    async EditProfileAsync(profile: UserProfile): Promise<ActionResult> {
        return await this._profileProvider.EditProfileAsync(profile);
    }
    async ChangeEmailAsync(email: string): Promise<ActionResult> {
        return await this._profileProvider.ChangeEmailAsync(email);
    }
    async SetPhoneAsync(phone: string): Promise<ActionResult> {
        return await this._profileProvider.SetPhoneAsync(phone);
    }
    async ChangePasswordAsync(oldPassword: string, newPassword: string, confirmPassword: string): Promise<ActionResult> {
        return await this._profileProvider.ChangePasswordAsync(oldPassword, newPassword, confirmPassword);
    }
    async SendAsync(topic: string, method: string, message: string, email: string | null, phone: string | null, name: string | null): Promise<ActionResult>{
        return await this._profileProvider.SendAsync(topic, method, message, email, phone, name);
    }
}