import Vue, { VueConstructor } from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
    AppSettings, ITariffService, TariffSymbols,
    Tariff, Cart, RewriteCartItem, BaseSystemLocalizationDictionary
} from 'web-platform-core-ui';
import { Prop, DefaultProps } from 'vue/types/options';
import { Watch } from 'vue-property-decorator';
import BaseUILocalizationDictionary from './../../../Localization/base-ui-localization-terms';

export const wpTariffRewriteItemProp = Vue.extend({
    props: {
        tariff: Object as Prop<Tariff>,
        Cart: Object as Prop<Cart>,
        Card: String,
        Date: String,
        CardType: Number,
        CardName: String
    }
})


@Component({
    template: require("./wp-tariff-rewrite-item.html")
})

export default class WpTariffRewriteItem extends mixins<WpBaseComponent<WpTariffRewriteItemDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, wpTariffRewriteItemProp, Vue) {
    _tariffService!: ITariffService;
    private _tariffLoading: boolean = true;

    TariffAvailable: boolean = false;

    Settings!: AppSettings;


    Price: number = 0;

    get TariffLoading(): boolean {
        return this._tariffLoading;
    }
    set TariffLoading(value: boolean) {
        this._tariffLoading = value;
        this.$emit('LoadingChange', this.tariff.Id, this._tariffLoading);
    }

    @Watch('Date')
    DateChanged(val: string, oldVal: string) {
        if (val == null || val == '')
            return;

        this.GetTariffPrice();
    }

    AddToCart(): void {
        this.$emit('addtocart', new RewriteCartItem(this.tariff, this.Date, this.Price, this.Card, undefined, undefined, this.CardType, this.CardName));
    }

    async mounted() {
        this.Settings = GlobalService.GetSettings<AppSettings>();

        this._tariffService = this.GlobalService.Get<ITariffService>(TariffSymbols.TariffService);
        if (this.Date != null && this.Date != '')
            this.GetTariffPrice()
    }

    async GetTariffPrice(): Promise<any> {
        this.TariffLoading = true;

        let result = await this._tariffService.GetTariffPrice(this.Date, this.tariff.Id);
        if (result.Success) {
            this.Price = result.Data;
            this.TariffAvailable = true;
        }
        else {
            this.TariffAvailable = false;
        }
        this.$emit('AvailableChange', this.tariff.Id, this.TariffAvailable);
        this.TariffLoading = false;
    }
}

export class WpTariffRewriteItemDictionary extends BaseUILocalizationDictionary {
    GenericToCart: string = 'В корзину';
    TariffUnavailableForThisDate: string = 'Тариф недоступен на эту дату';
}