import './wp-arena-seances-datepicker.scss';

import Vue from 'vue';
import WpBaseComponent from './../../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, { AppSettings, ArenaCalendarType } from 'web-platform-core-ui';
import { PropSync } from 'vue-property-decorator';
import BaseUILocalizationDictionary from './../../../../Localization/base-ui-localization-terms';

@Component({
	template: require('./wp-arena-seances-datepicker.html'),
})
export default class WpArenasDatepicker extends mixins<WpBaseComponent<WpArenaDatepickerDictionary>>(WpBaseComponent, Vue) {
	static DateSelected: boolean = false;

	SessionDateRangeModal: boolean = false;
	ArenaDatePicker: any;
	SessionDateRange: string = '';
	SessionDateRangeSub: string = '';
	SessionDatesVisible: boolean = true;

	ShowButtonVisible: boolean = true;
	CloseAvailable: boolean = false;

	DateRangeSub: string = '';
	DateRangeTitle: string = '';

	TimeOffset: string = '';

	MinDate: string = '';
	MaxDate: string = '';
	ForceDate?: Date;

	Today: Date = new Date();

	// This array stores AVAILABLE DATES
	@PropSync('allowedDates', { type: Array }) allowedDatesArray!: Array<string>;

	Settings!: AppSettings;

	created(): void {
		this.Settings = GlobalService.GetSettings<AppSettings>();
		this.ArenaDatePicker = this.SessionDateRange = this.DateHandler.Parse(new Date()).Format('YYYY-MM-DD');

		this.HandlingSettings();
		if (this.CalendarType == ArenaCalendarType.Page && !WpArenasDatepicker.DateSelected) this.SessionDateRangeModal = true;
	}

	get CalendarType(): ArenaCalendarType {
		return this.Settings != null ? this.Settings.ArenaCalendar : ArenaCalendarType.None;
	}

	get DatesChoosen(): string {
		let date = this.DateHandler.Parse(new Date(this.ArenaDatePicker)).Format('DD.MM.YYYY');
		return date;
	}

	AllowedDates(val: string): boolean {
		let date = this.DateHandler.Parse(new Date(val)).Format('DD.MM.YYYY');
		if (this.Settings.Disableddates != undefined && this.Settings.Disableddates.length > 0)
			return this.Settings.Disableddates.indexOf(date) === -1 && this.allowedDatesArray.some((x) => x == val);

		return this.allowedDatesArray.some((x) => x == val);
	}

	async HandlingSettings() {
		this.MinDate = this.allowedDatesArray[0] as string;
		this.MaxDate = this.allowedDatesArray[this.allowedDatesArray.length - 1] as string;

		if (this.Settings.Maxdate && this.Settings.Maxdate != '') {
			let maxdate = new Date(this.Settings.Maxdate.split('.').reverse().join('-'));
			this.MaxDate = this.DateHandler.Parse(new Date(maxdate)).Format('YYYY-MM-DD');
		}

		if (this.Settings.Mindate && this.Settings.Mindate != '') {
			let mindate = new Date(this.Settings.Mindate.split('.').reverse().join('-'));
			this.MinDate = this.DateHandler.Parse(new Date(mindate)).Format('YYYY-MM-DD');
		}

		let disableddates: Array<string> = [];
		if (this.Settings.Disableddates) {
			if (!Array.isArray(this.Settings.Disableddates)) {
				new Array(this.Settings.Disableddates).forEach((x) =>
					disableddates.push(this.DateHandler.Parse(x, 'DD.MM.YYYY').Format('YYYY-MM-DD'))
				);
			} else {
				this.Settings.Disableddates.forEach((x) => disableddates.push(this.DateHandler.Parse(x, 'DD.MM.YYYY').Format('YYYY-MM-DD')));
			}
		}

		let arr = this.allowedDatesArray.filter((x: string) => {
			let date = new Date(x);
			return (
				date.getTime() <= new Date(this.MaxDate).getTime() &&
				date.getTime() >= new Date(this.MinDate).getTime() &&
				!disableddates.some((d) => d == x)
			);
		});

		this.SessionDatesVisible = arr.length != 0;
		this.MinDate = arr.length > 0 ? arr[0] : '';
		this.MaxDate = arr.length > 0 ? arr[arr.length - 1] : '';

		// <- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- -> \\
		// { "forcedate":"21.02.2022" }
		if (this.Settings.Forcedate !== undefined) {
			let ForcedDate = new Date(this.Settings.Forcedate.split('.').reverse().join('/'));

			if (this.DateHandler.IsValid(ForcedDate)) {
				let min = new Date(this.MinDate).getTime();
				let max = new Date(this.MaxDate).getTime();
				let fdt = ForcedDate.getTime();

				if (fdt < min) {
					this.ArenaDatePicker = this.DateHandler.Parse(new Date(this.MinDate)).Format('YYYY-MM-DD');
					this.SessionDateRange = this.DateHandler.Parse(new Date(this.MinDate)).Format('DD.MM.YYYY');
				} else if (fdt > max) {
					this.ArenaDatePicker = this.DateHandler.Parse(new Date(this.MaxDate)).Format('YYYY-MM-DD');
					this.SessionDateRange = this.DateHandler.Parse(new Date(this.MaxDate)).Format('DD.MM.YYYY');
				} else {
					if (this.allowedDatesArray.some((x) => new Date(x).getTime() == fdt)) {
						this.ArenaDatePicker = this.DateHandler.Parse(ForcedDate).Format('YYYY-MM-DD');
						this.SessionDateRange = this.DateHandler.Parse(ForcedDate).Format('DD.MM.YYYY');
					} else {
						this.ArenaDatePicker = this.DateHandler.Parse(new Date(this.MinDate)).Format('YYYY-MM-DD');
						this.SessionDateRange = this.DateHandler.Parse(new Date(this.MinDate)).Format('DD.MM.YYYY');
					}
				}
			} else {
				console.error('Force date is invalid');
			}
		} else {
			this.ArenaDatePicker = this.DateHandler.Parse(new Date(this.MinDate)).Format('YYYY-MM-DD');
			this.SessionDateRange = this.DateHandler.Parse(new Date(this.MinDate)).Format('DD.MM.YYYY');
		}

		if (this.CalendarType != ArenaCalendarType.None) this.$emit('dateSet', this.SessionDateRange);
	}

	ClearDates(): void {
		this.ArenaDatePicker = this.DateHandler.Parse(new Date(this.MinDate)).Format('YYYY-MM-DD');
		this.SessionDateRange = this.DateHandler.Parse(new Date(this.MinDate)).Format('DD.MM.YYYY');
	}

	SetDateRange(e: any): void {
		this.CloseAvailable = true;
		WpArenasDatepicker.DateSelected = true;
		this.SessionDateRangeModal = false;
		this.SessionDateRange = this.FormatDate(this.ArenaDatePicker);
		this.$emit('dateSet', this.SessionDateRange);
	}

	FormatDate(date: string): string {
		return this.DateHandler.Parse(new Date(date)).Format('DD.MM.YYYY');
	}

	FormatTime(dateString: string): string {
		return this.DateHandler.Parse(new Date(dateString)).Format('HH:mm');
	}
}

export class WpArenaDatepickerDictionary extends BaseUILocalizationDictionary {
	TextBoxLabel: string = '';
	TitleText: string = '';

	DialogBtnClean: string = '';
	DialogBtnOk: string = '';
	DialogBtnCancel: string = '';
}
