import './wp-password-reset.scss';

import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import GlobalService, { IdentityAccountProvider, INotificationService, NotificationSymbols, ErrorTypes, AppSettings } from 'web-platform-core-ui';
import { Ref } from 'vue-property-decorator';
import WpBaseComponent from '../../wp-base-component';
import BaseUILocalizationDictionary from '../../Localization/base-ui-localization-terms';

@Component({
    template: require("./wp-password-reset.html")
})
export default class WpPasswordReset extends mixins<WpBaseComponent<WpPasswordResetDictionary>>(WpBaseComponent, Vue) {
    protected _notificationService!: INotificationService;
    
    ResetPasswordByPhoneLoading: boolean = false;
    ResetPasswordByEmailLoading: boolean = false;
    ResetPasswordByPhoneFormValid: boolean = false;
    ResetPasswordByEmailFormValid: boolean = false;
    @Ref('ResetPasswordByPhoneForm') ResetPasswordByPhoneForm!: HTMLFormElement;
    @Ref('ResetPasswordByEmailForm') ResetPasswordByEmailForm!: HTMLFormElement;
    @Ref('ConfirmPasswordInput') ConfirmPasswordInput!: any;

    CodeId: number = 0;
    NewPassword: string = '';
    ConfirmPassword: string = '';

    NewPasswordShow: boolean = false;
    ConfirmPasswordShow: boolean = false;
    ResetPasswordLoading: boolean = false;

    Tab: string = 'email';
    ResetPasswordStep: number = 1;

    Email: string = '';

    Settings!: AppSettings;

    get NewPassowrdRules() {
        return [
            (v: string) => !!v || this.Terms.FieldRequiredMessage,
            (v: string) => (v && v.length > 6) || this.Terms.PasswordLengthError,
            (v: string) => /^[а-яА-Яa-zA-Z0-9ёЁ!№;%:?*()_+=\-<>.,/\\'`@#$%^&{}\[\]|""~ ]{0,50}$/.test(v) || this.Terms.PasswordNotAvailableCharacters
        ]
    }

    get ConfirmPassowrdRules() {
        return [
            (v: string) => !!v || this.Terms.FieldRequiredMessage,
            (v: string) => v == this.NewPassword || this.Terms.ComparePasswordError,
        ]
    }

    get EmailRules() {
        return [
            (v: string) => !!v || this.Terms.EmailRequiredMessage,
            (v: string) => /.+@.+\..+/.test(v) || this.Terms.EmailRequiredMessage,
        ]
    }

    NewPasswordChanged(e: any) {
        this.ConfirmPasswordInput.validate();
    }

    CodeConfirmed(code: any): void {
        this.CodeId = code.CodeId;
        this.ResetPasswordStep = 2;       
    }

    TabChanged(tab: string) {
        if (tab == 'email') {
            this.CodeId = 0;
            this.ResetPasswordStep = 1;
        }
    }


    async ResetPasswordByPhoneFormSubmit(e: any) {
        e.preventDefault();
        await this.ResetPasswordByPhone();
    }
    async ResetPasswordByPhone() {
        if (!this.ResetPasswordByPhoneForm.validate())
            return;


        this.ResetPasswordByPhoneLoading = true;
        let _identityAccountPorvider: IdentityAccountProvider = new IdentityAccountProvider();
        let resetResult = await _identityAccountPorvider.ResetPasswordByPhone(this.NewPassword, this.ConfirmPassword);
        if (!resetResult.Success) {
            this._notificationService.Error("", resetResult.ErrorMessage ?? this.Terms.InternalError);
            this.ResetPasswordByPhoneLoading = false;

            if (resetResult.ErrorType == ErrorTypes.NotFoundData) {
                this.ResetPasswordStep = 1;
                this.ResetPasswordByPhoneForm.reset();
            }

            return;
        }

        this.ResetPasswordByPhoneLoading = false;
        this.ResetPasswordByPhoneForm.reset();
        this._notificationService.Success("", this.Terms.SuccessPasswordChangedMessage);
        this.$emit("Back");
        this.ResetPasswordStep = 1;

    }

    async ResetPasswordByEmailFormSubmit(e: any) {
        e.preventDefault();
        await this.ResetPasswordByEmail();
    }
    async ResetPasswordByEmail() {
        if (!this.ResetPasswordByEmailForm.validate())
            return;

        this.ResetPasswordByEmailLoading = true;
       

        let _identityAccountPorvider: IdentityAccountProvider = new IdentityAccountProvider();

        let resetResult = await _identityAccountPorvider.ResetPasswordByEmail(this.Email,
            this.Settings.CurrentSite != null ? this.Settings.CurrentSite.OrganizationId : 0);
        if (!resetResult.Success) {
            this.ResetPasswordByEmailLoading = false;
            this._notificationService.Error("", resetResult.ErrorMessage ?? this.Terms.InternalError);
            return;
        }
        
        this.ResetPasswordByEmailLoading = false;
        this.ResetPasswordByEmailForm.reset();
        this._notificationService.Success("", this.Terms.NewPasswordSendedMessage);
        this.$emit("Back");
        this.ResetPasswordStep = 1;
    }


    mounted() {
        this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
        this.Settings = GlobalService.GetSettings();
    }  

    Back() {
        this.$emit("Back");
    }
}

export class WpPasswordResetDictionary extends BaseUILocalizationDictionary {
    SuccessPasswordChangedMessage: string = 'Пароль изменен';
    NewPasswordSendedMessage: string = 'Новый пароль отправлен на почту'
    EmailRequiredMessage: string = 'Введите e-mail';
    FieldRequiredMessage: string = 'Поле обязательно к заполнению';
    PasswordLengthError: string = 'Должно быть длинее 6 символов';
    PasswordNotAvailableCharacters: string = 'Доступные символы для пароля: цифры, кириллица, латинские буквы, (пробел) ! " № # $ % & \'() * + , - . / : ; + = ?@[\] ^ _` { | } ~ <>';
    ComparePasswordError: string = 'Пароли не совпадают';
    ResetPasswordTitle: string = 'Восстановление пароля';
    EmailLblText: string = 'Введите email';
    NewPasswordLblText: string = 'Введите новый пароль';
    RepeatPasswordLblText: string = 'Повторите пароль';
    ResetBtnText: string = 'Восстановить';
    ChangeBtnText: string = 'сменить';
}