import { UserAccountWireframeType } from "./user-account-wireframe-type";

export default class UserAccountParameters{
    WireframeType: UserAccountWireframeType = UserAccountWireframeType.Base; 
    LogoUrl?: string;
    HeaderColor?: string;
    HaveFooter: boolean = false;
    FooterColor?: string;
    NewsEnabled: boolean = false;
    ProfileEnabled: boolean = false;
    OrdersEnabled: boolean = false;
    CardsEnabled: boolean = false;
    FooterContent?: string;
}