import Vue, { VueConstructor } from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { BaseSystemLocalizationDictionary } from 'web-platform-core-ui';
import { DefaultProps } from 'vue/types/options';

export const wpPersonificationItemProps = Vue.extend({
    props: {
        Bundles: Array,
        UserPersons: Array,
        removable: {
            type: Boolean,
            default: true,
            required: false,
        }
    }
})

@Component({
    template: require("./wp-personification-bundle.html")
})
export default class WpPersonificationBundle extends mixins<WpBaseComponent<WpPersonificationBundleDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, wpPersonificationItemProps, Vue) {
    TotalSum: number = 0;    
    Loading: boolean = false;

    created(): void {
        this.Bundles.forEach((element: any) => {
            this.TotalSum += element.OrderItem.TotalSum;
        });
    };

    Remove(): void {
        this.Loading = true;
        this.$emit("ItemRemove", (this.Bundles[0] as any).OrderItem);
    };
};

export class WpPersonificationBundleDictionary extends BaseSystemLocalizationDictionary {
    GenericDecline: string = 'Отказаться';
    GenericTotalSum: string = 'Сумма';
};