import IOrganizationProvider from './i-organization-provider';
import OrganizationInfo from './organization-info';
import { ActionResult, ErrorTypes } from './../Common';
import { IdentitySymbols } from './../Identity/symbols';
import { IContext } from './../Identity';
import { inject, injectable } from 'inversify';
import { RestClient } from 'typed-rest-client';
import GlobalService, { AppSettings } from './..';

@injectable()
export default class OrganizationProvider implements IOrganizationProvider {
    private _context: IContext;
    protected _settings: AppSettings;

    constructor(@inject(IdentitySymbols.Context) context: IContext) {
        this._context = context;
        this._settings = GlobalService.GetSettings();
    }

    async GetOrganizationInfoAsync(): Promise<ActionResult<OrganizationInfo>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<OrganizationInfo>>(`/websale/api/organizations`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<OrganizationInfo>(response.result?.Data);
    }

    async GetOrganizationRulesAsync(): Promise<ActionResult<string>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<string>>(`/websale/api/organizations/rules`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<string>(response.result?.Data);
    }
}

class AjaxResult<T> {
    Result: number = 0;
    Message: string = '';
    Data?: T;
}
