import './wp-navigation.scss';

import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WpPageBaseComponent from './../wp-page-base-component';
import { IMetricsService, MetricsSymbols } from 'web-platform-core-ui';
import { MetricsEvents } from '../metrics-events-symbols';

export const wpNavigationProps = Vue.extend({
    props: {
        Src: String,
        LonTo: {
            type: Number,
            required: false,
            default: null
        },
        LatTo: {
            type: Number,
            required: false,
            default: null
        }
    }
})


@Component({
    template: require("./wp-navigation.html")
})
export default class WpNavigation extends mixins(WpPageBaseComponent, wpNavigationProps, Vue) {
    _metricsService!: IMetricsService;

    FrameSrc: string = '';
    get MapStyleClass() {
        return this.LatTo != null && this.LonTo != null ? 'navigation-map' : 'navigation-map-full';
    }
    mounted() {
        this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
        this.FrameSrc = this.Src;

        this._metricsService.RegistrateEvent(MetricsEvents.NavigationPage);
    }

    OpenAddress(): void {
        // @ts-ignore
        openInBrowser(`navigate: ${this.LatTo}, ${this.LonTo}`);
        this._metricsService.RegistrateEvent(MetricsEvents.NavigationGoTo);
    };

    FrameLoaded(): void {
        console.info("Method not yet implemented");
    }
}      