import IIdentity from './i-identity';
import AccessToken from './access-token';

export default class EmptyIdentity implements IIdentity {
    get AccessToken(): AccessToken {
        return new AccessToken("", new Date());
    }
    get IsAuthentificated(): boolean {
        return false;
    }
    get Username(): string {
        return "";
    }
    get UserId(): string {
        return "";
    }

    get Phone() {
        return undefined;
    }
}