import ActionResult from './../Common/action-result';
import { IRequestOptions, RestClient } from 'typed-rest-client';
import { ErrorTypes, WpEvent, WpEventHandler, BaseFilter } from './../Common';
import GlobalService from './global-service';
import { AppSettings } from './app-settings';
import Site from './site';
import AppSettingsInitializedEventArgs from './app-settings-initialized-event-args';
import { IContext, IdentitySymbols } from '../Identity';
import { ILocalizationService, LocalizationSymbols } from '../Localization';
import { ICmsService, CmsSymbols } from '../Cms';
import WpCorePrefixes from '../wp-core-prefixes';
import WidgetOpenParameters from './widget-open-parameters';

export default class Initializer {
    private static _settingsInitialized: WpEventHandler<AppSettingsInitializedEventArgs> = new WpEventHandler<AppSettingsInitializedEventArgs>();

    static get SettingsInitialed(): WpEvent<AppSettingsInitializedEventArgs> {
        return this._settingsInitialized;
    }

    static async InitializeAsync(globalService: GlobalService): Promise<ActionResult> {
        let settings = GlobalService.GetSettings<AppSettings>();

        if (!settings.CheckSettings()) return ActionResult.Failed(ErrorTypes.InvalidData);

        let context = globalService.Get<IContext>(IdentitySymbols.Context);
        let localizationService = globalService.Get<ILocalizationService>(LocalizationSymbols.LocalizationService);
        let client: RestClient = new RestClient('wp-app', settings.ServerUrl, []);

        try {
            let options: IRequestOptions = { additionalHeaders: { 'sessionid': `${settings.SessionId}` } };
            if (settings.SiteId)
                options.additionalHeaders!.moduleId = settings.SiteId;

            let response = await client.create<AjaxResult<Site>>(`/widget/initialize`, settings, options);
            if (response.result != null && response.result.Result != 0)
                return ActionResult.Failed(ErrorTypes.InternalError);

            if (response.result != null && response.result.Data != null) {
                settings.CurrentSite = new Site();
                settings.CurrentSite.Id = response.result.Data.Id;
                settings.CurrentSite.Name = response.result.Data.Name;
                settings.CurrentSite.ConnectionId = response.result.Data.ConnectionId;
                settings.CurrentSite.ConnectionType = response.result.Data.ConnectionType;
                settings.CurrentSite.Status = response.result.Data.Status;
                settings.CurrentSite.OrganizationId = response.result.Data.OrganizationId;
                settings.CurrentSite.OrganizationName = response.result.Data.OrganizationName;
                settings.CurrentSite.Url = response.result.Data.Url;
                settings.CurrentSite.Offset = response.result.Data.Offset || '';
                settings.CurrentSite.OrganizationStatus = response.result.Data.OrganizationStatus;
                settings.CurrentSite.PlatformHelpDeskAddress = response.result.Data.PlatformHelpDeskAddress;

                if (settings.CurrentSite.Status != 1 || response.result.Data.OrganizationStatus != 1) return ActionResult.Failed(ErrorTypes.SiteOrOrganizationDisabled, "���� ��� ����������� �������������");

                let cmsService = globalService.Get<ICmsService>(CmsSymbols.CmsService);
                let filter = new BaseFilter();
                filter.PlatformId = settings.SiteId;
                let initParams = await cmsService.GetEntitiesAsync<WidgetOpenParameters>(WpCorePrefixes.WidgetOpenParameters, filter, `${settings.CurrentSite.Id}`);

                if (initParams.Success) {
                    if (initParams.Data != null && initParams.Data != undefined) {
                        if (initParams.Data.Entities != null && initParams.Data.Entities != undefined) {
                            if (initParams.Data.Entities.length != 0) {
                                let param = initParams.Data.Entities[0];

                                if (param != null && param != undefined) {
                                    if (param.Data != null && param.Data != "")
                                        settings.HandleJson(param.Data);
                                }
                            }
                        }
                    }
                }

                let hashHandleResult = settings.HandleJson(null);
                if (!hashHandleResult)
                    throw new Error("Settings parsing error");

                let sessionId = '';
                if (response.result.Data.SessionId != null && response.result.Data.SessionId != '')
                    sessionId = response.result.Data.SessionId;
                else
                    sessionId = Object.getOwnPropertyDescriptor(response.headers, 'sessionid')?.value as string;

                if (settings.SessionId != sessionId) {
                    settings.SessionId = sessionId;
                    parent.postMessage({ status: 'wp-session-id', data: sessionId }, '*');
                }
            };

            await localizationService.LoadAdditionalDictionary(settings.SiteId);

            await context.Initialize(settings, globalService);

            this._settingsInitialized.Send(this, new AppSettingsInitializedEventArgs(settings));

            return ActionResult.SuccessData(response.result?.Data);
        } catch (e) {
            return ActionResult.FailedData(ErrorTypes.InternalError);
        }
    }
}

class AjaxResult<T> {
    Result: number = 0;
    Message: string = '';
    Data?: T;
}
