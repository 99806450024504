import { EcomAddToCart } from './../Metrics/i-ecom-data';
import { CartItem, CartItemTypes } from './../Cart';
import { ReservationItem, ActionResult } from './..';
import { ErrorTypes, WpEventHandler, WpEventArgs, WpEvent } from './../Common';
import { MarketCartItemState } from './market-cart-item-state';
import GlobalService from './../Global/global-service';
import { ISaleService, SaleSymbols } from './../Sale';
import IMarketService from './i-market-service';
import MarketRule from './market-rule';
import { MarketDateTimeRange } from '.';
import MarketClient from './market-client';
import MarketEmployee from './market-employee';

export default class MarketTariffCartItem extends CartItem {
    private _type: CartItemTypes = CartItemTypes.MarketTariff;
    protected _marktetService: IMarketService;

    OrderId?: number;
    OrderItemId?: number;

    Rule: MarketRule = new MarketRule();
    Period: MarketDateTimeRange = new MarketDateTimeRange();
    Client: MarketClient = new MarketClient();
    Employee?: MarketEmployee;
    State: MarketCartItemState = MarketCartItemState.Available;

    constructor(marktetService: IMarketService) {
        super();
        this._marktetService = marktetService;
    }

    get IsAvailable(): boolean {
        return true;
    }
    get Info(): string {
        return `${this.Rule.Name}, ${this.Period.StartDateTime[0]} - ${this.Period.EndDateTime[0]}`;
    }
    get Type(): CartItemTypes {
        return this._type;
    }
    get Price(): number {
        return this.Rule.Price[0];
    }
    get Count(): number {
        return 1;
    }
    get MetricsId(): any | undefined {
        return this.Rule?.id;
    }
    public get Dates(): Array<string> {
        return this.Period.StartDateTime;
    }
    public get AdditionalInfo(): any {
        return {
            rule: this.Rule,
            period: this.Period,
        }
    }

    get IsCanIncreaseCount(): boolean {
        return false;
    }
    get IsCanReduceCount(): boolean {
        return false;
    }
    IncreaseCount(): ActionResult {
        throw new Error("Method not implemented.");
    }
    ReduceCount(): ActionResult<any> {
        throw new Error("Method not implemented.");
    }
    IsConflictItem(item: CartItem): boolean {
        return item.Type != CartItemTypes.MarketTariff;
    }
    IsEqualsItem(item: CartItem): boolean {
        return false;
    }
    CombineItem(item: CartItem): ActionResult<any> {
        throw new Error("Method not implemented.");
    }
    GetReservationItem(): ReservationItem[] {
        throw new Error("Method not implemented.");
    }

    async ReservateItem(): Promise<ActionResult> {
        this.State = MarketCartItemState.Busy;
        let result = await this._marktetService.ReservateTariffAsync(this);

        if (result.Success && result.Data != null) {
            this.OrderId = result.Data.Id;
            let orderItem = result.Data.Items.find(x => x.AdditionalInfo.CartItemId == this.TempId);
            this.OrderItemId = orderItem?.Id;
        }
        this.State = MarketCartItemState.Available;

        return result;
    }

    async RemoveItem(): Promise<ActionResult> {
        if (this.OrderItemId == undefined) return ActionResult.Success();

        this.State = MarketCartItemState.Busy;
        let result = await this._marktetService.UnreservateTariffAsync(this.OrderItemId);
        this.State = MarketCartItemState.Available;

        this._cartItemChanged.Send(this, WpEventArgs.Empty);

        return result;
    }

    IsAssociatedReservationItem(item: ReservationItem): boolean{
        return false;
    }

    GetEcomData(eventId: Symbol): EcomAddToCart {
        throw new Error('Not implemented');
    }
}