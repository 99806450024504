import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WpPageBaseComponent from './../wp-page-base-component';
import { INotificationService, NotificationSymbols, IProfileService, ProfileSymbols, MetricsSymbols, IMetricsService, ErrorTypes } from 'web-platform-core-ui';
import { Ref } from 'vue-property-decorator';
import { MetricsEvents } from '../metrics-events-symbols';

@Component({
    template: require("./wp-change-password.html")
})
export default class WpChangePassword extends mixins(WpPageBaseComponent, Vue) {
    protected _notificationService!: INotificationService;
    private _profileService!: IProfileService;
    private _metricsService!: IMetricsService;

    ChangePasswordFormValid: boolean = false;
    ChangePasswordLoading: boolean = false;

    @Ref('ConfirmPasswordInput') ConfirmPasswordInput!: any;
    @Ref('ChangePasswordForm') ChangePasswordForm!: HTMLFormElement;

    OldPassword: string = '';
    NewPassword: string = '';
    ConfirmPassword: string = '';

    OldPasswordShow: boolean = false;
    NewPasswordShow: boolean = false;
    ConfirmPasswordShow: boolean = false;

    async ChangePasswordSubmit(e: any) {
        e.preventDefault();
        await this.ChangePassword();
    };

    get OldPasswordRules() {
        return [
            (v: string) => !!v || 'Поле обязательно к заполнению'
        ];
    };

    get NewPassowrdRules() {
        return [
            (v: string) => !!v || 'Поле обязательно к заполнению',
            (v: string) => (v && v.length > 6) || 'Должно быть длинее 6 символов',
            (v: string) => /^[а-яА-Яa-zA-Z0-9ёЁ!№;%:?*()_+=\-<>.,/\\'`@#$%^&{}\[\]|""~ ]{0,50}$/.test(v) || 'Доступные символы для пароля: цифры, кириллица, латинские буквы, (пробел) ! " № # $ % & \'() * + , - . / : ; + = ?@[\] ^ _` { | } ~ <>'
        ];
    };

    get ConfirmPassowrdRules() {
        return [
            (v: string) => !!v || 'Поле обязательно к заполнению',
            (v: string) => v == this.NewPassword || 'Пароли не совпадают',
        ];
    };

    NewPasswordChanged(e: any) {
        this.ConfirmPasswordInput.validate();
    };

    async ChangePassword() {
        this._metricsService.RegistrateEvent(MetricsEvents.PasswordChanged);

        if (!this.ChangePasswordForm.validate()) return;

        this.ChangePasswordLoading = true;
        let resetResult = await this._profileService.ChangePasswordAsync(this.OldPassword, this.NewPassword, this.ConfirmPassword);
        this.ChangePasswordLoading = false;

        if (!resetResult.Success) {           
            if (resetResult.ErrorType == ErrorTypes.InvalidData) {
                this._notificationService.Error("", "Неверный текущий пароль");
                this.OldPassword = "";
            } else {
                this._notificationService.Error("", resetResult.ErrorMessage ?? "Произошла непредвиденная ошибка");
                this.ChangePasswordForm.reset();
            };
            return;
        };

        this.ChangePasswordForm.reset();
        this._notificationService.Success("", "Пароль изменен");
    };

    mounted() {
        this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
        this._profileService = this.GlobalService.Get<IProfileService>(ProfileSymbols.ProfileService);
        this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
        this._metricsService.RegistrateEvent(MetricsEvents.ChangePasswordPage);
    };
};