import './wp-personification.scss';

import Vue from 'vue';
import WpBaseComponent from './../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
    OrderSale, ISaleService, SaleSymbols, OrderItem, INotificationService, NotificationSymbols,
    PersonSale, IContext, IdentitySymbols, PersonificationApiOrderItem, BaseSystemLocalizationDictionary, AppSettings
} from 'web-platform-core-ui';
import { OrderItemType } from 'web-platform-core-ui/src/Sale/order-item-type';
import { DefaultProps } from 'vue/types/options';
import { VueConstructor } from 'vue/types/umd';
import BaseUILocalizationDictionary from './../../Localization/base-ui-localization-terms';

export const wpPersonificationProps = Vue.extend({
    props: {
        OrderId: Number,
        PersonificationItems: Array
    }
})

@Component({
    template: require("./wp-personification.html")
})
export default class WpPersonification extends mixins<WpBaseComponent<WpPersonificationDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, wpPersonificationProps, Vue) {
    OrderLoading: boolean = true;
    Order: OrderSale = new OrderSale();
    UserPersons: Array<PersonSale> = [];
    Settings!: AppSettings;

    protected _service!: ISaleService;
    protected _notificationService!: INotificationService;

    get TotalSum(): number {
        let sum = 0;
        this.Order.Items.forEach(x => sum += x.TotalSum);
        return sum;
    }

    Bundles: any = {};
    Items: Array<any> = [];

    created() {
        this.Settings = GlobalService.GetSettings();
    }

    async mounted() {
        this.UserPersons = [{
            FirstName: this.Terms.PersonSaleFirstName, LastName: this.Terms.PersonSaleLastName, PersonId: 0, TNodId: 0, Phone: '', SecondName: ''
        }]
        this.OrderLoading = true;
        this._service = await this.GlobalService.Get<ISaleService>(SaleSymbols.SaleService);
        this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);

        let context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);

        let order = await this._service.GetOrderAsync(this.OrderId);
        if (!order.Success || order.Data === undefined || order.Data === null)
            this._notificationService.Error(this.Terms.GenericError, order.ErrorMessage ? order.ErrorMessage : this.Terms.InternalError);
        else
            this.Order = order.Data;

        this.HandlingItems();
        this.OrderLoading = false;

        if (context.CurrentIdentity.IsAuthentificated) {
            let userArray = await this._service.GetPersonificationUserPersons(context.CurrentIdentity.UserId);
            if (userArray.Data !== undefined) 
                userArray.Data.forEach(x => this.UserPersons.push(x));
        }
    }

    get canRemove() {
        return this.Order.Items.length > this.Settings.CartMinItems
    };

    protected HandlingItems() {
        let items = this.Order.Items.filter(x => (x.Type == OrderItemType.TariffPersonification ||
            x.Type == OrderItemType.TariffBarcodePesonification ||
            x.Type == OrderItemType.TariffRewritablePesonification)
            && x.AdditionalInfo != undefined && x.AdditionalInfo.Info != undefined &&
            x.AdditionalInfo.Info != null && x.AdditionalInfo.Info.includes('_unknow'));


        let bundles: any = {};
        let singleItems: Array<any> = [];

        this.PersonificationItems.splice(0);
        items.forEach((element) => {
            let bundleId = element.BundleId;
            let personification = new PersonificationApiOrderItem();
            personification.Id = element.Id;
            personification.PersonId = element.AdditionalInfo.PersonId;
            personification.IsdTariffpersonification = element.AdditionalInfo.PersonificationQuestionnaire.Members;

            this.PersonificationItems.push(personification);

            if (element.BundleId !== null && element.BundleId !== undefined && element.BundleId !== '') {
                if (bundleId in bundles) {
                    bundles[bundleId].push({ OrderItem: element, Personification: personification });
                }
                else {
                    bundles[bundleId] = [];
                    bundles[bundleId].push({ OrderItem: element, Personification: personification });
                }
            } else {
                singleItems.push({ OrderItem: element, Personification: personification });
            }
        });

        this.Bundles = bundles;
        this.Items = singleItems;
    }

    async ItemRemove(item: OrderItem) {
        let removeResult = await this._service.RemoveItemAsync(item.Id);

        if (removeResult.Success) {
            if (item.BundleId != null) {
                this.Order.Items = this.Order.Items.filter(x => x.BundleId != item.BundleId);
            }
            else {
                this.Order.Items = this.Order.Items.filter(x => x.Id != item.Id);
            }
        }
        else {
            this._notificationService.Error(this.Terms.GenericError, removeResult.ErrorMessage ? removeResult.ErrorMessage : this.Terms.InternalError);
        }

        if (this.Order.Items.filter(x => (x.Type == OrderItemType.TariffPersonification ||
            x.Type == OrderItemType.TariffBarcodePesonification ||
            x.Type == OrderItemType.TariffRewritablePesonification)
            && x.AdditionalInfo != undefined && x.AdditionalInfo.Info.includes('_unknow')).length == 0) {
            this.Order = new OrderSale();
            this.$emit("RemovedAllItems", this.Order.Items.length > 0);
        }

        this.HandlingItems();
    }
}

export class WpPersonificationDictionary extends BaseUILocalizationDictionary {
    PersonSaleFirstName: string = 'клиента';
    PersonSaleLastName: string = 'Нового';
    GenericTotalSum: string = 'Итого';
    GenericTotalSumDiscounted: string = 'Итого со скидкой';
}