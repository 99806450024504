import { LoyaltyProgramType } from './loyalty-program-type';
import { LoyaltyProgramStatus } from './loyalty-program-status';

export default class LoyaltyProgram {
	Id: number = 0;
	Name: string = '';
	RuleAddress: string = '';
	ProgramType?: LoyaltyProgramType;
	Status?: LoyaltyProgramStatus;
	EnableUsingBonuses?: boolean;
}
