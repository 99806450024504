import ActionResult from './../Common/action-result'
import RHAppSettings from './rh-app-settings'
import { RestClient } from 'typed-rest-client';
import { ErrorTypes } from './../Common';
import GlobalService from './../Global/global-service';
import { IIdentity } from './../Identity';
import RHIdentity from './rh-identity';
import { Site } from './../Global';

export default class RHInitializer {
    static async InitializeAsync(): Promise<ActionResult<IIdentity>> {
        let settings = GlobalService.GetSettings<RHAppSettings>();

        let client: RestClient = new RestClient('wp-app', settings.ServerUrl, [], {
            headers: {
                "moduleId": settings.SiteId
            }
        });

        try {
            let response = await client.create<AjaxResult<InitializeResult>>(`/rh/widget/initialize`, settings);

            if (response.result != null && response.result.Result != 0)
                return ActionResult.Failed(ErrorTypes.InternalError);

            settings.PersonId = response.result?.Data != null ? response.result?.Data.PersonId : 0;
            let userId = response.result != null && response.result.Data != null ? response.result.Data.UserId : "";
            let token = response.result != null && response.result.Data != null ? response.result.Data.Token : "";

            let rhIdentity = new RHIdentity(settings.Email, userId, settings.PersonId, token, settings.Phone);

            if (response.result != null && response.result.Data != null) {
                settings.CurrentSite = new Site();
                settings.CurrentSite.Id = response.result.Data.Id;
                settings.CurrentSite.Name = response.result.Data.Name;
                settings.CurrentSite.ConnectionType = response.result.Data.ConnectionType;
                settings.CurrentSite.Status = response.result.Data.Status;
                settings.CurrentSite.OrganizationId = response.result.Data.OrganizationId;
                settings.CurrentSite.OrganizationName = response.result.Data.OrganizationName;
                settings.CurrentSite.Url = response.result.Data.Url;
            }

            return ActionResult.SuccessData<IIdentity>(rhIdentity);
        }
        catch (e) {
            return ActionResult.FailedData(ErrorTypes.InternalError);
        }
    }
}

class InitializeResult extends Site {
    UserId: string = "";
    PersonId: number = 0;
    Token: string = "";
}

class AjaxResult<T> {
    Result: number = 0;
    Message: string = '';
    Data?: T;
}
