export default class LTTariffGroup {
    Id: number = 0;
    Name: string = '';
    Children: string = '';
    Parent: number = 0;
    Tariffs: string = '';
    Description: string = '';
    ImageLink: string = '';
    Index: number = 0;

    static GetSubroups(item: LTTariffGroup): Array<number> {
        if (item.Children == null || item.Children == '') return [];
        try {
            return JSON.parse(item.Children) as Array<number>;
        } catch {
            return [];
        }
    }

    static GetTariffs(item: LTTariffGroup): Array<number> {
        if (item.Tariffs == null || item.Tariffs == '') return [];
        try {
            return JSON.parse(item.Tariffs) as Array<number>;
        } catch {
            return [];
        }
    }
}