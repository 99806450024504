import { Tariff } from '../Tariffs';

export default class LTTariff extends Tariff {
    LTAvailableContracts: string = '';
    LTDuration: string = '';
    AvailableContracts?: Array<number>;

    static SetAvailableContracts(tariff: LTTariff): void {
        if (tariff.LTAvailableContracts == null || tariff.LTAvailableContracts == "")
            return;

        try {
            let contractsIds = JSON.parse(tariff.LTAvailableContracts) as Array<number>;
            tariff.AvailableContracts = contractsIds;
        }
        catch {}
    }
}