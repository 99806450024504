import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WpPageBaseComponent from './../wp-page-base-component';
import {
    NewsArticle, CmsSymbols, ICmsService, BaseFilter, WpCorePrefixes,
    IMetricsService, MetricsSymbols, Sorting, SortingMode
} from 'web-platform-core-ui';
import { MetricsEvents } from '../metrics-events-symbols';

@Component({
    template: require("./wp-news.html")
})
export default class WpNews extends mixins(WpPageBaseComponent, Vue) {
    NewsList: Array<NewsArticle> = [];

    PageCount: number = 5;
    CurrentPage: number = 1;
    TotalCount: number = 0;

    async mounted() {
        let metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
        metricsService.RegistrateEvent(MetricsEvents.NewsList);

        await this.LoadNews();
    }

    async More() {
        this.CurrentPage++;
        await this.LoadNews();
    }

    protected async LoadNews() {
        this.PageLoading = true;
        let service = this.GlobalService.Get<ICmsService>(CmsSymbols.CmsService);

        let filter = new BaseFilter();
        filter.Page = this.CurrentPage;
        filter.PageCount = this.PageCount;
        filter.Sorting = new Sorting();
        filter.Sorting.Member = "Date";
        filter.Sorting.Mode = SortingMode.DESC;

        let result = await service.GetEntitiesAsync<NewsArticle>(WpCorePrefixes.News, filter, '');

        if (result.Success && result.Data != null) {
            result.Data.Entities.forEach(x => this.NewsList.push(x));
            this.TotalCount = typeof result.Data.TotalCount == "number" ? result.Data.TotalCount : Number.parseInt(result.Data.TotalCount);
        }

        this.PageLoading = false;
    }
} 