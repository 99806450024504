import CartItem from './cart-item';
import { ActionResult, ErrorTypes, WpEventHandler, WpEvent, WpEventArgs } from './../Common';
import { createGuid } from './../Utility';
import { IArenaService, ArenaCartItem } from '../Arena';
import { CartItemTypes } from '.';
import { EcomRemoveFromCart, IEcomData } from '../Metrics';
import { Brand, EcomBaseProducts, EcomProduct } from '../Metrics/i-ecom-data';
import Tariff from '../Tariffs/tariff';
import TariffsBundle from '../Tariffs/tariffs-bundle';

export default class Cart implements IEcomData {
	private _arenaService: IArenaService;
	private _cartChanged: WpEventHandler<WpEventArgs>;

	private _orderId?: number | undefined;
	private _sessionId: string;
	private _siteId: number;
	private _tempId: string;

	private _items: Array<CartItem>;
	CartMaxItems: number;
	public get OrderId(): number | undefined {
		return this._orderId;
	}
	public set OrderId(value: number | undefined) {
		this._orderId = value;
		this._cartChanged.Send(this, WpEventArgs.Empty);
	}
	public get SessionId(): string {
		return this._sessionId;
	}
	public get SiteId(): number {
		return this._siteId;
	}
	public get TempId(): string {
		return this._tempId;
	}
	public get Items(): CartItem[] {
		return this._items;
	}
	public get CartChanged(): WpEvent<WpEventArgs> {
		return this._cartChanged;
	}
	public get LeftCount(): number {
		let sum = 0;
		this._items.forEach((x) => (sum = sum + x.Count));
		return this.CartMaxItems - sum;
	}
	public get Sum(): number {
		let sum = 0;
		this._items.forEach((x) => (sum += x.Price));
		return sum;
	}
	get Count(): number {
		// Refactor this to declarative style? Maybe using array.reduce()
		let sum = 0;
		this._items.forEach((x) => (sum = sum + x.Count));
		return sum;
	}

	constructor(siteId: number, sessionId: string, arenaService: IArenaService, maxItemsCount: number, orderId: number | undefined = undefined) {
		this._siteId = siteId;
		this.CartMaxItems = maxItemsCount;
		this._sessionId = sessionId;
		this._tempId = createGuid();
		this._items = new Array<CartItem>();
		this._cartChanged = new WpEventHandler<WpEventArgs>();
		this.OrderId = orderId;
		this._arenaService = arenaService;
	}

	public AddItem(cartItem: CartItem): ActionResult {
		if (this._items.length > 0 && this._items.some((x) => x.IsConflictItem(cartItem)))
			return ActionResult.Failed(
				ErrorTypes.CartItemsConflict,
				'Данная позиция не может быть оформлена вместе с существующими элементами корзины'
			);

		let countSum: number = 0;
		this._items.forEach((a) => (countSum += a.Count));

		if (countSum + cartItem.Count > this.CartMaxItems)
			return ActionResult.Failed(ErrorTypes.CartError, 'Превышено максимальное количество элементов корзины');

		let isCombined = false;
		let combineResult = new ActionResult();
		this._items.forEach((item) => {
			if (item.IsEqualsItem(cartItem)) {
				isCombined = true;
				combineResult = item.CombineItem(cartItem);
			}
		});

		if (isCombined) return combineResult;

		this._items.push(cartItem);

		cartItem.CartItemChanged.Subscribe((sender, e) => {
			this._cartChanged.Send(this, WpEventArgs.Empty);
		});

		this._cartChanged.Send(this, WpEventArgs.Empty);

		return ActionResult.Success();
	}

	public async RemoveItem(cartItemId: string): Promise<ActionResult> {
		let searchedItem = this.Items.find((x) => x.TempId == cartItemId);

		if (searchedItem) {
			let result = await searchedItem.RemoveItem();
			if (result.Success) {
				let index = this.Items.indexOf(searchedItem);
				this._items.splice(index, 1);
				this._cartChanged.Send(this, this.Items);
				return ActionResult.SuccessData(this.Items);
			} else {
				return result;
			}
		}

		this._cartChanged.Send(this, WpEventArgs.Empty);

		return ActionResult.Failed(ErrorTypes.InternalError, 'Не удалось удалить товар из корзины');
	}

	public async Clear() {
		if (this._orderId != null) {
			await this._arenaService.CancelOrderAsync(this._orderId).then((x) => {
				if (!x.Success) {
					console.error(x.ErrorMessage);
				}
			});

			this._orderId = undefined;
		} else {
			this._items.forEach(async (x) => {
				await x.RemoveItem();
			});
		}

		this._items = new Array<CartItem>();

		this._cartChanged.Send(this, WpEventArgs.Empty);
	}

	public Reset() {
		this._orderId = undefined;
		this._items = new Array<CartItem>();

		this._cartChanged.Send(this, WpEventArgs.Empty);
	}

	public static get Empty(): Cart {
		return new Cart(0, '', {} as IArenaService, 0);
	}

	public SimpleCart(): any {
		let simpleCart: any = [];

		this._items.forEach((item: CartItem) =>
			simpleCart.push({ name: item.Info, count: item.Count, price: item.Price, date: item.Dates, additionalinfo: item.AdditionalInfo })
		);

		return simpleCart;
	}

	GetEcomData(eventId: Symbol): EcomRemoveFromCart | null{
		let tmpList: EcomRemoveFromCart = new EcomRemoveFromCart();
        tmpList.remove = new EcomBaseProducts();
		this.ClearCartEcom(tmpList.remove);

		return tmpList.remove.products.length > 0 ? tmpList : null;
	}

	ClearCartEcom(tmpList: EcomBaseProducts) {
		let tariff: Tariff;
		let bundle: TariffsBundle;

		this.Items.forEach((item: any ) => {
			if(item.Type === CartItemTypes.Tariff) {
				tariff = item.Tariff;

				for (let i = 0; i < item.Count; i++) {
					tmpList.products.push({
						id: tariff.TariffCode.toString(),
						name: tariff.Name,
						list: tariff.GroupName,
						brand: Brand.ISDS,
						quantity: 1,
						variant: EcomProduct.GetProductVariant(item.Seance, tariff.OptionName),
					});
				};
			} else if (item.Type === CartItemTypes.Bundle) {				
				bundle = item._bundle;

				bundle.Tariffs.forEach(bundle => {
						tmpList.products.push({
						id: bundle.TariffCode.toString(),
						name: bundle.Name,
						list: bundle.GroupName,
						brand: Brand.ISDS,
						quantity: 1,
						variant: EcomProduct.GetProductVariant(item.Seance, bundle.OptionName),
					});
				});
			}
		});
	}
}
