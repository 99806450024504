import { injectable } from 'inversify';

import { RestClient } from 'typed-rest-client';
import { ActionResult, ErrorTypes } from './../Common';
import IPhoneConfirmationProvider from './../PhoneConfirm/i-phone-confirmation-provider';
import { UserPhoneConfirmBan } from './../PhoneConfirm';
import PhoneConfirmCode from './../PhoneConfirm/phone-confirm-code';
import IdentityLoginState from './identity-login-state';
import GlobalService from '../Global/global-service';
import { AppSettings } from '../Global/app-settings';
import INewTelProvider from './../PhoneConfirm/i-newtel-provider';

@injectable()
export default class IndentityPhoneConfirmationProvider implements IPhoneConfirmationProvider, INewTelProvider {
    private _settings: AppSettings;

    constructor() {
        this._settings = GlobalService.GetSettings<AppSettings>();
    }

    async GetCurrentBanAsync(): Promise<ActionResult<UserPhoneConfirmBan>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "session-id": this._settings.SessionId,
                "moduleId": this._settings.SiteId,
                "wp.language": this._settings.Locale
            }
        });

        let response = await client.get<AjaxResult<UserPhoneConfirmBan>>(`/idsrv/phoneconfirmation/currentban`);

        if (response.result != null && response.result.Result != 'ok')
            return ActionResult.FailedData<UserPhoneConfirmBan>(ErrorTypes.InternalError, response.result?.Content);

        return ActionResult.SuccessData<UserPhoneConfirmBan>(response.result?.Content);
    }

    async GetCurrentCodeAsync(): Promise<ActionResult<PhoneConfirmCode>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "session-id": this._settings.SessionId,
                "moduleId": this._settings.SiteId,
                "wp.language": this._settings.Locale
            }
        });

        let response = await client.get<AjaxResult<PhoneConfirmCode>>(`/idsrv/phoneconfirmation/currentcode`);

        if (response.result != null && response.result.Result != 'ok')
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Content);

        return ActionResult.SuccessData<PhoneConfirmCode>(response.result?.Content);
    }

    async SendCodeAsync(phone: string): Promise<ActionResult<PhoneConfirmCode>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "session-id": this._settings.SessionId,
                "moduleId": this._settings.SiteId,
                "wp.language": this._settings.Locale
            }
        });

        let response = await client.create<AjaxResult<PhoneConfirmCode>>(`/idsrv/phoneconfirmation/${IdentityLoginState.IsResetPassword ? 'resetsendcode' : 'sendcode'}`, { Phone: phone, OrganizationId: this._settings.CurrentSite?.OrganizationId });

        if (response.result != null && response.result.Result != 'ok')
            return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

        return ActionResult.SuccessData<PhoneConfirmCode>(response.result?.Content);
    }

    async ConfirmCodeAsync(code: string): Promise<ActionResult<PhoneConfirmCode>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "session-id": this._settings.SessionId,
                "moduleId": this._settings.SiteId,
                "wp.language": this._settings.Locale
            }
        });

        let response = await client.create<AjaxResult<PhoneConfirmCode>>(`/idsrv/phoneconfirmation/confirmcode`, { code: code });

        if (response.result != null && response.result.Result != 'ok')
            return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

        return ActionResult.SuccessData<PhoneConfirmCode>(response.result?.Content);
    }

    async CodeLeftTimeAsync(codeId: number): Promise<ActionResult<number>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "session-id": this._settings.SessionId,
                "moduleId": this._settings.SiteId,
                "wp.language": this._settings.Locale
            }
        });

        let response = await client.get<AjaxResult<number>>(`/idsrv/phoneconfirmation/codelefttime/${codeId}`);

        if (response.result != null && response.result.Result != 'ok')
            return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

        return ActionResult.SuccessData<number>(response.result?.Content);
    }

    async CancelCodeAsync(codeId: number): Promise<ActionResult> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "session-id": this._settings.SessionId,
                "moduleId": this._settings.SiteId,
                "wp.language": this._settings.Locale
            }
        });

        let response = await client.create<AjaxResult<any>>(`/idsrv/phoneconfirmation/cancelcode`, { Id: codeId });

        if (response.result != null && response.result.Result != 'ok')
            return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

        return ActionResult.SuccessData<number>(response.result?.Content);
    }

    async GetStateAsync(): Promise<ActionResult> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "session-id": this._settings.SessionId,
                "moduleId": this._settings.SiteId,
                "wp.language": this._settings.Locale
            }
        });

        let response = await client.create<AjaxResult<any>>(`/idsrv/phoneconfirmation/newtel/state`, {});

        if (response.result != null && response.result.Result != 'ok')
            return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

        return ActionResult.SuccessData();
    }
}


class AjaxResult<T> {
    Result: string = '';
    Message: string = '';
    Content?: T;
}