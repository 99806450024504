import Vue, { VueConstructor } from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { ArenaSector, BaseSystemLocalizationDictionary } from 'web-platform-core-ui';
import { Prop, DefaultProps } from 'vue/types/options';

export const wpArenaSectorItemProp = Vue.extend({
    props: {
        Sector: Object as Prop<ArenaSector>,
        FreePlaces: Number,
        MinPrice: Number,
        MaxPrice: Number
    }
}) 

@Component({
    template: require("./wp-arena-sector-item.html")
})
export default class WpArenaSectorItem extends mixins<WpBaseComponent<WpArenaSectorItemDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, Vue, wpArenaSectorItemProp) {

    Selected() {
        this.$emit("Selected", this.Sector);
    }
}

export class WpArenaSectorItemDictionary extends BaseSystemLocalizationDictionary {
    TicketPrice: string = '';    
    PricesLoading: string = '';
    SeatsLoading: string = '';
    CurrencyIcon: string = '';
    SeatsSingular: string = '';
    SeatsPlural: string = '';
    GenericRemaining: string = '';
}