import BaseSystemLocalizationDictionary from './base-system-localization-terms';

export default class LocalizationStaticDictionary<T extends BaseSystemLocalizationDictionary> {
    Prefix: symbol;
    Terms: T;

    constructor(prefix: symbol, terms: T) {
        this.Prefix = prefix;
        this.Terms = terms;
    }
}