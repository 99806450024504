import LTUpperGroup from './lt-upper-group';

export default class LTSubGroup {
    Id: number = 0;
    Name: string = '';
    ParentGroup: number = 0;
    ImageLink: string = '';
    PlacesIds: string = '';
    IsCoaches: boolean = false;
    ZIndex: number = 0;
    Subgroups: string = '';
    Tariffs: string = '';
    Description: string = '';
    Tags: string = '';
    Preview: string = '';
    AdditionalTariff: number = 0;
    PersonalTariff: number = 0;
    GroupTariff: number = 0;
    IsServicePackage: number = 0;
    LessonsCount: string = '';
    LessonsDuration: string = '';
    PackageServiceGroups: string = '';
    PackageInstructorsCategory: string = '';
    Note: string = '';
    IsAdditional: number = 0;

    TagsList: Array<string> = [];
    TariffsList: Array<any> = [];
    PackageTariffs: any = {};
    PackageLessonsCount: Array<string> = [];
    PackageLessonsDuration: Array<string> = [];
    PackageInstructorsCategoryList: Array<number> = [];

    static GetSubGroups(item: LTSubGroup): Array<number> {
        if (item.Subgroups == null || item.Subgroups == '') return [];
        try {
            return JSON.parse(item.Subgroups) as Array<number>;
        } catch {
            return [];
        }
    }

    static GetTags(item: LTSubGroup): Array<string> {
        if (item.Tags == null || item.Tags == '') return [];
        try {
            return JSON.parse(item.Tags) as Array<string>;
        } catch {
            return [];
        }
    }

    static GetTariffs(item: LTSubGroup): Array<number> {
        if (item.Tariffs == null || item.Tariffs == '') return [];
        try {
            return JSON.parse(item.Tariffs) as Array<number>;
        } catch {
            return [];
        }
    }

    static GetPackageLessonsCount(item: LTSubGroup): Array<string> {
        if (item.LessonsCount == null || item.LessonsCount == '') return [];
        try {
            return JSON.parse(item.LessonsCount) as Array<string>;
        } catch {
            return [];
        }
    }

    static GetPackageLessonsDuration(item: LTSubGroup): Array<string> {
        if (item.LessonsDuration == null || item.LessonsDuration == '') return [];
        try {
            return JSON.parse(item.LessonsDuration) as Array<string>;
        } catch {
            return [];
        }
    }

    static GetPackageServiceGroups(item: LTSubGroup): Array<string> {
        if (item.PackageServiceGroups == null || item.PackageServiceGroups == '') return [];
        try {
            return JSON.parse(item.PackageServiceGroups) as Array<string>;
        } catch {
            return [];
        }
    }

    static GetPackageInstructorsCategory(item: LTSubGroup): Array<number> {
        if (item.PackageInstructorsCategory == null || item.PackageInstructorsCategory == '') return [];
        try {
            return JSON.parse(item.PackageInstructorsCategory) as Array<number>;
        } catch {
            return [];
        }
    }

    static GetPackageTariffs(item: LTSubGroup): any {
        if (item.PackageServiceGroups == null || item.PackageServiceGroups == '') return [];
        try {
            return JSON.parse(item.PackageServiceGroups) as any;
        } catch {
            return [];
        }
    }
}