import { AppSettings } from './../Global/app-settings';

export default class ClubAppSettings extends AppSettings {
    ClubServicesSiteId: number = 0;
    GuestContractsIds: number = 0;

    public ParseHashParams(ignoreWindowName: boolean = false): boolean {
        let result = super.ParseHashParams(ignoreWindowName);

        if (!result) return result;

        if ('clubsiteid' in this._hashParams) {
            this.ClubServicesSiteId = this._hashParams.clubsiteid;
        }

        return true;
    }
}