import { injectable, inject } from 'inversify';

import { ActionResult } from './../Common';
import { IdentitySymbols } from './../Identity/symbols';
import GlobalService, { IdentityPhoneConfirmationProvider } from './..';
import IPhoneConfirmationProvider from './i-phone-confirmation-provider';
import { UserPhoneConfirmBan } from './';
import PhoneConfirmCode from './phone-confirm-code';
import { AppSettings } from '../Global/app-settings';
import DefaultPhoneConfirmationProvider from './default-phone-confirmation-provider';
import { IContext } from './../Identity';

@injectable()
export default class CombinePhoneConfirmationProvider implements IPhoneConfirmationProvider {
    private _settings: AppSettings;
    private _context: IContext;

    private _identityProvider: IdentityPhoneConfirmationProvider;
    private _defaultProvider: DefaultPhoneConfirmationProvider;

    constructor(@inject(IdentitySymbols.Context) context: IContext) {
        this._context = context;
        this._settings = GlobalService.GetSettings();
        this._identityProvider = new IdentityPhoneConfirmationProvider();
        this._defaultProvider = new DefaultPhoneConfirmationProvider(context);
    }

    async GetCurrentBanAsync(): Promise<ActionResult<UserPhoneConfirmBan>> {
        return this._context.CurrentIdentity.IsAuthentificated ?
            await this._defaultProvider.GetCurrentBanAsync() :
            await this._identityProvider.GetCurrentBanAsync();
    }

    async GetCurrentCodeAsync(): Promise<ActionResult<PhoneConfirmCode>> {
        return this._context.CurrentIdentity.IsAuthentificated ?
            await this._defaultProvider.GetCurrentCodeAsync() :
            await this._identityProvider.GetCurrentCodeAsync();
    }

    async SendCodeAsync(phone: string): Promise<ActionResult<PhoneConfirmCode>> {
        return this._context.CurrentIdentity.IsAuthentificated ?
            await this._defaultProvider.SendCodeAsync(phone) :
            await this._identityProvider.SendCodeAsync(phone);
    }

    async ConfirmCodeAsync(code: string): Promise<ActionResult<PhoneConfirmCode>> {
        return this._context.CurrentIdentity.IsAuthentificated ?
            await this._defaultProvider.ConfirmCodeAsync(code) :
            await this._identityProvider.ConfirmCodeAsync(code);
    }

    async CodeLeftTimeAsync(codeId: number): Promise<ActionResult<number>> {
        return this._context.CurrentIdentity.IsAuthentificated ?
            await this._defaultProvider.CodeLeftTimeAsync(codeId) :
            await this._identityProvider.CodeLeftTimeAsync(codeId);
    }

    async CancelCodeAsync(codeId: number): Promise<ActionResult> {
        return this._context.CurrentIdentity.IsAuthentificated ?
            await this._defaultProvider.CancelCodeAsync(codeId) :
            await this._identityProvider.CancelCodeAsync(codeId);
    }
}