import { injectable, inject } from 'inversify';
import ActionResult from './../Common/action-result';
import ITariffCapacityProvider from './i-tariff-capacity-provider';
import { TariffSymbols } from './symbols';
import { TariffCapacity, TariffCapacityService } from '.';
import { MLogger } from '../Global/m-logger';
import Tariff from './tariff';

@injectable()
export default class TariffCapacityServiceCached extends TariffCapacityService {
    protected _capacityItems: Array<TariffCapacity> = [];
    protected _logger: MLogger = new MLogger();
    protected _cache: any = [];
    protected _processed: any = [];

    constructor(@inject(TariffSymbols.TariffCapacityProvider) provider: ITariffCapacityProvider) {
        super(provider);
    }

    async GetCapacityAsync(tariffId: number, date: string, seanceId: string): Promise<ActionResult<TariffCapacity>> {
        // Form a string ID to access cached capacity
        let stringId = `${tariffId}_${date}_${seanceId}`;
        // Look up cached item
        let cachedItem: TariffCapacity = this._cache[`${stringId}`];

        // If CAPACITY has been CACHED
        if (cachedItem != null) {
            //this._logger.say(`${stringId} CAPACITY found in cache`, 'success');
            return ActionResult.SuccessData(cachedItem);
        } else {
            // OTHERWISE send a request on per item basis
            //this._logger.say(`${stringId} CAPACITY NOT CACHED. Requesting...`, 'error');

            let capacity = await this._provider.GetCapacityAsync(tariffId, date, seanceId);

            if (capacity.Success && capacity.Data != null) {
                let capacityInArray = this._capacityItems.find(x => x.ZoneId == capacity.Data?.ZoneId && x.Date == date && x.SeanceId == capacity.Data?.SeanceId);
                if (capacityInArray != null) {
                    capacityInArray.Count = capacity.Data.Count;
                    return ActionResult.SuccessData(capacityInArray);
                } else {
                    capacity.Data.TariffId = tariffId;
                    capacity.Data.Date = date;
                    this._capacityItems.push(capacity.Data);
                }
            }

            return capacity;
        }        
    }

    // === THIS DOESN'T SEEM TO BE USED ANYWHERE. MARKED FOR DELETION
    async RefreshCapacity(): Promise<void> {
        for (let i = 0; i < this._capacityItems.length; i++) {
            let capacity = this._capacityItems[i];
            let newCapacity = await await this._provider.GetCapacityAsync(capacity.TariffId, capacity.Date, capacity.SeanceId);

            if (newCapacity.Success && newCapacity.Data != null)
                capacity.Count = newCapacity.Data.Count;
        }
    }
    // === THIS DOESN'T SEEM TO BE USED ANYWHERE. MARKED FOR DELETION

    // ����� ������ - http://redmine.isd.su/issues/6843
    async GetTariffsAllCapacities(id: number, date: string): Promise<ActionResult> {
        // Form an ID type of a string to access the _cache array
        let stringId = `${id}_${date}`;
        //this._logger.say(`GET CAPACITY - ${stringId}`, 'info');

        if (this._processed[`${id}_${date}`] != null) {
            //this._logger.say(`${id}_${date} CAPACITY CACHED already! Skipping...`, 'warning');
        } else {
            // Mark as processed
            this._processed[`${id}_${date}`] = true;
            // Send request
            let result = await this._provider.GetTariffsAllCapacities(id, date);

            // If request was successful and data was obtained
            if (result.Success == true && result.Data != null) {
                //this._logger.say(`Found ${Object.keys(result.Data).length} capacity entries`, 'message');
                // For each item in the data
                for (var r in result.Data) {
                    //this._logger.say(`CAPACITY - ${stringId}_${r}`, 'work');
                    // Cache the capacity
                    let TC = new TariffCapacity();
                    TC.Count = result.Data[r].Count;
                    TC.Capacity = result.Data[r].Capacity;
                    TC.ZoneId = result.Data[r].ZoneId
                    TC.BindedZoneIds = result.Data[r].BindedZoneIds;
                    TC.TariffId = id;
                    TC.Date = date;
                    TC.SeanceId = r;
                    
                    this._cache[`${stringId}_${r}`] = TC;
                }
            }

        }
        return ActionResult.Success();
    }

    async GetCapacitiesForAllTariffs(tariffs: Array<Tariff>, date: string): Promise<void>{
        let requests = new Array<Promise<ActionResult>>();
        this._cache = [];
        this._capacityItems = [];
        this._processed = [];
        tariffs.forEach(x=> requests.push(this.GetTariffsAllCapacities(x.Id, date)));

        await Promise.allSettled(requests);

        let newCache: any = [];
        let helper: Array<TariffCapacity> =  [];
        Object.keys(this._cache).forEach((x: string) => {       
            let capacity = this._cache[x] as TariffCapacity;
            if(capacity.Capacity == null) return;

            helper.push(capacity);
        })
        
        helper = helper.sort((x: TariffCapacity,y: TariffCapacity): number => {
            if(x.BindedZoneIds!.length < y.BindedZoneIds!.length) return -1;
            if(x.BindedZoneIds!.length > y.BindedZoneIds!.length) return 1;
            else return 0;
        })

        helper.forEach((x: TariffCapacity) => {            
            let capacityInArray = this._capacityItems.find(z => z.ZoneId == x.ZoneId && z.Date == date && z.SeanceId == x.SeanceId);
            if (capacityInArray == null) {
                this._capacityItems.push(x);
                capacityInArray = x;
                this._capacityItems.filter(z=> capacityInArray!.BindedZoneIds != null && capacityInArray!.BindedZoneIds
                          .some(y=> y == z.ZoneId) && z.SeanceId == capacityInArray!.SeanceId)
                .forEach(z => z.AddBindedCapacity(capacityInArray!));
            }
        });

        Object.keys(this._cache).forEach((x: string) => {
            let capacity = this._cache[x] as TariffCapacity;
            if(capacity.Capacity == null) return;
            let capacityInArray = this._capacityItems.find(x => x.ZoneId == capacity.ZoneId && x.Date == date && x.SeanceId == capacity.SeanceId);
            newCache[`${x}`] = capacityInArray;
        })

        this._cache = newCache;
    }
}