import './wp-orders.scss';

import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import GlobalService, { Order, IOrderService, OrderServiceSymbols, MetricsSymbols, IMetricsService, OrdersFilter } from 'web-platform-core-ui';
import WpPageBaseComponent from '../wp-page-base-component';
import { MetricsEvents } from '../metrics-events-symbols';

export const wpOrdersProps = Vue.extend({
	props: {
		IsApp: {
			type: Boolean,
			default: false,
		},
	},
});

@Component({
	template: require('./wp-orders.html'),
})
export class WpOrders extends mixins(WpPageBaseComponent, wpOrdersProps, Vue) {
	OrderList: Array<Order> = [];
	Loading: boolean = true;
	NoData: boolean = false;
	OrderHeaders: Array<Object> = [
		{ text: '№ заказа', value: 'Code' },
		{ text: 'Дата', value: 'RegistredDate', sort: this.DateSort },
		{ text: 'Статус', value: 'Status' },
		{ text: 'Сумма', value: 'Sum', align: 'right' },
		{ text: '', value: 'Actions', sortable: false },
	];
	SortBy: string = 'Id';
	SortDesc: boolean = true;
	GetOrder(order: Order): void {
		this.$router.push({ name: 'wp-order', params: { OrderId: order.Id.toString() } });
	}
	DateSort(a: Date, b: Date): number {
		var FirstDate = this.DateHandler.ParseToDate(new Date(a)).getTime();
		var SecondDate = this.DateHandler.ParseToDate(new Date(b)).getTime();

		if (FirstDate > SecondDate) {
			return 1;
		} else {
			return -1;
		}
	}

	FormatStatus(Status: number): string {
		return Object.values(WpOrders.OrderStatus)[Status - 1];
	}

	FormatDate(date: string): string {
		let d = date.split('.')[0];
		return this.DateHandler.Parse(new Date(d)).Format('DD.MM.YYYY HH:mm');
	}

	FormatPrice(Price: number): string {
		return parseFloat(Price.toString()).toFixed(2).toString().replace(',', '.');
	}

	ItemRowBackgroundOpacity(item: any) {
		return item.Status != 4 ? '' : 'cancled-item';
	}

	ItemsPerPage: number = 10;
	Page: number = 1;
	PageCount: number = 0;
	PageAmounts: Array<number> = [10, 25, 100];

	SetPerPage(itemsPerPageNew: string): void {
		this.ItemsPerPage = parseInt(itemsPerPageNew, 10);
	}

	async mounted() {
		this.PageLoading = true;
		let service = this.GlobalService.Get<IOrderService>(OrderServiceSymbols.OrderService);

		let filter = GlobalService.GetSettings().PrivateSettings.ordersFilter;
		let result = await service.GetOrderListAsync(filter);

		if (result.Success && result.Data != null) {
			this.Loading = false;
			this.OrderList = result.Data.Entities;
		} else {
			this.NoData = true;
		}

		let metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
		metricsService.RegistrateEvent(MetricsEvents.OrdersList);

		this.PageLoading = false;
	}
}
export namespace WpOrders {
	export enum OrderStatus {
		Reserved = 'Зарезервирован',
		Locked = 'Заблокирован',
		Paid = 'Оплачен',
		Canceled = 'Отменён',
		Refunded = 'Возврат',
		Error = 'Ошибка',
		PartialRefund = 'Частичный возврат',
	}
}
export default WpOrders;
