import './wp-login-page.scss';

import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WpPageBaseComponent from './../wp-page-base-component';
import GlobalService, { AppSettings } from 'web-platform-core-ui';

@Component({
    template: require("./wp-login-page.html")
})
export default class WpLoginPage extends mixins(WpPageBaseComponent, Vue) {
    Step: number = 1;
    Url: string = '';
    Snackbar: boolean = false;

    mounted() {
        if (localStorage.getItem('identity-login-warn-message') != null)
            this.Snackbar = false;
        else
            this.Snackbar = true;

        let formSaleUrl = document.getElementById("form-sale-url");
        let formOrgId = document.getElementById("form-org-id");

        let saleUrl = '';
        let orgId = '';

        if (formSaleUrl != null) {
            saleUrl = formSaleUrl.getAttribute("value") ?? '';
        }

        if (formOrgId != null) {
            orgId = formOrgId.getAttribute("value") ?? '';
        }

        if (saleUrl != '' && orgId != '')
            this.Url = `${saleUrl}organizations/logo/${orgId}`;
        else
            this.Url = 'https://info.moipass.ru/images/webplatform.png';
    }

    NotShow() {
        localStorage.setItem('identity-login-warn-message', 'false');
        this.Snackbar = false;
    }

    AppGoBack(): void {
        if (this.Step == 1)
            // @ts-ignore
            closeApplication("");
        else
            this.Step = 1;
    }
} 