import IProfileProvider from './../Profile/i-profile-provider';
import UserProfile from './../Profile/user-profile';
import { ActionResult } from './../Common';
import { injectable } from 'inversify';


@injectable()
export default class FakeProfileProvider implements IProfileProvider {
    async SendAsync(topic: string, method: string, message: string, email: string, phone: string, name: string): Promise<ActionResult<any>> {
        return ActionResult.SuccessData<any>(undefined);
    }
    async ChangePasswordAsync(oldPassword: string, newPassword: string, confirmPassword: string): Promise<ActionResult<any>> {
        return ActionResult.Success();
    }
    async ChangeEmailAsync(email: string): Promise<ActionResult<any>> {
        return ActionResult.Success();
    }
    async SetPhoneAsync(phone: string): Promise<ActionResult<any>> {
        return ActionResult.Success();
    }
    async GetProfileAsync(): Promise<ActionResult<UserProfile>> {
        return ActionResult.SuccessData<UserProfile>(undefined);
    }

    async EditProfileAsync(profile: UserProfile): Promise<ActionResult> {    
        return ActionResult.Success();
    }

}