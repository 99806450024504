import Vue from 'vue';
import { Prop } from "vue/types/options";
import WpPageBaseComponent from './../wp-page-base-component';
import Component, { mixins } from 'vue-class-component';
import { IMetricsService, MetricsSymbols } from 'web-platform-core-ui';
import { MetricsEvents } from '../metrics-events-symbols';

export const wpFeedbackProps = Vue.extend({
    props: {
        Mail: {
            type: String,
            default: 'wptest@moipass.ru'
        }
    }
})

@Component({
    template: require("./wp-feedback.html")
})
export class WpFeedback extends mixins(WpPageBaseComponent, wpFeedbackProps, Vue) {
    protected _metricsService!: IMetricsService;

    IsIphone: boolean = false;
    StoreLabel: string = '';
    StoreLink: string = '';
    StoreName: string = '';

    OpenEmail(): void {
        this._metricsService.RegistrateEvent(MetricsEvents.FeedbackEmail);
        // @ts-ignore
        openInBrowser(`mailto:${this.Mail}`);
    }
    OpenStore(): void {
        if (this.IsIphone) {
            this._metricsService.RegistrateEvent(MetricsEvents.FeedbackAppleStore);

            // @ts-ignore
            storeRequestReview();
        } else {
            this._metricsService.RegistrateEvent(MetricsEvents.FeedbackPlayStore);

            // @ts-ignore
            openInBrowser(this.StoreLink);
        }
    }
    async mounted() {
        this.IsIphone = /iPhone|iPad|MacIntel|iPadOS|Macintosh|iPod/i.test(navigator.userAgent);
        if (this.IsIphone) {
            this.StoreName = WpFeedback.StoreNames.Iphone;
            this.StoreLink = WpFeedback.StoreLinks.Iphone;
            this.StoreLabel = WpFeedback.StoreLabels.Iphone + this.StoreName;
        }
        else {
            this.StoreName = WpFeedback.StoreNames.Android;
            this.StoreLink = WpFeedback.StoreLinks.Android;
            this.StoreLabel = WpFeedback.StoreLabels.Android + this.StoreName;
        }

        this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
    }
}

export namespace WpFeedback {
    export enum StoreLinks {
        Android = 'market://details?id=isds.mobiledemo&hl=en',
        Iphone = 'itms-apps://itunes.apple.com/app/1510758463?action=write-review',
    }
    export enum StoreLabels {
        Android = '<i aria-hidden="true" class="v-icon notranslate mdi mdi-google-play theme--dark"></i> Оценить в ',
        Iphone = '<i aria-hidden="true" class="v-icon notranslate mdi mdi-apple theme--dark"></i> Оценить в ',
    }
    export enum StoreNames {
        Android = 'Google Play',
        Iphone = 'App Store',
    }

}
export default WpFeedback; 