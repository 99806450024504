export * from './Global/app-settings';
export * from "./Global/service-registred-record";
export * from './Global/m-logger';

export * from './Arena';
export * from './Cart';
export * from './Club';
export * from './Cms';
export * from './Common';
export * from './Global';
export * from './Identity';
export * from './IdentityServerLogin';
export * from './Instructors';
export * from './Localization';
export * from './Loyalty';
export * from './Luzhniki';
export * from './Market';
export * from './Metrics';
export * from './News';
export * from './Notification';
export * from './Order';
export * from './Organizations';
export * from './PhoneConfirm';
export * from './Profile';
//export * from './Questionnaire';
export * from './RozaHutor';
export * from './Sale';
export * from './Sib';
export * from './StyleSettings';
export * from './Tariffs';
export * from './Utility';
export * from './Tennis';
export * from './Syntech';
export * from './LuzhnikiParking';
export * from './UserAccount';

import GlobalService from './Global/global-service';
export { GlobalSymbols } from './Global/global-service';
import WpCorePrefixes from './wp-core-prefixes';
export { WpCorePrefixes };
export default GlobalService;