export default class OrderDetails {
    Id: number = 0;
    Code: number = 0;
    UserName: string = "";
    Site: string = "";
    Organization: string = "";
    RegistredDate: string = "";
    Status: number = 0;
    CanSend: boolean = false;
    Sum: number = 0;
    TotalSum: number = 0;
    Discount: number = 0;
    CanRefund: boolean = false;
    RefundAddress: string = "";
    BonusSum: number = 0;
    Items?: Array<OrderDetailItem>;
    History?: Array<OrderHistoryItem>;
    Transactions?: Array<OrderTransactionItem>;
    SaleDate?: string;
    SaleTime?: string;
}
export class OrderDetailItem {
    Id: number = 0;
    Code: number = 0;
    Info: string = "";
    AdditionalInfo: any = {};
    Price: string = "";
    Tax: number = 0;
    Discount: string = "";
    Barcode?: string;
    Sum: number = 0;
    BonusSum: number = 0;
    AddiotionalInfo: any;
    TotalSum: number = 0;
    HaveTicket: boolean = false;
}
export class OrderHistoryItem {
    Id: number = 0;
    Date: string = "";
    Operation: number = 0;
    Result: number = 0;
    Description: string = "";
}
export class OrderTransactionItem {
    Currency: number = 0;
    Desciption: string = "";
    Id: number = 0;
    PaySystem: number = 0;
    RRN?: string = "";
    RegistredDate: string = "";
    Result: number = 0;
    Sum: number = 0;
    TransactionDate: string = "";
    TransactionId: string = "";
    Type: number = 0;

}