import Vue, { VueConstructor } from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { Prop, DefaultProps } from 'vue/types/options';
import GlobalService, { ArenaSeance, IArenaService, ArenaSymbols, AppSettings, BaseSystemLocalizationDictionary } from 'web-platform-core-ui';
import { formatDateTimeIsoToRu } from 'web-platform-core-ui/src/Utility/format';

export const wpArenaSeanceItemProp = Vue.extend({
    props: {
        Seance: Object as Prop<ArenaSeance>,
    }
}) 

@Component({
    template: require("./wp-arena-seance-item.html")
})
export default class WpArenaSeanceItem extends mixins<WpBaseComponent<WpArenaSeanceItemDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, Vue, wpArenaSeanceItemProp) {
    private _arenaService!: IArenaService;

    _formatDateTimeIsoToRu = formatDateTimeIsoToRu;
    
    MinPrice: number = 0;
    MaxPrice: number = 0;

    async mounted() {
        this._arenaService = this.GlobalService.Get<IArenaService>(ArenaSymbols.ArenaService);

        let seanceTariffs = await this._arenaService.GetTariffsAsync(this.Seance.Id);
        if (seanceTariffs.Data !== undefined) {
            this.MinPrice = Math.min.apply(Math, seanceTariffs.Data.map(function (x) { return x.MinPrice; }));
            this.MaxPrice = Math.max.apply(Math, seanceTariffs.Data.map(function (x) { return x.MaxPrice; }));
        }
    }

    Selected() {
        this.$emit("SeanceSelected", this.Seance);
    }
}

export class WpArenaSeanceItemDictionary extends BaseSystemLocalizationDictionary {
    TicketPrice: string = '';
    PricesLoading: string = '';
    CurrencyIcon: string = '';
}