import { CartItem } from "../Cart";
import { ErrorTypes } from "../Common";
import ActionResult from "../Common/action-result";
import OrderSale from "./order-sale";
import ReservationItem from "./reservation-item";

export default class ReservationResult extends ActionResult<OrderSale> {
    InvalidItem?: ReservationItem;
    InvalidCartItem?: CartItem;

    static IsSuccess(data?: OrderSale, invalidItem?: ReservationItem): ReservationResult {
        return { Success: true, Data: data, InvalidItem: invalidItem };
    };

    static IsFailed(errorType: ErrorTypes, invalidItem?: ReservationItem, errorMessage?: string): ReservationResult {
        return { Success: false, ErrorMessage: errorMessage, ErrorType: errorType, InvalidItem: invalidItem };
    }
}