export default class PersonAchievement {
    BadgeMissionIcon?: string | null;
    BadgeRewardIcon?: string | null;
    Category?: AchievementCategory;
    CompletedTasks?: CurrentTask[];
    ContractInfo!: ContractInfo | null;
    CurrentInfo!: CurrentInfo | null;
    CurrentTask!: CurrentTask | null;
    DateExpiry?: string | null;
    DateStart!: string;                     // "2022-08-10T00:00:00"
    GiftRequiredBadges?: number;            // 1
    GroupAchievements?: Array<PersonAchievement> | null;
    GroupOrder?: number;
    GroupType?: AchievementGroupType;
    Id!: number;                            // 14538800
    Name!: string;                          // "����� �� �����"
    PerfAchievementId?: number;             // 14547443
    RoundDurationValue?: number;
    TaskKind?: number;
    TaskType?: number;
};

class ContractInfo {
    BeginDate?: string;              // "2022-07-14T00:00:00"
    ClientId?: number;               // 14536715
    EndDate?: string;                // "2023-07-13T00:00:00"
    Id!: number;                     // 3255
}

export interface CurrentInfo {
    AvailableGifts: Array<AvailableGift> | null;
    CurrentBadges: number;
    CurrentNumberAvailableGifts: number;
    LastBadgeDate: Date;
    SelectedGifts: Array<AvailableGift>;
}

export class CurrentTask {
    BeginDate!: string;             // "2022-08-11T00:00:00"
    CompletedDescription?: string   // "���! � ���� �����������!"
    Description?: string;           // "���������� ���� ���� � �������: �������� 10 ������� antigravty-����"
    EndDate?: string;               // "2023-07-13T00:00:00"
    Id!: number;                    // 14539018
    Name!: string;                  // "�������� 10 ������� Antigravity-����� �� �����"
    NumberTargetActions?: number;   // 1
    ProgressCurrentValue!: number;  // 0
    ProgressRequiredValue!: number; // 10
    Status!: PerformStatusEnum;     // 1
    Targets?: Array<Target>;        // []
}

export class Target {
    Id!: number;                        // 14539019
    Name!: string;                      // "������� antigravty-����"
    NumberRepeats!: number;             // 1
    NumberCompletedRepeats: number = 0; // 0
    NumberCompletedTargets!: number;    // 0
    Status?: number;                    // 0
}

export interface AvailableGift {
    Barcode: string | undefined;
    CshdId: number | null;
    Id: number;
    Kind: number;
    Name: string;
    PerfGiftId: number;
    Status: GiftStatusEnum;
    TcoupId: number | null;
    TnodId: number | null;
    // Mutative properties not provided by back-end below
    ImageLink: string;
    Description: string;
};

enum PerformStatusEnum {
    NotAvailable = 0,   // ����������
    NotStarted = 1,     // ���������� �� ��������
    Performed = 2,      // �����������
    Completed = 3,      // ���������
};

export enum GiftStatusEnum {
    Selected = 1,       // ������ - ������������� (������������) - ������ ������ (������������) �������, �� ��� ��� �� �������
    Received = 2,       // ��������/������� - ������� �������� (����� ��� ����� ��� ��������� �� �����)
    Canceled = 3,       // ������� - ����� ������� ������� (������� ��������������� ����� ������ ������� �� ������ ���������)
};

enum AchievementCategory {
    none = 0,   // ��� ��������� (?)
    club,       // �� �� �����
    challenge,  // ������� ��������
    personal,   // ���������� ��� ������
}

export enum AchievementGroupType {
    none = 0,   // ���� none �� ���������� �� ���������
    set,
    serial,
}