export enum CartItemTypes {
    InstructorsPersonalLesson = 1,
    Tariff = 2,
    Bundle = 3,
    Rewrite = 4,
    InstructorsGroupLesson = 5,
    InstructorsBundleLessons = 6,
    ArenaTicket = 7,
    MarketTariff = 8,
    MarketBill = 9,
    NewClubContract = 10,
    ContractAdditionalService = 11,
    AccountReplenishment = 12,
    SyntechParking = 13,
    ContractAdditionalPackage = 14
}   