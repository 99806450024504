import './wp-profile.scss';

import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WpPageBaseComponent from './../wp-page-base-component';
import { IProfileService, ProfileSymbols, UserProfile, INotificationService, NotificationSymbols, IContext, IdentitySymbols } from 'web-platform-core-ui';
import { Ref } from 'vue-property-decorator';

@Component({
    template: require("./wp-profile.html")
})
export default class WpProfile extends mixins(WpPageBaseComponent, Vue) {

    @Ref('ProfileForm') ProfileForm!: HTMLFormElement;


    valid: boolean = true;
    lazy: boolean = false;

    IsSavingProfile: boolean = false;

    FirstName: string = "";
    LastName: string = "";
    Patronymic: string = "";

    BirthdateMenu: boolean = false;
    Birthdate: string = "";
    CurrentDate: string = "";

    Loading: boolean = true;

    UserSignOut(): void {
        let context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
        context.SignOut()
    }
    UserSignOutDialog: boolean = false;

    get BirthdateFormatted(): string {
        return this.Birthdate ? this.DateHandler.Parse(this.Birthdate, 'YYYY-MM-DD').Format('DD.MM.YYYY') : '';
    }


    Phone: string = "";
    NewPhone: string = "";
    /*
    get PhonePrefix(): string {
        if (this.Phone !== '')
            return '+7';
        return '';
    }
    */

    get NameRules() {
        return [
            (v: string) => v !== '' || 'Поле нельзя оставить пустым',
            (v: string) => (v && v.length > 2 && v.length < 30) || 'Неверный формат',
            (v: string) => (v && /^[A-aZ-zА-Яа-яЁё]*$/.test(v.trim())) || 'В поле могут быть только буквы'
        ];
    }
    get PhoneRules() {
        return [
            (v: string) => (/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/.test(v) || v === '') || 'Заполните номер до конца'
        ];
    }



    validate() {
        (this.$refs.ProfileForm as HTMLFormElement).validate();
    }
    PhoneFocus(): void {
        if (this.Phone === '') {
            this.Phone = '+7(';
        }
    }
    created() {
        this.CurrentDate = this.DateHandler.Parse(new Date()).Format('YYYY-MM-DD');
    }
    async mounted() {
        let service = this.GlobalService.Get<IProfileService>(ProfileSymbols.ProfileService);

        let result = await service.GetProfileAsync();

        if (result.Success && result.Data != null) {
            this.FirstName = result.Data.FirstName;
            this.LastName = result.Data.LastName;
            this.Patronymic = result.Data.SecondName;
            this.Birthdate = result.Data.Birthdate != null ? this.DateHandler.Parse(result.Data.Birthdate, 'YYYY-MM-DDTHH:mm:ss').Format('YYYY-MM-DD') : '';
            this.Phone = result.Data.Phone ?? '';
            this.Loading = false;
        }
    }

    async SaveProfile(): Promise<void> {
        this.IsSavingProfile = true;

        let userProfile = new UserProfile();

        userProfile.FirstName = this.FirstName.trim();
        userProfile.LastName = this.LastName.trim();
        userProfile.SecondName = this.Patronymic.trim();

        let tb = this.DateHandler.ParseToDate(this.Birthdate, 'YYYY-MM-DD');
        userProfile.Birthdate = new Date(tb.getFullYear(), tb.getMonth(), tb.getDate(), 12, 0);
        userProfile.Phone = this.Phone;

        let service = this.GlobalService.Get<IProfileService>(ProfileSymbols.ProfileService);

        let savingResult = await service.EditProfileAsync(userProfile);
        if (savingResult.Success) {
            let notification = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
            notification.Success('', 'данные по профилю изменены');
            notification.SendSystemNotification<UserProfile>("ProfileChanged", userProfile);
        }

        this.IsSavingProfile = false;
    }
} 