import  IUserStorage  from './i-user-storage'

export default class LocaleUserStorage implements IUserStorage{
    getItem(key: string) {
        return localStorage.getItem(key);
    }
    setItem(key: string, value: string) {
        localStorage.setItem(key, value);
    }
    removeItem(key: string) {
        localStorage.removeItem(key);
    }
    get length(): number {
        return localStorage.length;
    }
    key(index: number) {
        return localStorage.key(index);
    }
    async SaveStorage(token: string): Promise<void> {
        return;
    }
    async RestoreStorage(): Promise<void> {
    }

    async ClearStorage(): Promise<void> {
    }
}