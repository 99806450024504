export default class PersonalLessonsFilter {
    CategoryId: string = '';
    PlaceId: number = 0;
    Skill: string = '';
    DateFrom: string = '';
    DateTo: string = '';
    CoachId?: number | null;
    Location?: string | null;
    TariffId?: number | null;
    TariffCode?: number | null; // Duplicate of TariffId, see below point #1
    ContractId?: number | null;

    constructor(placeId: number, skill: string, categoryId: string, dateFrom: string, dateTo: string,
        coachId?: number | null, location?: string | null, tariffId?: number | null, contractId?: number | null) {
        this.PlaceId = placeId;
        this.Skill = skill;
        this.CategoryId = categoryId;
        this.DateFrom = dateFrom;
        this.DateTo = dateTo;
        this.CoachId = coachId;
        this.Location = location;
        this.TariffId = tariffId;
        this.ContractId = contractId;
        this.TariffCode = tariffId; // Duplicate of TariffId, see below point #1
    };
};

/*
 * Point #1 - Issue with filter not working
 * tl;dr - server received TariffId as a filter parameter. My guess it didn't know what to do with it, because it was totally ignored
 * I don't know if it's a typo or not - I don't want to modify existing code, so I just added TariffCode (as it is used in group lessons) and send it to the server
 * Please advise
 */