import ISaleProvider from './i-saler-provider';
import ReservationData from './reservation-data';
import { ActionResult, ErrorTypes } from './../Common';
import { OrderSale, OrderStatus, PersonificationApiOrderItem, PersonSale, PaymentSystem, ReservationItem, ReservationItemTypes } from './';
import { injectable, inject } from 'inversify';
import GlobalService, { IsdLoyaltyProgram } from './..';
import { RestClient } from 'typed-rest-client';
import PaymentForm from './payment-form';
import { IdentitySymbols } from './../Identity/symbols';
import { IContext } from './../Identity';
import OrderSaleState from './order-sale-state';
import * as moment from 'moment';
import LoyaltyProgram from './loyalty-program';
import LockOrderInfo from './lock-order-info';
import { AppSettings } from '../Global/app-settings';
import DiscountProgram from './discount-program';
import PayAccountPayment from './pay-account-payment';
import ReservationResult from './reservation-result';

@injectable()
export default class SaleProvider implements ISaleProvider {
	protected _settings: AppSettings;
	protected _context: IContext;

	constructor(@inject(IdentitySymbols.Context) context: IContext) {
		this._context = context;
		this._settings = GlobalService.GetSettings();
	}

	get NotAuthorizationHeaders(): any {
		return {
			headers: {
				SiteId: this._settings.SiteId,
				SessionId: this._settings.SessionId,
				User: this._settings.SessionId,
				moduleId: this._settings.SiteId,
			},
		};
	}

	get AuthorizationHeaders(): any {
		return {
			headers: {
				SiteId: this._settings.SiteId,
				SessionId: this._settings.SessionId,
				User: this._context.CurrentIdentity.UserId,
				Authorization: `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
				moduleId: this._settings.SiteId,
			},
		};
	}

	async PayForAccountPaymentAsync(data: PayAccountPayment): Promise<ActionResult> {
		let authHeaders = this.AuthorizationHeaders;
		// Remove SiteId from auth headers (hope it doesn't delete it for all instances)
		delete authHeaders.headers.SiteId;

		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? authHeaders : this.NotAuthorizationHeaders
		);

		try {
			let response = await client.create<AjaxResult<string>>(`/websale/api/accountpayment/pay`, data);

			if (response.result != null && response.result.Result != 0) {
				return ActionResult.FailedData(ErrorTypes.InternalError);
			}

			return ActionResult.SuccessData();
		} catch (e) {
			return ActionResult.FailedData(ErrorTypes.InternalError);
		}
	}

	async GetSignLinkToOrderAsync(orderId: number): Promise<ActionResult<string>> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		try {
			let response = await client.get<AjaxResult<string>>(`/api/widget/signlink?orderId=${orderId}`);

			if (response.result != null && response.result.Result != 0) {
				return ActionResult.FailedData(ErrorTypes.InternalError);
			}

			return ActionResult.SuccessData(response.result?.Data);
		} catch (e) {
			return ActionResult.FailedData(ErrorTypes.InternalError);
		}
	}
	async ReservateAsync(data: ReservationData): Promise<ReservationResult> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.create<AjaxResult<OrderSale>>(`/websale/api/order/reservate`, data);

		if (response.result != null && response.result.Result != 0) {
			const t2: number = ReservationItemTypes.Tariff;

			if (response.result.Result == 6) return ReservationResult.IsFailed(ErrorTypes.TariffNotAvailableForSale, response.result?.InvalidItem);

			if (response.result.Result == 7) return ReservationResult.IsFailed(ErrorTypes.QuotaExceededForTariff, response.result?.InvalidItem);

			if (response.result.Result == 10000) return ReservationResult.IsFailed(ErrorTypes.FreeTicketLimit, response.result?.InvalidItem);

			if (response.result.ErrorType == 'MediaCardExistSoTypeTariff')
				return ReservationResult.IsFailed(ErrorTypes.CardWithSoTypeTariff, response.result?.InvalidItem);

			if (response.result.ErrorType == 'RewriteTariffNotAvailable')
				return ReservationResult.IsFailed(ErrorTypes.RewriteTariffNotAvailable, response.result?.InvalidItem);

			if (response.result.ErrorType == 'InstructorsLessonBlocked' || response.result.ErrorType == 'InstructorsLessonNotAvailable')
				return ReservationResult.IsFailed(ErrorTypes.LessonNotAvailable, response.result?.InvalidItem);

			return ReservationResult.IsFailed(ErrorTypes.InternalError, response.result?.InvalidItem);
		}

		return ReservationResult.IsSuccess(response.result?.Data, response.result?.InvalidItem);
	}

	async RemoveItemAsync(itemId: number): Promise<ActionResult> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.get<AjaxResult<any>>(`/websale/api/order/removeitem/${itemId}`);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData(ErrorTypes.InternalError);

		return ActionResult.SuccessData();
	}

	async CancelOrderAsync(id: number): Promise<ActionResult> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.get<AjaxResult<any>>(`/websale/api/order/cancel/${id}`);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData(ErrorTypes.InternalError);

		return ActionResult.SuccessData();
	}

	async LeftTimeAsync(id: number): Promise<ActionResult<moment.Duration>> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.get<string>(`/websale/api/order/lefttime/${id}`);

		if (response.result == null) {
			return ActionResult.FailedData(ErrorTypes.InternalError);
		}

		return ActionResult.SuccessData<moment.Duration>(moment.duration(response.result));
	}

	async GetOrderAsync(id: number): Promise<ActionResult<OrderSale>> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.get<AjaxResult<OrderSale>>(`/websale/api/order/${id}`);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData(ErrorTypes.InternalError);

		return ActionResult.SuccessData<OrderSale>(response.result?.Data);
	}
	async LockOrderAsync(id: number): Promise<ActionResult> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.get<AjaxResult<any>>(`/websale/api/order/lock/${id}`);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData(ErrorTypes.InternalError);

		return ActionResult.SuccessData();
	}

	async GetPaymentsAsync(orderId: number): Promise<ActionResult<Array<PaymentSystem>>> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.get<AjaxResult<Array<PaymentSystem>>>(`/websale/api/order/payments/${orderId}`);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData(ErrorTypes.InternalError);

		return ActionResult.SuccessData<Array<PaymentSystem>>(response.result?.Data);
	}

	async GetPaymentFormAsync(id: number): Promise<ActionResult<PaymentForm>> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.get<AjaxResult<PaymentForm>>(`/websale/api/order/paymentform/${id}`);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData(ErrorTypes.InternalError);

		return ActionResult.SuccessData<PaymentForm>(response.result?.Data);
	}

	async GetSpecificPaymentFormAsync(id: number, paymentId: number): Promise<ActionResult<PaymentForm>> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.get<AjaxResult<PaymentForm>>(`/websale/api/order/paymentform/${paymentId}/specific/${id}`);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData(ErrorTypes.InternalError);

		return ActionResult.SuccessData<PaymentForm>(response.result?.Data);
	}

	async SetOrderSaleStateAsync(id: number, status: OrderStatus): Promise<ActionResult> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.get<AjaxResult<PaymentForm>>(
			`/api/widget/setorderstate?orderId=${id}&status=${status}&guid=${this._settings.GUID}`
		);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData(ErrorTypes.InternalError);

		return ActionResult.SuccessData<PaymentForm>(response.result?.Data);
	}

	async SetUserOrderState(id: number): Promise<ActionResult> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], this.AuthorizationHeaders);

		let response = await client.get<AjaxResult<PaymentForm>>(`/api/widget/setuser?guid=${this._settings.GUID}`);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData(ErrorTypes.InternalError);

		return ActionResult.SuccessData<PaymentForm>(response.result?.Data);
	}

	async GetOrderSaleStateAsync(): Promise<ActionResult<OrderSaleState>> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);
		let response = await client.get<AjaxResult<OrderSaleState>>(`/api/widget/getorderstate?guid=${this._settings.GUID}`);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData(ErrorTypes.InternalError);

		return ActionResult.SuccessData<OrderSaleState>(response.result?.Data);
	}

	async ClearOrderSaleStateAsync(): Promise<ActionResult> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.get<AjaxResult<any>>(`/api/widget/clearorderstate?guid=${this._settings.GUID}`);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData(ErrorTypes.InternalError);

		return ActionResult.SuccessData();
	}

	async ApplyCodeToOrderAsync(orderId: number, code: string): Promise<ActionResult<OrderSale>> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let data = { Id: orderId, Code: code };
		let response = await client.create<AjaxResult<OrderSale>>(`/websale/api/order/applycode`, data);

		if (response.result != null && response.result.Result != 0)
			return ActionResult.FailedData<OrderSale>(ErrorTypes.InternalError, undefined, response.result.Message);

		return ActionResult.SuccessData<OrderSale>(response.result?.Data);
	}

	async ClearOrderCodesAsync(orderId: number): Promise<ActionResult<OrderSale>> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.del<AjaxResult<any>>(`/websale/api/order/clearcodes?id=${orderId}`);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData(ErrorTypes.InternalError);

		return ActionResult.SuccessData<OrderSale>(response.result?.Data);
	}

	async SetPersonificationAsync(personificationItem: PersonificationApiOrderItem): Promise<ActionResult<any>> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.replace<AjaxResult<any>>(`/websale/api/order/personification/`, personificationItem);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData(ErrorTypes.InternalError);

		return ActionResult.SuccessData<any>(response.result?.Data);
	}
	async GetPersonificationUserPersons(userId: string): Promise<ActionResult<Array<PersonSale>>> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], this.AuthorizationHeaders);

		let response = await client.get<AjaxResult<any>>(`/websale/api/persons/userpersons?userId=${userId}`);

		if (response.result != null && response.result.Result != 0) return ActionResult.FailedData(ErrorTypes.InternalError);

		return ActionResult.SuccessData<Array<PersonSale>>(response.result?.Data);
	}

	async LockOrderWithDataAsync(lockOrderInfo: LockOrderInfo): Promise<ActionResult> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.create<AjaxResult<any>>(`/websale/api/order/lock/`, lockOrderInfo);

		if (response.result != null && response.result.Result != 0) {
			if (response.result.Result == 5) {
				return ActionResult.FailedData(ErrorTypes.InvalidData);
			}

			return ActionResult.FailedData(ErrorTypes.InternalError);
		}

		return ActionResult.SuccessData();
	}

	async SetUser(userId: string, id: number): Promise<ActionResult<OrderSale>> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let data = { Id: id, UserId: userId };
		let response = await client.replace<AjaxResult<OrderSale>>(`/websale/api/order/user`, data);

		if (response.result != null && response.result.Result != 0)
			return ActionResult.FailedData<OrderSale>(ErrorTypes.InternalError, undefined, response.result.Message);

		return ActionResult.SuccessData<OrderSale>(response.result?.Data);
	}

	async GetBalanceAsync(orderId: number): Promise<ActionResult<any>> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], this.AuthorizationHeaders);

		let response = await client.get<AjaxResult<any>>(`/websale/api/order/balance/${orderId}`);

		if (response.result != null && response.result.Result != 0) return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

		return ActionResult.SuccessData<IsdLoyaltyProgram>(response.result?.Data);
	}
	async ChargeBonusesAsync(orderId: number, bonuses: number): Promise<ActionResult<OrderSale>> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], this.AuthorizationHeaders);

		let response = await client.create<AjaxResult<OrderSale>>(`/websale/api/order/chargebonuses/`, { Id: orderId, Bonuses: bonuses });

		if (response.result != null && response.result.Result != 0)
			return ActionResult.FailedData<OrderSale>(ErrorTypes.InternalError, undefined, response.result.Message);

		return ActionResult.SuccessData<OrderSale>(response.result?.Data);
	}
	async GetLoyaltyListAsync(): Promise<ActionResult<Array<LoyaltyProgram>>> {
		let authHeaders = this.NotAuthorizationHeaders;
		//// Remove SiteId from auth headers (hope it doesn't delete it for all instances)

		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? authHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.get<AjaxResult<Array<LoyaltyProgram>>>(`/websale/api/order/loyalties/`);

		if (response.result != null && response.result.Result != 0) return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

		if (response.result != null && response.result.Data != null) return ActionResult.SuccessData(response.result.Data);

		return ActionResult.SuccessData();
	}
	async ApplyLoyaltyAsync(orderId: number, loyaltyId: number): Promise<ActionResult<OrderSale>> {
		let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], this.AuthorizationHeaders);

		let response = await client.create<AjaxResult<OrderSale>>(`/websale/api/order/applyloyalty/`, { Id: orderId, LoyaltyId: loyaltyId });

		if (response.result != null && response.result.Result != 0)
			return ActionResult.FailedData<OrderSale>(ErrorTypes.InternalError, undefined, response.result.Message);

		return ActionResult.SuccessData<OrderSale>(response.result?.Data);
	}
	async GetDiscountProgramsAsync(): Promise<ActionResult<Array<DiscountProgram>>> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.get<AjaxResult<Array<DiscountProgram>>>(`/websale/api/order/discounts/`);

		if (response.result != null && response.result.Result != 0) return ActionResult.Failed(ErrorTypes.InternalError, response.result.Message);

		if (response.result != null && response.result.Data != null) return ActionResult.SuccessData(response.result.Data);

		return ActionResult.SuccessData();
	}
	async ChangeDiscountAsync(orderId: number, discountId: number): Promise<ActionResult<OrderSale>> {
		let client: RestClient = new RestClient(
			'wp-app',
			this._settings.ServerUrl,
			[],
			this._context.CurrentIdentity.IsAuthentificated ? this.AuthorizationHeaders : this.NotAuthorizationHeaders
		);

		let response = await client.create<AjaxResult<OrderSale>>(`/websale/api/order/changediscount/`, { Id: orderId, DiscountId: discountId });

		if (response.result != null && response.result.Result != 0)
			return ActionResult.FailedData<OrderSale>(ErrorTypes.InternalError, undefined, response.result.Message);

		return ActionResult.SuccessData<OrderSale>(response.result?.Data);
	}
}

class AjaxResult<T> {
	Result: number = 0;
	Message: string = '';
	Data?: T;
	ErrorType: string = '';
	InvalidItem?: any;
}
