import { Brand, EcomAddToCart, EcomBaseProducts, EcomProduct, EcomRemoveFromCart } from './../Metrics/i-ecom-data';
import CartItem from './../Cart/cart-item';
import Tariff from './tariff';
import { CartItemTypes } from './../Cart/cart-item-types';
import { ActionResult, ErrorTypes, WpEventArgs } from './../Common';
import { ReservationItemTypes, ReservationItem } from './../Sale';
import * as moment from 'moment';

export default class RewriteCartItem extends CartItem {

    private _date: string = "";
    private _tariff: Tariff;
    private _price: number = 0;
    private _quota?: number;
    private _type: CartItemTypes = CartItemTypes.Rewrite;
    private _card: string = '';
    private _rentalOptionId?: string;
    private _cardType?: number;
    private _cardName?: string;

    get IsAvailable(): boolean {
        return true;
    }
    public get Info(): string {
        return `<strong>${this._tariff.Name}</strong>, ${this._date} на карту ${this._cardName ?? this._card}`;
    }
    public get Type(): CartItemTypes {
        return this._type;
    }
    public get Price(): number {
        return this._price;
    }
    public get Count(): number {
        return 1;
    }
    public get IsCanIncreaseCount(): boolean {
        return false;
    }
    public get IsCanReduceCount(): boolean {
        return false;
    }
    public get Tariff(): Tariff {
        return this._tariff;
    }
    public get Date(): string {
        return this._date;
    }
    public get Card(): string {
        return this._card;
    }
    get MetricsId(): any | undefined {
        return this._tariff?.Id;
    }
    public get Dates(): Array<string> {
        let result = new Array<string>();

        if (this.Date !== undefined) {
            result.push(moment.parseZone(this.Date, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY'));
        }

        return result;
    }
    public get AdditionalInfo(): any {
        return {
            card: this._card,
            cardType: this._cardType,
            tariff: this._tariff
        }
    }

    constructor(tariff: Tariff, date: string, price: number, card: string, quota?: number, rentalOptionId?: string, cardType?: number, cardName?: string) {
        super();

        this._date = date;
        this._tariff = tariff;
        this._price = price;
        this._quota = quota;
        this._card = card;
        this._rentalOptionId = rentalOptionId;
        this._cardType = cardType;
        this._cardName = cardName;
    }

    public IncreaseCount(): ActionResult {
        return ActionResult.Failed(ErrorTypes.CartError, "Превышено допустимое количество для данного типа услуг");
    }

    public ReduceCount(): ActionResult {
        return ActionResult.Failed(ErrorTypes.CartError, "Достигнуто минимальное возможное количество");
    };

    public IsConflictItem(item: CartItem): boolean {
        if (item.Type != CartItemTypes.Rewrite) return false;

        let rewriteCartItem = item as RewriteCartItem;
        if (rewriteCartItem == null) return false;
        if (rewriteCartItem.Card == this.Card) return true;

        return false;
    };

    public IsEqualsItem(item: CartItem): boolean {
        return false;
    };

    public CombineItem(item: CartItem): ActionResult {
        return ActionResult.Failed(ErrorTypes.CartError, "Превышено допустимое количество для данного типа услуг");
    };

    public GetReservationItem(): Array<ReservationItem> {
        // Object deconstruction also needed here
        return [new ReservationItem(
            1,
            ReservationItemTypes.RewritableTariff,
            this._tariff.Id,
            this._date,
            null,
            null,
            this._card,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            this._rentalOptionId ? `${this._tariff.Id}_${this._rentalOptionId}` : undefined,
            this._cardType
        )];
    };

    async RemoveItem(): Promise<ActionResult> {
        return ActionResult.Success();
    };

    IsAssociatedReservationItem(item: ReservationItem): boolean{
        return item.ItemType == ReservationItemTypes.RewritableTariff &&
               item.Count == 1 &&
               this._tariff.Id == item.TariffId &&
               this._card == item.MediaNum &&
               this._cardType == item.MediaType &&
               this._date == item.Date;
    }

    GetEcomData(eventId: Symbol) {	
        let removeResult = new EcomRemoveFromCart();
        let result = new EcomAddToCart();	
        result.add = new EcomBaseProducts();
        removeResult.remove = new EcomBaseProducts();

        if(eventId == Symbol.for('incart_plus') || eventId == Symbol.for('service_cart_add')){
            result?.add?.products.push({
                id: this._tariff.TariffCode.toString(),
                name: this._tariff.Name,
                list: this._tariff.GroupName,
                brand: Brand.ISDS,
                quantity: 1,
                variant: EcomProduct.GetProductVariant(undefined, this._tariff.OptionName),
                price: this._price
            });
        } else if(eventId == Symbol.for('incart_minus') || eventId == Symbol.for('incart_remove')){        
            removeResult?.remove?.products.push({
                id: this._tariff.TariffCode.toString(),
                name: this._tariff.Name,
                list: this._tariff.GroupName,
                brand: Brand.ISDS,
                quantity: 1,
                variant: EcomProduct.GetProductVariant(undefined, this._tariff.OptionName)
            });

            return removeResult;
        }

        return result;
	}
};