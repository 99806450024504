import Vue, { VueConstructor } from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';
import {
    INotificationService, NotificationSymbols, InstructorsGroupLesson,
    InstructorsGroupCartItem, IInstructorsService, InstructorsSymbols, BaseSystemLocalizationDictionary
} from 'web-platform-core-ui';
import { DefaultProps } from 'vue/types/options';


export const wpInstructorsGroupLessonsProp = Vue.extend({
    props: {
        lessons: Array,
        dates: Array,
        categoryName: String
    }
})
@Component({
    template: require("./wp-instructors-group-lessons.html")
})

export default class WpInstructorsGroupLessons extends mixins<WpBaseComponent<WpInstructorsGroupLessonsDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, wpInstructorsGroupLessonsProp, Vue) {
    private _price: number = 0;
    protected _notificationService!: INotificationService;
    private _instructorsService!: IInstructorsService;

    @PropSync('lessonsBought', { type: Array }) LessonsBought!: Array<InstructorsGroupCartItem>;

    Lessons!: Array<InstructorsGroupLesson>;
    Dates!: Array<string>;
    LessonsAndDatesLoaded: boolean = false;
    CategoryName: string = '';
    Price: number = 0;

    GroupAmount: number = 1;

    GroupMin: number = 1;
    GroupMax: number = 10;
    get CanGroupDecrease() {
        return this.GroupAmount > this.GroupMin;
    }
    get CanGroupIncrease() {
        if (this.Lesson !== undefined) {
            return this.GroupAmount < this.GroupMax && this.GroupAmount < this.Lesson.FreeMembers;
        } else {
            return this.GroupAmount < this.GroupMax;
        }
    }
    GroupDecrease(): void {
        if (this.GroupAmount > this.GroupMin) {
            this.GroupAmount--;
        }
        this.Price = this.GroupAmount * this._price;
    }
    GroupIncrease(): void {
        if (this.Lesson !== undefined) {
            if (this.GroupAmount < this.GroupMax && this.GroupAmount < this.Lesson.FreeMembers) {
                this.GroupAmount++;
            }
        } else {
            if (this.GroupAmount < this.GroupMax) {
                this.GroupAmount++;
            }
        }
        this.Price = this.GroupAmount * this._price;
    }

    async mounted() {
        this.Lessons = await this.$props.lessons;
        this.Dates = await this.$props.dates;
        this.LessonsAndDatesLoaded = true;
        this.CategoryName = await this.$props.categoryName;
        if (this.Lesson !== undefined && this.Lesson.FreeMembers < this.GroupMax)
            this.GroupMax = this.Lesson.FreeMembers;

        this._instructorsService = this.GlobalService.Get<IInstructorsService>(InstructorsSymbols.InstructorsService);
        this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
    }

    LessonsByDate(date: string) {
        let _this = this;
        let result = this.Lessons.filter(x => _this.DateHandler.Parse(x.Date, 'YYYY-MM-DDTHH:mm:SS').Format('YYYY-MM-DD') == date)
        return result;//DateHandlerSame
    }

    Lesson?: InstructorsGroupLesson;
    LessonLoaded: boolean = false;
    LessonPriceLoaded: boolean = false;
    LessonTariffAvailable: boolean = false;

    PeopleAmountDialog: boolean = false;

    async ShowLessonPurchaseDialog(lesson: InstructorsGroupLesson) {
        this.LessonPriceLoaded = false;
        this.LessonTariffAvailable = false;
        if (!this.IsBought(lesson)) {
            this.Lesson = this.Lessons?.find(x => x.TariffId === lesson.TariffId &&
                x.Date === lesson.Date &&
                x.BeginTime === lesson.BeginTime &&
                x.EndTime === lesson.EndTime &&
                x.SkillType === lesson.SkillType);
            this.LessonLoaded = true;
            this.PeopleAmountDialog = true;

            if (this.Lesson != null) {
                let price = await this._instructorsService.GetGroupLessonPriceAsync(this.Lesson);

                if (price.Success && price.Data !== null && price.Data !== undefined) {
                    this._price = price.Data.PriceForOne;
                    this.LessonTariffAvailable = true;
                    this.Price = this.GroupAmount * this._price;
                } 
            } else {
                this._notificationService.Error("Ошибка", "Занятие не найдено");
            }
        }
        this.LessonPriceLoaded = true;
    }

    FormatDate(dateString: string): string {
        return this.DateHandler.Parse(dateString, 'YYYY-MM-DD').Format('DD.MM.YYYY');
    }
    FormatTime(dateString: string): string {
        return this.DateHandler.Parse(dateString, 'HH:mm:SS').Format('HH:mm');
    }

    PurchaseLesson(): void {
        this.$emit('purchaseLesson', this.Lesson, this.GroupAmount, this._price);
        this.PeopleAmountDialog = false;
    }
    IsBought(lesson: InstructorsGroupLesson): boolean {
        let boughtLesson = this.LessonsBought.find(x => x.Lesson.TariffId === lesson.TariffId &&
            x.Lesson.Date === lesson.Date &&
            x.Lesson.BeginTime === lesson.BeginTime &&
            x.Lesson.EndTime === lesson.EndTime &&
            x.Lesson.SkillType === lesson.SkillType);
        if (boughtLesson !== undefined)
            return true
        return false
    }
}

export class WpInstructorsGroupLessonsDictionary extends BaseSystemLocalizationDictionary {
    Vacant: string = '';
    GuestsAmmount: string = '';
    NoAvailableLessons: string = '';
}