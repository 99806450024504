import { injectable } from 'inversify';

import ActionResult from '../Common/action-result';
import { RestClient } from 'typed-rest-client';
import GlobalService from './../Global/global-service';
import { ErrorTypes } from './../Common/error-types';
import ISyntechProvider from './i-syntech-provider';
import ParkingSessionInfo from './parking-session-info';
import Tariff from './../Tariffs/tariff';
import { AppSettings } from './../Global/app-settings';

@injectable()
export default class DefaultSyntechProvider implements ISyntechProvider {
    protected _settings: AppSettings;

    constructor() {
        this._settings = GlobalService.GetSettings();
    }


    async GetSessionInfoAsync(ticket: string): Promise<ActionResult<ParkingSessionInfo>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<ParkingSessionInfo>>(`/websale/api/syntech/info/${ticket}`);

        if (response.result != null && response.result.Result != 0) {
            if (response.result.Result == 16)
                return ActionResult.FailedData(ErrorTypes.NotFoundData, response.result?.Data, response.result.Message);

            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data, response.result.Message);
        }

        return ActionResult.SuccessData<ParkingSessionInfo>(response.result?.Data);
    }

    async GetTariffsAsync(): Promise<ActionResult<Array<Tariff>>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<Array<Tariff>>>(`/websale/api/syntech/tariffs`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data, response.result?.Message);

        return ActionResult.SuccessData<Array<Tariff>>(response.result?.Data);
    };

}

class AjaxResult<T> {
    Result: number = 0;
    Message: string = '';
    Data?: T;
}
