import WpEventArgs from './wp-event-args';

export default abstract class WpEvent<T extends WpEventArgs>{
    protected _handlers: { (sender: any, e: T): void; }[] = [];

    Subscribe(handler: { (sender: any, e: T): void }): void {
        this._handlers.push(handler);
    }

    Unsubscribe(handler: { (sender: any, e: T): void }): void {
        this._handlers = this._handlers.filter(h => h !== handler);
    }
}