import { EcomAddToCart, Brand, EcomProduct, EcomRemoveFromCart, EcomBaseProducts } from './../Metrics/i-ecom-data';
import CartItem from './../Cart/cart-item';
import Tariff, { RentalOption } from './tariff';
import { CartItemTypes } from '../Cart/cart-item-types';
import { ActionResult, ErrorTypes, WpEventArgs } from './../Common';
import { ReservationItemTypes, ReservationItem } from './../Sale';
import { Cart, TariffSale } from './..';
import TariffSeance from './tariff-seance';
import TariffCapacity from './tariff-capacity';
import * as moment from 'moment';

export default class TariffCartItem extends CartItem { 
    private _capacity?: TariffCapacity;
    private _date: string = "";
    private _count: number;
    private _tariff: Tariff;
    private _price: number = 0;
    private _tariffSale: TariffSale = new TariffSale();
    private _cart: Cart;
    private _maxcartitems: number = 6;
    private _type: CartItemTypes = CartItemTypes.Tariff;
    private _seance?: TariffSeance;
    private _carNumber?: string;
    private _rentalOption?: RentalOption;

    get IsAvailable(): boolean {
        //if (this._tariffSale.Quota != null && this.TariffSale.LeftCount != null && this.TariffSale.LeftCount != null) {
        //    return this.TariffSale.Count + this.TariffSale.ReservedCount < this.TariffSale.Quota;
        //}

        //if (this._capacity != null) {
        //    return this._capacity.Count + this._capacity.ReservedCount < this._capacity.Capacity;
        //}

        return true;
    }
    get MetricsId(): any | undefined {
        return this._tariff?.Id;
    }
    public get Info(): string {
        let name = this._tariff.Name;
        let date = this._date;
        let seance = this._seance != null ? TariffSeance.SeanceTime(this._seance) : '';
        let carNumber = this._carNumber != null ? this._carNumber : '';
        let rentalOption = this._rentalOption != null ? this._rentalOption.Name : ''
        let string = `<strong>${name}</strong>, ${date} ${seance} ${carNumber}`;
        if (rentalOption) {
            string += `<br/> ${rentalOption}`;
        };
        return string;
    }
    public get Type(): CartItemTypes {
        return this._type;
    }
    public get Price(): number {
        return this._price * this._count;
    }
    public get Count(): number {
        return this._count;
    }
    public get IsCanIncreaseCount(): boolean {
        if (this._tariffSale != null && this._tariffSale.Quota != null && this.TariffSale.LeftCount != null) {
            return this._cart.LeftCount > 0 && this.TariffSale.LeftCount >= this._count + 1;
        }

        if (this._capacity != null) {
            return this._capacity.LeftCount >= 1 && this._cart.LeftCount > 0;
        }

        return this._cart.LeftCount > 0;
    }

    public get IsCanReduceCount(): boolean {
        return this._count >= 2;
    }
    public get Tariff(): Tariff {
        return this._tariff;
    }
    public get TariffSale(): TariffSale {
        return this._tariffSale;
    }
    public get Seance(): TariffSeance | undefined {
        return this._seance;
    }
    public get CarNumber(): string | undefined {
        return this._carNumber;
    }
    public get Date(): string {
        return this._date;
    }
    public get Dates(): Array<string> {
        let result = new Array<string>();

        if (this.Date !== undefined) {
            result.push(moment.parseZone(this.Date, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY'));
        }

        return result;
    }
    public get AdditionalInfo(): any {
        return this._tariff;
    }

    constructor(tariff: Tariff, count: number, date: string, price: number, cart: Cart,
        tariffSale: TariffSale, seance?: TariffSeance, capacity?: TariffCapacity,
        carNumber?: string, rentalOption?: RentalOption ) {
        super();

        this._date = date;
        this._tariff = tariff;
        this._count = count;
        this._price = price;
        this._tariffSale = tariffSale;
        this._cart = cart;
        this._seance = seance;
        this._capacity = capacity;
        this._carNumber = carNumber;
        this._rentalOption = rentalOption;

        if (cart !== undefined)
            this._maxcartitems = cart.CartMaxItems;

        if (this._capacity != null)
            this._capacity.ReservedCount = this._capacity.ReservedCount + this._count;

        if (this._tariffSale.Quota != null)
            this._tariffSale.ReservedCount = this._tariffSale.ReservedCount + this._count;
    };

    public IncreaseCount(): ActionResult {
        let increase = this._count < this._maxcartitems;

        if (this.TariffSale != null && this.TariffSale.Quota != null && this.TariffSale.LeftCount != null) {
            increase = this.TariffSale.LeftCount >= this._count + 1;
        }

        if (this._capacity != null) {
            increase = this._capacity.LeftCount >= 1;
        }

        if (increase) {
            if (this._capacity) {
                this._capacity.ReservedCount++;
            }

            if (this._tariffSale.Quota != null) {
                this._tariffSale.ReservedCount++;
            }

            this._count++;

            this._cartItemChanged.Send(this, WpEventArgs.Empty);

            return ActionResult.Success();
        }
        else
            return ActionResult.Failed(ErrorTypes.CartError, "Превышено допустимое количество для данного типа услуг");
    }

    public ReduceCount(): ActionResult {
        if (this._count > 1) {
            if (this._capacity) {
                this._capacity.ReservedCount--;
            }

            if (this._tariffSale) {
                this._tariffSale.ReservedCount--;
            }

            this._count--;

            this._cartItemChanged.Send(this, WpEventArgs.Empty);
            return ActionResult.Success();
        }
        else {
            return ActionResult.Failed(ErrorTypes.CartError, "Достигнуто минимальное возможное количество");
        }

    }

    public IsConflictItem(item: CartItem): boolean {
        return false;
    }

    public IsEqualsItem(item: CartItem): boolean {
        if (item.Type != CartItemTypes.Tariff) return false;

        let cartItem = item as TariffCartItem;
        return cartItem != null && cartItem._tariff.Id == this._tariff.Id && cartItem.Date == this._date &&
            cartItem.Seance?.SeanceId == this.Seance?.SeanceId && cartItem.CarNumber == this._carNumber;
    }

    public CombineItem(item: CartItem): ActionResult {
        let cartItem = item as TariffCartItem;

        if (cartItem.Count == this._maxcartitems)
            return ActionResult.Failed(ErrorTypes.CartError, "Превышено допустимое количество для данного типа услуг");

        this._count = cartItem._count + this._count;

        this._cartItemChanged.Send(item, WpEventArgs.Empty);

        return ActionResult.Success();
    }

    public GetReservationItem(): Array<ReservationItem> {
        // We desperately need OBJECT DECONSTRUCTION here
        let item = new ReservationItem(
            this._count,
            ReservationItemTypes.Tariff,
            this._tariff.Id,
            this._date,
            null,
            null,
            null,
            null,
            this._seance?.SeanceId,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            this._rentalOption ? `${this._tariff.Id}_${this._rentalOption.Id}` : undefined
        );

        if (this.CarNumber != null && this.CarNumber != '')
            item.CarNumbers = [this.CarNumber];
        return [item];
    }

    async RemoveItem(): Promise<ActionResult> {
        if (this._capacity) {
            this._capacity.ReservedCount = this._capacity.ReservedCount - this.Count;
        }

        if (this._tariffSale.Quota != null) {
            this._tariffSale.ReservedCount = this._tariffSale.ReservedCount - this.Count;
        }

        return ActionResult.Success();
    }

    IsAssociatedReservationItem(item: ReservationItem): boolean{
        return item.ItemType == ReservationItemTypes.Tariff &&
               item.Count == this._count &&
               this._tariff.Id == item.TariffId &&
               this._seance?.SeanceId == item.SeanceId &&
               this._date == item.Date;
    }

    GetEcomData(eventId: Symbol) {	
        let removeResult = new EcomRemoveFromCart();
        let result = new EcomAddToCart();	
        result.add = new EcomBaseProducts();
        removeResult.remove = new EcomBaseProducts();
        
        if(eventId == Symbol.for('incart_plus')){
            result?.add?.products.push({
                id: this._tariff.TariffCode.toString(),
                name: this._tariff.Name,
                list: this._tariff.GroupName,
                brand: Brand.ISDS,
                quantity: 1,
                variant: EcomProduct.GetProductVariant(this._seance, this._tariff.OptionName),
                price: this._price
            });
        } else if(eventId == Symbol.for('service_cart_add')){    
            for (let i = 0; i < this._count; i++) {
                result?.add?.products.push({
                    id: this._tariff.TariffCode.toString(),
                    name: this._tariff.Name,
                    list: this._tariff.GroupName,
                    brand: Brand.ISDS,
                    quantity: 1,
                    variant: EcomProduct.GetProductVariant(this._seance, this._tariff.OptionName),
                    price: this._price
                });
            };
        } else if(eventId == Symbol.for('incart_minus')){
            removeResult?.remove?.products.push({
                id: this._tariff.TariffCode.toString(),
                name: this._tariff.Name,
                list: this._tariff.GroupName,
                brand: Brand.ISDS,
                quantity: 1,
                variant: EcomProduct.GetProductVariant(this._seance, this._tariff.OptionName)
            });

            return removeResult;
        } else if(eventId == Symbol.for('incart_remove')){
            for (let i = 0; i < this._count; i++) {
                removeResult?.remove?.products.push({
                    id: this._tariff.TariffCode.toString(),
                    name: this._tariff.Name,
                    list: this._tariff.GroupName,
                    brand: Brand.ISDS,
                    quantity: 1,
                    variant: EcomProduct.GetProductVariant(this._seance, this._tariff.OptionName)
                });
            };

            return removeResult;
        }

        return result;
	}
}