import { WpEventArgs } from './../Common';
import { Localization } from './localization';

export default class LocalizationChangedEventArgs extends WpEventArgs {
    OldLocalization: Localization;
    NewLocalization: Localization;

    constructor(oldLocalization: Localization, newLocalization: Localization) {
        super();

        this.OldLocalization = oldLocalization;
        this.NewLocalization = newLocalization;
    }
}