import './wp-arena-sectors.scss';

import Vue, { VueConstructor } from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
    ArenaSector, AppSettings, IArenaService, ArenaSymbols, ArenaSeance,
    ArenaSectorList, ArenaPolygonPoint, WpCorePrefixes, ICmsService,
    CmsSymbols, BaseFilter, BaseSystemLocalizationDictionary
} from 'web-platform-core-ui';
import { PropSync, Watch } from 'vue-property-decorator';
import { DefaultProps } from 'vue/types/options';

export const wpArenaSectorsProp = Vue.extend({
    props: {
        //Seance: Object as Prop<ArenaSeance>,
    }
})

@Component({
    template: require("./wp-arena-sectors.html")
})
export default class WpArenaSectors extends mixins<WpBaseComponent<WpArenaSectorsDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, Vue, wpArenaSectorsProp) {
    private _arenaService!: IArenaService;

    Settings!: AppSettings;
    SectorsList: ArenaSectorList = new ArenaSectorList();
    SectorFreePlacesArray: Array<any> = [];
    Loading: boolean = false;
    ForcedSector: ArenaSector | null = null;

    @PropSync('seance', { type: Object }) Seance!: ArenaSeance;

    @Watch('Seance')
    async OnSeanceChange(seance: ArenaSeance) {
        this.Seance = seance;
        this.RenderSectors();
    };

    async mounted(): Promise<void> {
        this._arenaService = this.GlobalService.Get<IArenaService>(ArenaSymbols.ArenaService);
        this.Settings = GlobalService.GetSettings<AppSettings>();
        await this.RenderSectors();
        if (this.Settings.ForceSectorId) {
            this.ForcedSector = this.SectorsList.Sectors.find(sector => sector.SectorCode == this.Settings.ForceSectorId) || null;
            if (this.ForcedSector) {
                this.SectorSelected(this.ForcedSector);
                this.$emit("ForcedSectorDetected", this.ForcedSector);
            }
        };
    };

    // Investigate this later in case it doesn't work (duh)
    freePlaces(item: any): number {
        let free = this.SectorFreePlacesArray.find(
            x => x.SectorCode === item.SectorCode && x.SeanceId == this.Seance.Id
        );
        return free?.FreePlaces || 0;
    }

    async RenderSectors(): Promise<void> {
        this.Loading = true;

        if (this.Seance != null) {
            let sectors = await this._arenaService.GetSectorsAsync(this.Seance.Id);
            let tariffs = await this._arenaService.GetTariffsAsync(this.Seance.Id);

            let sectorsList: Array<ArenaSector> = [];
            // todo: переписать с нормальными функциями
            if (sectors.Success && sectors.Data != null) {
                let cmsService = this.GlobalService.Get<ICmsService>(CmsSymbols.CmsService);
                let result = await cmsService.GetEntitiesAsync<ArenaSector>(WpCorePrefixes.ArenaSectorNames, new BaseFilter(), '');

                if (result.Success && result.Data != null) {
                    sectors.Data.Sectors.forEach(x => {
                        let sector = result.Data?.Entities.find(sc => sc.SectorCode == x.SectorCode);
                        if (sector != null) x.Name = sector.Name;
                    })
                }

                if (tariffs.Success && tariffs.Data !== undefined) {
                    for (let i = 0; i < sectors.Data.Sectors.length; i++) {

                        let SectorCode = sectors.Data.Sectors[i].SectorCode;
                        let SectorCodeFreePlaces = 0;

                        let TariffCodesInSector = [];

                        for (let j = 0; j < tariffs.Data.length; j++) {
                            let row = tariffs.Data[j];

                            if (row.FreePlaces !== undefined) {
                                for (let k = 0; k < row.FreePlaces?.length; k++) {
                                    let row2 = row.FreePlaces[k];

                                    if (row2.SectorCode === SectorCode) {

                                        TariffCodesInSector.push({
                                            TariffCode: row.TariffCode,
                                            MinPrice: row.MinPrice,
                                            MaxPrice: row.MaxPrice
                                        });
                                        SectorCodeFreePlaces = SectorCodeFreePlaces + row2.Count;
                                    }
                                }
                            }
                        }
                        let MinPriceArr = [];
                        let MaxPriceArr = [];

                        for (let l = 0; l < TariffCodesInSector.length; l++) {
                            let tariffrow = TariffCodesInSector[l];

                            MinPriceArr.push(tariffrow.MinPrice);
                            MaxPriceArr.push(tariffrow.MaxPrice);
                        }

                        this.SectorFreePlacesArray.push({
                            SectorCode: SectorCode,
                            FreePlaces: SectorCodeFreePlaces,
                            MinPrice: Math.min.apply(null, MinPriceArr),
                            MaxPrice: Math.max.apply(null, MaxPriceArr),
                            SeanceId: this.Seance.Id
                        });

                        if (SectorCodeFreePlaces > 0)
                            sectorsList.push(sectors.Data.Sectors[i]);
                    }
                }
                sectors.Data.Sectors = sectorsList;
                this.SectorsList = sectors.Data;
                this.Loading = false;
            }
        }
    }

    get ViewBox(): string {
        return `0 0 ${this.SectorsList?.ImageWidth} ${this.SectorsList?.ImageHeight}`;
    };

    StringPoints(points: Array<ArenaPolygonPoint>): string {
        let result = '';
        points.forEach(point => {
            result += `${point.X},${point.Y} `
        });
        return result.trimRight();
    };

    SectorSelected(sector: ArenaSector) {
        this.$emit("SectorSelected", { Sector: sector, ArenaText: this.SectorsList.ArenaText, ArenaPosition: this.SectorsList.ArenaPosition });
    };

    PolygonSelected(sector: ArenaSector) {
        this.$emit("SectorSelected", { Sector: sector, ArenaText: this.SectorsList.ArenaText, ArenaPosition: this.SectorsList.ArenaPosition });
    };
};

export class WpArenaSectorsDictionary extends BaseSystemLocalizationDictionary {
}