import { injectable, inject, id } from 'inversify';

import ILocalizationService from './i-localization-service';
import LocalizationChangedEventArgs from './localization-changed-event-args';
import { Localization } from './localization';
import LocalizationStaticDictionary from './localization-static-dictionary';

import { WpEvent, WpEventHandler, ActionResult, ErrorTypes, BaseFilter } from './../Common';
import ITranslatable from './i-translatable';
import { BaseSystemLocalizationDictionary } from '.';
import IDataProvider from '../Cms/i-data-provider';
import { CmsSymbols } from '../Cms/symbols';
import WpCorePrefixes from '../wp-core-prefixes';

@injectable()
export default class DefaultLocalizationService implements ILocalizationService {
    private _downloadedDictionaries: any = {};
    private _currentLocalization: Localization = Localization.RU;
    private _ruStaticDictionaries: Array<any> = [];
    private _enStaticDictionaries: Array<any> = [];

    private _provider: IDataProvider;

    private _localizationChanged: WpEventHandler<LocalizationChangedEventArgs> =
        new WpEventHandler<LocalizationChangedEventArgs>();

    get CurrentLocalization(): Localization {
        return this._currentLocalization;
    }

    get LocalizationChanged(): WpEvent<LocalizationChangedEventArgs> {
        return this._localizationChanged;
    }

    constructor(@inject(CmsSymbols.DataProvider) provider: IDataProvider) {
        this._provider = provider;
    }

    AddDictionary<T extends BaseSystemLocalizationDictionary>(localization: Localization, dictionary: LocalizationStaticDictionary<T>): void {
        if (localization == Localization.EN)
            this._enStaticDictionaries.push(dictionary);
        else
            this._ruStaticDictionaries.push(dictionary);
    }

    GetStaticDictionary<T extends BaseSystemLocalizationDictionary>(prefix: symbol): ActionResult<LocalizationStaticDictionary<T>> {
        let result: LocalizationStaticDictionary<T>;
        if (this.CurrentLocalization == Localization.EN) {
            result = this._enStaticDictionaries.find(x => x.Prefix == prefix) as LocalizationStaticDictionary<T>;
        } else {
            result = this._ruStaticDictionaries.find(x => x.Prefix == prefix) as LocalizationStaticDictionary<T>;
        }

        if (result == null)
            return ActionResult.FailedData(ErrorTypes.NotFoundData, result);

        return ActionResult.SuccessData(result);
    }

    async LoadAdditionalDictionary(siteId: number): Promise<void> {
        let filter = new BaseFilter();
        filter.PlatformId = siteId;
        let result = await this._provider.GetEntitiesAsync<any>(WpCorePrefixes.SiteDictionary, filter, "", this.CurrentLocalization);

        if (result.Success && result.Data != null && result.Data.Entities != null && result.Data.Entities.length > 0) {
            let data = result.Data.Entities[0].Data;
            if (data == null || data.trim() === '')
                return;

            let siteDictionary = JSON.parse(result.Data.Entities[0].Data);
            let staticDictionary = this.CurrentLocalization == Localization.EN ? this._enStaticDictionaries : this._ruStaticDictionaries;

            Object.getOwnPropertyNames(siteDictionary).forEach(propName => {
                let terms = staticDictionary.find(x => x.Prefix == Symbol.for(propName));
                if (terms != null) {
                    for (let prop in siteDictionary[propName]) {
                        terms.Terms[prop] = siteDictionary[propName][prop]
                    }
                }
            })
        }
    }

    async TranslateAsync(prefix: symbol, entity: any): Promise<void> {
        throw new Error("Method not implemented.");
    }
    async TranslateManyAsync(prefix: symbol, entities: Array<any>): Promise<void> {
        if (entities.length == 0) return;
        let filter = new BaseFilter();
        filter.PageCount = 200;

        let translate = this._downloadedDictionaries[prefix];
        if (translate == null) {
            translate = await this._provider.GetEntitiesAsync<any>(prefix, filter, '', this._currentLocalization);
            this._downloadedDictionaries[prefix] = translate;
        }

        if (translate.Success && translate.Data != null) {
            translate.Data.Entities.forEach((x: any) => {   
                let ids = x.PlatformEntities.split(';');
                entities.filter(e => ids.includes(`${e.Id}`)).forEach(s => {
                    Object.getOwnPropertyNames(x).forEach(propName => {
                        if (propName.toLowerCase() != 'id' && x[propName] != null && x[propName] != '') {
                            s[propName] = x[propName];
                        }
                    })
                });
            })
        }
    }

    async ChangeLocalizationAsync(localization: Localization): Promise<void> {
        this._currentLocalization = localization;
        this._localizationChanged.Send(this, { OldLocalization: Localization.RU, NewLocalization: Localization.EN });
    }

}
