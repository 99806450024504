import Vue, { VueConstructor } from 'vue';
import { Prop, DefaultProps } from "vue/types/options";
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { CartItem, IMetricsService, MetricsSymbols, BaseSystemLocalizationDictionary } from 'web-platform-core-ui';
import { MetricsEvents } from '../../../metrics-events-symbols';

export const wpCartItemProps = Vue.extend({
    props: {
        CartItem: Object as Prop<CartItem>,
        disabled: Boolean
    }
})

@Component({  
    template: require("./wp-cart-item.html")
})
export default class WpCartItem extends mixins<WpBaseComponent<WpCartItemDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, wpCartItemProps, Vue) {
    _metricsService!: IMetricsService;

    created() {
        // Moved this
        this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
    }

    RemoveItem() {        
        this.$emit('RemoveItem', this.CartItem.TempId);
    }

    IncreaseCount() {
        this.CartItem.IncreaseCount();
        this._metricsService.RegistrateEvent(MetricsEvents.IncartPlus, this.CartItem?.MetricsId, this.CartItem);
    }

    ReduceCount() {
        this.CartItem.ReduceCount();
        this._metricsService.RegistrateEvent(MetricsEvents.IncartMinus, this.CartItem?.MetricsId, this.CartItem);
    }
}

export class WpCartItemDictionary extends BaseSystemLocalizationDictionary {
    // No words here... yet...
}