import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WpPageBaseComponent from './../../../wp-page-base-component';
import GlobalService, {
	ILoyaltyProvider,
	LoyaltySymbols,
	IsdLoyaltyProgram,
	ActionResult,
	INotificationService,
	NotificationSymbols,
	IsdUserLoyalty,
	AppSettings,
	PersonIsdLoyaltyData,
	IMetricsService,
	MetricsSymbols,
} from 'web-platform-core-ui';
import WpBaseComponent from '../../../wp-base-component';
import { Prop } from 'vue/types/options';
import { MetricsEvents } from '../../../metrics-events-symbols';
import { Watch } from 'vue-property-decorator';

export const wpIsdLoyaltyEditProps = Vue.extend({
	props: {
		IsdUserLoyalty: Object as Prop<IsdUserLoyalty>,
	},
});

@Component({
	template: require('./wp-isd-loyalty-edit.html'),
})
export default class WpIsdLoyaltyEdit extends mixins(WpBaseComponent, Vue, wpIsdLoyaltyEditProps) {
	_metricsService!: IMetricsService;
	_provider!: ILoyaltyProvider;
	_notificationService!: INotificationService;

	valid: boolean = true;
	questionnaireValid: boolean = false;
	Today = new Date();

	lazy: boolean = false;

	LoyaltyId: number = 0;

	IsdLoyaltyProgram!: IsdLoyaltyProgram;
	PersonIsdLoyaltyData: PersonIsdLoyaltyData = new PersonIsdLoyaltyData();
	IsEditingLoyalty: boolean = false;
	DataLoaded: boolean = false;

	@Watch('PersonIsdLoyaltyData.LastName')
	LastNameChanged(data: string): void {
		this.PersonIsdLoyaltyData.LastName = data.replace(' ', '');
	}

	@Watch('PersonIsdLoyaltyData.FirstName')
	FirstNameChanged(data: string): void {
		this.PersonIsdLoyaltyData.FirstName = data.replace(' ', '');
	}

	@Watch('PersonIsdLoyaltyData.SecondName')
	SecondNameChanged(data: string): void {
		this.PersonIsdLoyaltyData.SecondName = data.replace(' ', '');
	}

	get NameRules() {
		return [
			(v: string) => v !== '' || 'Поле нельзя оставить пустым',
			(v: string) => (v && v.length > 1 && v.length <= 30) || 'Неверный формат',
			(v: string) => (v[0] != ' ' && v[v.length - 1] != ' ') || 'Неверный формат',
			(v: string) => (v && /^[A-aZ-zА-Яа-яЁё]*(-[A-aZ-zА-Яа-яЁё]*)?$/.test(v.trim())) || 'В поле могут быть только буквы',
		];
	}
	get CityRules() {
		return [
			(v: string) => v !== '' || 'Поле нельзя оставить пустым',
			(v: string) => (v && v.length > 1 && v.length <= 30) || 'Неверный формат',
			(v: string) => (v && /^[a-zA-Zа-яёА-ЯЁ\-]+( [a-zA-Zа-яёА-ЯЁ\-]+)*$/.test(v.trim())) || 'В поле могут быть только буквы',
		];
	}

	get RadioRules() {
		return [(v: string) => !!v || 'Выберите один из вариантов'];
	}

	BirthdateMenu: boolean = false;
	Birthdate: string = '';
	CurrentDate: string = '';

	get BirthdateFormatted(): string {
		return this.Birthdate ? this.DateHandler.Parse(this.Birthdate, 'YYYY-MM-DD').Format('DD.MM.YYYY') : '';
	}

	@Watch('Birthdate')
	UpdateBirthdate(): void {
		if (this.Birthdate) {
			//this.PersonIsdLoyaltyData!.Birthdate = this.DateHandler.Parse(this.Birthdate, 'YYYY-MM-DD').Format('YYYY-MM-DDTHH:mm:SS');
			this.PersonIsdLoyaltyData.Birthdate = this.DateHandler.Parse(this.Birthdate, 'DD.MM.YYYY').Format('YYYY-MM-DDTHH:mm:SS');
		} else {
			this.PersonIsdLoyaltyData.Birthdate = null;
		}
	}

	validate() {
		(this.$refs.QuestionnaireForm as HTMLFormElement).validate();
	}

	created() {
		this.CurrentDate = this.DateHandler.Parse(new Date()).Format('YYYY-MM-DD');
		this._provider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
		this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
	}

	async mounted() {
		let getProgramInfoAsync = await this._provider.GetProgramInfoAsync(GlobalService.GetSettings<AppSettings>().LoyaltyId);

		if (getProgramInfoAsync.Data !== null && getProgramInfoAsync.Data !== undefined) {
			this.IsdLoyaltyProgram = getProgramInfoAsync.Data;

			if (getProgramInfoAsync.Data?.LoyaltyProgramId !== undefined) {
				this.LoyaltyId = getProgramInfoAsync.Data?.LoyaltyProgramId;
			}
		} else {
			this._notificationService.Error(
				'Ошибка',
				getProgramInfoAsync != null && getProgramInfoAsync.ErrorMessage != null
					? getProgramInfoAsync.ErrorMessage
					: 'Произошла непредвиденная ошибка'
			);
		}

		this.PersonIsdLoyaltyData.LoyaltyId = GlobalService.GetSettings<AppSettings>().LoyaltyId;
		this.PersonIsdLoyaltyData.Id = this.IsdUserLoyalty.Id;
		this.PersonIsdLoyaltyData.FirstName = this.IsdUserLoyalty.FirstName;
		this.PersonIsdLoyaltyData.LastName = this.IsdUserLoyalty.LastName;
		this.PersonIsdLoyaltyData.SecondName = this.IsdUserLoyalty.SecondName;

		this.PersonIsdLoyaltyData.Birthdate =
			this.IsdUserLoyalty.Birthdate != null
				? this.DateHandler.Parse(this.IsdUserLoyalty.Birthdate, 'YYYY-MM-DDTHH:mm:SS').Format('DD.MM.YYYY')
				: null;

		this.Birthdate =
			this.IsdUserLoyalty.Birthdate != null
				? this.DateHandler.Parse(this.IsdUserLoyalty.Birthdate, 'YYYY-MM-DDTHH:mm:SS').Format('DD.MM.YYYY')
				: '';

		this.PersonIsdLoyaltyData.Gender = this.IsdUserLoyalty.Gender ?? 0;
		this.PersonIsdLoyaltyData.AddressId = this.IsdUserLoyalty.AddressId;
		this.PersonIsdLoyaltyData.City = this.IsdUserLoyalty.City;

		this.DataLoaded = true;
	}

	async EditAsync() {
		this.PersonIsdLoyaltyData.FirstName = this.PersonIsdLoyaltyData.FirstName.trim();
		this.PersonIsdLoyaltyData.LastName = this.PersonIsdLoyaltyData.LastName.trim();
		this.PersonIsdLoyaltyData.SecondName = this.PersonIsdLoyaltyData.SecondName.trim();

		this.IsEditingLoyalty = true;
		let result = await this._provider.EditAsync(this.PersonIsdLoyaltyData);
		if (!result.Success) {
			this._notificationService.Error('', result.ErrorMessage ?? 'Произошла непредвиденная ошибка');
			this.IsEditingLoyalty = false;
			return;
		}

		let loyaltyProfile = await this._provider.GetIsdUserLoyaltyAsync();
		if (!loyaltyProfile.Success || loyaltyProfile.Data == null) {
			this._notificationService.Error('', loyaltyProfile.ErrorMessage ?? 'Произошла непредвиденная ошибка');
			this.IsEditingLoyalty = false;
			return;
		}

		this.PersonIsdLoyaltyData.AddressId = loyaltyProfile.Data.AddressId;
		this._metricsService.RegistrateEvent(MetricsEvents.IsdLoyaltyEditProfile);
		this.$emit('edited', loyaltyProfile.Data);

		this.IsEditingLoyalty = false;
		this._notificationService.Success('Редактирование', 'Данные сохранены');
	}

	TrimOnBlur(): void {
		this.PersonIsdLoyaltyData.FirstName.trim();
		this.PersonIsdLoyaltyData.LastName.trim();
		this.PersonIsdLoyaltyData.SecondName.trim();
	}
}
