import { InstructorsGender } from './instructors-gender';
import { InstructorUsageType } from './instructor-usage-type';

export default class Instructor {
    Id: number = 0;
    Code: number = 0;
    Name: string = '';
    Education: string = '';
    CoursesName: string = '';
    Rate: number = 0;
    InstructorsGender?: InstructorsGender;
    Skills: Array<string> = [];
    UsageType: InstructorUsageType = InstructorUsageType.All;
    Preview: string = '';
    Description: string = '';
    ImageLink: string = '';
    cmsid?: string;
}