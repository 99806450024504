import GlobalService from './global-service';

/*
 * Micro logger v1.0
 * A tiny wrapper for typical console methods and a custom timer method (unfortunately it returns time elasped in milliseconds instead of microseconds).
 * It puts out a pretty CSS styled message if current app is NOT in development enviroment, so it doesn't show in production.
 */
export class MLogger {
    private _style: String = 'color: white; text-shadow: 1px 1px #000, -1px -1px #000, -1px 1px #000, 1px -1px #000; border-radius: 6px; padding: 2px;';
    private _isDev: boolean = GlobalService.GetSettings().IsDevVersion;
    private _timers: any = [];
    private Mode: number = 1;

    /**
     * @param {number} mode - (optional) Mode. 0 - OFF. 1 - ON.
     */
    constructor(mode: number = 1) {
        this.Mode = mode;
    };

    /**
     * Logs a pretty message to the console
     * @param {string} text - Message text to output
     * @param {string} color - (optional) Define a background color according to the style of the message
     */
    say(text: string, color?: string): void {
        if (this.Mode == 0) return;
        if (this._isDev == true) {
            let bgcolor: string = '';

            switch (color) {
                case 'message':
                    bgcolor = 'background-color: #007bff;';
                    break;
                case 'work':
                    bgcolor = 'background-color: #17a2b8;';
                    break;
                case 'info':
                    bgcolor = 'background-color: #6c757d;';
                    break;
                case 'warning':
                    bgcolor = 'background-color: #ffc107;';
                    break;
                case 'error':
                    bgcolor = 'background-color: #dc3545;';
                    break;
                case 'success':
                    bgcolor = 'background-color: #28a745;';
                    break;
                default:
                    bgcolor = 'background-color: #343a40;';
                    break;
            };

            if (color == 'work' ||
                color == 'info' ||
                color == 'success') {
                console.info(`%c ${text} `, `${this._style} ${bgcolor}`);
            } else if (color == 'warning') {
                console.debug(`%c ${text} `, `${this._style} ${bgcolor}`);
            } else if (color == 'error') {
                console.error(`%c ${text} `, `${this._style} ${bgcolor}`);
            } else {
                console.log(`%c ${text} `, `${this._style} ${bgcolor}`);
            };

        };
    };
    /**
     * Describe an object. Currently just a copt of console.dir() - may be expanded later
     * @param {object} object - Object to describe
     */
    describe(object: Object): void {
        if (this.Mode == 0) return;
        if (this._isDev == true) {
            console.dir(object);
        };
    };
    /**
     * Places a timer on a label.
     * Returns time elapsed in milliseconds when called again. Returns 0 when called for the first time indicating success. Calls say() method if text param is provided
     * @param {string} label - String label that indicates the timer
     * @param {string} text - (optional) Message text to output
     * @param {string} color - (optional) Define a background color according to the style of the message
     * @param {boolean} updateOnCall - (optional) Update timer when checking how much time has passed
     */
    time(label: string, text?: string, color?: string, updateOnCall: boolean = true): number {
        if (this.Mode == 0) return 0;
        if (this._timers[label] != null) {
            // Return the time elapsed from when the timer started
            let timeElapsed = Date.now() - this._timers[label];
            if (updateOnCall) {
                // Update the timer with a new timestamp
                this._timers[label] = Date.now();
            };
            // Say something if required
            if (text != null) this.say(text.replace('%t', timeElapsed.toString()), color);
            return timeElapsed;
        } else {
            // Setting up the timer for the first time
            this._timers[label] = Date.now();
            // Say something if required
            if (text != null) this.say(text, color);
            return 0;
        };
    };
};