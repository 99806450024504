import { AppSettings } from './app-settings';
import { WpEventArgs } from '../Common';

export default class AppSettingsInitializedEventArgs extends WpEventArgs {
    Settings: AppSettings;

    constructor(settings: AppSettings) {
        super();
        this.Settings = settings;
    }
}