import Vue from 'vue';
import Router from 'vue-router';

import { WpSale } from 'web-platform-vuetify';

Vue.use(Router);

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'wp-sale',
            component: WpSale
        } 
    ]
});
