export default class InstructorsPersonalLesson {
    BeginTime?: string;
    Date?: string;
    Id: number = 0;
    EndTime?: string;
    PlaceId: number = 0;
    MaxPersonsCount: number = 0;
    PriceForOne: number = 0;
    PriceForSecond: number = 0;
    SkillTypeId: number = 0;
    SkillType: string = '';
    InstructorId: number = 0;
    CoachID: number = 0;
    TariffId: number = 0;
    TariffName: string = '';
    ExistToContract: boolean = false;
    SumOne?: number;
    LocationId: number = 0;
    // Fields below conflict with their contemporaries but thats what we receive from the server so...
    TimeFrom?: string;      // This conflicts with BeginTime
    TimeTo?: string;        // This conflicts with EndTime
    PlaceID: number = 0;    // This conflicts with PlaceId
}