import GlobalService, { AccessToken, IIdentity, IContext, IdentityChangedEventArgs, AppSettings } from './..';
import { WpEvent, WpEventHandler, ErrorTypes } from './../Common';
import { UserManager, User } from 'oidc-client';
import SibUser from './sib-user';
import { RestClient } from 'typed-rest-client';
import { SibAppSettings } from './';
import SibAuthorizationFailedEventArgs from './sib-authorization-failed-event-args';
import { OIDCStorageStore } from './../Identity';
var popupTools = require('popup-tools');

export default class SibIdentity implements IIdentity, IContext {
    IsInitialized: boolean = false;
    _store: OIDCStorageStore;
    _settings: SibAppSettings;
    _sibUser?: SibUser;
    _user?: User | null; 
    _manager: UserManager;
    _identityChanged: WpEventHandler<IdentityChangedEventArgs>;
    _sibAutorizationFailed: WpEventHandler<SibAuthorizationFailedEventArgs>;

    get AccessToken(): AccessToken {
        if (this._sibUser != null) {
            return new AccessToken(this._sibUser.Token, new Date());
        }

        let accessToken = this._user?.access_token ?? '';
        let date = this._user != null ? new Date(Date.now() + this._user.expires_in) : new Date();

        return new AccessToken(accessToken, date);
    }
    get IsAuthentificated(): boolean {        
        return (this._user != null && this._user != undefined && !this._user.expired) ||
            this._sibUser != null;
    }
    get Username(): string {
        if (this._sibUser != null)
            return this._sibUser.Email;

        return this._user?.profile?.preferred_username ?? "";
    }
    get UserId(): string {
        if (this._sibUser != null)
            return this._sibUser.UserId;

        return this._user?.profile?.sub ?? "";
    }

    get SibAuthorizationFailed(): WpEvent<IdentityChangedEventArgs> {
        return this._identityChanged;
    }
    get IdentityChanged(): WpEvent<IdentityChangedEventArgs> {
        return this._identityChanged;
    }
    get CurrentIdentity(): IIdentity {
        return this;
    }

    get Phone() {
        return this._user?.profile?.phone_number;
    }

    set Phone(val: string | undefined) {
        if (this._user != null && this._user.profile != null) {
            this._user.profile.phone_number = val;
        }
    }

    async Initialize(appSettings: AppSettings, globalService: GlobalService): Promise<void> {
    }

    constructor(manager: UserManager, store: OIDCStorageStore) {
        this._store = store;
        this._sibAutorizationFailed = new WpEventHandler<SibAuthorizationFailedEventArgs>();
        this._identityChanged = new WpEventHandler<IdentityChangedEventArgs>();
        this._manager = manager;
        let self = this;


        this._manager.events.addUserLoaded((user) => { self.UserSigninEvent(user) });
        this._manager.events.removeUserLoaded(() => { self.UserSignoutEvent() });
        this._manager.events.addUserSignedOut(() => { self.UserSignoutEvent() });
        this._manager.events.removeUserSignedOut(() => { self.UserSignoutEvent() });

        this._settings = GlobalService.GetSettings<SibAppSettings>();

        this.TryRestoreUser();
    }

    SignIn(): void {
        this._manager.signinPopup();
    }

    SignOut(): void {
        this._manager.signoutPopup();
    }

    ChangeIdentity(identity: IIdentity) {
        throw new Error("Method not implemented.");
    }

    UserSigninEvent(user: User) {
        this._user = user;

        this._store.SaveStorage(this.AccessToken.Token);

        this._identityChanged.Send(this, new IdentityChangedEventArgs(this));
    }
    UserSignoutEvent() {
        this._store.SaveStorage(this.AccessToken.Token);

        this._user = undefined;        

        this._identityChanged.Send(this, new IdentityChangedEventArgs(this));
    }

    SigInSib() {
        let self = this;
        let popupUrl = `${this._settings.SibAuthority}Authorize.aspx?source=${this._settings.CurrentSite?.Url}&redirect_uri=${this._settings.RedirectUri}&response_type=token&client_id=${this._settings.SibClientId}`;
        popupTools.popup(popupUrl, "Arena Expert", {
            width: 600,
            height: 700
        }, async (err: any, url: any) => {
            await self.SibUserSigned(err, url)
        });
    }
    async SibUserSigned(err: any, url: any) {
        if (err) {
            console.warn(err.message);
            return;
        }

        let params = this.ParseUrlFragment(url);
        if (params.error) {
            console.warn(params.error)
            return
        }

        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {});
        let response = await client.create<AjaxResult<SibUser>>(`/sib/user/authetificate/`, { Token: params.access_token, SiteId: this._settings.SiteId });

        if (response.result != null && response.result.Result != 0) {
            this._sibAutorizationFailed.Send(this, new SibAuthorizationFailedEventArgs(response.result.Message));
        }

        if (response.result != null && response.result.Data != null) {
            this._settings.SibId = response.result.Data.SibId;
            this._sibUser = response.result.Data;            

            this._store.setItem('sib:user', JSON.stringify(response.result.Data));
            this._store.SaveStorage(this.AccessToken.Token);

            this._identityChanged.Send(this, new IdentityChangedEventArgs(this));
        }
    }

    ParseUrlFragment(value: string, delimiter = "?"): any {

        var idx = value.lastIndexOf(delimiter);
        if (idx >= 0) {
            value = value.substr(idx + 1);
        }

        if (delimiter === "?") {
            // if we're doing query, then strip off hash fragment before we parse
            idx = value.indexOf('#');
            if (idx >= 0) {
                value = value.substr(0, idx);
            }
        }

        var params: { [name: string]: string } = {},
            regex = /([^&=]+)=([^&]*)/g,
            m;

        var counter = 0;
        while (m = regex.exec(value)) {
            params[decodeURIComponent(m[1])] = decodeURIComponent(m[2].replace(/\+/g, ' '));
            if (counter++ > 50) {
                return {
                    error: "Response exceeded expected number of parameters"
                };
            }
        }

        for (var prop in params) {
            return params;
        }

        return {};
    }

    TryRestoreUser() {
        let self = this;
        this._store.RestoreStorage().then(() => {
            self._manager.getUser().then(user => {
                if (user != null) {
                    self._user = user
                    let args = new IdentityChangedEventArgs(self);
                    self._identityChanged.Send(self, args);
                } else {
                    let sibUser = this._store.getItem('sib:user');
                    if (sibUser != null && sibUser != '') {                     
                        this._sibUser = JSON.parse(sibUser);
                        this._settings.SibId = this._sibUser != null ? this._sibUser.SibId : '';
                        let args = new IdentityChangedEventArgs(self);
                    }
                }
                self.IsInitialized = true;
            });
        });
    }
}

class AjaxResult<T> {
    Result: number = 0;
    Message: string = '';
    Data?: T;
}