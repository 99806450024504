import './wp-phone-confirm.scss';

import Vue, { PropOptions, VueConstructor } from 'vue';
import Component, { mixins } from 'vue-class-component';
import WpBaseComponent from './../wp-base-component';
import GlobalService, {
    IPhoneConfirmationService, PhoneConfirmationSymbols, PhoneConfirmCode,
    UserPhoneConfirmBan, PhoneConfirmationService, INotificationService,
     NotificationSymbols, IProfileService, ProfileSymbols, INewTelProvider,
     Localization
} from 'web-platform-core-ui';
import { ActionResult, ErrorTypes } from 'web-platform-core-ui/src/Common';
import { Ref, Watch } from 'vue-property-decorator';
import BaseUILocalizationDictionary from '../Localization/base-ui-localization-terms';
import { DefaultProps } from 'vue/types/options';
import countriesRu from './countriesRu';
import countriesEn from './countriesEn';

export const wpPhoneConfirmProps = Vue.extend({
    props: {
        Outlined: <PropOptions>{
            type: Boolean,
            default: false
        },
        LoyaltyPhone: <PropOptions>{
            type: String,
            default : ''
        }
    }
})

@Component({
    template: require("./wp-phone-confirm.html")
})
export default class WpPhoneConfirm extends mixins<WpBaseComponent<WpPhoneConfirmDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, wpPhoneConfirmProps, Vue) {
    _phoneConfirmationService!: IPhoneConfirmationService;
    _newTelProvider!: INewTelProvider;
    _notificationService!: INotificationService;
    _profileService!: IProfileService;

    @Ref('PhoneForm') _phoneForm!: HTMLFormElement;
    @Ref('CodeForm') _codeForm!: HTMLFormElement;

    StepEnterPhone: boolean = false;
    StepEnterCode: boolean = false;

    IsSendingPhone: boolean = false;
    IsSendingCode: boolean = false;

    PhoneValid: boolean = false;
    CodeValid: boolean = false;

    IsNewTelConfirm: boolean = false;
    NewTelQrCode: string = "";
    NewTelConfirmNumber: string = "";
    
    SelectedCountry: any = {};
    ChangeCountryHidden: boolean = true;

    get PhonePrefix(){
        if(this.SelectedCountry && this.SelectedCountry.code) return `+${this.SelectedCountry.code}`;

        return '+';
    }

    get Countries(){
        if(this.Locale == Localization.EN) return countriesEn;

        return countriesRu;
    }

    get PhoneRules() {
        return [
            (v: string) => (/^[0-9]*$/).test(v) || this.Terms.PhoneNotDigitsError,
            (v: string) => v.length > 6 || this.Terms.PhoneRequiredError,
            (v: string) => v.length < 13 || this.Terms.PhoneMaxLengthError,
        ];
    }
    get CodeRules() {
        return [
            (v: string) => (/^\d{4}$/).test(v) && v.length === 4 || this.Terms.CodeRequiredError
        ];
    }

    PhoneFocus(): void {
        if (this.Phone === '') {
            this.Phone = '+7(';
        }
    }

    Error: string = '';

    Phone: string = '';
    CurrentPhone: string = '';
    Code: string = '';
    CodeId: number = 0;

    created() {
        this._phoneConfirmationService = this.GlobalService.Get<IPhoneConfirmationService>(PhoneConfirmationSymbols.PhoneConfirmationService);
        this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
        this._newTelProvider = this.GlobalService.Get<INewTelProvider>(PhoneConfirmationSymbols.NewTelProvider);
        this._profileService = this.GlobalService.Get<IProfileService>(ProfileSymbols.ProfileService);
    }
    async mounted() {
        this.SelectDefualtCountry();

        let loyaltyPhone = this.LoyaltyPhone;
        if (loyaltyPhone != null && loyaltyPhone != '') {
            this.Phone = loyaltyPhone;
            this.IsSendingPhone = true;
            let result = await this._phoneConfirmationService.SendCodeAsync(this.Phone);

            if (result.Success) {
                this.StepEnterPhone = false;

                if (result.Data!.Id !== undefined) {

                    this.CodeId = result.Data!.Id;

                    let codeLeftTimeAsync = await this.CodeLeftTimeAsync(result.Data!.Id);
                    if (codeLeftTimeAsync.Success) {
                        this.CurrentPhone = this.Phone;
                        if (this._phoneForm)
                            this._phoneForm.reset();
                        this.TimerValue = codeLeftTimeAsync.Data;
                        this.TimerActive = true;
                        this.ResendActive = false;
                        this.TimerStart();
                        this.StepEnterCode = true;
                        this.IsSendingPhone = false;
                    }

                    if(result.Data != null && result.Data?.Data != null){                    
                        this.NewTelQrCode =  result.Data.Data.QrCodeUri;
                        this.NewTelConfirmNumber = result.Data.Data.ConfirmationNumber;
                        this.IsNewTelConfirm = true;
                        this.NewTelCheckStateStart();
                    }
                }
                else {
                    this._notificationService.Error('Ошибка', this.Terms.InternalError);

                    this.IsSendingPhone = false;
                }
            }
            else {
                if (result.ErrorType == ErrorTypes.InvalidData)
                    this._notificationService.Error('Ошибка', this.Terms.SomeOneElsePhoneError);
                else
                    this._notificationService.Error('Ошибка', result.ErrorMessage != null ? result.ErrorMessage : this.Terms.InternalError);

                this.IsSendingPhone = false;

                this.$emit('code-confirm', { CodeId: this.CodeId, Phone: this.CurrentPhone, Error: true });
            }
            return;
        }

        let getCurrentBanAsync = await this._phoneConfirmationService.GetCurrentBanAsync();
        if (!getCurrentBanAsync.Success) {
            return;
        }

        if (getCurrentBanAsync.Data) {
            this.Error = this.Terms.TryCountError + ' ' + this.DateHandler.Parse(getCurrentBanAsync.Data.ExpireDate).Format('DD.MM.YYYY HH:mm');
            return;
        }

        let getCurrentCodeAsync = await this._phoneConfirmationService.GetCurrentCodeAsync();
        if (!getCurrentCodeAsync.Success) {
            return;
        }

        if (getCurrentCodeAsync.Data == null) {
            let profile = await this._profileService.GetProfileAsync();
            if (profile.Data != null && profile.Data?.Phone !== null && profile.Data?.Phone != '') {
                this.Phone = profile.Data!.Phone;
                this.CurrentPhone = profile.Data!.Phone;

                this.$emit('code-confirm', { CodeId: this.CodeId, Phone: this.CurrentPhone });
            }
            this.StepEnterPhone = true;
            this.StepEnterCode = false;
            return;
        }

        if (getCurrentCodeAsync.Data != null && getCurrentCodeAsync.Data.Phone !== undefined
            && getCurrentCodeAsync.Data?.Id !== undefined) {

            await this.CancelCodeAsync();
            this.CurrentPhone = getCurrentCodeAsync.Data.Phone;
            this.CodeId = getCurrentCodeAsync.Data?.Id;

            let codeLeftTimeAsync = await this.CodeLeftTimeAsync(getCurrentCodeAsync.Data?.Id);
            if (codeLeftTimeAsync.Success) {
                this.TimerValue = codeLeftTimeAsync.Data;
                this.TimerActive = true;
                this.TimerStart();

                this.ResendActive = false;
                this.StepEnterCode = true;
            }
        }
    }

    async PhoneFormSubmit(e: any) {
        e.preventDefault();
        await this.SendCodeAsync();
    }

    async SendCodeAsync() {
        if (!this._phoneForm.validate())
            return;

        this.IsSendingPhone = true;
        let result = await this._phoneConfirmationService.SendCodeAsync(`+${this.SelectedCountry.code}${this.Phone}`);

        if (result.Success) {
            this.StepEnterPhone = false;

            if (result.Data!.Id !== undefined) {

                this.CodeId = result.Data!.Id;

                let codeLeftTimeAsync = await this.CodeLeftTimeAsync(result.Data!.Id);
                if (codeLeftTimeAsync.Success) {
                    this.CurrentPhone = `+${this.SelectedCountry.code}${this.Phone}`;
                    if (this._phoneForm)
                        this._phoneForm.reset();
                    this.TimerValue = codeLeftTimeAsync.Data;
                    this.TimerActive = true;
                    this.ResendActive = false;
                    this.TimerStart();
                    this.StepEnterCode = true;
                    this.IsSendingPhone = false;
                }

                if(result.Data != null && result.Data?.Data != null){                    
                    this.NewTelQrCode =  result.Data.Data.QrCodeUri;
                    this.NewTelConfirmNumber = result.Data.Data.ConfirmationNumber;
                    this.IsNewTelConfirm = true;
                    this.NewTelCheckStateStart();
                }
            }
            else {
                this._notificationService.Error('Ошибка', this.Terms.InternalError);

                this.IsSendingPhone = false;
            }
        }
        else {
            if (result.ErrorType == ErrorTypes.InvalidData)
                this._notificationService.Error('Ошибка', this.Terms.SomeOneElsePhoneError);
            else
                this._notificationService.Error('Ошибка', result.ErrorMessage != null ? result.ErrorMessage : this.Terms.InternalError);

            this.IsSendingPhone = false;
        }
    }

    async ResendCodeAsync() {
        this.IsSendingPhone = true;
        let cancelResult = await this._phoneConfirmationService.CancelCodeAsync(this.CodeId);
        clearInterval(WpPhoneConfirm.Timer);
        clearInterval(WpPhoneConfirm.NewTelTimer);
        let result = await this._phoneConfirmationService.SendCodeAsync(this.CurrentPhone);

        if (result.Success) {
            this.StepEnterPhone = false;

            if (result.Data!.Id !== undefined) {

                this.CodeId = result.Data!.Id;

                let codeLeftTimeAsync = await this.CodeLeftTimeAsync(result.Data!.Id);
                if (codeLeftTimeAsync.Success) {
                    if(this._phoneForm != null) this._phoneForm.reset();
                    this.TimerValue = codeLeftTimeAsync.Data;
                    this.TimerActive = true;
                    this.ResendActive = false;
                    this.TimerStart();
                    this.StepEnterCode = true;
                    this.IsSendingPhone = false;
                }

                if(result.Data != null && result.Data?.Data != null){                    
                    this.NewTelQrCode =  result.Data.Data.QrCodeUri;
                    this.NewTelConfirmNumber = result.Data.Data.ConfirmationNumber;
                    this.IsNewTelConfirm = true;
                    this.NewTelCheckStateStart();
                }
            }
            else {
                this._notificationService.Error('Ошибка', this.Terms.InternalError);

                this.IsSendingPhone = false;
            }
        }
        else {
            this._notificationService.Error('Ошибка', result.ErrorMessage != null ? result.ErrorMessage : this.Terms.InternalError);

            this.IsSendingPhone = false;
        }
    }

    async CodeLeftTimeAsync(codeId: number): Promise<ActionResult<number>> {

        let result = await this._phoneConfirmationService.CodeLeftTimeAsync(codeId);
        if (result.Success) {
            this.StepEnterCode = true;
            this.StepEnterPhone = false;
        }
        else {
            this._notificationService.Error('Ошибка', result.ErrorMessage != null ? result.ErrorMessage : this.Terms.InternalError);
        }

        return result;
    }

    async CodeFormSubmit(e: any) {
        e.preventDefault();
        await this.ConfirmCodeAsync();
    }
    async ConfirmCodeAsync() {
        if (!this._codeForm.validate())
            return;

        this.IsSendingCode = true;

        let result = await this._phoneConfirmationService.ConfirmCodeAsync(this.Code);
        if (result.Success) {
            this._codeForm.reset();

            if (this.LoyaltyPhone == null || this.LoyaltyPhone == '') {
                this.StepEnterCode = false;
                this.StepEnterPhone = true;
            }
            
            this.IsSendingCode = false;
            this.$emit('code-confirm', { CodeId: this.CodeId, Phone: this.CurrentPhone });
        }
        else if (result.ErrorMessage !== undefined) {
            this.Code = '';
            this.IsSendingCode = false;
            this._notificationService.Error('Ошибка', result.ErrorMessage);
        }

    }
    async CancelCodeAsync(): Promise<ActionResult> {
        this.CurrentPhone = '';

        this.IsSendingPhone = false;
        this.StepEnterCode = false;

        let result = await this._phoneConfirmationService.CancelCodeAsync(this.CodeId);
        if (result.Success) {
            this.StepEnterPhone = true;
            clearInterval(WpPhoneConfirm.Timer);
        }
        else {
            this.StepEnterPhone = true;
            this._notificationService.Error('Ошибка', this.Terms.InternalError);
        }
        return result;
    }

    unmounted(){
        if(WpPhoneConfirm.Timer) clearInterval(WpPhoneConfirm.Timer);
        if(WpPhoneConfirm.NewTelTimer) clearInterval(WpPhoneConfirm.NewTelTimer);
    }

    TimerActive: boolean = false;
    TimerValue: number | undefined = 0;
    static Timer: any;
    static NewTelTimer: any;

    TimerStart(): void {
        const _this = this;
        if (WpPhoneConfirm.Timer != undefined)
            clearInterval(WpPhoneConfirm.Timer);
            WpPhoneConfirm.Timer = setInterval(function () {
            if (_this.TimerValue !== undefined) {
                if (_this.TimerValue <= 1) {
                    _this.TimerValue = 0;
                    _this.TimerActive = false;
                    _this.ResendActive = true;
                    clearInterval(_this.Timer);
                }
                else {
                    _this.TimerValue--;
                }
            }
        }, 1000);
    }
    ResendActive: boolean = false;

    NewTelCheckStateStart(): void {
        const _this = this;
        if (WpPhoneConfirm.NewTelTimer != undefined)
            clearInterval(WpPhoneConfirm.NewTelTimer);
            WpPhoneConfirm.NewTelTimer = setInterval(async () => {
            try
            {
               var state = await _this._newTelProvider.GetStateAsync();
               if(state != null && state.Success){
                    if (_this.LoyaltyPhone == null || _this.LoyaltyPhone == '') {
                        _this.StepEnterCode = false;
                        _this.StepEnterPhone = true;
                    }
                    
                    _this.IsSendingCode = false;
                    _this.$emit('code-confirm', { CodeId: this.CodeId, Phone: this.CurrentPhone });
               }                
            }
            catch(e){ console.error(e); }
        }, 1500);
    }

    IsNumber(evt: any): boolean {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        console.log(charCode);
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            evt.preventDefault();
            return false;
        } else {
            return true;
        }
    }

    private SelectDefualtCountry(){       
        this.SelectedCountry = this.Countries.find(x => x.name.startsWith('🇷🇺'));
    }
}

export class WpPhoneConfirmDictionary extends BaseUILocalizationDictionary {
    TryCountError: string = 'Слишком много попыток. Попробуйте после';
    CodeRequiredError: string = 'Введите код';
    PhoneNotDigitsError: string = 'Должны быть только цифры';
    PhoneRequiredError: string = 'Заполните номер до конца';
    PhoneMaxLengthError: string = 'Должно быть не более 12 символов';
    PhoneLblText: string = 'Введите номер телефона';
    PhoneCountryChangeLink: string = 'изменить';
    PhoneCountryTitle: string = 'Выбранный регион ';
    SendSMSBtnText: string = 'Отправить';
    CodeSendedMessage: string = 'Код выслан на номер';
    AnotherPhoneLink: string = 'другой номер';
    CodeLblText: string = 'Введите код';
    SendAgainLink: string = 'Отправить повторно';
    TimeoutResendMessage: string = 'Повторно вы сможете запросить код через';
    TimeoutSecondsMessage: string = 'сек.';
    ConfirmCodeBtnText: string = 'подтвердить';
    SomeOneElsePhoneError: string = 'Данный телефон зарегистрирован на другого пользователя';

    NewTelTitle: string = 'Подтверждение номера телефона';
    NewTelDescription: string = 'Для подтверждения позвоните по номеру';
    NewTelResendMessage: string = 'Повторно вы сможете запросить проверку через';
}
