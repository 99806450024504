import Vue from 'vue';
import { Prop } from "vue/types/options";
import WpBaseComponent from './../../wp-base-component';
import Component, { mixins } from 'vue-class-component';

import { NewsArticle } from 'web-platform-core-ui';

export const wpNewsItemProps = Vue.extend({
    props: {
        Entity: Object as Prop<NewsArticle>
    }
})

@Component({
    template: require("./main-news-item.html")
})
export default class WpMainNewsItem extends mixins(WpBaseComponent, wpNewsItemProps, Vue) {
    ArticleDate?: string = '';
    mounted() {
        this.ArticleDate = this.DateHandler.Parse(this.$props.Entity.Date, 'YYYY-MM-DDTHH:mm:SS').Format('DD MMMM YYYY');
    }

} 