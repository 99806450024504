import { WpEventArgs } from '../Common';
import LoyaltyNotification from './LoyaltyNotification';

export default class LoyaltyNotificationAddedEventArgs extends WpEventArgs {
    Data: LoyaltyNotification;

    constructor(data: LoyaltyNotification) {
        super();

        this.Data = data;
    }
}