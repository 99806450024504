import { BaseSystemLocalizationDictionary } from 'web-platform-core-ui';

export default abstract class BaseUILocalizationDictionary extends BaseSystemLocalizationDictionary {
    GenericError: string = '';
    GenericPleaseWait: string = '';
    GenericBack: string = '';
    GenericNext: string = '';
    GenericChange: string = '';
    GenericClose: string = '';
    GenericClear: string = '';
    GenericCancel: string = '';
    GenericOK: string = '';
    GenericYes: string = '';
    GenericNo: string = '';
    GenericLogIn: string = '';
    GenericAdd: string = '';
    GenericEdit: string = '';
    GenericUpdate: string = '';
    GenericApply: string = '';
    GenericSelect: string = '';

    FormFieldRequired: string = '';
}