import IDataProvider from './i-data-provider';
import { ActionResult, FiltringResult, BaseFilter } from './../Common';
import { Localization } from './../Localization';
import { injectable } from 'inversify';
import { RestClient } from 'typed-rest-client';
import { AppSettings } from '../Global/app-settings';
import GlobalService from '..';

@injectable()
export default class DefaultCmsDataProvider implements IDataProvider {
    async GetEntitiesAsync<T>(prefix: symbol, filter: BaseFilter, query: string, localization: Localization): Promise<ActionResult<FiltringResult<T>>> {
        let settings = GlobalService.GetSettings<AppSettings>();
        let client: RestClient = new RestClient('wp-app', settings.ServerUrl, [], {
            headers: {
                "moduleId" : settings.SiteId
            }
        });
        let result = new FiltringResult<T>();

        try {
            let sorting = '';
            if (filter.Sorting != null)
                sorting = `&sortingMemberCode=${filter.Sorting.Member}&sortingDirection=${filter.Sorting.Mode}`

            let url = `cms/api/v2/${Symbol.keyFor(prefix)}${settings.CurrentSite?.OrganizationId}/objects/?localization=${localization}&pagingSkip=${(filter.Page - 1) * filter.PageCount}&pagingTake=${filter.PageCount}&platformid=${filter.PlatformId}${sorting}`;
            let response = await client.get<Array<T>>(url);

            result.Entities = response.result ?? new Array<T>();

            let total = Object.getOwnPropertyDescriptor(response.headers, 'paging-total')?.value as number;

            result.Page = filter.Page;
            result.PageCount = filter.PageCount;
            result.TotalCount = total ?? 0;
        } catch (ex) {
            console.error(ex);
        };
        return ActionResult.SuccessData<FiltringResult<T>>(result);
    };

    async FindEntityAsync<T>(prefix: symbol, id: number, localization: Localization): Promise<ActionResult<T>> {
        let settings = GlobalService.GetSettings<AppSettings>();
        let client: RestClient = new RestClient('wp-app', settings.ServerUrl, [], {
            headers: {
                "moduleId" : settings.SiteId
            }
        });

        try {
            let response = await client.get<T>(`cms/api/v2/${Symbol.keyFor(prefix)}${settings.CurrentSite?.OrganizationId}/objects/${id}`);

            if (response.result != null)
                return ActionResult.SuccessData<T>(response.result);
            else
                return ActionResult.Success();
        } catch (ex) {
            console.error(ex);
            return ActionResult.Success();
        };
    };
};