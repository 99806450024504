import Vue, { VueConstructor } from 'vue';
import { Prop, DefaultProps } from "vue/types/options";
import WpPageBaseComponent from './../../wp-page-base-component';
import Component, { mixins } from 'vue-class-component';
import {
    OrderSale, ISaleService, SaleSymbols, INotificationService, NotificationSymbols, OrderStatus, IMetricsService,
    MetricsSymbols, BaseSystemLocalizationDictionary
} from 'web-platform-core-ui';
import { MetricsEvents } from '../../metrics-events-symbols';
import BaseUILocalizationDictionary from './../../Localization/base-ui-localization-terms';


@Component({  
    template: require("./wp-skidata-parking.html")
})
export default class WpSkidataParking extends mixins<WpPageBaseComponent<WpSkidataParkingDictionary>>(WpPageBaseComponent, Vue)  {
    
}

export class WpSkidataParkingDictionary extends BaseUILocalizationDictionary {

}