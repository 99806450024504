import { EcomAddToCart } from './../Metrics/i-ecom-data';
import { CartItem, CartItemTypes } from './../Cart';
import { ReservationItem, ActionResult } from './..';
import { ErrorTypes, WpEventHandler, WpEventArgs, WpEvent } from './../Common';
import { MarketCartItemState } from './market-cart-item-state';
import GlobalService from './../Global/global-service';
import { ISaleService, SaleSymbols, ReservationItemTypes } from './../Sale';
import IMarketService from './i-market-service';
import MarketRule from './market-rule';
import { MarketDateTimeRange } from '.';
import MarketClient from './market-client';
import MarketEmployee from './market-employee';
import MarketBill from './market-bill';

export default class MarketTariffCartItem extends CartItem {

    private _type: CartItemTypes = CartItemTypes.MarketBill;

    Bill: MarketBill;

    get IsAvailable(): boolean {
        return true;
    }
    get Info(): string {
        return `Оплата счета ${this.Bill.id} ${this.Bill.Email[0]}`;
    }
    get Type(): CartItemTypes {
        return this._type;
    }
    get Price(): number {
        return this.Bill.Price[0];
    }
    get Count(): number {
        return 1;
    }
    get MetricsId(): any | undefined {
        return this.Bill?.id;
    }
    public get Dates(): Array<string> {
        return [];
    }
    public get AdditionalInfo(): any {
        return {
            bill: this.Bill,
        }
    }
    get IsCanIncreaseCount(): boolean {
        return false;
    }
    get IsCanReduceCount(): boolean {
        return false;
    }

    constructor(bill: MarketBill) {
        super();
        this.Bill = bill;
    }

    IncreaseCount(): ActionResult {
        throw new Error("Method not implemented.");
    }
    ReduceCount(): ActionResult<any> {
        throw new Error("Method not implemented.");
    }
    IsConflictItem(item: CartItem): boolean {
        return item.Type != CartItemTypes.MarketBill;
    }
    IsEqualsItem(item: CartItem): boolean {
        return false;
    }
    CombineItem(item: CartItem): ActionResult<any> {
        throw new Error("Method not implemented.");
    }
    GetReservationItem(): ReservationItem[] {
        return [new ReservationItem(1, ReservationItemTypes.MarketBill, null, null, null, null, null, null, null, this.Bill.id)];
    }

    async RemoveItem(): Promise<ActionResult> {
        return ActionResult.Success();
    }

    IsAssociatedReservationItem(item: ReservationItem): boolean{
        return item.ItemType == ReservationItemTypes.MarketBill &&
               item.Count == 1 &&
               this.Bill.id == item.MarketBillId;
    }

    GetEcomData(eventId: Symbol): EcomAddToCart {
        throw new Error('Not implemented');
    }
}