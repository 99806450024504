export default class IsdLoyaltyQuestionnaire {
    PersId: number = 0;
    TNodId: number = 0;
    AddressId?: number;

    get HaveFields(): boolean {
        return this.FirstNameEnabled || this.LastNameEnabled || this.SecondNameEnabled || this.BirthdateEnabled || this.GenderEnabled
    }

    FirstNameEnabled: boolean = false;
    FirstNameRequired: boolean = false;
    FirstName: string = '';

    LastNameEnabled: boolean = false;
    LastNameRequired: boolean = false;
    LastName: string = '';

    SecondNameEnabled: boolean = false;
    SecondNameRequired: boolean = false;
    SecondName: string = '';

    BirthdateEnabled: boolean = false;
    BirthdateRequired: boolean = false;
    Birthdate?: string | null;

    GenderEnabled: boolean = false;
    GenderRequired: boolean = false;
    Gender?: number | null;

    CityEnabled: boolean = false;
    CityRequired: boolean = false;
    City?: string = '';
}