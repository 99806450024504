import { EcomAddToCart } from './../Metrics/i-ecom-data';
import CartItem from './../Cart/cart-item';
import InstructorsPersonalLesson from './instructors-personal-lesson';
import { CartItemTypes } from './../Cart/cart-item-types';
import { ActionResult, ErrorTypes, WpEventArgs } from './../Common';
import { ReservationItemTypes, ReservationItem } from './../Sale';
import * as moment from 'moment';
import { Cart } from './..';


export default class InstructorsPersonalCartItem extends CartItem {
    private _beginTime: string = "";
    private _endTime: string = "";
    private _date: string = "";
    private _count: number;
    private _cart: Cart;
    private _lesson: InstructorsPersonalLesson;
    private _type: CartItemTypes = CartItemTypes.InstructorsPersonalLesson;
    private _contractId?: number | null;

    get IsAvailable(): boolean {
        return true;
    }
    public get Info(): string {
        return `<strong>${this._date}</strong> ${this.Lesson.TariffName} с ${this._beginTime} до ${this._endTime} (${this._count}) чел.`;
    }
    public get Type(): CartItemTypes {
        return this._type;
    }
    public get Price(): number {
        return this._count > 1 ? this._lesson.PriceForOne + this._lesson.PriceForSecond : this._lesson.PriceForOne;
    }
    get MetricsId(): any | undefined {
        return this._lesson?.TariffId;
    }
    public get Count(): number {
        return this._count;
    }
    public get IsCanIncreaseCount(): boolean {
        return this._count == 1 && this._cart.LeftCount > 0; 
    }
    public get IsCanReduceCount(): boolean {
        return this._count == 2;
    }
    public get Lesson(): InstructorsPersonalLesson {
        return this._lesson;
    }
    public get Dates(): Array<string> {
        let result = new Array<string>();

        if (this.Lesson.Date !== undefined) {
            result.push(moment.parseZone(this.Lesson.Date, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY'));
        }

        return result;
    }
    public get AdditionalInfo(): any {
        return this._lesson;
    }

    constructor(lesson: InstructorsPersonalLesson, count: number, cart: Cart, contractId?: number | null) {
        super();        

        this._beginTime = moment.parseZone(lesson.BeginTime, "HH:mm:SS").format("HH:mm");
        this._endTime = moment.parseZone(lesson.EndTime, "HH:mm:SS").format("HH:mm");
        this._date = moment.parseZone(lesson.Date, "YYYY-MM-DD").format("DD.MM.YYYY");

        this._lesson = lesson;
        this._count = count;
        this._cart = cart;
        this._contractId = contractId;
    }

    public IncreaseCount(): ActionResult {
        if (this._count == 1 && this._cart.LeftCount > 0) {
            this._count = 2;

            this._cartItemChanged.Send(this, WpEventArgs.Empty);

            return ActionResult.Success();
        }
        else
            return ActionResult.Failed(ErrorTypes.CartError, "Превышено допустимое количество для данного типа услуг");
    }

    public ReduceCount(): ActionResult {
        if (this._count = 2) {
            this._count = 1;

            this._cartItemChanged.Send(this, WpEventArgs.Empty);

            return ActionResult.Success();
        } else
            return ActionResult.Failed(ErrorTypes.CartError, "Достигнуто минимальное возможное количество");
    }

    public IsConflictItem(item: CartItem): boolean {
        return false;
    }

    public IsEqualsItem(item: CartItem): boolean {
        if (item.Type != CartItemTypes.InstructorsPersonalLesson) return false;

        let cartItem = item as InstructorsPersonalCartItem;

        return cartItem != null && cartItem.Lesson.Id == this._lesson.Id;
    }

    public CombineItem(item: CartItem): ActionResult {
        let cartItem = item as InstructorsPersonalCartItem;

        if (this._count == 2 || cartItem.Count == 2)
            return ActionResult.Failed(ErrorTypes.CartError, "Превышено допустимое количество для данного типа услуг");

        this._count = 2;

        this._cartItemChanged.Send(this, WpEventArgs.Empty);

        return ActionResult.Success();
    }

    public GetReservationItem(): Array<ReservationItem> {
        return [new ReservationItem(this._count, ReservationItemTypes.PersonalLesson,
            this._lesson.TariffId, moment.parseZone(this._lesson.Date).format("DD.MM.YYYY"), null, this._lesson.Id, null, null, null,
            null, null, null, null, this._contractId)]; 
    }

    async RemoveItem(): Promise<ActionResult> {
        return ActionResult.Success();
    }

    IsAssociatedReservationItem(item: ReservationItem): boolean{
        let date = moment.parseZone(this._lesson.Date).format("DD.MM.YYYY");
        return item.ItemType == ReservationItemTypes.PersonalLesson &&
               item.Count == 1 &&
               date == item.Date?.split('T')[0] &&
               this._lesson.TariffId ==  item.TariffId &&
               this._lesson.Id == item.LessonId &&
               this._contractId == item.ClubContractId;
    }

    GetEcomData(eventId: Symbol): EcomAddToCart {
        throw new Error('Not implemented');
    }
}