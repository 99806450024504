import { AppSettings } from './../Global/app-settings';
import { Localization } from '../Localization';

export default class IdentityServerAppSettings extends AppSettings {
    get Localization(): Localization {
        let localeInput = document.getElementById("locale");

        if (localeInput != null) {
            let locale = localeInput.getAttribute("value") ?? 'ru';
            try {
                return <Localization>locale;
            } catch {
                return Localization.RU;
            }

        } else {
            return Localization.RU;
        }
    }
}