







































import WpInstructorsDatepickerClass from './wp-instructors-datepicker';
import Component from 'vue-class-component';
@Component
export default class WpInstructorsDatepicker extends WpInstructorsDatepickerClass { };
