import './wp-arena-seats.scss';

import Vue, { VueConstructor } from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';

import panzoom, { PanZoom, PanZoomController, PanZoomOptions, Transform } from 'panzoom';

import GlobalService, { ArenaSeatsList, ArenaPosition, ArenaSector, ArenaSeat, ArenaSeance, ArenaTariff, AppSettings, IArenaService, ArenaSymbols, Cart, ArenaTariffOption, BaseSystemLocalizationDictionary, WpCorePrefixes, ICmsService, CmsSymbols, BaseFilter } from 'web-platform-core-ui';
import { Prop, DefaultProps } from 'vue/types/options';
import { PropSync, Watch } from 'vue-property-decorator';


export const wpArenaSeatsProp = Vue.extend({
    props: {
        Cart: Object as Prop<Cart>,
        ArenaText: String,
        ArenaPosition: Number,
    }
})

@Component({
    template: require("./wp-arena-seats.html")
})
export default class WpArenaSeats extends mixins<WpBaseComponent<WpArenaSeatsDictionary>, DefaultProps, VueConstructor>(WpBaseComponent, Vue, wpArenaSeatsProp) {
    private _arenaService!: IArenaService;
    PanTransform: Transform | null = null;

    ShowOptions: boolean = false;
    SelectedSeatTariff: ArenaTariff = new ArenaTariff();
    SeatReservateFunc: any = {};

    Settings!: AppSettings;
    Seats: ArenaSeat[][] = [];
    Tariffs: Array<ArenaTariff> = [];
    SeatsList: ArenaSeatsList = new ArenaSeatsList();
    TariffColor: { [TariffCode: number]: string; } = {};

    Sidebar: boolean = true;
    Panning: boolean = false;
    Zooming: boolean = false;
    Buying: boolean = false;
    Loading: boolean = false;

    SeatSize: number = 40;

    MaxX: number = 0;
    MaxY: number = 0;
    MapWrap!: HTMLElement | any | null; // This may cause problems in the future with "any" option. Needs investigation.
    MapBlock!: HTMLElement | null;
    MapSeats!: Array<HTMLOrSVGElement> | null;
    Map?: any;
    Zoom: number = 1;
    PanZoom!: PanZoom;

    MiniMap!: HTMLElement | null;
    MiniMapViewport!: HTMLElement | null;
    MinimapVisible: boolean = true;
    StickySeats!: HTMLElement | null;

    TooltipTariff: ArenaTariff = new ArenaTariff();
    TooltipSeat: ArenaSeat = new ArenaSeat();
    TooltipStyle: string = 'display:none';

    @PropSync('sector', { type: Object }) Sector!: ArenaSector;
    @PropSync('seance', { type: Object }) Seance!: ArenaSeance;

    @Watch('Sector')
    async OnSectorChange(sector: ArenaSector) {
        this.Sector = sector;
        await this.RenderSeats();
    };

    @Watch('Seance')
    async OnSeanceChange(seance: ArenaSeance) {
        this.Seance = seance;
        await this.RenderSeats();
    };

    created(): void {
        this.Settings = GlobalService.GetSettings<AppSettings>();
        this._arenaService = this.GlobalService.Get<IArenaService>(ArenaSymbols.ArenaService);
    };

    async mounted(): Promise<void> {
        await this.RenderSeats();
    };

    SetBuying(buying: boolean): void {
        this.Buying = buying;
    };

    async RenderSeats() {
        this.Loading = true;
        this.Tariffs = [];
        if (this.Sector != null && this.Seance != null) {
            let seats = await this._arenaService.GetSeatsAsync(this.Seance.Id, this.Sector.SectorCode);
            let options: Array<ArenaTariffOption> = [];

            if (seats.Success && seats.Data != null) {
                this.HandlingData(seats.Data.Places);
                this.SeatsList = seats.Data;
                seats.Data.Tariffs.forEach(x => {
                    if (seats.Data?.Places.some(y => y.TariffCode == x.TariffCode)) {
                        if (x.Options != null && x.Options.length > 0) {
                            options = options.concat(x.Options);
                        }

                        this.Tariffs.push(x);
                    }
                });
                let i = 1;
                this.Tariffs.forEach(x => {
                    this.TariffColor[x.TariffCode] = `place-type-${i}`;
                    i++;
                });
            };

            let cmsService = this.GlobalService.Get<ICmsService>(CmsSymbols.CmsService);
            let result = await cmsService.GetEntitiesAsync<ArenaTariffOption>(WpCorePrefixes.ArenaTariffOptionNames, new BaseFilter(), '');

            if (result.Success && result.Data != null) {
                options.forEach(x => {
                    let option = result.Data?.Entities.find(opt => opt.OptionCode == x.OptionCode);
                    if (option != null) x.Name = option.Name;
                });
            };

            this.MapZoom();
            this.Loading = false;
        };
    };

    get ArenaPositionStyle(): string {
        if (this.ArenaPosition == ArenaPosition.Top) return 'arena-top';
        if (this.ArenaPosition == ArenaPosition.Bottom) return 'arena-bottom';
        if (this.ArenaPosition == ArenaPosition.Left) return 'arena-left';
        else return 'arena-right';
    };

    get ArenaPositionMiniMapStyle(): string {
        if (this.ArenaPosition == ArenaPosition.Top) return 'mini-top';
        if (this.ArenaPosition == ArenaPosition.Bottom) return 'mini-bottom';
        if (this.ArenaPosition == ArenaPosition.Left) return 'mini-left';
        else return 'mini-right';
    };

    UpdateMiniMapStyle(): void {
        if (this.PanZoom !== undefined && this.MiniMapViewport !== undefined && this.MiniMap !== undefined) {
            let MapWrapWidth = this.MapWrap!.clientWidth;
            let MapWrapHeight = this.MapWrap!.clientHeight;

            let MapBlockWidth = this.MapBlock!.clientWidth;
            let MapBlockHeight = this.MapBlock!.clientHeight;

            let MiniMapWidth = this.MiniMap!.clientWidth;
            let MiniMapHeight = this.MiniMap!.clientHeight;

            let ViewportWidth = MiniMapWidth / MapBlockWidth / this.Zoom * MapWrapWidth;
            let ViewportHeight = MiniMapHeight / MapBlockHeight / this.Zoom * MapWrapHeight;

            let Transform = this.PanZoom!.getTransform();
            let TransformX = Transform.x;
            let TransformY = Transform.y;

            let ViewportLeft = MiniMapWidth / MapBlockWidth / this.Zoom * TransformX * -1;
            let ViewportTop = MiniMapHeight / MapBlockHeight / this.Zoom * TransformY * -1;

            if (ViewportWidth > MiniMapWidth) {
                ViewportWidth = MiniMapWidth;
                ViewportLeft = 0;
            };
            if (ViewportHeight > MapWrapHeight) {
                ViewportHeight = MapWrapHeight;
                ViewportTop = 0;
            };
            this.MiniMapViewport?.setAttribute('style', 'width: ' + ViewportWidth + 'px; height: ' + ViewportHeight + 'px; left:' + ViewportLeft + 'px; top:' + ViewportTop + 'px');
        };
    };

    SeatMouseEnter(data: any): void {
        // Check if data has a tariff inside, so we can pull up the tooltip. Look for a better solution
        if (data.tariff) {
            this.TooltipSeat = data.seat;
            this.TooltipTariff = data.tariff;
            //this.TooltipStyle = `left: ${data.left - 55}px; top: ${data.top - 65}px; display:block`;
            this.TooltipStyle = `left: ${data.left}px; top: ${data.top}px; display: block; transform: Translate(-50%, -150%);`;
        };
    };

    SeatMouseLeave(): void {
        // If there is NO tooltip this will return "is undefined" error. Look for a better solution
        if (this.TooltipTariff) this.TooltipStyle = 'display:none';
    };

    ShowTariffOptions(data: any): void {
        this.SelectedSeatTariff = data.tariff;
        this.ShowOptions = true;
        this.SeatReservateFunc = data.reservateFunc;
    };

    OptionSelected(option: ArenaTariffOption) {
        this.ShowOptions = false;
        this.SeatReservateFunc(option);
    };

    ZoomIn(): void {
        if (this.PanZoom !== undefined && this.MapWrap !== undefined) {
            // Changed custom code to panzoom library method. Comment marked for deletion
            this.PanZoom.zoomTo(this.MapWrap.clientWidth / 2, this.MapWrap.clientHeight / 2, 1.25);
        };
        this.TriggerZoom();
    };

    ZoomOut(): void {
        if (this.PanZoom !== undefined && this.MapWrap !== undefined) {
            // Changed custom code to panzoom library method. Comment marked for deletion
            this.PanZoom.zoomTo(this.MapWrap?.clientWidth / 2, this.MapWrap?.clientHeight / 2, 0.75);
        };
        this.TriggerZoom();
    };

    SetStickySeats(): void {
        if (this.PanZoom !== undefined) {
            let StickySeatsDivs = document.querySelectorAll('#sticky_seats div');

            let Transform = this.PanZoom.getTransform();
            if (this.StickySeats !== null) {
                this.StickySeats.setAttribute('style', 'top:' + (Transform.y + (47 * Transform.scale)) + 'px;');
            }
            if (StickySeatsDivs.length !== null) {
                let that = this;
                StickySeatsDivs.forEach(function (element: any) {
                    element.setAttribute('style', 'height:' + that.Zoom * 50 + 'px;line-height:' + that.Zoom * 50 + 'px;');
                });
            };
        };
    };

    GetSeatMiniMapStyle(y: number, x: number) {
        let seat = this.Seats[y - 1][x - 1];
        if (seat === undefined) return 'mini-map-empty';
        if (seat.IsFree) return `mini-map-free ${this.TariffColor[seat.TariffCode]}`
        else return `mini-map-notfree`
    };

    protected HandlingData(arenaSeats: Array<ArenaSeat>): void {
        this.MaxX = Math.max(...arenaSeats.map(x => x.X));
        this.MaxY = Math.max(...arenaSeats.map(x => x.Y));

        for (let y = 0; y < this.MaxY; y++) {
            let row = [];
            for (let x = 0; x < this.MaxX; x++) {
                let seat = arenaSeats.find(item => item.X == x + 1 && item.Y == y + 1);
                if (seat != null) row[x] = seat;
            };
            this.Seats[y] = row;
        };
    };

    protected MapZoom(): void {
        if (!panzoom) {
            console.log(panzoom);
            console.error("PanZoom is not installed");
            return;
        };

        let that = this;
        //this.MapBlock = document.getElementById('seats_block');
        this.MapBlock = document.getElementById('svgArena');
        this.MapWrap = document.getElementById('seats_wrap');
        this.StickySeats = document.getElementById('sticky_seats');

        this.MiniMap = document.getElementById('mini_map');
        this.MiniMapViewport = document.getElementById('mini_map_viewport');

        /* CREATION OF THE MAP IS HERE. This includes paning and zooming functionality. */

        if (this.MapBlock !== null && this.MapWrap !== null && this.StickySeats !== null) {

            // Instantiate a panzoom object on this.PanZoom object which allows zooming/panning (sadly not both at the same time)
            this.PanZoom = panzoom(this.MapBlock, {
                bounds: true,
                minZoom: 1,
                maxZoom: 3,
                zoomSpeed: 0.065,       // This affects wheel zoom
                initialZoom: this.Zoom,
                smoothScroll: true,
                transformOrigin: { x: 0.5, y: 0.5 },
            });

            this.MapWrap.addEventListener("wheel", function () {
                // Catch wheel events to stop zooming, since we don't really have any problems with using zoom on PC (or with mouse)
                that.Zooming = false;
                // Bug fix - sidemap will also be updated on mouse wheel event
                updateSideMap();
            });

            this.MapWrap.addEventListener("touchend", function () {
                // THIS is a panzoom fix. We catch touchend event (doesn't work everywhere, look for a better solution)
                // To flag zooming as false. The "zoom" event described below causes an intervention like this in console:
                // [Intervention] Unable to preventDefault inside passive event listener due to target being treated as passive

                setTimeout(function () {
                    that.Zooming = false;
                    updateSideMap();
                }, 250);
            });

            this.PanZoom.on('zoom', function (e: any) {
                that.PanTransform = that.PanZoom.getTransform();
                // This setting PREVENTS from choosing a seat (Meaning "can't pick a seat while zooming")
                that.Zooming = true;
            });

            this.PanZoom.on('zoomend', function (e: any) {
                // Remove "Zooming" flag to allow selection of the seats.
                // THIS DOESN'T WORK ON PINCH ZOOM. See fix on line 287. This is most probably panzoom's issue
                that.Zooming = false;
                // This works with smoothZoom() on line 202 for example so it's fine for buttons
                updateSideMap();
            });

            this.PanZoom.on('panstart', function (e: any) {
                that.PanTransform = that.PanZoom.getTransform();
                // This setting PREVENTS from choosing a seat (Meaning "can't pick a seat while panning")
                that.Panning = true;
            });

            this.PanZoom.on('panend', function (e: any) {
                // Remove "Panning" flag to allow selection of the seats.
                // Timeout sort of FIXES accidental selection of seats right after zoom in/out
                setTimeout(function () {
                    that.Panning = false;
                }, 250);
                if (that.MapBlock !== null && that.MapWrap !== null && that.Map !== undefined && that.StickySeats !== null) {

                    let Transform = that.Map.getTransform();
                    let TransformX = Transform.x;
                    let TransformY = Transform.y;

                    let MapWrapWidth = that.MapWrap.clientWidth;
                    let MapWrapHeight = that.MapWrap.clientHeight;

                    let MapBlockWidth = that.MapBlock.clientWidth * Transform.scale;
                    let MapBlockHeight = that.MapBlock.clientHeight * Transform.scale;

                    if (TransformX < MapWrapWidth - MapBlockWidth) TransformX = MapWrapWidth - MapBlockWidth;
                    if (TransformX > 0) TransformX = 0;

                    if (TransformY < MapWrapHeight - MapBlockHeight) TransformY = MapWrapHeight - MapBlockHeight;
                    if (TransformY > 0) TransformY = 0;

                    if (MapBlockWidth < MapWrapWidth && MapBlockHeight < MapWrapHeight) {
                        e.smoothMoveTo((MapWrapWidth - MapBlockWidth) / 2, (MapWrapHeight - MapBlockHeight) / 2);
                    } else if (MapBlockWidth < MapWrapWidth && MapBlockHeight > MapWrapHeight) {
                        e.smoothMoveTo((MapWrapWidth - MapBlockWidth) / 2, TransformY);
                    } else if (MapBlockWidth > MapWrapWidth && MapBlockHeight < MapWrapHeight) {
                        e.smoothMoveTo(TransformX, (MapWrapHeight - MapBlockHeight) / 2);
                    } else {
                        e.smoothMoveTo(TransformX, TransformY);
                    };
                };
            });

            this.PanZoom.on('pan', function (e: any) {
                that.PanTransform = that.PanZoom.getTransform();
                that.Panning = true;
                updateSideMap();
            });

            let updateSideMap = function () {
                if (that.MapBlock !== null && that.MapWrap !== null && that.PanZoom !== undefined && that.StickySeats !== null) {

                    let Transform = that.PanZoom.getTransform();
                    let TransformY = Transform.y;

                    that.StickySeats.setAttribute('style', 'top:' + (TransformY + (47 * Transform.scale)) + 'px;');

                    let MapWrapHeight = that.MapWrap.clientHeight;
                    let MapBlockHeight = that.MapBlock.clientHeight * Transform.scale;

                    if (TransformY < MapWrapHeight - MapBlockHeight) TransformY = MapWrapHeight - MapBlockHeight;
                    if (TransformY > 0) TransformY = 0;

                    let StickySeatsDivs = document.querySelectorAll('#sticky_seats div');
                    if (StickySeatsDivs.length !== null) {
                        StickySeatsDivs.forEach(function (element: any) {
                            element.setAttribute('style', 'height:' + Transform.scale * 50 + 'px;line-height:' + Transform.scale * 50 + 'px;');
                        });
                    };
                };
            };
            this.PanTransform = this.PanZoom.getTransform();
        };
    };

    MinimapMoved(e: Transform): void {
        if (!this.PanZoom) return;
        this.PanZoom.moveTo(0 - e.x * e.scale + 35, 0 - e.y * e.scale);
        this.TriggerPan();
    };

    MinimapWheel(e: WheelEvent): void {
        this.PanZoom.zoomTo(
            this.MapWrap.clientWidth / 2,
            this.MapWrap.clientHeight / 2,
            1 - e.deltaY / 1000
        );
        this.TriggerZoom();
    };

    TriggerPan(): void {
        this.Panning = true;
        this.$nextTick(() => {
            this.Panning = false;
        });
    };

    TriggerZoom(): void {
        this.Zooming = true;
        this.$nextTick(() => {
            this.Zooming = false;
        });
    };
};

export class WpArenaSeatsDictionary extends BaseSystemLocalizationDictionary {
    ChooseSeatsOption: string = '';
    GenericOccupied: string = '';
    GenericPicked: string = '';
    GenericRow: string = '';
    GenericSeat: string = '';
};