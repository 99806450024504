import { injectable, inject } from 'inversify';

import { ActionResult } from './../Common';
import { IdentitySymbols } from './../Identity/symbols';
import GlobalService, { INewTelProvider, IdentityPhoneConfirmationProvider } from './..';
import { AppSettings } from '../Global/app-settings';
import DefaultPhoneConfirmationProvider from './default-phone-confirmation-provider';
import { IContext } from './../Identity';

@injectable()
export default class CombineNewTelProvider implements INewTelProvider {
    private _settings: AppSettings;
    private _context: IContext;

    private _identityProvider: IdentityPhoneConfirmationProvider;
    private _defaultProvider: DefaultPhoneConfirmationProvider;

    constructor(@inject(IdentitySymbols.Context) context: IContext) {
        this._context = context;
        this._settings = GlobalService.GetSettings();
        this._identityProvider = new IdentityPhoneConfirmationProvider();
        this._defaultProvider = new DefaultPhoneConfirmationProvider(context);
    }

    async GetStateAsync(): Promise<ActionResult> {
        return this._context.CurrentIdentity.IsAuthentificated ?
            await this._defaultProvider.GetStateAsync() :
            await this._identityProvider.GetStateAsync();
    }
}