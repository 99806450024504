export default class AccessToken {

    Token: string = '';
    Expired: Date = new Date();
    RefreshToken?: string;

    constructor(token: string, expired: Date, refreshToken?: string) {
        this.Token = token;
        this.Expired = expired;
        this.RefreshToken = refreshToken;
    }

}