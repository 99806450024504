export default class TariffCapacity {
	_reservedCount: number = 0;

	_bindedAnotherReserved: any = {};

	TariffId: number = 0;
	Date: string = '';
	ZoneId?: string;
	Capacity: number = 0;
	Count: number = 0;
	SeanceId: string = '';
	BindedZoneIds: Array<string> = [];
	BindedCapacity: Array<TariffCapacity> = [];

	get ReservedCount(): number {
		return this._reservedCount;
	}

	set ReservedCount(value: number) {
		if (this._reservedCount < value) {
			this.BindedCapacity.forEach((x) => {
				if (x.Capacity >= this.Capacity) {
					x.AddBindedReserve(value - this._reservedCount, this.ZoneId!);
				} else if (x.LeftCount >= this.LeftCount) {
					x.AddBindedReserve(x.LeftCount - (this.LeftCount - value + this._reservedCount), this.ZoneId!);
				}
			});
		} else {
			this.BindedCapacity.forEach((x) => {
				x.ReduceBindedReserve(this._reservedCount - value, this.ZoneId!);
			});
		}

		this._reservedCount = value;
	}

	get LeftCount(): number {
		let result = this.Capacity - this.Count - this._reservedCount;
		return result < 0 ? 0 : result;
	}

	AddBindedCapacity(capacity: TariffCapacity) {
		let exist = this.BindedCapacity.find((x) => x.ZoneId == capacity.ZoneId);
		if (exist != null) return;

		let _this = capacity.BindedCapacity.find((x) => x.ZoneId == this.ZoneId);
		if (_this == null) capacity.BindedCapacity.push(this);

		this.BindedCapacity.push(capacity);
	}

	AddBindedReserve(count: number, zoneId: string) {
		console.log(this._bindedAnotherReserved);
		count = count > this.LeftCount ? this.LeftCount : count;
		let b = this._bindedAnotherReserved[zoneId];

		if (b != null) this._bindedAnotherReserved[zoneId] = b + count;
		else this._bindedAnotherReserved[zoneId] = count;

		this._reservedCount = this._reservedCount + count;
	}

	ReduceBindedReserve(count: number, zoneId: string) {
		let b = this._bindedAnotherReserved[zoneId];

		if (b != null && b != 0) {
			count = b - count < 0 ? b : count;
			this._bindedAnotherReserved[zoneId] = b - count;
			this._reservedCount = this._reservedCount - count;
		}
	}
}
