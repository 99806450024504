import { injectable, inject } from 'inversify';

import { IOrderProvider, Order, OrderDetails, OrderDetailItem } from '.';
import { RestClient } from 'typed-rest-client';
import { FiltringResult, ActionResult, ErrorTypes } from './../Common';
import { IdentitySymbols, IContext } from './../Identity';
import OrdersFilter from './orders-filter';
import GlobalService from './..';
import { AppSettings } from './../Global/app-settings';

@injectable()
export default class OrderProvider implements IOrderProvider {
    protected _context: IContext;
    protected _settings: AppSettings;

    constructor(@inject(IdentitySymbols.Context) context: IContext) {
        this._context = context;
        this._settings = GlobalService.GetSettings();
    }

    async GetOrderListAsync(filter: OrdersFilter): Promise<ActionResult<FiltringResult<Order>>> {

        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        var queryParams = '';
        if (filter != null) {
            queryParams = `?page=${filter.Page}&pageCount=${filter.PageCount}&fromDate=${filter.FromDate ?? ''}&toDate=${filter.ToDate ?? ''}&connectionId=${filter.ConnectionId ?? ''}&orderCode=${filter.OrderCode ?? ''}`

            if (filter.Status != null) {
                filter.Status.forEach(x => {
                    queryParams += `&status=${x}`;
                })
            }
        }

        let response = await client.get<AjaxResult<FiltringResult<Order>>>(`/account/api/orders/filter/${queryParams}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData<FiltringResult<Order>>(ErrorTypes.InternalError, new FiltringResult<Order>());

        return ActionResult.SuccessData<FiltringResult<Order>>(response.result?.Data);
    }
    async GetOrderAsync(id: number): Promise<ActionResult<OrderDetails>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "Authorization": `Bearer ${this._context.CurrentIdentity.AccessToken.Token}`,
                "SessionId": this._settings.SessionId,
                "moduleId": this._settings.SiteId
            }
        });

        let response = await client.get<AjaxResult<OrderDetails>>(`/account/api/orders/${id}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData<OrderDetails>(ErrorTypes.InternalError);
        
        if(response != null && response.result != null && response.result.Data != null){
            let ticketsTasks = new Array<Promise<void>>();
            response.result.Data!.Items!.forEach(x => {
                if(x.HaveTicket) ticketsTasks.push(this.GetTicket(id, x, client));                
            })    

            await Promise.all(ticketsTasks);
        }

        return ActionResult.SuccessData<OrderDetails>(response.result?.Data);
    }

    async GetTicket(orderId: number, orderItem: OrderDetailItem, client: RestClient): Promise<void>{
        try{
            let barcode = await client.get<AjaxResult<any>>(`/account/api/orders/${orderId}/ticket/${orderItem.Id}`);
            if(barcode.result != null && barcode.result.Result == 0 && barcode.result.Data){
                orderItem.Barcode = barcode.result.Data!.FileName?.split('.')[0];
            } else {
                orderItem.HaveTicket = false;
                console.error(barcode.result?.Message);
            }
        }catch(e){
            orderItem.HaveTicket = false;
            console.error(e);
        }
    }
}


class AjaxResult<T> {
    Result: number = 0;
    Message: string = '';
    Data?: T;
}