import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import WpPageBaseComponent from './../../wp-page-base-component';
import { Ref } from 'vue-property-decorator';
import { INotificationService, NotificationSymbols, IdentitySymbols, LuzhnikFeedbackProvider, IContext, ILoyaltyProvider, LoyaltySymbols } from 'web-platform-core-ui';
import WpPayConfirm from './../../WpSale/WpPayConfirm/wp-payconfirm';

@Component({
    template: require("./luzh-feedback.html")
})
export default class LuzhFeedback extends mixins(WpPageBaseComponent, Vue) {
    private _context!: IContext;
    private _loyaltyProvider!: ILoyaltyProvider

    FormLoading: boolean = false;
    FormValid: boolean = false;

    Topic: Array<string> = ['Проблема в работе приложения'];
    Method: Array<string> = ['Email', 'Телефон'];

    Name: string = '';
    Message: string = '';
    SelectedTopic: string = '';
    SelectedMethod: string = '';
    Agree: boolean = false;
    NeedEmail: boolean = false;
    NeedName: boolean = false;
    Email: string = '';
    Phone: string = '+7';

    @Ref('Form') Form!: HTMLFormElement;

    get EmailRules() {
        return [
            (v: string) => !!v || 'Введите e-mail',
            (v: string) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Введите e-mail'
        ];
    };

    get PhoneRules() {
        return [
            (v: string) => (/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/.test(v)) || 'Введите телефон'
        ];
    };

    PhoneChange(phone: string): void {
        if (phone.length < 2) {
            this.$nextTick(() => {
                this.Phone = "+7" + phone;
            });
        };
    };

    get NameRules() {
        return [
            (v: string) => !!v || 'Введите имя',
        ];
    };

    PhoneFocus() { if (this.Phone === '') { this.Phone = '+7('; } }

    Oferta() {
        try {
            // @ts-ignore
            openInBrowser('https://www.luzhniki.ru/media/documents/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8_06.11.pdf');
        } catch {
            window.open('https://www.luzhniki.ru/media/documents/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8_06.11.pdf', '_blank');
        }
    }

    EmailChanged(d: any): void {
        this.$nextTick(() => {
            this.Email = this.Email.split(" ").join("").trim();
        });
    };


    async mounted() {
        this._context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
        this._loyaltyProvider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);

        try {
            let email = localStorage.getItem(WpPayConfirm.WpEmailConfirmKey);
            if (email != null) this.Email = email;
            let phone = localStorage.getItem(WpPayConfirm.WpPhoneConfirmKey);
            if (phone != null) this.Phone = phone;
        } catch (e) {
            console.error(e);
        };

        if (this._context.CurrentIdentity.IsAuthentificated) {
            this.Topic = ['Проблема в работе приложения', 'Удалить аккаунт'];
            this.Email = this._context.CurrentIdentity.Username;
            this.Phone = this._context.CurrentIdentity.Phone ?? "";

            let userLoyalty = await this._loyaltyProvider.GetIsdUserLoyaltyAsync();
            if (userLoyalty.Success && userLoyalty.Data != null && userLoyalty.Data.FirstName != null &&
                userLoyalty.Data.FirstName != '') {
                this.Name = userLoyalty.Data.LastName + ' ' + userLoyalty.Data.FirstName;
            } else {
                this.NeedName = true;
            }

        } else {
            this.NeedName = true;
            this.NeedEmail = true;
        }
    }

    async FormSubmit(e: any) {
        e.preventDefault();
        await this.Send();
    }

    async Send() {
        if (!this.Form.validate())
            return;

        this.FormLoading = true;
        let provider = new LuzhnikFeedbackProvider(this._context);

        let result = await provider.SendAsync(this.SelectedTopic, this.SelectedMethod, this.Message, this.Email, this.Phone, this.Name);

        if (result.Success) {
            this._notificationService.Success("Отправка", "Сообщение отправлено");
            this.Form.reset();
        } else {
            this._notificationService.Error("Ошибка", result.ErrorMessage ?? "Произошла непредвиденная ошибка");
        }

        this.FormLoading = false;
    }
}