import './wp-isd-loyalty-register.scss';

import Vue, { VueConstructor } from 'vue';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
	ILoyaltyProvider,
	LoyaltySymbols,
	IsdLoyaltyProgram,
	ActionResult,
	INotificationService,
	NotificationSymbols,
	PersonIsdLoyaltyData,
	AppSettings,
	IMetricsService,
	MetricsSymbols,
} from 'web-platform-core-ui';
import WpBaseComponent from '../../../wp-base-component';
import { MetricsEvents } from '../../../metrics-events-symbols';
import BaseUILocalizationDictionary from '../../../Localization/base-ui-localization-terms';
import { DefaultProps } from 'vue/types/options';
import { Watch } from 'vue-property-decorator';

export const wpWpIsdLoyaltyRegisterProps = Vue.extend({
	props: {
		EnableAgreements: {
			type: Boolean,
			default: false,
		},
	},
});

@Component({
	template: require('./wp-isd-loyalty-register.html'),
})
export default class WpIsdLoyaltyRegister extends mixins<WpBaseComponent<WpIsdLoyaltyRegisterDictionary>, DefaultProps, VueConstructor>(
	WpBaseComponent,
	wpWpIsdLoyaltyRegisterProps,
	Vue
) {
	_metricsService!: IMetricsService;
	_provider!: ILoyaltyProvider;
	_notificationService!: INotificationService;
	IsdLoyaltyProgram!: IsdLoyaltyProgram;
	PersonIsdLoyaltyData!: PersonIsdLoyaltyData;

	valid: boolean = true;
	questionnaireValid: boolean = false;
	IsRegisteringLoyalty: boolean = false;
	lazy: boolean = false;
	IsSavingProfile: boolean = false;
	StepCode: boolean = true;
	StepQuestionnaire: boolean = false;
	Agree: boolean = false;
	FirstName: string = '';
	LastName: string = '';
	SecondName: string = '';
	BirthdateMenu: boolean = false;
	Birthdate: string = '';
	CurrentDate: string = '';
	LoyaltyId: number = 0;
	CodeId: number = 0;
	Phone: string = '';
	Loading: boolean = true;
	Today = new Date();

	get BirthdateTextRules() {
		return [() => this.validBirthdate || this.Terms.GenericValidBirthdateRequired];
	}

	get BirthdateTextRulesFull() {
		return [(v: string) => v !== '' || this.Terms.GenericFieldRequired, () => this.validBirthdate || this.Terms.GenericValidBirthdateRequired];
	}

	CodeConfirmed(code: any): void {
		this.CodeId = code.CodeId;
		this.Phone = code.Phone;
		this.PersonIsdLoyaltyData.ConfirmCodeId = this.CodeId;
		this.PersonIsdLoyaltyData.Phone = code.Phone;
		this.BindUserAsync();
		this.StepCode = false;
	}

	get NameRules() {
		return [
			(v: string) => v !== '' || this.Terms.GenericFieldRequired,
			(v: string) => (v && v.length > 1 && v.length <= 30) || this.Terms.GenericFormatIncorrect,
			(v: string) => (v && /^[A-aZ-zА-Яа-яЁё]*(-[A-aZ-zА-Яа-яЁё]*)?$/.test(v.trim())) || this.Terms.GenericLettersOnly,
		];
	}

	get CityRules() {
		return [
			(v: string) => v !== '' || this.Terms.GenericFieldRequired,
			(v: string) => (v && v.length > 1 && v.length <= 30) || this.Terms.GenericFormatIncorrect,
			(v: string) => (v && /^[a-zA-Zа-яёА-ЯЁ\-]+( [a-zA-Zа-яёА-ЯЁ\-]+)*$/.test(v.trim())) || this.Terms.GenericLettersOnly,
		];
	}

	get BirthdateRules() {
		return [(v: string) => v !== '' || this.Terms.GenericFieldRequired];
	}

	get RadioRules() {
		return [(v: string) => !!v || this.Terms.GenericPickOneOption];
	}

	@Watch('Birthdate')
	UpdateBirthdate(): void {
		if (this.Birthdate) {
			// Return this back to normal if you need a DATEPICKER value instead of text
			//this.PersonIsdLoyaltyData!.Birthdate = this.DateHandler.Parse(this.Birthdate, 'YYYY-MM-DD').Format('YYYY-MM-DDTHH:mm:SS');
			this.PersonIsdLoyaltyData!.Birthdate = this.DateHandler.Parse(this.Birthdate, 'DD.MM.YYYY').Format('YYYY-MM-DDTHH:mm:SS');
		} else {
			this.PersonIsdLoyaltyData!.Birthdate = null;
		}
	}

	validate(): void {
		(this.$refs.QuestionnaireForm as HTMLFormElement).validate();
	}

	Oferta(): void {
		if (this.IsdLoyaltyProgram.RuleAddress != null && this.IsdLoyaltyProgram.RuleAddress != '') {
			try {
				// @ts-ignore
				openInBrowser(this.IsdLoyaltyProgram.RuleAddress);
			} catch {
				window.open(this.IsdLoyaltyProgram.RuleAddress, '_blank');
			}
		}
	}

	async created(): Promise<void> {
		this.LoyaltyId = GlobalService.GetSettings<AppSettings>().LoyaltyId;
		this.CurrentDate = this.DateHandler.Parse(new Date()).Format('YYYY-MM-DD');
		this.PersonIsdLoyaltyData = new PersonIsdLoyaltyData();
		this._provider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
		this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);

		let getProgramInfoAsync = await this._provider.GetProgramInfoAsync(this.LoyaltyId);
		if (getProgramInfoAsync.Success && getProgramInfoAsync.Data != null) {
			this.IsdLoyaltyProgram = getProgramInfoAsync.Data;
			this.LoyaltyId = getProgramInfoAsync.Data.LoyaltyProgramId;
			this.PersonIsdLoyaltyData.LoyaltyId = this.LoyaltyId;
		} else {
			this._notificationService.Error('Ошибка', getProgramInfoAsync.ErrorMessage ?? this.Terms.InternalError);
		}
		this.Loading = false;
	}

	async BindUserAsync(): Promise<ActionResult<PersonIsdLoyaltyData>> {
		let result = await this._provider.BindUserAsync(this.LoyaltyId, this.CodeId, this.Phone);
		if (result.Success) {
			this.$emit('registered', true);
		} else {
			if (result.ErrorType === 6) {
				// дать дозаполнить анкету
				if (result.Data !== null) {
					Object.assign(this.PersonIsdLoyaltyData, result.Data);

					if (result.Data?.Birthdate !== null) {
						this.Birthdate = this.DateHandler.Parse(result.Data?.Birthdate, 'YYYY-MM-DDTHH:mm:SS').Format('YYYY-MM-DD');
					}
				}
				this.PersonIsdLoyaltyData.Phone = this.Phone;
				this.StepQuestionnaire = true;
			} else if (result.ErrorType === 5) {
				// дать заполнить анкету
				this.StepQuestionnaire = true;
			} else {
				this._notificationService.Error('', result?.ErrorMessage ?? this.Terms.InternalError);
				this.$emit('registered', false);
			}
		}
		return result;
	}

	async RegisterAsync(): Promise<ActionResult<number>> {
		this.PersonIsdLoyaltyData.FirstName = this.FirstName;
		this.PersonIsdLoyaltyData.LastName = this.LastName;
		this.PersonIsdLoyaltyData.SecondName = this.SecondName;
		this.PersonIsdLoyaltyData.City = this.PersonIsdLoyaltyData.City?.trim();

		this.IsRegisteringLoyalty = true;
		let result = await this._provider.RegisterAsync(this.PersonIsdLoyaltyData);
		if (result.Success) {
			this._metricsService.RegistrateEvent(MetricsEvents.IsdLoyaltyRegistration);
			this.$emit('registered', true);
		} else {
			this._notificationService.Error('', result?.ErrorMessage ?? this.Terms.InternalError);
			this.$emit('registered', false);
		}
		this.IsRegisteringLoyalty = false;
		return result;
	}

	TrimOnBlur(): void {
		this.FirstName = this.FirstName.trim();
		this.LastName = this.LastName.trim();
		this.SecondName = this.SecondName.trim();
		this.PersonIsdLoyaltyData.City = this.PersonIsdLoyaltyData.City?.trim();
	}
}

export class WpIsdLoyaltyRegisterDictionary extends BaseUILocalizationDictionary {
	LastName: string = 'Фамилия';
	FirstName: string = 'Имя';
	SecondName: string = 'Отчество';
	Birthdate: string = 'Дата рождения';
	ChooseGender: string = 'Укажите ваш пол';
	GenderMale: string = 'Мужской';
	GenderFemale: string = 'Женский';
	City: string = 'Город';
	AcceptRequired: string = 'Необходимо согласиться';
	GenericSave: string = 'Сохранить';
	AcceptPartOne: string = 'Я согласен(-на) с';
	AcceptPartTwo: string = 'условиями';

	GenericFieldRequired: string = 'Поле нельзя оставить пустым';
	GenericFormatIncorrect: string = 'Неверный формат';
	GenericLettersOnly: string = 'В поле могут быть только буквы';
	GenericPickOneOption: string = 'Выберите один из вариантов';
	GenericValidBirthdateRequired = 'Введены некорректные данные';
}
