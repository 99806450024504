import { EcomAddToCart } from './../Metrics/i-ecom-data';
import { CartItem, CartItemTypes } from '../Cart';
import { ActionResult, ErrorTypes } from '../Common';
import { ReservationItem, ReservationItemTypes } from '../Sale';

export default class AccountReplenishmentCartItem extends CartItem {
    private _price: number = 0;
    private _type: CartItemTypes = CartItemTypes.AccountReplenishment;
    private _personId?: number;

    get PersonId(): number | undefined {
        return this._personId;
    }
    get Info(): string {
        return `<strong>���������� �����</strong>`;
    }
    get Type(): CartItemTypes {
        return this._type;
    }
    get Price(): number {
        return this._price;
    }
    get Count(): number {
        return 1;
    }
    public get Dates(): Array<string> {
        return [];
    }
    public get AdditionalInfo(): any {
        return { Sum: this._price };
    }
    get MetricsId(): any {
        return "";
    }
    get IsCanIncreaseCount(): boolean {
        return false;
    }
    get IsCanReduceCount(): boolean {
        return false;
    }
    get IsAvailable(): boolean {
        return true;
    }

    constructor(price: number, personId?: number) {
        super();
        this._price = price;
        this._personId = personId;
    }

    IncreaseCount(): ActionResult {
        return ActionResult.Failed(ErrorTypes.CartError, "��������� ���������� ���������� ��� ������� ���� �����");
    }
    ReduceCount(): ActionResult {
        return ActionResult.Failed(ErrorTypes.CartError, "���������� ����������� ��������� ����������");
    }
    IsConflictItem(item: CartItem): boolean {
        return true;
    }
    IsEqualsItem(item: CartItem): boolean {
        return false;
    }
    CombineItem(item: CartItem): ActionResult {
        return ActionResult.Failed(ErrorTypes.CartError, "��������� ���������� ���������� ��� ������� ���� �����");
    }
    GetReservationItem(): ReservationItem[] {
        return [new ReservationItem(1, ReservationItemTypes.AccountReplenishment, null, null, null, null, null, null, null, null, null, null, null, null, this._price * 100)];
    }
    async RemoveItem(): Promise<ActionResult> {
        return ActionResult.Success();
    }

    IsAssociatedReservationItem(item: ReservationItem): boolean{
        return item.ItemType == ReservationItemTypes.AccountReplenishment &&
               item.Count == 1 &&
               this._price * 100 == item.ReplenishmentSum;
    }

    GetEcomData(eventId: Symbol): EcomAddToCart {
        throw new Error('Not implemented');
    }
}