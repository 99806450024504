export default class WpCorePrefixes {
    static Error = Symbol.for("WpError");
    static News = Symbol.for("WpNews");
    static WidgetStyle = Symbol.for("WpWidgetStyle");
    static Tariff = Symbol.for("WpTariff");
    static ArenaSeance = Symbol.for("WpArenaSeance");
    static SiteDictionary = Symbol.for("WpSiteDictionary");
    static ArenaSectorNames = Symbol.for("WpArenaSectorNames");
    static ArenaTariffOptionNames = Symbol.for("WpArenaTariffOptionNames");
    static LoyaltyProgram = Symbol.for("WpLoyaltyProgram");
    static DiscountProgram = Symbol.for("WpDiscountProgram");
    static TariffBundle = Symbol.for("WpTariffBundle");
    static InstructorsTariffOptionNames = Symbol.for("WpInstructorsTariffOptionNames");
    static InstructorsSkillsNames = Symbol.for("WpInstructorsSkillsNames");
    static InstructorsLessonsLocation = Symbol.for("WpInstructorsLessonsLocation");
    static ClubContract = Symbol.for("WpClubContract");
    static Intructors = Symbol.for("WpIntructors");
    static SiteAdditionalSettings = Symbol.for("WpAdditionalSiteSettings");
    static WidgetOpenParameters = Symbol.for("WpWidgetOpenParameters");
    static AchievementGift = Symbol.for("WpAchievementGift");
    static UserAccountParameters = Symbol.for("WpLkSettings");
};