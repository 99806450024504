import ILoyaltyNotificationService from './i-loyalty-notification-service';
import { injectable, inject } from 'inversify';
import LoyaltyNotificationAddedEventArgs from './loyalty-notification-added-event-args';
import { WpEvent, WpEventHandler } from '../Common';
import LoyaltyNotification from './LoyaltyNotification';
import { LoyaltySymbols } from '.';
import ILoyaltyNotificationProvider from './i-loyalty-notification-provider';

@injectable()
export default class DefaultLoyaltyNotificationService implements ILoyaltyNotificationService {
    protected _provider: ILoyaltyNotificationProvider;

    protected _notificationAdded: WpEventHandler<LoyaltyNotificationAddedEventArgs>;


    get NotificationAdded(): WpEvent<LoyaltyNotificationAddedEventArgs> {
        return this._notificationAdded;
    }

    constructor(@inject(LoyaltySymbols.LoyaltyNotificationProvider) provider: ILoyaltyNotificationProvider) {
        this._provider = provider;
        
        let _this = this;
        this._notificationAdded = new WpEventHandler<LoyaltyNotificationAddedEventArgs>();
        this._provider.NotificationAdded.Subscribe((sender: any, e: LoyaltyNotificationAddedEventArgs) => {
            this._notificationAdded.Send(_this, e);
        })
    }

    GetNotifications(): Array<LoyaltyNotification> {
        return this._provider.GetNotifications();
    }
    CheckNotification(id: string) {
        this._provider.CheckNotification(id);
    }
}