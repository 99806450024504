import OrderItem from './order-item';
import LoyaltyProgram from './loyalty-program';
import OrderDiscount from './order-discount';
import { OrderStatus } from './order-status';
import { OrderType } from './order-type';

export default class OrderSale {
    Id: number = 0;
    Code: number = 0;
    Sum: number = 0;
    SiteId: number = 0;
    SiteUrl: string = '';
    Status: OrderStatus = OrderStatus.Reserved;
    OrderType: OrderType = OrderType.TariffsOrder;
    Items: Array<OrderItem> = new Array<OrderItem>();
    LoyaltyProgram?: LoyaltyProgram;
    Discount: number = 0;
    DiscountsList: Array<OrderDiscount> = new Array<OrderDiscount>();
    DiscountProgramId?: number;
    DiscountProgramName?: string;
    UserName: string = '';
    UserPhone: string = '';

    get TotalSum(): number {
        return this.Sum - this.Discount;
    }

}