import { AccessToken, IIdentity, IdentityChangedEventArgs } from './';
import { WpEvent, WpEventHandler } from './../Common';
import { UserManager, User, UserManagerSettings } from 'oidc-client';
import AppSettingsInitializedEventArgs from '../Global/app-settings-initialized-event-args';
import { AppSettings } from '../Global/app-settings';
import GlobalService, { ILocalizationService, LocalizationSymbols } from '..';
import IContext from './i-context';

export default class MobileUserIdentity implements IIdentity, IContext {
    private _user?: User | null;
    private _manager?: UserManager;
    private _managerSettings: any;
    private _identityChanged: WpEventHandler<IdentityChangedEventArgs>;

    IsInitialized: boolean = false;

    get AccessToken(): AccessToken {
        let accessToken = this._user?.access_token ?? '';
        let date = this._user != null ? new Date(this._user.expires_in) : new Date();

        return new AccessToken(accessToken, date);
    }
    get IsAuthentificated(): boolean {
        return this._user != null && this._user != undefined && !this._user.expired;
    }
    get Username(): string {
        return this._user?.profile?.preferred_username ?? "";
    }
    get UserId(): string {
        return this._user?.profile?.sub ?? "";
    }
    get Phone() {
        return this._user?.profile?.phone_number;
    }
    set Phone(val: string | undefined) {
        if (this._user != null && this._user.profile != null) {
            this._user.profile.phone_number = val;
        }
    }

    get IdentityChanged(): WpEvent<IdentityChangedEventArgs> {
        return this._identityChanged;
    }
    get CurrentIdentity(): IIdentity {
        return this;
    }

    constructor(managerSettings: any) {
        this._identityChanged = new WpEventHandler<IdentityChangedEventArgs>();
        this._managerSettings = managerSettings;
    }

    SignIn(): void {
        if (this._manager != null)
            this._manager.signinRedirect();
        else
            Error("Manager not initialzed");
    }

    SignOut(): void {
        if (this._manager != null)
            this._manager.signoutRedirect();
        else
            Error("Manager not initialzed");
    }

    ChangeIdentity(identity: IIdentity) {
        throw new Error("Method not implemented.");
    }

    async Refresh() {
        if (this._user != null && this._user.profile != null && this._manager != null) {
            console.log(this._user);
            this._user = await this._manager.signinSilent({ access_token: this.AccessToken });
            console.log(this._user);
        }
    }

    public async Initialize(appSettings: AppSettings, globalService: GlobalService) {
        let localizationService = globalService.Get<ILocalizationService>(LocalizationSymbols.LocalizationService);
        this._managerSettings.ui_locales = localizationService.CurrentLocalization;
        this._managerSettings.acr_values = ` siteid:${appSettings.SiteId} organizationId:${appSettings.CurrentSite?.OrganizationId} newauth`;
        this._manager = new UserManager(this._managerSettings);

        let identity = this;

        this._manager.events.addUserLoaded(identity.userSigninEvent);
        this._manager.events.removeUserLoaded(identity.userSignoutEvent);
        this._manager.events.addUserSignedOut(identity.userSignoutEvent);
        this._manager.events.removeUserSignedOut(identity.userSignoutEvent);

        this._user = await this._manager.getUser();

        if (this._user != null) {
            let args = new IdentityChangedEventArgs(identity);
            identity._identityChanged.Send(identity, args);
        }
        identity.IsInitialized = true;
    }

    protected userSigninEvent(user: User) {
        this._user = user;
        let args = new IdentityChangedEventArgs(this);
        this._identityChanged.Send(this, args);
    }
    protected userSignoutEvent() {
        this._user = null;
    }
}