export default class WidgetStyle {
	Id: number = 0;
	Font?: string;
	Primary?: string;
	Secondary?: string;
	// Not yet implemented colors
	Tertiary?: string;
	Quaternary?: string;
	Quinary?: string;
	Senary?: string;
	// _/\_
	Accent?: string;
	Error?: string;
	Info?: string;
	Success?: string;
	Warning?: string;
}
