import Vue, { VueConstructor } from 'vue';
import WpBaseComponent from './../../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';
import GlobalService, {
	InstructorsPersonalLesson,
	InstructorsPersonalCartItem,
	IInstructorsService,
	InstructorsSymbols,
	INotificationService,
	NotificationSymbols,
	BaseSystemLocalizationDictionary,
	AppSettings,
} from 'web-platform-core-ui';
import { DefaultProps } from 'vue/types/options';

export const wpInstructorsLessonsProp = Vue.extend({
	props: {
		lessons: Array,
		dates: Array,
		categoryName: String,
	},
});
@Component({
	template: require('./wp-instructors-lessons.html'),
})
export default class WpInstructorsLessons extends mixins<WpBaseComponent<WpInstructorsLessonsDictionary>, DefaultProps, VueConstructor>(
	WpBaseComponent,
	wpInstructorsLessonsProp,
	Vue
) {
	private _instructorsService!: IInstructorsService;
	protected _notificationService!: INotificationService;
	Settings!: AppSettings;

	@PropSync('lessonsBought', { type: Array }) LessonsBought!: Array<InstructorsPersonalCartItem>;

	Lessons!: Array<InstructorsPersonalLesson>;
	Dates!: Array<string>;
	LessonsAndDatesLoaded: boolean = false;
	CategoryName: string = '';

	GroupAmount: number = 1;

	GroupMin: number = 1;
	GroupMax: number = 10;

	created() {
		this.Settings = GlobalService.GetSettings();
	}

	async mounted() {
		this.Lessons = await this.$props.lessons;
		this.Dates = await this.$props.dates;
		this.LessonsAndDatesLoaded = true;
		this.CategoryName = await this.$props.categoryName;

		this._instructorsService = this.GlobalService.Get<IInstructorsService>(InstructorsSymbols.InstructorsService);
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
	}

	GroupDecrease(): void {
		if (this.GroupAmount > this.GroupMin) {
			this.GroupAmount--;
		}
	}

	GroupIncrease(): void {
		if (this.GroupAmount < this.GroupMax) {
			this.GroupAmount++;
		}
	}

	get SingleGuest(): boolean {
		return this.Settings.SingleGuest;
	}

	LessonsByDate(date: string) {
		let _this = this;
		let result = this.Lessons.filter((x) => _this.DateHandler.Parse(x.Date, 'YYYY-MM-DDTHH:mm:SS').Format('YYYY-MM-DD') == date);
		return result; //DateHandlerSame
	}

	Lesson?: InstructorsPersonalLesson;
	LessonLoaded: boolean = false;
	LessonPriceLoaded: boolean = false;
	LessonTariffAvailable: boolean = false;

	PeopleAmountDialog: boolean = false;
	SessionAmount: number = 1;

	async ShowLessonPurchaseDialog(Id: number) {
		this.LessonPriceLoaded = false;
		this.LessonTariffAvailable = false;
		if (!this.IsBought(Id)) {
			this.Lesson = this.Lessons?.find((x) => x.Id === Id);
			this.LessonLoaded = true;
			this.PeopleAmountDialog = true;

			if (this.Lesson != null) {
				let price = await this._instructorsService.GetPersonalLessonPriceAsync(this.Lesson);

				if (price.Success && price.Data !== null && price.Data !== undefined) {
					this.Lesson.PriceForOne = price.Data.PriceForOne;
					this.Lesson.PriceForSecond = price.Data.PriceForSecond != null ? price.Data.PriceForSecond : 0;
					this.LessonTariffAvailable = true;
				}
			} else {
				this._notificationService.Error('Ошибка', 'Занятие не найдено');
			}
		}
		this.LessonPriceLoaded = true;
	}

	FormatDate(dateString: string): string {
		return this.DateHandler.Parse(dateString, 'YYYY-MM-DD').Format('DD.MM.YYYY');
	}
	FormatTime(dateString: string): string {
		return this.DateHandler.Parse(dateString, 'HH:mm:SS').Format('HH:mm');
	}

	PurchaseLesson(): void {
		this.$emit('purchaseLesson', this.Lesson, this.SessionAmount);
		this.PeopleAmountDialog = false;
	}
	IsBought(lessonId: number): boolean {
		let boughtLesson = this.LessonsBought.find((x) => x.Lesson.Id === lessonId);
		if (boughtLesson !== undefined) return true;
		return false;
	}
}

export class WpInstructorsLessonsDictionary extends BaseSystemLocalizationDictionary {
	GuestsAmmount: string = '';
	ShorthandPeople: string = '';
	NoAvailableLessons: string = '';
}
