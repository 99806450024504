import { CartItem, CartItemTypes } from '../Cart';
import { ActionResult, ErrorTypes } from '../Common';
import { EcomAddToCart } from '../Metrics';
import { ReservationItem, ReservationItemTypes } from '../Sale';



export default class ContractClubPackageCartItem extends CartItem {
    private _id: number = 0;
    private _templateContractId: number = 0;
    private _personContractId: number = 0;
    private _code: number = 0;
    private _date: string = "";
    private _price: number = 0;
    private _type: CartItemTypes = CartItemTypes.ContractAdditionalPackage;
    private _name: string = "";

    get Info(): string {
        return `<strong>${this._name}</strong>`; 
    }
    get Type(): CartItemTypes {
        return this._type;
    }
    get Price(): number {
        return this._price;
    }
    get Count(): number {
        return 1;
    }
    public get Dates(): Array<string> {
        let result = new Array<string>();

        if (this._date !== undefined) {
            result.push(this._date);
        }

        return result;
    }
    public get AdditionalInfo(): any {
        return { Id: this._id, Name: this._name, Code: this._code, Price: this._price, TemplateContractId: this._templateContractId, PersonContractId: this._personContractId };
    }
    get MetricsId(): any {
        return this._id;
    }
    get IsCanIncreaseCount(): boolean {
        return false;
    }
    get IsCanReduceCount(): boolean {
        return false;
    }
    get IsAvailable(): boolean {
        return true;
    }

    constructor(id: number, templateContractId: number, personContractId: number, date: string, name: string,
        price: number, code: number) {
        super();
        this._id = id;
        this._templateContractId = templateContractId;
        this._personContractId = personContractId;
        this._code = code;
        this._name = name;
        this._price = price;
        this._date = date;
    }

    IncreaseCount(): ActionResult {
        return ActionResult.Failed(ErrorTypes.CartError, "��������� ���������� ���������� ��� ������� ���� �����");
    }
    ReduceCount(): ActionResult {
        return ActionResult.Failed(ErrorTypes.CartError, "���������� ����������� ��������� ����������");
    }
    IsConflictItem(item: CartItem): boolean {
        return false;
    }
    IsEqualsItem(item: CartItem): boolean {
        return false;
    }
    CombineItem(item: CartItem): ActionResult {
        return ActionResult.Failed(ErrorTypes.CartError, "��������� ���������� ���������� ��� ������� ���� �����");
    }
    GetReservationItem(): ReservationItem[] {
        return [new ReservationItem(1, ReservationItemTypes.ContractAdditionalPackage, this._id, this._date, null, null, null, null, null, null, null, null, this._templateContractId, this._personContractId)];
    }
    async RemoveItem(): Promise<ActionResult> {
        return ActionResult.Success();
    }

    IsAssociatedReservationItem(item: ReservationItem): boolean{
        return item.ItemType == ReservationItemTypes.ContractAdditionalPackage &&
             item.Count == 1 &&
             this._date == item.Date && 
             this._id == item.TariffId &&
             this._templateContractId == item.ClubContractId && 
             this._personContractId == item.PersonContractId;
    }

    GetEcomData(eventId: Symbol): EcomAddToCart {
        throw new Error('Not implemented');
    }
}