import GlobalService from './../Global/global-service';
import { AppSettings } from './../Global/app-settings';
import { RestClient } from 'typed-rest-client';
import IUserStorage from './i-user-storage';

const MemoryStorage = require('memorystorage'); // Can be converted to import?

export default class OIDCStorageStore implements IUserStorage {
    _storage: any;

    constructor() {
        this._storage = MemoryStorage('my-app');
    }

    getItem(key: string) {
        return this._storage.getItem(key);
    }

    setItem(key: string, value: string) {
        this._storage.setItem(key, value);
    }

    removeItem(key: string) {
        this._storage.removeItem(key);
    }

    get length(): number {
        return this._storage.length();
    }

    key(index: number) {
        return this._storage.key(index);
    }

    async ClearStorage(): Promise<void> {
        // Y is this empty?
    }

    async SaveStorage(token: string) {
        let settings = GlobalService.GetSettings<AppSettings>();

        let client: RestClient = new RestClient('wp-app', settings.ServerUrl, [], {
            headers: {
                "SessionId": settings.SessionId,
                "moduleId": settings.SiteId,
                "Authorization": `Bearer ${token}`
            }
        });

        let data = JSON.stringify(this._storage);
        try {
            await client.replace(`/api/widget/store/`, { Data: data });
        } catch (err) {
            console.error(err);
        }
    }

    async RestoreStorage(): Promise<void> {
        let settings = GlobalService.GetSettings<AppSettings>();

        let client: RestClient = new RestClient('wp-app', settings.ServerUrl, [], {
            headers: {
                "SessionId": settings.SessionId,
                "moduleId": settings.SiteId
            }
        });

        try {
            let response = await client.get<any>(`/api/widget/store/`);

            if (response.result != null && response.result !== '') {

                let store = response.result;

                for (const prop in store) {
                    if (prop != 'id' && prop != 'length' && Object.prototype.hasOwnProperty.call(store, prop)) {
                        this._storage.setItem(prop, store[prop] as string);
                    }
                }
            }

        } catch (err) {
            console.error(err);
        }
    }

    Sleep(milliseconds: number) {
        var start = new Date().getTime();
        for (var i = 0; i < 1e7; i++) {
            if ((new Date().getTime() - start) > milliseconds) {
                break;
            }
        }
    }

}