import Vue from 'vue';

import Component, { mixins } from 'vue-class-component';
import { VueConstructor } from 'vue/types/umd';
import GlobalService, {
    IContext, IdentitySymbols, IsdUserLoyalty,
    ILoyaltyProvider, LoyaltySymbols, INotificationService, NotificationSymbols,
    AppSettings
} from 'web-platform-core-ui';
import BaseUILocalizationDictionary from '../Localization/base-ui-localization-terms';
import { DefaultProps } from 'vue/types/options';
import WpPageBaseComponent from '../wp-page-base-component';

export const wpSettingsProps = Vue.extend({
    props: {
        CustomSettings: Array,
    }
});

@Component({
    template: require("./wp-settings.html")
})
export class WpSettings extends mixins<WpPageBaseComponent<WpSettingsDictionary>, DefaultProps, VueConstructor>(WpPageBaseComponent, wpSettingsProps, Vue) {
    protected _context!: IContext;
    protected _provider!: ILoyaltyProvider;
    protected _isdUserLoyalty!: IsdUserLoyalty;
    protected _notificationService!: INotificationService;
    protected _settings!: AppSettings;

    DarkTheme: boolean = false;
    IsIphone: boolean = false;
    StoreIcon: string = '';
    StoreLink: string = '';
    StoreLabel: string = '';

    EmailNotification: boolean = false;
    EmailNotificationLoading: boolean = true;
    SmsNotification: boolean = false;
    SmsNotificationLoading: boolean = true;
    PushNotification: boolean = false;
    PushNotificationLoading: boolean = true;


    protected Settings: Array<Setting> = [];

    created(): void {
        this._settings = GlobalService.GetSettings<AppSettings>();
        this._context = this.GlobalService.Get<IContext>(IdentitySymbols.Context);
        this._provider = this.GlobalService.Get<ILoyaltyProvider>(LoyaltySymbols.LoyaltyProvider);
        this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);

        this.IsIphone = /iPhone|iPad|MacIntel|iPadOS|Macintosh|iPod/i.test(navigator.userAgent);
        if (this.IsIphone) {
            this.StoreIcon = WpSettings.StoreIcons.Iphone;
            this.StoreLink = WpSettings.StoreLinks.Iphone;
            this.StoreLabel = WpSettings.StoreLabels.Iphone;
        } else {
            this.StoreIcon = WpSettings.StoreIcons.Android;
            this.StoreLink = WpSettings.StoreLinks.Android;
            this.StoreLabel = WpSettings.StoreLabels.Android;
        };
    };

    async mounted(): Promise<void> {
        let query = await this._provider.GetIsdUserLoyaltyAsync();
        if (!query.Success || query.Data == undefined) return;

        this._isdUserLoyalty = query.Data!;

        if (this.CustomSettings) {
            this.Settings = this.CustomSetting;
        } else {
            this.EmailNotification = this._isdUserLoyalty.EmailNotification;
            this.SmsNotification = this._isdUserLoyalty.SmsNotification;
            this.PushNotification = this._isdUserLoyalty.PushNotification;

            this.EmailNotificationLoading = false;
            this.SmsNotificationLoading = false;
            this.PushNotificationLoading = false;
        };

    };

    async EmailNotificationChange() {
        if (this.EmailNotificationLoading)
            return;

        this.EmailNotificationLoading = true;
        let res = await this._provider.UpdateNotificationSettings("Email", this.EmailNotification);
        if (!res.Success)
            this._notificationService.Error("", res.ErrorMessage ?? "Произошла непредвиденная ошибка");
        this.EmailNotificationLoading = false;
    }

    async SmsNotificationChange() {
        if (this.SmsNotificationLoading)
            return;

        this.SmsNotificationLoading = true;
        let res = await this._provider.UpdateNotificationSettings("SMS", this.SmsNotification);
        if (!res.Success)
            this._notificationService.Error("", res.ErrorMessage ?? "Произошла непредвиденная ошибка");
        this.SmsNotificationLoading = false;
    }

    async PushNotificationChange() {
        if (this.PushNotificationLoading)
            return;

        this.PushNotificationLoading = true;
        let res = await this._provider.UpdateNotificationSettings("Push", this.PushNotification);
        if (!res.Success)
            this._notificationService.Error("", res.ErrorMessage ?? "Произошла непредвиденная ошибка");
        this.PushNotificationLoading = false;
    }

    OpenStore(): void {
        if (this.IsIphone) {
            // @ts-ignore
            storeRequestReview();
        } else {
            try {
                // @ts-ignore
                openInBrowser(this.StoreLink);
            } catch (e) {
                console.log('We tried to open a link in the browser, but got this error instead:');
                console.error(e);
            };
        };
    };

    SignOut() {
        try {
            // @ts-ignore
            removePushToken(this._context.CurrentIdentity.AccessToken.Token);
        } catch (e) {
            console.log('We tried to remove push token, but got this error instead:');
            console.error(e);
        };
        this._context.SignOut();
        this._pageNotifitcationService.SendSystemNotification("LogOut");
    };

    SwitchTheme() {
        this.DarkTheme = !this.DarkTheme;
        this.$vuetify.theme.dark = this.DarkTheme;
    };
};

export class WpSettingsDictionary extends BaseUILocalizationDictionary {
    //
};

export class Setting {
    Icon!: string;
    Label!: string;
    To?: any = null;
    IsSwitch?: boolean;
    Callback?: Function;
    SwitchModel?: boolean = false;
    Disabled?: boolean;
};

export namespace WpSettings {
    export enum StoreIcons {
        Android = '<i aria-hidden="true" class="v-icon notranslate mdi mdi-google-play theme--dark"></i> Оценить в ',
        Iphone = '<i aria-hidden="true" class="v-icon notranslate mdi mdi-apple theme--dark"></i> Оценить в ',
    };
    export enum StoreLinks {
        Android = 'market://details?id=ru.aqua.luzhniki',
        Iphone = 'itms-apps://itunes.apple.com/app/1487953621?action=write-review',
    };
    export enum StoreLabels {
        Android = 'Оценить в Google Play',
        Iphone = 'Оценить в App Store',
    };
};
export default WpSettings;