import {
    INotificationService,
    NotificationSymbols,
    NotificationEventArgs,
    NotificationTypes,
    BaseSystemLocalizationDictionary,
    IdentityLoginState
} from 'web-platform-core-ui';
import WpBaseComponent from './wp-base-component';
import Component from 'vue-class-component';

@Component
export default class WpPageBaseComponent<T extends BaseSystemLocalizationDictionary = BaseSystemLocalizationDictionary> extends WpBaseComponent<T> {
    public static MainRoute: string = '';

    private _pageLoading: boolean = false;
    protected _pageNotifitcationService!: INotificationService;

    set PageLoading(val: boolean) {
        this._pageLoading = val;
        this._pageNotifitcationService.SendSystemNotification("WpPageBaseComponentLoading", val);
    };

    get PageLoading(): boolean {
        return this._pageLoading;
    };

    constructor() {
        super();
        this._pageLoading = false;
    };

    created() {
        this._pageNotifitcationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
        this._pageNotifitcationService.NotificationSended.Subscribe(this.baseNotification);
    };

    beforeDestroy() {
        this._pageNotifitcationService.NotificationSended.Unsubscribe(this.baseNotification);
    };

    baseNotification(sender: any, e: NotificationEventArgs): void {
        if (e.Data.Type == NotificationTypes.System && e.Data.SystemProcessKey == "AndroidBackButtonPressed")
            this.AppGoBack();
    };

    protected AppGoBack(): void {
        if (IdentityLoginState.IsLoginShow)
            return;

        if (this.$route.meta?.routeback != null && this.$route.meta.routeback != '') {
            this.$router.push({
                name: this.$route.meta.routeback as string
            });
        } else {
            if (this.$route.name != WpPageBaseComponent.MainRoute)
                this.$router.push({ name: WpPageBaseComponent.MainRoute });
            else
                try {
                    // @ts-ignore
                    closeApplication('close');
                } catch (e) {
                    console.error(e);
                }
        };
    };

    protected GetTermsKey() {
        if (this.$options != null){
            let tag = (<any>this.$options)._componentTag;
            if(tag) return tag;
        }
        
        if (this.$route != null)
            return this.$route.name;
    };
};