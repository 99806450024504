import { EcomAddToCart } from './../Metrics/i-ecom-data';
import CartItem from './../Cart/cart-item';
import InstructorsGroupLesson from './instructors-group-lesson';
import { CartItemTypes } from './../Cart/cart-item-types';
import { ActionResult, ErrorTypes, WpEventArgs } from './../Common';
import { ReservationItemTypes, ReservationItem } from './../Sale';
import * as moment from 'moment';
import { Cart } from './..';


export default class InstructorsGroupCartItem extends CartItem {
    private _maxCount: number = 10;
    private _price: number = 0;
    private _beginTime: string = "";
    private _endTime: string = "";
    private _date: string = "";
    private _count: number;
    private _cart: Cart;
    private _lesson: InstructorsGroupLesson;
    private _type: CartItemTypes = CartItemTypes.InstructorsGroupLesson;
    private _lessonCoachId?: number | null;
    private _lessonLocationId?: number | null;
    private _contractId?: number | null;

    get IsAvailable(): boolean {
        return true;
    }
    public get Info(): string {
        return `<strong>${this._date}</strong> ${this.Lesson.TariffName} с ${this._beginTime} до ${this._endTime} (${this._count}) чел.`;
    }
    public get Type(): CartItemTypes {
        return this._type;
    }
    public get Price(): number {
        return this._count * this._price;
    }
    public get Count(): number {
        return this._count;
    }
    get MetricsId(): any | undefined {
        return this._lesson?.TariffId;
    }
    public get IsCanIncreaseCount(): boolean {
        return this._count < this._maxCount && this._cart.LeftCount > 0; 
    }
    public get IsCanReduceCount(): boolean {
        return this._count > 1 ;
    }
    public get Lesson(): InstructorsGroupLesson {
        return this._lesson;
    }
    public get Dates(): Array<string> {
        let result = new Array<string>();

        if (this.Lesson.Date !== undefined) {
            result.push(moment.parseZone(this.Lesson.Date, 'YYYY-MM-DDTHH:mm').format('DD.MM.YYYY'));
        }

        return result;
    }
    public get AdditionalInfo(): any {
        return this._lesson;
    }


    get LessonCoachId(): number | null | undefined {
        return this._lessonCoachId;
    }
    get LessonLocationId(): number | null | undefined {
        return this._lessonLocationId;
    }

    constructor(lesson: InstructorsGroupLesson, count: number, price: number, cart: Cart, lessonCoachId?: number | null,
        lessonLocationId?: number | null, contractId?: number | null) {
        super();        

        this._beginTime = moment.parseZone(lesson.BeginTime, "HH:mm:SS").format("HH:mm");
        this._endTime = moment.parseZone(lesson.EndTime, "HH:mm:SS").format("HH:mm");
        this._date = moment.parseZone(lesson.Date, "YYYY-MM-DD").format("DD.MM.YYYY");

        this._lesson = lesson;
        this._count = count;
        this._price = price;
        this._cart = cart;
        this._lessonCoachId = lessonCoachId;
        this._lessonLocationId = lessonLocationId;
        this._contractId = contractId;
        if (lesson.FreeMembers < this._maxCount)
            this._maxCount = lesson.FreeMembers;
    }

    public IncreaseCount(): ActionResult {
        if (this._count < this._maxCount && this._cart.LeftCount > 0) {
            this._count++;

            this._cartItemChanged.Send(this, WpEventArgs.Empty);

            return ActionResult.Success();
        }
        else
            return ActionResult.Failed(ErrorTypes.CartError, "Превышено допустимое количество для данного типа услуг");
    }

    public ReduceCount(): ActionResult {
        if (this._count >= 2) {
            this._count--;

            this._cartItemChanged.Send(this, WpEventArgs.Empty);

            return ActionResult.Success();
        } else
            return ActionResult.Failed(ErrorTypes.CartError, "Достигнуто минимальное возможное количество");
    }

    public IsConflictItem(item: CartItem): boolean {
        return false;
    }

    public IsEqualsItem(item: CartItem): boolean {
        if (item.Type != CartItemTypes.InstructorsGroupLesson) return false;

        let cartItem = item as InstructorsGroupCartItem;

        return cartItem != null &&
            cartItem.Lesson.TariffId == this._lesson.TariffId &&
            cartItem.Lesson.Date == this._lesson.Date &&
            cartItem.Lesson.BeginTime == this._lesson.BeginTime &&
            cartItem.Lesson.EndTime == this._lesson.EndTime &&
            cartItem.Lesson.SkillType == this._lesson.SkillType;
    }

    public CombineItem(item: CartItem): ActionResult {
        let cartItem = item as InstructorsGroupCartItem;

        if (this._count + cartItem.Count > this._maxCount || this._cart.LeftCount < this._count + cartItem.Count)
            return ActionResult.Failed(ErrorTypes.CartError, "Превышено допустимое количество для данного типа услуг");

        this._count = this._count + cartItem.Count;

        this._cartItemChanged.Send(this, WpEventArgs.Empty);

        return ActionResult.Success();
    }

    public GetReservationItem(): Array<ReservationItem> {
        return [new ReservationItem(this._count, ReservationItemTypes.GroupLesson,
            this._lesson.TariffId, moment.parseZone(this._lesson.Date).format("DD.MM.YYYY"), null, this._lesson.Id, undefined, undefined, undefined, undefined,
            this.LessonCoachId, this.LessonLocationId, null, this._contractId)];
    }

    async RemoveItem(): Promise<ActionResult> {
        return ActionResult.Success();
    }

    IsAssociatedReservationItem(item: ReservationItem): boolean{
        let date = moment.parseZone(this._lesson.Date).format("DD.MM.YYYY");
        return item.ItemType == ReservationItemTypes.GroupLesson &&
               item.Count == 1 &&
               date == item.Date?.split('T')[0] &&
               this._lesson.TariffId ==  item.TariffId &&
               this._lesson.Id == item.LessonId &&
               this.LessonCoachId == item.LessonCoachId &&
               this.LessonLocationId == item.LessonLocationId &&
               this._contractId == item.ClubContractId;
    }

    GetEcomData(eventId: Symbol): EcomAddToCart {
        throw new Error('Not implemented');
    }
}