import { injectable, inject } from 'inversify';

import IInstructorsProvider from './i-instructors-provider';
import { ActionResult, ErrorTypes } from './../Common';
import InstructorsPlace from './instructors-places';
import SkillType from './skill-type';

import { RestClient } from 'typed-rest-client';
import { IdentitySymbols } from './../Identity/symbols';
import { IContext } from './../Identity';
import InstructorTariffCategory from './instructor-tariff-category';
import Instructor from './instructor';
import {
    PersonalLessonsFilter, InstructorsPersonalLesson,
    InstructorsPersonalLessonPrice, InstructorsGroupLesson, GroupLessonsFilter, InstructorsGroupLessonPrice
} from '.';
import GlobalService, { Tariff } from './..';
import * as moment from 'moment';
import InstructorsBundlesGroupsLessons from './instructors-bundles-groups-lessons';
import InstructorCategory from './instructor-category';
import { AppSettings } from './../Global/app-settings';

@injectable()
export default class InstructorsProvider implements IInstructorsProvider {
    private _settings: AppSettings;
    private _context: IContext;

    constructor(@inject(IdentitySymbols.Context) context: IContext) {
        this._context = context;
        this._settings = GlobalService.GetSettings();
    }

    async GetPlacesAsync(): Promise<ActionResult<Array<InstructorsPlace>>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "moduleId": this._settings.SiteId,
                "SessionId":  this._settings.SessionId
            }
        });

        let response = await client.get<AjaxResult<Array<InstructorsPlace>>>(`/websale/api/instructors/places/`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<Array<InstructorsPlace>>(response.result?.Data);
    }

    async GetSkillAsync(placeId: number): Promise<ActionResult<Array<SkillType>>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "moduleId": this._settings.SiteId,
                "SessionId":  this._settings.SessionId
            }
        });

        let response = await client.get<AjaxResult<Array<SkillType>>>(`/websale/api/instructors/skills/${placeId}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<Array<SkillType>>(response.result?.Data);
    }
    async GetInstructorsTariffCategoriesAsync(): Promise<ActionResult<Array<InstructorTariffCategory>>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "moduleId": this._settings.SiteId,
                "SessionId":  this._settings.SessionId
            }
        });
        let response = await client.get<AjaxResult<Array<InstructorTariffCategory>>>(`/websale/api/instructors/categories/`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<Array<InstructorTariffCategory>>(response.result?.Data);
    }

    async GetInstructorsAsync(placeId: number): Promise<ActionResult<Array<Instructor>>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "moduleId": this._settings.SiteId,
                "SessionId":  this._settings.SessionId
            }
        });

        let response = await client.get<AjaxResult<Array<Instructor>>>(`/websale/api/instructors/list/${placeId}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<Array<Instructor>>(response.result?.Data);
    }

    async GetInstructorsTariffsAsync<T extends Tariff>(): Promise<ActionResult<Array<T>>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "moduleId": this._settings.SiteId,
                "SessionId":  this._settings.SessionId
            }
        });

        let response = await client.get<AjaxResult<Array<T>>>(`/websale/api/instructors/tariffs`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<Array<T>>(response.result?.Data);
    }

    async GetPersonalLessonsAsync(filter: PersonalLessonsFilter): Promise<ActionResult<Array<InstructorsPersonalLesson>>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "moduleId": this._settings.SiteId,
                "SessionId":  this._settings.SessionId
            }
        });

        let filterStr = Object.entries(filter).map(([key, val]) => `${key}=${val === undefined || val === null ? '' : val}`).join('&');
        
        let response = await client.get<AjaxResult<Array<InstructorsPersonalLesson>>>(`/websale/api/instructors/personal/lessons?${filterStr}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<Array<InstructorsPersonalLesson>>(response.result?.Data);
    }

    async GetGroupLessonsAsync(filter: GroupLessonsFilter): Promise<ActionResult<Array<InstructorsGroupLesson>>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "moduleId": this._settings.SiteId,
                "SessionId":  this._settings.SessionId
            }
        });

        let filterStr = Object.entries(filter).map(([key, val]) => `${key}=${val === undefined || val === null ? '' : val}`).join('&');

        let response = await client.get<AjaxResult<Array<InstructorsGroupLesson>>>(`/websale/api/instructors/group/lessons?${filterStr}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<Array<InstructorsGroupLesson>>(response.result?.Data);
    }

    async GetBundlesLessonsAsync(filter: GroupLessonsFilter): Promise<ActionResult<Array<InstructorsBundlesGroupsLessons>>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "moduleId": this._settings.SiteId,
                "SessionId":  this._settings.SessionId
            }
        });

        let filterStr = Object.entries(filter).map(([key, val]) => `${key}=${val === undefined || val === null ? '' : val}`).join('&');

        let response = await client.get<AjaxResult<Array<InstructorsBundlesGroupsLessons>>>(`/websale/api/instructors/bundles/group/lessons?${filterStr}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<Array<InstructorsBundlesGroupsLessons>>(response.result?.Data);
    }

    async GetPersonalLessonPriceAsync(lesson: InstructorsPersonalLesson): Promise<ActionResult<InstructorsPersonalLessonPrice>>  {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SessionId":  this._settings.SessionId,
                "moduleId": this._settings.SiteId,
            }
        });

        let date = moment.parseZone(lesson.Date).format("DD.MM.YYYY");
        let beginTime = moment.parseZone(lesson.BeginTime, 'HH:mm:ss').format('HH:mm:ss');

        let response = await client.get<AjaxResult<InstructorsPersonalLessonPrice>>(`/trfsrv/api/tariffs/instructorslessonprice?id=${lesson.TariffId}&date=${date}&beginTime=${beginTime}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<InstructorsPersonalLessonPrice>(response.result?.Data);
    }

    async GetGroupLessonPriceAsync(lesson: InstructorsGroupLesson): Promise<ActionResult<InstructorsGroupLessonPrice>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SessionId":  this._settings.SessionId,
                "moduleId": this._settings.SiteId,
            }
        });

        let date = moment.parseZone(lesson.Date).format("DD.MM.YYYY");
        let beginTime = moment.parseZone(lesson.BeginTime, 'HH:mm:ss').format('HH:mm:ss');

        let response = await client.get<AjaxResult<InstructorsGroupLessonPrice>>(`/trfsrv/api/tariffs/instructorslessonprice?id=${lesson.TariffId}&date=${date}&beginTime=${beginTime}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<InstructorsGroupLessonPrice>(response.result?.Data);
    }

    async GetInstructorsCategoriesAsync(placeId: number): Promise<ActionResult<Array<InstructorCategory>>> {
        let client: RestClient = new RestClient('wp-app', this._settings.ServerUrl, [], {
            headers: {
                "SiteId": this._settings.SiteId,
                "moduleId": this._settings.SiteId,
                "SessionId":  this._settings.SessionId
            }
        });

        let response = await client.get<AjaxResult<Array<InstructorCategory>>>(`/websale/api/instructors/levels/${placeId}`);

        if (response.result != null && response.result.Result != 0)
            return ActionResult.FailedData(ErrorTypes.InternalError, response.result?.Data);

        return ActionResult.SuccessData<Array<InstructorCategory>>(response.result?.Data);
    }
}


class AjaxResult<T> {
    Result: number = 0;
    Message: string = '';
    Data?: T;
}
