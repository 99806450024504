import { DateHandlerSymbols, DateHandlerService } from './..';

const formatPrice = function (price: number): string {
    if (price !== undefined) 
        return new Intl.NumberFormat('ru-RU').format(parseInt(price.toString())) + '₽';
    return '';
    
}
const formatDateIsoToRu = function (date: string): string {

    let dateHandler = new DateHandlerService();
    if (date !== '') {
        return dateHandler.Parse(date, 'YYYY-MM-DDTHH:mm:ss').Format('DD.MM.YYYY');
    }
    return '';
}
const formatTimeIsoToRu = function (date: string): string {

    let dateHandler = new DateHandlerService();
    if (date !== '') {
        return dateHandler.Parse(date, 'HH:mm:ss').Format('HH:mm');
    }
    return '';
}
const formatDateTimeIsoToRu = function (date: string): string {
    
    let dateHandler = new DateHandlerService();
    if (date !== '') {
        return dateHandler.Parse(date, 'YYYY-MM-DDTHH:mm:ss').Format('DD.MM.YYYY HH:mm');
    }
    return '';
}

export { formatPrice, formatDateIsoToRu, formatTimeIsoToRu, formatDateTimeIsoToRu }