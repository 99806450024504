import IUserStorage from './i-user-storage';

export default class MobileUserStorage implements IUserStorage {
    getItem(key: string) {
        return localStorage.getItem(key);
    }
    setItem(key: string, value: string) {
        localStorage.setItem(key, value);
    }
    removeItem(key: string) {
        localStorage.removeItem(key);

        try {
            this.deleteCookie(key);
        } catch (e) {
            console.error(e);
        }

        try {
            this.SaveStorage("{}");
        } catch (e) {
            console.error(e);
        }
    }

    get length(): number {
        return localStorage.length;
    }
    key(index: number) {
        return localStorage.key(index);
    }

    async ClearStorage(): Promise<void> {
        this.deleteCookie("wp-app-browser-store");
        this.deleteCookie("wp-app-mobile-store");
        localStorage.clear();

        try {
            saveInStorage("{}");
        } catch (e) {
            console.error(e);
        }

        try {
            clearCookies("");
        } catch (e) {
            console.error(e);
        }
    }

    async SaveStorage(token: string): Promise<void> {
        let storage = encodeURIComponent(JSON.stringify(localStorage));
        this.setCookie("wp-app-browser-store", storage, 365);
        try {
            saveInStorage(storage);
        } catch (e) {
            console.error(e);
        }
    }

    async RestoreStorage(): Promise<void> {
        let coockie = this.getCookie("wp-app-mobile-store");
        if (coockie == null)
            coockie = this.getCookie("wp-app-browser-store");

        if (coockie == null) return;

        localStorage.clear();
        let store = JSON.parse(decodeURIComponent(coockie));

        let keys = Object.keys(store);
        let i = keys.length;

        while (i--) {
            localStorage.setItem(keys[i], store[keys[i]]);
        }
    }

    setCookie(name: string, value: string, days: number): void {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + ";";
    }

    getCookie(name: string): string | null {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    deleteCookie(name: string): void {
        let cookie = this.getCookie(name);
        if (cookie != null) {
            document.cookie = name + "=" +
                ";expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        }
    }
}

interface clearCookiesHandler {
    (str: string): void;
}

interface saveInStorageHandler {
    (storage: string): void;
}

declare var saveInStorage: saveInStorageHandler;
declare var clearCookies: clearCookiesHandler;