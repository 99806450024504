import { EcomAddToCart } from './../Metrics/i-ecom-data';
import { CartItem, CartItemTypes } from './../Cart';
import { ReservationItem, ActionResult, ReservationItemTypes } from './..';

export default class SyntechParkingCartItem extends CartItem {
    private _type: CartItemTypes = CartItemTypes.SyntechParking;

    private _tariffId: number = 0;
    private _sessionId: string = '';
    private _price: number = 0;
    private _ticket: string = '';

    get IsAvailable(): boolean {
        return true;
    }
    get Info(): string {
        return `������ �������� ${this._ticket}`;
    }
    get Type(): CartItemTypes {
        return this._type;
    }
    get Price(): number {
        return this._price;
    }
    get Count(): number {
        return 1;
    }
    get MetricsId(): any | undefined {
        return this._ticket;
    }
    public get Dates(): Array<string> {
        return [];
    }
    public get AdditionalInfo(): any {
        return {};
    }
    get IsCanIncreaseCount(): boolean {
        return false;
    }
    get IsCanReduceCount(): boolean {
        return false;
    }

    constructor(ticket: string, sessionId: string, price: number, tariffId: number) {
        super();
        this._sessionId = sessionId;
        this._tariffId = tariffId;
        this._ticket = ticket;
        this._price = price;

    }

    IncreaseCount(): ActionResult {
        throw new Error("Method not implemented.");
    }
    ReduceCount(): ActionResult<any> {
        throw new Error("Method not implemented.");
    }
    IsConflictItem(item: CartItem): boolean {
        return item.Type != CartItemTypes.MarketBill;
    }
    IsEqualsItem(item: CartItem): boolean {
        return false;
    }
    CombineItem(item: CartItem): ActionResult<any> {
        throw new Error("Method not implemented.");
    }
    GetReservationItem(): ReservationItem[] {
        return [new ReservationItem(1, ReservationItemTypes.SyntechParking, this._tariffId, null, null, null, this._ticket, null, null, null)];
    }

    async RemoveItem(): Promise<ActionResult> {
        return ActionResult.Success();
    }

    IsAssociatedReservationItem(item: ReservationItem): boolean{
        return item.ItemType == ReservationItemTypes.SyntechParking &&
               item.Count == 1 &&
               this._tariffId == item.TariffId &&
               this._ticket == item.MediaNum;
    }

    GetEcomData(eventId: Symbol): EcomAddToCart {
        throw new Error('Not implemented');
    }
}