import { SoldClubContract } from '../Club';

export class TennisSoldContract extends SoldClubContract {
    private _placesIds?: Array<number>;
    private _availableSkills?: Array<number>;
    private _availableAdditionalServices?: Array<number>;
    private _hiddenServices?: Array<number>;

    LTExtend: number = 0;
    LTBuy: number = 0;
    LTRequest: number = 0;
    LTPlacesIds: string = '[]';
    LTAvailableSkills: string = '[]';
    LTAvailableAdditionalServices: string = '[]';
    LTHiddenServices: string = '[]';

    LTAccessLK: number = 0;
    LTServices: number = 0;
    LTSchedule: number = 0;
    LTAchievements: number = 0;

    get CanExtend(): boolean {
        return this.LTExtend == 1;
    }
    get CanBuy(): boolean {
        return this.LTBuy == 1;
    }
    get NeedRequest(): boolean {
        return this.LTRequest == 1;
    }

    get ContractPlacesIds(): Array<number> {
        if (this._placesIds != null)
            return this._placesIds;

        try {
            let result = JSON.parse(this.LTPlacesIds) as Array<number>;

            if (result != null)
                this._placesIds = result;
            else
                this._placesIds = new Array<number>(); 

            return this._placesIds;
        }
        catch {
            this._placesIds = new Array<number>();
            return [];
        }
    }
    get AvailableSkills(): Array<number> {
        if (this._availableSkills != null)
            return this._availableSkills;

        try {
            let result = JSON.parse(this.LTAvailableSkills) as Array<number>;

            if (result != null)
                this._availableSkills = result;
            else
                this._availableSkills = new Array<number>();

            return this._availableSkills;
        }
        catch {
            this._availableSkills = new Array<number>();
            return [];
        }
    }

    get AvailableAdditionalServices(): Array<number> {
        if (this._availableAdditionalServices != null)
            return this._availableAdditionalServices;

        try {
            let result = JSON.parse(this.LTAvailableAdditionalServices) as Array<number>;

            if (result != null)
                this._availableAdditionalServices = result;
            else
                this._availableAdditionalServices = new Array<number>();

            return this._availableAdditionalServices;
        } catch {
            this._availableAdditionalServices = new Array<number>();
            return [];
        };
    };

    get HiddenServices(): Array<number> {
        if (this._hiddenServices != null)
            return this._hiddenServices;

        try {
            let result = JSON.parse(this.LTHiddenServices) as Array<number>;

            if (result != null)
                this._hiddenServices = result;
            else
                this._hiddenServices = new Array<number>();

            return this._hiddenServices;
        } catch {
            this._hiddenServices = new Array<number>();
            return [];
        };
    };


    get HaveAccessLK(): boolean {
        return this.LTAccessLK == 1;
    }
    get HaveAccessServices(): boolean {
        return this.LTServices == 1;
    }
    get HaveAccessSchedule(): boolean {
        return this.LTSchedule == 1;
    }
    get HaveAccessAchievements(): boolean {
        return this.LTAchievements == 1;
    }

}