import Vue from 'vue';
import { Watch } from 'vue-property-decorator';
import Component, { mixins } from 'vue-class-component';
import WpBaseComponent from './../wp-base-component';
import { INotificationService, NotificationSymbols, NotificationEventArgs, NotificationTypes } from 'web-platform-core-ui';

export const wpNotificationQueueProps = Vue.extend({
    props: {
        value: {
            required: false,
            default: false
        },
        timeout: {
            required: false,
            default: 3000
        },
        color: {
            required: false,
            default: 'error'
        },
        top: {
            required: false,
            default: false
        },
        right: {
            required: false,
            default: false
        },
        absolute: {
            required: false,
            default: false
        },
        autoHeight: {
            required: false,
            default: false
        },
        bottom: {
            required: false,
            default: false
        },
        left: {
            required: false,
            default: false
        },
        multiLine: {
            required: false,
            default: false
        },
        vertical: {
            required: false,
            default: false
        }
    },
})

@Component({
    template: require("./wp-notification-queue.html"),
})
export default class WpNotificationQueue extends mixins(WpBaseComponent, wpNotificationQueueProps, Vue) {
    _service!: INotificationService;

    processing: boolean = false;
    timeoutId: any = false;
    value: boolean = false;
    items: Array<WpNotificationQueueItem> = [];

    @Watch('items')
    processItems() {
        const vm = this
        this.processing = true
        if (this.items.length > 0) {
            let item = this.items[0] as any;
            return this.timeoutId = setTimeout(() => {
                vm.removeItem(item.Id)
            }, vm.timeout)
        }
        this.processing = false
    }

    removeItem(id: string) { 
        clearTimeout(this.timeoutId)
        let item = this.items.find(x => x.Id == id);
        if (item != null) { 
            let index: number = this.items.indexOf(item);
            this.items.splice(index, 1);
        }

        if (this.items.length > 0) {
            return this.processItems()
        }
    }

    async mounted() {
        this._service = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);

        this._service.NotificationSended.Subscribe(this.notification);
    }

    notification(sender: any, e: NotificationEventArgs) {
        if (e.Data.Type != NotificationTypes.System) {
            let item = new WpNotificationQueueItem();

            item.Id = this.uniqueId('item');
            item.Color = this.getColorString(e.Data.Type);
            item.Message = e.Data.Message;

            this.items.push(item);
        }
    }

    uniqueId(prefix: string): string {
        return `${prefix}_${Math.random().toString(36).substr(2, 9)}`;
    }

    getColorString(type: NotificationTypes): string {
        if (type == NotificationTypes.Warning) return 'warning'
        else if (type == NotificationTypes.Error) return 'error'
        else if (type == NotificationTypes.Success) return 'success'
        else return 'info';
    }
}

class WpNotificationQueueItem {
    Id: string = '';
    Message: string = '';
    Color: string = 'info';
}