import 'reflect-metadata';
import { Container } from 'inversify';
import { ServiceRegistredRecord } from './service-registred-record';
import * as Order from './../Order';
import * as Localization from './../Localization';
import * as Cms from './../Cms';
import * as Identity from './../Identity';
import * as Profile from './../Profile';
import * as Loyalty from './../Loyalty';
import * as Notification from './../Notification';
import * as PhoneConfirm from './../PhoneConfirm';
import * as Instructors from './../Instructors';
import * as Tariffs from './../Tariffs';
import * as Cart from './../Cart';
import * as Sale from './../Sale';
import * as Organizations from './../Organizations';
import * as Metrics from './../Metrics';
import * as Arena from './../Arena';
import * as DateHandler from './../Utility';
import * as Market from './../Market';
import * as Syntech from './../Syntech';
import * as Sib from './../Sib';
import * as RH from './../RozaHutor';
import * as Club from './../Club';

import { AppSettings } from './app-settings';

export const GlobalSymbols = {
	GlobalService: Symbol.for('GlobalService'),
};

export default class GlobalService {
	protected static _settings: AppSettings | undefined = undefined;

	static SetSettings<T extends AppSettings>(settings: T): void {
		this._settings = settings;
	}

	static GetSettings<T extends AppSettings>(): T {
		return this._settings! as T;
	}

	protected _container: Container = new Container();
	protected _records: Array<ServiceRegistredRecord> = new Array<ServiceRegistredRecord>();

	Get<T>(type: symbol): T {
		return this._container.get<T>(type);
	}

	protected registerServices(redefinedServices?: Array<ServiceRegistredRecord>) {
		if (redefinedServices == null) {
			this._records.forEach((item) => {
				let bind = this._container.bind(item.Key).to(item.Value);

				if (item.IsSingelton) bind.inSingletonScope();
			});

			return;
		}

		this._records.forEach((item) => {
			let service = redefinedServices.find((x) => x.Key == item.Key);

			if (service == null) service = item;

			let bind = this._container.bind(service.Key).to(service.Value);

			if (service.IsSingelton) bind.inSingletonScope();
		});

		this._container.bind<GlobalService>(GlobalSymbols.GlobalService).toConstantValue(this);
	}

	protected registerAllSymbols(): void {
		this._records = new Array<ServiceRegistredRecord>(
			//news
			new ServiceRegistredRecord(Cms.CmsSymbols.CmsService, Cms.CmsService),
			new ServiceRegistredRecord(Cms.CmsSymbols.DataProvider, Cms.DefaultCmsDataProvider),
			//order
			new ServiceRegistredRecord(Order.OrderServiceSymbols.OrderService, Order.OrderService),
			new ServiceRegistredRecord(Order.OrderServiceSymbols.OrderProvider, Order.OrderProvider),
			//localization
			new ServiceRegistredRecord(Localization.LocalizationSymbols.LocalizationService, Localization.DefaultLocalizationService),
			//profile
			new ServiceRegistredRecord(Profile.ProfileSymbols.ProfileProvider, Profile.DefaultProfileProvider),
			new ServiceRegistredRecord(Profile.ProfileSymbols.ProfileService, Profile.DefaultProfileService),
			//loyalty
			new ServiceRegistredRecord(Loyalty.LoyaltySymbols.LoyaltyProvider, Loyalty.DefaultLoyaltyProvider),
			new ServiceRegistredRecord(Loyalty.LoyaltySymbols.LoyaltyNotificationProvider, Loyalty.DefaultLoyaltyNotificationProvider),
			new ServiceRegistredRecord(Loyalty.LoyaltySymbols.LoyaltyNotificationService, Loyalty.DefaultLoyaltyNotificationService),
			//Notification
			new ServiceRegistredRecord(Notification.NotificationSymbols.NotificationService, Notification.DefaultNotificationService),
			//PhoneConfirm
			new ServiceRegistredRecord(PhoneConfirm.PhoneConfirmationSymbols.PhoneConfirmationService, PhoneConfirm.PhoneConfirmationService),
			new ServiceRegistredRecord(
				PhoneConfirm.PhoneConfirmationSymbols.PhoneConfirmationProvider,
				PhoneConfirm.CombinePhoneConfirmationProvider
			),
			new ServiceRegistredRecord(PhoneConfirm.PhoneConfirmationSymbols.NewTelProvider, PhoneConfirm.CombineNewTelProvider),
			//Instructors
			new ServiceRegistredRecord(Instructors.InstructorsSymbols.InstructorsService, Instructors.InstructorsService),
			new ServiceRegistredRecord(Instructors.InstructorsSymbols.InstructorsProvider, Instructors.InstructorsProvider),
			//Tariffs
			new ServiceRegistredRecord(Tariffs.TariffSymbols.TariffService, Tariffs.TariffServiceCached),
			new ServiceRegistredRecord(Tariffs.TariffSymbols.TariffProvider, Tariffs.TariffProvider),
			new ServiceRegistredRecord(Tariffs.TariffSymbols.TariffCapacityService, Tariffs.TariffCapacityServiceCached),
			new ServiceRegistredRecord(Tariffs.TariffSymbols.TariffCapacityProvider, Tariffs.TariffCapacityProvider),
			//Cart
			new ServiceRegistredRecord(Cart.CartSymbols.CartService, Cart.CartService),
			new ServiceRegistredRecord(Cart.CartSymbols.CartProvider, Cart.CartProvider),
			//Sale
			new ServiceRegistredRecord(Sale.SaleSymbols.SaleService, Sale.SaleService),
			new ServiceRegistredRecord(Sale.SaleSymbols.SaleProvider, Sale.SaleProvider),
			//Sale
			new ServiceRegistredRecord(Organizations.OrganizationSymbols.OrganizationService, Organizations.OrganizationService),
			new ServiceRegistredRecord(Organizations.OrganizationSymbols.OrganizationProvider, Organizations.OrganizationProvider),
			//Metrics
			new ServiceRegistredRecord(Metrics.MetricsSymbols.MetricsService, Metrics.EcomMetricsService),
			new ServiceRegistredRecord(Metrics.MetricsSymbols.MetricsProvider, Metrics.MetricsProvider),
			//Arena
			new ServiceRegistredRecord(Arena.ArenaSymbols.ArenaService, Arena.DefaultArenaService),
			new ServiceRegistredRecord(Arena.ArenaSymbols.ArenaProvider, Arena.DefaultArenaProvider),
			//DateHandler
			new ServiceRegistredRecord(DateHandler.DateHandlerSymbols.DateHandlerService, DateHandler.DateHandlerService),
			//Market
			new ServiceRegistredRecord(Market.MarketSymbols.MarketProvider, Market.MarketProvider),
			new ServiceRegistredRecord(Market.MarketSymbols.MarketService, Market.MarketService),
			//Club
			new ServiceRegistredRecord(Club.ClubSymbols.ClubProvider, Club.DefaultClubProvider),
			new ServiceRegistredRecord(Club.ClubSymbols.ClubService, Club.DefaultClubService),
			//Syntech
			new ServiceRegistredRecord(Syntech.SyntechSymbols.SyntechProvider, Syntech.DefaultSyntechProvider),
			new ServiceRegistredRecord(Syntech.SyntechSymbols.SyntechService, Syntech.DefaultSyntechService)
		);
	}

	protected registerAdditionalService(additionalServices: Array<ServiceRegistredRecord>) {
		additionalServices.forEach((item) => {
			let bind = this._container.bind(item.Key).to(item.Value);

			if (item.IsSingelton) bind.inSingletonScope();
		});
	}

	public LoadMobileConfiguration(
		context: Identity.IContext,
		redefinedServices?: Array<ServiceRegistredRecord>,
		additionalServices?: Array<ServiceRegistredRecord>
	): void {
		this._container.bind<Identity.IContext>(Identity.IdentitySymbols.Context).toConstantValue(context);

		this.registerAllSymbols();
		this.registerServices(redefinedServices);
		if (additionalServices != null) this.registerAdditionalService(additionalServices);
	}

	public LoadRozaHutorConfiguration(redefinedServices?: Array<ServiceRegistredRecord>, additionalServices?: Array<ServiceRegistredRecord>): void {
		let context = new RH.RHContext();
		this._container.bind<Identity.IContext>(Identity.IdentitySymbols.Context).toConstantValue(context);

		this.registerAllSymbols();
		this.registerServices(redefinedServices);
		if (additionalServices != null) this.registerAdditionalService(additionalServices);
	}

	public LoadWebSaleConfiguration(
		context: Identity.IContext,
		redefinedServices?: Array<ServiceRegistredRecord>,
		additionalServices?: Array<ServiceRegistredRecord>
	): void {
		this._container.bind<Identity.IContext>(Identity.IdentitySymbols.Context).toConstantValue(context);

		this.registerAllSymbols();
		this.registerServices(redefinedServices);
		if (additionalServices != null) this.registerAdditionalService(additionalServices);
	}

	public LoadSibSaleConfiguration(
		context: Sib.SibIdentity,
		redefinedServices?: Array<ServiceRegistredRecord>,
		additionalServices?: Array<ServiceRegistredRecord>
	): void {
		this._container.bind<Identity.IContext>(Identity.IdentitySymbols.Context).toConstantValue(context);
		this._container.bind<Sib.SibIdentity>(Sib.SibSymbols.SibContext).toConstantValue(context);

		this.registerAllSymbols();
		this.registerServices(redefinedServices);
		if (additionalServices != null) this.registerAdditionalService(additionalServices);
	}
}
