import { injectable, inject } from 'inversify';

import { IOrderService, Order, OrderServiceSymbols, OrderDetails, OrdersFilter } from './../Order';
import ILuzhOrderProvider from './i-luzh-order-provider';

import { ILocalizationService, LocalizationChangedEventArgs, LocalizationSymbols } from './../Localization';
import { ActionResult, FiltringResult, ErrorTypes } from './../Common';
import { ILoyaltyProvider, LoyaltySymbols } from '../Loyalty';


@injectable()
export default class LuzhnikiOrderService implements IOrderService {
    private readonly _provider: ILuzhOrderProvider;
    private readonly _loyaltyProvider: ILoyaltyProvider;
    private readonly _localizationService: ILocalizationService;

    constructor(@inject(OrderServiceSymbols.OrderProvider) provider: ILuzhOrderProvider,
        @inject(LoyaltySymbols.LoyaltyProvider) loyaltyProvider: ILoyaltyProvider,
        @inject(LocalizationSymbols.LocalizationService) localizationService: ILocalizationService) {

        this._provider = provider;
        this._loyaltyProvider = loyaltyProvider
        this._localizationService = localizationService;
    }

    async GetOrderListAsync(filter: OrdersFilter): Promise<ActionResult<FiltringResult<Order>>> {
        let userLoyalty = await this._loyaltyProvider.GetIsdUserLoyaltyAsync();

        if (!userLoyalty.Success || userLoyalty.Data == null)
            return ActionResult.Failed(userLoyalty.ErrorType ?? ErrorTypes.InternalError, userLoyalty.ErrorMessage ?? '');

        return await this._provider.GetOrderListByTNodAsync(userLoyalty.Data.TNodId);
    }
    async GetOrderAsync(id: number): Promise<ActionResult<OrderDetails>> {
        return await this._provider.GetOrderAsync(id);
    }

    private LocalizationChange(sender: any, e: LocalizationChangedEventArgs) {

    }

    async UpdateHeight(height: number): Promise<ActionResult> {
        await this._provider.UpdateHeight(height);
        return ActionResult.Success();
    }

    async GetData(): Promise<ActionResult<UserData>> {
        try {
            let result = await this._provider.GetData();
            if (result.Success) return ActionResult.SuccessData(new UserData(result.Data));
        } catch (e) {
            console.error(e);
        }
        return ActionResult.Failed(ErrorTypes.InternalError);
    }

    async GetBio(activityType: number, dateStart: string, dateEnd: string): Promise<ActionResult> {
        let result = await this._provider.GetBio(activityType, dateStart, dateEnd);
        return ActionResult.SuccessData(result);
    }
}

class UserData {
    Gender!: number;
    FullYears!: number;
    Height?: number;

    constructor(userdata: any) {
        this.Gender = userdata.Gender;
        this.FullYears = userdata.FullYears;
        this.Height = userdata.Height;
    }
}