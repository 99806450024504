import 'reflect-metadata';
import Vue from 'vue';
import WebSaleApp from './WebSaleApp.vue';
import GlobalService, {
	GlobalSymbols,
	INotificationService,
	NotificationSymbols,
	AppSettings,
	OIDCStorageStore,
	IDateHandler,
	DateHandlerSymbols,
	ILocalizationService,
	LocalizationSymbols,
	ModifiedWebSaleUserIdentity,
} from 'web-platform-core-ui';
import vuetify from './vuetify';
import router from './router';
import VueTheMask from 'vue-the-mask';
const Inputmask = require('inputmask');

import * as moment from 'moment';
import 'moment/locale/ru';
import loadStaticLocaliztionDictionaries from 'web-platform-vuetify/src/Localization';

moment.locale('ru');

Vue.use(VueTheMask);

Vue.component('VOffline', () => require('v-offline'));
Vue.directive('input-mask', {
	bind: function (el) {
		if (el.tagName == 'INPUT') new Inputmask().mask(el);
		else {
			let inp = el.getElementsByTagName('input')[0];
			new Inputmask().mask(inp);
		}
	},
	unbind: function (el) {
		if (el.tagName == 'INPUT') Inputmask.remove(el);
		else {
			let inp = el.getElementsByTagName('input')[0];
			Inputmask.remove(inp);
		}
	},
});

const settings = new AppSettings();
let parsingResult = settings.ParseHashParams();

if (!parsingResult) throw new Error('Settings parsing error');

if (settings.IsDevVersion) settings.ServerUrl = 'https://sale-test.moipass.ru';
else settings.ServerUrl = 'https://sale.moipass.ru';

GlobalService.SetSettings<AppSettings>(settings);

const store: any = new OIDCStorageStore();
const globalService = new GlobalService();
const context = new ModifiedWebSaleUserIdentity(store);
globalService.LoadWebSaleConfiguration(context);
globalService.Get<IDateHandler>(DateHandlerSymbols.DateHandlerService).Locale('ru');

// This is required for LOCALIZATION DICTIONARIES to work (i.e. Terms.GenericBuy to be available in class)
let localizationService = globalService.Get<ILocalizationService>(LocalizationSymbols.LocalizationService);
loadStaticLocaliztionDictionaries(localizationService);
localizationService.ChangeLocalizationAsync(settings.Localization);

router.beforeEach(async (to, from, next) => {
	const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	if (requiresAuth && context.IsInitialized) {
		if (!context.CurrentIdentity.IsAuthentificated) context.SignIn();
		else next();
	} else {
		next();
	}
});

new Vue({
	vuetify,
	router,
	render: (h) => h(WebSaleApp),
	provide: {
		[GlobalSymbols.GlobalService]: globalService,
	},
}).$mount('#app');

function _errorEventHandler(errorMsg: string, url: string, lineNumber: string): void {
	let notificationService = globalService.Get<INotificationService>(NotificationSymbols.NotificationService);
	notificationService.Error('Ошибка', errorMsg);

	console.log(`error: ${errorMsg}, url: ${url}, lineNumber: ${lineNumber}`);
}

const _global = (window || global) as any;
_global.onerror = _errorEventHandler;
