import IClubService, { ClubLesson } from './i-club-service';
import { ActionResult, ErrorTypes, WpEventHandler, WpEvent } from '../Common';
import ClubContract from './club-contract';
import { injectable, inject } from 'inversify';
import { IClubProvider, ClubSymbols, ClubAppSettings, SoldContractChangedEventArgs } from '.';
import SoldClubContract from './sold-club-contract';
import GlobalService from '../Global/global-service';
import { ILocalizationService, LocalizationSymbols } from '../Localization';
import WpCorePrefixes from '../wp-core-prefixes';
import { IsdUserLoyalty, PersonIsdLoyaltyData, ILoyaltyProvider, LoyaltySymbols } from '../Loyalty';
import { IDateHandler, DateHandlerSymbols } from '../Utility';
import { Tariff } from '../Tariffs';
import { INotificationService, NotificationSymbols } from '../Notification';

@injectable()
export default class DefaultClubService implements IClubService {
    protected _dateHndler: IDateHandler;
    protected _settings!: ClubAppSettings;
    protected _clubProvider!: IClubProvider;
    protected _localizationService!: ILocalizationService;
    protected _loyaltyProvider!: ILoyaltyProvider;
    protected _notificationService!: INotificationService;

    protected _currentPersonContract?: SoldClubContract | null;
    protected _contracts?: Array<ClubContract>;

    private _persontContractChanged: WpEventHandler<SoldContractChangedEventArgs> = new WpEventHandler<SoldContractChangedEventArgs>();

    CurrentPersonContract<T extends SoldClubContract>(): T | null | undefined {
        return this._currentPersonContract as T;
    };

    get PersonContractChanged(): WpEvent<SoldContractChangedEventArgs> {
        return this._persontContractChanged;
    };

    constructor(@inject(ClubSymbols.ClubProvider) clubProvider: IClubProvider,
        @inject(LocalizationSymbols.LocalizationService) localizationService: ILocalizationService,
        @inject(DateHandlerSymbols.DateHandlerService) dateHandler: IDateHandler,
        @inject(LoyaltySymbols.LoyaltyProvider) loyaltyProvider: ILoyaltyProvider,
        @inject(NotificationSymbols.NotificationService) notificationService: INotificationService) {
        this._settings = GlobalService.GetSettings<ClubAppSettings>();
        this._clubProvider = clubProvider;
        this._localizationService = localizationService;
        this._dateHndler = dateHandler;
        this._loyaltyProvider = loyaltyProvider;
        this._notificationService = notificationService;
    };

    async GetContractsAsync(): Promise<ActionResult<Array<ClubContract>>> {
        // Если контракты закэшированы - вернуть их
        if (this._contracts != null) return ActionResult.SuccessData(this._contracts);
        // Запрашиваем список контрактов
        let result = await this._clubProvider.GetContractsAsync();
        // Если не удалось - закончить с ошибкой
        if (!result.Success || result.Data == null)
            return ActionResult.FailedData(result.ErrorType!, [], result.ErrorMessage);

        this._contracts = result.Data;

        await this._localizationService.TranslateManyAsync(WpCorePrefixes.ClubContract, this._contracts);

        this._contracts.forEach(x => { x.IsGuestContract = this._settings.GuestContractsIds == x.ContractCode; });
        return ActionResult.SuccessData(this._contracts);
    };

    async GetContractAsync(id: number): Promise<ActionResult<ClubContract>> {
        if (this._contracts != null) {
            let result = this._contracts.find(x => x.Id == id);

            if (result != null)
                return ActionResult.SuccessData<ClubContract>(result);
        };
        return await this._clubProvider.GetContractAsync(id);
    };

    async GetPersonContractsAsync(userLoyalty: IsdUserLoyalty): Promise<ActionResult<Array<SoldClubContract>>> {
        if (!userLoyalty) {
            console.error("Отсутствует программа лояльности. Контракты загрузить не получится");
            //this._notificationService.Error("Error", "Отсутствует программа лояльности пользователя");
            return ActionResult.Failed(ErrorTypes.InternalError);
        };
            
        if (this._contracts == null) {
            let contracts = await this.GetContractsAsync();

            if (!contracts.Success || contracts.Data == null)
                return ActionResult.FailedData(contracts.ErrorType ?? ErrorTypes.InternalError, new Array<SoldClubContract>(), contracts.ErrorMessage);
        };

        let personsContracts = await this._clubProvider.GetPersonContractsAsync(userLoyalty.TNodId, userLoyalty.Id);

        if (!personsContracts.Success || personsContracts.Data == null)
            return personsContracts;

        let children = await this._loyaltyProvider.GetChildrenAsync();
        let result: Array<SoldClubContract> = [];
        personsContracts.Data.forEach(contract => {
            let toPush = this._attachTemplate(contract, userLoyalty, children.Success && children.Data ? children.Data : []);
            if (toPush) result.push(contract);
        });

        // Отфильтровать список контрактов.
        // Статус 5 - удалённый - он нам не нужен
        result = result.filter((contract: SoldClubContract) => contract.STATUS != 5);

        this._currentPersonContract = result.find(x => x.Template.ContractCode == this._settings.GuestContractsIds);

        // Если контрактов несколько
        if (result.length > 1) {
            // Найти первый попавшийся контракт, который не является гостевым
            let match = result.find(contract => !contract.Template.IsGuestContract && !contract.Child);
            if (match) this._currentPersonContract = match;
        };

        /*let last = localStorage.getItem("lt:last-contract");
        if (last) {
            let contract = <SoldClubContract>JSON.parse(last);
            let match = result.find(c => c.ID === contract.ID);
            if (match) this._currentPersonContract = match;
        };*/

        return ActionResult.SuccessData(result);
    };

    private _attachTemplate(contract: SoldClubContract, userLoyalty: IsdUserLoyalty, children?: PersonIsdLoyaltyData[]) {
        if (!this._contracts) return;
        let template = this._contracts.find(c => c.ContractCode == contract.SHABLON_CLUB_CONTRACT_ID);
        if (template) {
            contract.Template = template;

            let person = new PersonIsdLoyaltyData();
            person.PersId = userLoyalty.PersId;
            person.TNodId = userLoyalty.TNodId;
            person.FirstName = userLoyalty.FirstName;
            person.LastName = userLoyalty.LastName;
            person.SecondName = userLoyalty.SecondName;
            person.AddressId = userLoyalty.AddressId;
            person.Birthdate = userLoyalty.Birthdate != null ? this._dateHndler.Parse(userLoyalty.Birthdate, "YYYY-MM-DDTHH:mm:ss").Format("DD.MM.YYYY") : "";
            person.Gender = userLoyalty.Gender;
            person.LoyaltyId = userLoyalty.Id;
            contract.Person = person;

            if (!children) return;
            if (contract.SOLD_CARD_LIST != null && contract.SOLD_CARD_LIST.length > 1) {
                children.forEach(child => {
                    if (contract.SOLD_CARD_LIST != null && contract.SOLD_CARD_LIST.some(c => c.OWNER_PERSON != null &&
                        c.OWNER_PERSON == child.TNodId)) {
                        contract.Person = child;
                    };
                });
            };
            return true;
        };
        return false;
    };

    async CheckGuestContractAsync(userLoyalty: IsdUserLoyalty): Promise<ActionResult> {
        let personsContracts = await this.GetPersonContractsAsync(userLoyalty);

        if (!personsContracts.Success || personsContracts.Data == null)
            return ActionResult.FailedData(ErrorTypes.NotFoundData, "Не удалось заполучить список контрактов клиента");

        if (!personsContracts.Data.some(x => this._settings.GuestContractsIds == x.SHABLON_CLUB_CONTRACT_ID)) {
            await this._clubProvider.CreateGuestContractAsync();
            return ActionResult.SuccessData({ Status: 2, Message: "Гостевой контракт создан" });
        };

        return ActionResult.SuccessData({ Status: 1, Message: "Проверка успешно завершена" });
    };

    async ChangePersonContractAsync(id: number, userLoyalty: IsdUserLoyalty): Promise<ActionResult> {
        let personsContracts = await this._clubProvider.GetPersonContractsAsync(userLoyalty.TNodId, userLoyalty.Id);

        if (!personsContracts.Success || personsContracts.Data == null)
            return personsContracts;

        let contract = personsContracts.Data.find(x => x.ID == id);
        if (contract != null) {

            let children = await this._loyaltyProvider.GetChildrenAsync();
            let arrayChildren = children.Success && children.Data ? children.Data : [];

            this._attachTemplate(contract, userLoyalty, arrayChildren);
            this._currentPersonContract = contract;

            this._notificationService.SendSystemNotification("CurrentPersonContractChanged", this._currentPersonContract);

            localStorage.setItem("lt:last-contract", JSON.stringify(contract));
            return ActionResult.Success();
        } else {
            return ActionResult.Failed(ErrorTypes.NotFoundData, "Контракт не найден");
        };
    };

    async RegistrationToPersonalLessson(lessonId: number, userLoyalty: IsdUserLoyalty, contratId?: number, persId?: number, tnodId?: number): Promise<ActionResult> {
        return await this._clubProvider.RegistrationToPersonalLessson(persId ?? userLoyalty.PersId, tnodId ?? userLoyalty.TNodId, userLoyalty.Id, lessonId, contratId);
    };

    async RegistrationToGroupLessson(lessonId: number, userLoyalty: IsdUserLoyalty, contratId?: number, persId?: number, tnodId?: number): Promise<ActionResult> {
        return await this._clubProvider.RegistrationToGroupLessson(persId ?? userLoyalty.PersId, tnodId ?? userLoyalty.TNodId, userLoyalty.Id, lessonId, contratId);
    };

    async RegistrationToFreeGroupLessson(lessonId: number, userLoyalty: IsdUserLoyalty, contratId?: number, persId?: number, tnodId?: number): Promise<ActionResult> {
        return await this._clubProvider.RegistrationToFreeGroupLessson(persId ?? userLoyalty.PersId, tnodId ?? userLoyalty.TNodId, userLoyalty.Id, lessonId, contratId);
    };

    async GetContractsAdditionalServicesAsync<T extends Tariff>(): Promise<ActionResult<Array<T>>> {
        return await this._clubProvider.GetContractsAdditionalServicesAsync<T>();
    };

    /**
     * http://redmine.isd.su/issues/8403
     */
    // Запрос для получения списка занятий на которые записан клиент
    async GetLessonsAsync(user: IsdUserLoyalty, dateFrom: string, dateTo: string, filterOutdated: boolean = true, TNodId?: number): Promise<ActionResult<Array<ClubLesson>>> {
        // Get all personal lessons (maybe move it somewhere outside?)
        let query = await this._clubProvider.GetPersonalLessons(
            TNodId ? TNodId : user.TNodId,
            user.Id,
            dateFrom,
            dateTo
        );

        if (query.Success && query.Data != null) {
            query.Data.forEach((lesson: any) => {
                if (!lesson.Date || !lesson.TimeFrom) return;           // Stop if no startdate or timefrom present
                let d = new Date(lesson.Date);                          // Create new date object
                let tzOffset = d.getTimezoneOffset();                   // Save timezone offset
                let beginTime = lesson.TimeFrom.split(":");             // "17:30:15" => [17, 30, 15];
                d.setHours(parseInt(beginTime[0]) - (tzOffset / 60));   // Hours
                d.setMinutes(parseInt(beginTime[1]));                   // Minutes
                d.setSeconds(parseInt(beginTime[2]));                   // Seconds
                lesson.Date = d.toISOString().substring(0, 19);         // Mutate the original date so it now includes time from BeginTime
            });

            let result = query.Data;

            if (filterOutdated) {
                // Remove lessons that have already started
                result = result.filter((l: any) => { return new Date(l.Date).getTime() > new Date().getTime() })
            };

            // Sort them in an ascending order of time
            result = result.sort((o1: any, o2: any) => { return new Date(o1.Date).getTime() - new Date(o2).getTime(); });

            return ActionResult.SuccessData(result);
        };

        return ActionResult.Failed(ErrorTypes.InternalError);
    };

    // Get nearest event
    async GetNearestLessonAsync(user: IsdUserLoyalty, dateFrom: string, dateTo: string, TNodId?: number): Promise<ActionResult> {
        let query = await this.GetLessonsAsync(user, dateFrom, dateTo, true, TNodId);
        if (query.Success && query.Data != null && query.Data.length != 0) {
            query.Data = query.Data.sort((x, y) => {
                let xDate = `${x.Date} ${x.TimeFrom}`
                let yDate = `${y.Date} ${y.TimeFrom}`

                if (xDate == yDate) return 0;
                if (xDate < yDate) return -1
                else return 1;
            })

            return ActionResult.SuccessData(query.Data[0]);
        }
        return ActionResult.Failed(ErrorTypes.InternalError);
    };

    /*
     * Заморозка карты
     */
    async FreezeCard(ContractId: number, DateFrom: string, DaysAmount: number, UserLoyaltyId: number, ClubSiteId: number, TNodId: number): Promise<ActionResult> {
        let freeze = await this._clubProvider.FreezeCard(ContractId, DateFrom, DaysAmount, UserLoyaltyId, ClubSiteId, TNodId);
        if (freeze.Success) {
            this._notificationService.SendSystemNotification("CurrentPersonContractChanged", this._currentPersonContract);
            return ActionResult.SuccessData(freeze.Data);
        };
        return freeze;
    };

    async CancelLessonAsync(user: IsdUserLoyalty, cashListId: number, cashItemId: number): Promise<ActionResult<Array<ClubLesson>>> {
        return await this._clubProvider.CancelLessonAsync(user.TNodId, user.Id, cashListId, cashItemId);
    };

    async GetStatsAsync(user: IsdUserLoyalty, personContract: SoldClubContract): Promise<ActionResult<Array<any>>> {
        console.error("Method not implemented");
        return ActionResult.Failed(ErrorTypes.InternalError);
    };

    ResetCache(): void { this._contracts = undefined; this._currentPersonContract = undefined };
};