import './wp-tariffs.scss';

import Vue from 'vue';
import WpBaseComponent from './../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
	ITariffService,
	TariffSymbols,
	AppSettings,
	INotificationService,
	Cart,
	Tariff,
	TariffsBundle,
	CartItem,
	TariffSeance,
	IMetricsService,
	MetricsSymbols,
	WpCorePrefixes,
	NotificationEventArgs,
	NotificationTypes,
	NotificationSymbols,
	ITariffCapacityService,
	MLogger,
	CustomSeanceTitle,
} from 'web-platform-core-ui';
import { Prop, DefaultProps } from 'vue/types/options';
import { MetricsEvents } from '../../metrics-events-symbols';
import { VueConstructor } from 'vue/types/umd';
import BaseUILocalizationDictionary from './../../Localization/base-ui-localization-terms';
import { TariffItem, TariffItemType, EcomTariffsHelper } from './tariff-item';

export const wpTariffsProp = Vue.extend({
	props: {
		Cart: Object as Prop<Cart>,
	},
});

@Component({
	template: require('./wp-tariffs.html'),
})
export default class WpTariffs extends mixins<WpBaseComponent<WpTariffsDictionary>, DefaultProps, VueConstructor>(
	WpBaseComponent,
	wpTariffsProp,
	Vue
) {
	protected _tariffService!: ITariffService;
	protected _tariffCapacityService!: ITariffCapacityService;
	protected _notificationService!: INotificationService;
	protected _metricsService!: IMetricsService;
	protected _logger!: MLogger;

	Step: number = 1;
	Settings!: AppSettings;
	SelectedSeanceId: string | undefined = '';
	SelectedSeance?: TariffSeance;
	Seances: Array<TariffSeance> = [];
	Loading: boolean = true;
	TariffsSeancesFilter: boolean = false;
	Date: string = '';
	TariffsZoneList?: Array<string>;
	DatepickerSettings: any = {};
	Datepicker: boolean = false;
	SeanceIdFilter: number = 0;
	NotAvailable: boolean = false;
	TariffsLoading: boolean = true;
	ItemsAvailable: boolean = false;
	// ShowOverSeances: boolean = false;
	// ShowNoTicketsSeances: boolean = false;
	customSeanceTitles: CustomSeanceTitle[] = [];
	HaveItems: boolean = true;

	// Тарифов может быть 1-2
	Tariffs: Array<Tariff> = [];
	Bundles: Array<TariffsBundle> = [];
	// Но товаров для отображения может быть много, в зависимости от кол-ва сеансов
	Items: Array<TariffItem> = [];
	tempItems: Array<TariffItem> = [];

	created(): void {
		// Настройки...
		this.Settings = GlobalService.GetSettings<AppSettings>();
		this.customSeanceTitles = this.Settings.CustomSeanceTitles;
		// this.ShowOverSeances = this.Settings.ShowOverSeances;
		// this.ShowNoTicketsSeances = this.Settings.ShowNoTicketsSeances;
		this.TariffsSeancesFilter = this.Settings.TariffsSeancesFilter;
		// Сервис по работе с метрикой
		this._metricsService = this.GlobalService.Get<IMetricsService>(MetricsSymbols.MetricsService);
		// Get notification service up and ready
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
		// Watch for notification rules
		this._notificationService.NotificationSended.Subscribe(this._notification);
		// Сервис по работе с тарифами (Загрузить, узнать цену и прочее)
		this._tariffService = this.GlobalService.Get<ITariffService>(TariffSymbols.TariffService);
		// Сервис по работе с нагрузкой (Кол-во доступных билетов и прочее)
		this._tariffCapacityService = this.GlobalService.Get<ITariffCapacityService>(TariffSymbols.TariffCapacityService);
		// Микро логер
		this._logger = new MLogger();
	}

	async mounted(): Promise<void> {
		let result = await this._tariffService.GetTariffsAsync();
		let resultBundles = await this._tariffService.GetTariffsBundlesAsync();

		if (result.Data != undefined) {
			if (this.Settings.TariffsId != null && this.Settings.TariffsId.length > 0) {
				result.Data.filter((x) => !x.IsRewritable).forEach((x) => {
					if (this.Settings?.TariffsId.some((id) => id == x.Id)) {
						this.AddTariffItem(x);
						this.Tariffs.push(x);
					}
				});
			} else {
				result.Data.filter((x) => !x.IsRewritable).forEach((x) => {
					this.AddTariffItem(x);
					this.Tariffs.push(x);
				});
			}
		}

		await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.Tariff, this.Tariffs.length > 0 ? this.Tariffs : [new Tariff()]);

		if (this.Settings.BundlesId != null && this.Settings.BundlesId.length > 0) {
			if (resultBundles.Data != undefined) {
				resultBundles.Data.forEach((x) => {
					if (this.Settings?.BundlesId.some((id) => id == x.Id)) {
						this.LocalizationService.TranslateManyAsync(WpCorePrefixes.Tariff, x.Tariffs);
						this.AddBundleItem(x);
						this.Bundles.push(x);
					}
				});

				await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.TariffBundle, this.Bundles);
			}
		} else {
			if (resultBundles.Data != undefined) {
				resultBundles.Data.forEach((x) => {
					this.LocalizationService.TranslateManyAsync(WpCorePrefixes.Tariff, x.Tariffs);
					this.AddBundleItem(x);
					this.Bundles.push(x);
				});

				await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.TariffBundle, this.Bundles);
			}
		}

		// Get every bundle
		this.Bundles.forEach((b) => {
			// Get every tariff in the bundle
			b.Tariffs.forEach((t) => {
				// If this tariff is NOT in the array this.Tariffs - add it
				if (this.Tariffs.find((a) => a.Id == t.Id) == undefined) this.Tariffs.push(t);
			});
		});

		this.Seances = this.Seances.filter((thing, i, arr) => arr.findIndex((t) => t.SeanceId === thing.SeanceId) === i);

		this.Seances = this.Seances.sort((x, y) => {
			if (x.SeanceValue == y.SeanceValue) return 0;
			if (x.SeanceValue < y.SeanceValue) return -1;
			else return 1;
		});
		this.Items = this.tempItems.sort((x, y) => {
			let partsX = x.ZIndex.split('=');
			let partsY = y.ZIndex.split('=');

			if (partsX.length > 1 && partsY.length > 1) {
				let xZIndex = `${partsX[1]}=${partsX[0]}`;
				let yZIndex = `${partsY[1]}=${partsY[0]}`;

				if (xZIndex == yZIndex) return 0;
				if (xZIndex < yZIndex) return -1;
				else return 1;
			} else {
				if (x.ZIndex == y.ZIndex) return 0;
				if (x.ZIndex < y.ZIndex) return -1;
				else return 1;
			}
		});

		// And now we have a list of all tariffs in one array - this.Tariffs
		await this.UpdateTariffsPricesCapacitiesAll(this.Date);

		if (this.Items.length == 0) {
			this.TariffsLoading = false;
			this.HaveItems = false;
		}

		this.SeanceIdFilter = this.FindFilteredSeance();
		this.StartDatepicker();

		this.Loading = false;
	}

	protected _notification(sender: any, e: NotificationEventArgs): void {
		if (e.Data.Type == NotificationTypes.System && e.Data.SystemProcessKey == 'CartContent') this.SeanceIdFilter = this.FindFilteredSeance();
	}

	StartDatepicker(): void {
		this.DatepickerSettings = this.Settings;
		this.Datepicker = true;
	}

	AddToCart(cartItem: CartItem): void {
		let result = this.Cart.AddItem(cartItem);

		this._metricsService.RegistrateEvent(MetricsEvents.ServiceCartAdd, cartItem.MetricsId, cartItem);

		if (!result.Success)
			this._notificationService.Error(this.Terms.GenericError, result.ErrorMessage ? result.ErrorMessage : this.Terms.InternalError);
	}

	FindFilteredSeance(): number {
		try {
			// Find if any cart items have Seance in them
			var r = this.Cart.Items.find((e: TariffItem) => {
				return e.Seance != null;
			});
		} catch (e) {
			console.error(e);
		} finally {
			// If the corresponding setting ( disablemultipleseances ) is set to true then return the result
			if (this.Settings?.DisableMultipleSeances) {
				return r ? r.Seance.SeanceId : 0;
			} else {
				return 0;
			}
		}
	}

	beforeDestroy() {
		this._notificationService.NotificationSended.Unsubscribe(this.notification);
	}

	SelectSeance(seance: TariffSeance) {
		if (this.SelectedSeance != null) {
			if (this.SelectedSeance.SeanceId == seance.SeanceId) {
				this._metricsService.RegistrateEvent(MetricsEvents.ServiceSeanceUnselected, seance.SeanceId);

				this.SelectedSeance = undefined;
				this.Items.forEach((x) => {
					x.Show = true;
				});
			} else {
				this._metricsService.RegistrateEvent(MetricsEvents.ServiceSeanceSelected, seance.SeanceId);
				this.SelectedSeance = seance;
				this.Items.forEach((x) => {
					if (x.Seance != null && x.Seance.SeanceId != this.SelectedSeance?.SeanceId) x.Show = false;
					else x.Show = true;
				});
			}
		} else {
			this._metricsService.RegistrateEvent(MetricsEvents.ServiceSeanceSelected, seance.SeanceId);
			this.SelectedSeance = seance;
			this.Items.forEach((x) => {
				if (x.Seance != null && x.Seance.SeanceId != this.SelectedSeance?.SeanceId) x.Show = false;
			});
		}
	}

	async DateSet(date: string): Promise<void> {
		if (this.Date == date || !this.HaveItems) return;
		this.TariffsLoading = true;

		this.Seances.forEach((x) => (x.Disabled = true));
		// Кэшируем цены и загрузку меньшим кол-вом запросов при обновлении даты
		await this.UpdateTariffsPricesCapacitiesAll(date);
		this.Date = date;
	}

	ItemLoadingChange(id: string, value: boolean) {
		let element = this.Items.find((x) => x.Id == id);
		if (element != undefined) {
			element.ItemLoading = value;
			this.TariffsLoading = this.Items.some((x) => x.ItemLoading == true);
		}
	}

	ItemAvailableChange(id: string, value: boolean, availableCount?: number, seanceId?: string, price?: number, bundleItems?: []) {
		let element = this.Items.find((x) => x.Id == id);
		if (element != undefined) {
			element.ItemAvailable = value;
			element.Price = price;
			if (bundleItems) element.BundleItems = bundleItems;
			this.ItemsAvailable = this.Items.some((x) => x.ItemAvailable == true);
		}

		if (value == true) {
			if (seanceId != null && (availableCount == null || (availableCount != null && availableCount > 0))) {
				let seance = this.Seances.find((x) => x.SeanceId == seanceId);
				if (seance != null) seance.Disabled = false;
			}
		}
	}

	private AddTariffItem(tariff: Tariff) {
		let _this = this;
		if (tariff.Seances != null && tariff.Seances.length > 0) {
			tariff.Seances.forEach((x) => {
				_this.Seances.push({ SeanceId: x.SeanceId, SeanceValue: x.SeanceValue, Disabled: true });

				this.tempItems.push({
					Id: `tariff_${tariff.Id}_${x.SeanceId}`,
					Type: TariffItemType.Tariff,
					Data: tariff,
					ZIndex: `${tariff.ZIndex.toString().padStart(2, '0')}=${TariffSeance.SeanceTime(x)}`,
					ItemLoading: true,
					ItemAvailable: false,
					Seance: x,
					Show: true,
					SeanceId: x.SeanceId,
					BundleItems: [],
				});
			});
		} else {
			this.tempItems.push({
				Id: `tariff_${tariff.Id}`,
				Type: TariffItemType.Tariff,
				Data: tariff,
				ZIndex: `${tariff.ZIndex.toString().padStart(2, '0')}=`,
				ItemLoading: true,
				ItemAvailable: false,
				Show: true,
				SeanceId: '',
				BundleItems: [],
			});
		}
	}

	private AddBundleItem(bundle: TariffsBundle) {
		let tariff = bundle.Tariffs.find((x) => x.Seances != null && x.Seances.length > 0);
		let _this = this;
		if (tariff != null) {
			tariff.Seances.forEach((x) => {
				_this.Seances.push({ SeanceId: x.SeanceId, SeanceValue: x.SeanceValue, Disabled: true });
				this.tempItems.push({
					Id: `bundle_${bundle.Id}_${x.SeanceId}`,
					Type: TariffItemType.Bundle,
					Data: bundle,
					ZIndex: `${Math.min
						.apply(
							Math,
							bundle.Tariffs.map(function (o) {
								return o.ZIndex;
							})
						)
						.toString()
						.padStart(2, '0')}=${TariffSeance.SeanceTime(x)}`,
					ItemLoading: true,
					ItemAvailable: false,
					Seance: x,
					Show: true,
					SeanceId: x.SeanceId,
					BundleItems: [],
				});
			});
		} else {
			this.tempItems.push({
				Id: `bundle_${bundle.Id}`,
				Type: TariffItemType.Bundle,
				Data: bundle,
				ZIndex: `${Math.min
					.apply(
						Math,
						bundle.Tariffs.map(function (o) {
							return o.ZIndex;
						})
					)
					.toString()
					.padStart(2, '0')}=`,
				ItemLoading: true,
				ItemAvailable: false,
				Show: true,
				SeanceId: '',
				BundleItems: [],
			});
		}
	}

	// Новые методы - http://redmine.isd.su/issues/6843
	async UpdateTariffsPricesCapacitiesAll(date: string): Promise<any> {
		this.PricesLoading = true;

		if (this.Tariffs.length > 0) {
			let prices: Array<Promise<any>> = [];

			let tariffWithCapacity = this.Tariffs.filter((x) => x.ShowLeftTicketCount == true && x.Quota == null);
			await this._tariffCapacityService.GetCapacitiesForAllTariffs(tariffWithCapacity, date);

			// Дата, по которой будем кэшировать цены и загрузку будет установленной из календаря (date), минимальной по настройкам (Mindate) или сегодняшней (new Date())
			let activeDate = date || this.Settings.Mindate || new Date().toLocaleDateString('ru-RU');

			this._logger.time('caching', 'Кэшируем цены и загрузку по дате: ' + activeDate);

			// Mark items as loading so it doesn't show while we update prices
			this.Items.forEach((i) => {
				i.ItemLoading = true;
			});

			for (var i = 0; i < this.Tariffs.length; i++) {
				let item = this.Tariffs[i];
				// Этот сервис загружает цены in bulk
				prices.push(this._tariffService.GetTariffsAllPrices(item.Id, activeDate));
				// Этот сервис подгружает загрузку in bulk
				//promises.push(this._tariffCapacityService.GetTariffsAllCapacities(item.Id, activeDate));
			}

			// После того как прогрузили все цены...
			await Promise.allSettled(prices);
			this.PricesLoading = false;
			this._logger.time('caching', 'Кэширование заняло %t миллисекунд(ы)');
			setTimeout(() => {
				this._metricsService.RegistrateEvent(MetricsEvents.ServiceChangeDate, date, new EcomTariffsHelper(this.Items));
			}, 500);
			// Когда все загрузки прогрузились....
		}
	}

	// SeanceTime(seance: TariffSeance): string | undefined {
	// 	return TariffSeance.SeanceTime(seance);
	// }

	// SeanceFilterBlockResizeFix() {
	// 	if (this.TariffsSeancesFilter && this.SelectedSeance == null) {
	// 		//let seanceSlideBlock = document.getElementById('seances-filter-block')?.querySelector('.v-slide-group__content') as HTMLElement;
	// 		let seanceSlideBlock = (this.$refs.filter as HTMLElement).querySelector('.v-slide-group__content') as HTMLElement;
	// 		if (seanceSlideBlock != null) seanceSlideBlock.style.transform = 'translateX(0px)';
	// 	}
	// }

	// IsSeanceOver(seance: TariffSeance) {
	// 	let selectedDate = this.DateHandler.Parse(this.Date, 'DD.MM.YYYY').ParseToDate();
	// 	let set = seance.SeanceValue.split('-');
	// 	let startSet = set[1].split(':');
	// 	let hours = parseInt(startSet[0]);
	// 	let minutes = parseInt(startSet[1]);
	// 	let offset = parseInt(this.Settings.CurrentSite!.Offset.split(':')[0]);
	// 	let now = new Date();
	// 	now.setHours(new Date().getUTCHours() + offset);
	// 	let compareDate = new Date(selectedDate);
	// 	compareDate.setHours(hours);
	// 	compareDate.setMinutes(minutes);
	// 	return compareDate < now;
	// }

	// https://redmine.moipass.ru/issues/12916
	// customSeanceTitleAvailable(seance: TariffSeance) {
	// 	let day = new Date(this.Date.split('.').reverse().join('/')).getDay();
	// 	let find = this.customSeanceTitles.find((s) => s.seanceId === parseInt(seance.SeanceId));
	// 	if (find) {
	// 		if (Array.isArray(find.dates) && find.dates.indexOf(this.Date) != -1) {
	// 			return true;
	// 		}
	// 		if (Array.isArray(find.days) && find.days.indexOf(day) != -1) {
	// 			return true;
	// 		}
	// 		return false;
	// 	} else {
	// 		return false;
	// 	}
	// }
	// customSeanceTitle(seance: TariffSeance) {
	// 	let find = this.customSeanceTitles.find((s) => s.seanceId === parseInt(seance.SeanceId));
	// 	if (find) {
	// 		return find.text.replace('script', '');
	// 	}
	// 	return false;
	// }
	// customSeanceColor(seance: TariffSeance) {
	// 	let find = this.customSeanceTitles.find((s) => s.seanceId === parseInt(seance.SeanceId));
	// 	if (find && find.color) {
	// 		return { color: find.color };
	// 	}
	// 	return null;
	// }
}

export class WpTariffsDictionary extends BaseUILocalizationDictionary {
	NoAvailableLessonsForThisDate: string = '';
}
