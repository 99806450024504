export default class OrganizationInfo {
    public Id: number = 0;
    public DisplayName: string = "";
    public OrgUrl: string = "";
    public OrgDesc: string = "";
    public OrgName: string = "";
    public SupportEmail: string = "";
    public SupportPhone: string = "";
    public LegalName: string = "";
    public OGRN: string = "";
    public INN: string = "";
    public LegalAddress: string = "";
    public License: string = "";
}
