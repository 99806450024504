export default class Order {
    Id: number = 0;
    UserPhone: string = '';
    UserName: string = '';
    Site: string = "";
    Organization: string = "";
    Date: Date = new Date();
    Status: number = 0;
    Sum: number = 0;
    RegistredDate?: string;
}