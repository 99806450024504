import './wp-instructors.scss';

import Vue, { VueConstructor } from 'vue';
import { Prop, DefaultProps, PropOptions } from 'vue/types/options';
import WpBaseComponent from './../../wp-base-component';
import Component, { mixins } from 'vue-class-component';
import GlobalService, {
	ActionResult,
	AppSettings,
	InstructorsSymbols,
	IInstructorsService,
	GroupLessonsFilter,
	InstructorsGroupLesson,
	InstructorTariffCategory,
	SkillType,
	InstructorsPlace,
	INotificationService,
	NotificationSymbols,
	CartItem,
	Cart,
	CartItemTypes,
	Tariff,
	InstructorsGroupCartItem,
	BaseSystemLocalizationDictionary,
	WpCorePrefixes,
	BaseFilter,
	ICmsService,
	CmsSymbols,
	InstructorsLessonsLocation,
	Instructor,
} from 'web-platform-core-ui';

export const wpInstructorsProp = Vue.extend({
	props: {
		Cart: Object as Prop<Cart>,
		multiday: <PropOptions>{
			default: false,
			required: false,
		},
	},
});

@Component({
	template: require('./wp-instructors-group.html'),
})
export default class WpInstructorsGroup extends mixins<WpBaseComponent<WpInstructorsGroupDictionary>, DefaultProps, VueConstructor>(
	WpBaseComponent,
	wpInstructorsProp,
	Vue
) {
	private _allTariffs: Array<Tariff> = [];
	private _instructorsService!: IInstructorsService;
	protected _notificationService!: INotificationService;

	Settings!: AppSettings;
	DatepickerSettings: any = {};

	SelectedPlace: InstructorsPlace | null = null;

	TariffId: number = 0;
	Tariffs: Array<Tariff> = [];
	TariffLoaded: boolean = false;
	TariffVisible: boolean = true;
	NoTariffs: boolean = false;

	Skill: number = 0;
	Skills: Array<SkillType> = [];
	SkillsLoaded: boolean = false;
	SkillsVisible: boolean = true;
	NoSkills: boolean = false;

	get Category(): string {
		if (this.Categories.length !== 0) {
			let categoryById = this.Categories.filter((x) => x.Id == parseInt(this.CategoryId));
			return categoryById.length !== 0 ? categoryById[0].Name : '';
		} else {
			return '';
		}
	}

	CategoryId: string = '';
	Categories: Array<InstructorTariffCategory> = [];
	CategoriesLoaded: boolean = false;
	CategoriesVisible: boolean = true;
	NoCategories: boolean = false;

	LocationId: number = 0;
	Locations: Array<InstructorsLessonsLocation> = [];
	LocationsLoaded: boolean = false;
	LocationsVisible: boolean = true;
	NoLocations: boolean = false;

	ShowButtonVisible: boolean = true;

	Filter?: GroupLessonsFilter;
	FilterLoading = false;
	LessonsLoaded = false;
	Lessons?: Array<InstructorsGroupLesson>;

	Dates: Array<string> = [];
	DatesVisible: boolean = true;

	Instructor: Instructor | null = null;
	NoCoach: boolean = false;

	get DatesArray(): Array<string> {
		let datesArray = [];
		let firstDate = this.DateHandler.ParseToDate(this.Dates[0], 'YYYY-MM-DD');

		if (this.Dates.length == 2) {
			let lastDate = this.DateHandler.ParseToDate(this.Dates[1], 'YYYY-MM-DD');

			while (firstDate <= lastDate) {
				datesArray.push(this.DateHandler.Parse(firstDate).Format('YYYY-MM-DD'));
				firstDate.setDate(firstDate.getDate() + 1);
			}
		} else if (this.Dates.length == 1) {
			datesArray.push(this.DateHandler.Parse(firstDate).Format('YYYY-MM-DD'));
		}

		return datesArray;
	}

	FilterComplete: boolean = false;

	Datepicker: boolean = false;

	StartDatepicker(): void {
		this.DatepickerSettings = this.Settings;
		this.Datepicker = true;
	}

	async mounted(): Promise<void> {
		this._instructorsService = this.GlobalService.Get<IInstructorsService>(InstructorsSymbols.InstructorsService);
		this._notificationService = this.GlobalService.Get<INotificationService>(NotificationSymbols.NotificationService);
		let cmsService = this.GlobalService.Get<ICmsService>(CmsSymbols.CmsService);

		this.Settings = GlobalService.GetSettings<AppSettings>();
		this.StartDatepicker();

		if (this.Settings.NoSkills) this.NoSkills = true;
		if (this.Settings.NoCategories) this.NoCategories = true;
		if (this.Settings.NoTariffs) this.NoTariffs = true;
		if (this.Settings.NoLocations) this.NoLocations = true;
		if (this.Settings.NoCoach) this.NoCoach = true;

		let places = await this._instructorsService.GetPlacesAsync();

		if (!places.Success || places.Data == undefined || places.Data.length == 0) {
			this._notificationService.Error('Ошибка', places.ErrorMessage ? places.ErrorMessage : 'Непредвиденная ошибка');
			return;
		}

		if ('PlaceId' in this.Settings) {
			let place = places.Data.filter((x) => x.Id == this.Settings?.PlaceId);
			if (place.length !== 0) {
				this.SelectedPlace = place[0];
			} else {
				this.SelectedPlace = places.Data[0];
			}
		} else {
			this.SelectedPlace = places.Data[0];
		}

		let Skills = await this._instructorsService.GetSkillAsync(this.SelectedPlace.Id);
		if (Skills.Data !== undefined && Skills.Data.length >= 1) {
			await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.InstructorsSkillsNames, Skills.Data);

			let translateSkill = await cmsService.GetEntitiesAsync<SkillType>(WpCorePrefixes.InstructorsSkillsNames, new BaseFilter(), '');

			if (translateSkill.Success && translateSkill.Data != null) {
				Skills.Data.forEach((x) => {
					let skill = translateSkill.Data?.Entities.find((sc) => sc.Code == x.Id);
					if (skill != null) x.Name = skill.Name;
				});
			}

			this.Skills = Skills.Data.sort((x, y) => {
				if (x.Name == y.Name) return 0;
				if (x.Name < y.Name) return -1;
				else return 1;
			});

			if (Skills.Data.length === 1) {
				this.Skill = this.Skills[0].Id;
				this.SkillsVisible = false;
			}
		}

		this.SkillsLoaded = true;

		let Categories = await this._instructorsService.GetInstructorsTariffCategoriesAsync();
		if (Categories.Data !== undefined && Categories.Data.length >= 1) {
			await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.InstructorsTariffOptionNames, Categories.Data);

			let translateCategories = await cmsService.GetEntitiesAsync<InstructorTariffCategory>(
				WpCorePrefixes.InstructorsTariffOptionNames,
				new BaseFilter(),
				''
			);

			if (translateCategories.Success && translateCategories.Data != null) {
				Categories.Data.forEach((x) => {
					let category = translateCategories.Data?.Entities.find((sc) => sc.Code == x.Id);
					if (category != null) x.Name = category.Name;
				});
			}

			this.Categories = Categories.Data.sort((x, y) => {
				if (x.Name == y.Name) return 0;
				if (x.Name < y.Name) return -1;
				else return 1;
			});

			if (this.Categories.length === 1) {
				this.CategoryId = this.Categories[0].Id.toString();
				this.CategoriesVisible = false;
			}
		}

		this.CategoriesLoaded = true;

		let locations = await this._instructorsService.GetLessonsLocationsAsync();
		if (locations.Data !== undefined && locations.Data.length >= 1) {
			this.Locations = locations.Data.sort((x, y) => {
				if (x.Name == y.Name) return 0;
				if (x.Name < y.Name) return -1;
				else return 1;
			});

			if (this.Locations.length === 1) {
				this.LocationId = this.Locations[0].LocationId;
				this.LocationsVisible = false;
			}
		}

		this.LocationsLoaded = true;

		let tariffs = await this._instructorsService.GetInstructorsTariffsAsync();
		if (tariffs.Data !== undefined && tariffs.Data.length >= 1) {
			await this.LocalizationService.TranslateManyAsync(WpCorePrefixes.Tariff, tariffs.Data);
			this._allTariffs = tariffs.Data;

			// If multiday is true - get filter data from multidaylessonstariffsid setting instead of grouplessonstariffsid
			//let filterTariffsId = this.multiday !== false ? this.Settings.MultiDayLessonsTariffsId : this.Settings.GroupLessonsTariffsId;
			let filterTariffsId = this.Settings.GroupLessonsTariffsId;

			if (filterTariffsId !== undefined && filterTariffsId.length > 0)
				this._allTariffs = this._allTariffs.filter((x) => filterTariffsId.indexOf(x.Id) !== -1);
		}

		this.TariffLoaded = true;

		this.ResetTariffs();
		this.ResetFilterResults();
	}

	FormatDate(dateString: string): string {
		return this.DateHandler.Parse(dateString, 'YYYY-MM-DD').Format('DD.MM.YYYY');
	}

	FormatTime(dateString: string): string {
		return this.DateHandler.Parse(dateString, 'HH:mm:SS').Format('HH:mm');
	}

	SkillChanged(): void {
		this.ResetTariffs();
		this.ResetFilterResults();
	}

	CategoryChanged(): void {
		this.ResetTariffs();
		this.ResetFilterResults();
	}

	TariffChanged(tariffCode: number): void {
		this.TariffId = tariffCode;
		this.ResetFilterResults();
	}

	ResetFilterResults(): void {
		this.Lessons = [];
		this.LessonsLoaded = false;

		this.FilterComplete =
			this.TariffLoaded &&
			this.SkillsLoaded &&
			this.CategoriesLoaded &&
			this.LocationsLoaded &&
			(this.Skill !== 0 || this.NoSkills) &&
			(this.CategoryId !== '' || this.NoCategories) &&
			(this.LocationId !== 0 || this.NoLocations) &&
			(this.TariffId !== 0 || this.NoTariffs) &&
			this.Dates.length >= 1;
	}

	ResetTariffs(): void {
		if (this._allTariffs !== undefined) {
			this.Tariffs =
				this.CategoryId !== ''
					? this._allTariffs
							.filter((x) =>
								this.Settings.GroupLessonsSkillsTariffs[`${this.Skill}`] != undefined
									? this.Settings.GroupLessonsSkillsTariffs[`${this.Skill}`].indexOf(x.Id) !== -1
									: true
							)
							.filter((x) => x.OptionId === this.CategoryId)
							.sort((x, y) => {
								if (x.Name == y.Name) return 0;
								if (x.Name < y.Name) return -1;
								else return 1;
							})
					: this._allTariffs
							.filter((x) =>
								this.Settings.GroupLessonsSkillsTariffs[`${this.Skill}`] !== undefined
									? this.Settings.GroupLessonsSkillsTariffs[`${this.Skill}`].indexOf(x.Id) !== -1
									: true
							)
							.sort((x, y) => {
								if (x.Name == y.Name) return 0;
								if (x.Name < y.Name) return -1;
								else return 1;
							});

			if (this.multiday == true) {
				this.CategoryId !== ''
					? this._allTariffs
							.filter((x) =>
								this.Settings.MultiDaysLessonsSkillsTariffs[`${this.Skill}`] != undefined
									? this.Settings.MultiDaysLessonsSkillsTariffs[`${this.Skill}`].indexOf(x.Id) !== -1
									: true
							)
							.filter((x) => x.OptionId === this.CategoryId)
							.sort((x, y) => {
								if (x.Name == y.Name) return 0;
								if (x.Name < y.Name) return -1;
								else return 1;
							})
					: this._allTariffs
							.filter((x) =>
								this.Settings.MultiDaysLessonsSkillsTariffs[`${this.Skill}`] !== undefined
									? this.Settings.MultiDaysLessonsSkillsTariffs[`${this.Skill}`].indexOf(x.Id) !== -1
									: true
							)
							.sort((x, y) => {
								if (x.Name == y.Name) return 0;
								if (x.Name < y.Name) return -1;
								else return 1;
							});
			}
		}

		this.TariffId = this.Tariffs.length == 1 ? this.Tariffs[0].TariffCode : 0;
	}

	AddToCart(lesson: InstructorsGroupLesson, amount: number, price: number): void {
		let cartItem = new InstructorsGroupCartItem(
			lesson,
			amount,
			price,
			this.Cart,
			this.Settings?.CoachId,
			this.LocationId == 0 ? null : this.LocationId
		);
		let result = this.Cart.AddItem(cartItem);

		if (!result.Success) {
			this._notificationService.Error('Ошибка', result.ErrorMessage ? result.ErrorMessage : 'Непредвиденная ошибка');
		} else {
			var cartItems = this.Cart.SimpleCart();
			parent.postMessage({ status: 'addedtocart', data: cartItems }, '*');
		}
	}

	get LessonsBought(): Array<CartItem> {
		let lessonsBought: Array<CartItem> = [];
		this.Cart.Items.forEach((element: CartItem) => {
			if (element.Type == CartItemTypes.InstructorsGroupLesson) lessonsBought.push(element);
		});
		return lessonsBought;
	}

	async GetGroupLessonsAsync(): Promise<ActionResult<Array<InstructorsGroupLesson>>> {
		this.LessonsLoaded = false;
		this.FilterLoading = true;
		let dateFrom = this.DateHandler.Parse(this.Dates[0], 'YYYY-MM-DD').Format('DD.MM.YYYY');
		let dateTo = this.Dates.length == 2 ? this.DateHandler.Parse(this.Dates[1], 'YYYY-MM-DD').Format('DD.MM.YYYY') : dateFrom;

		let coachid = this.Settings.CoachId || null;
		if (this.Instructor) coachid = this.Instructor.Code;

		let skill = this.Skills.find((x) => x.Id == this.Skill);
		let filter = new GroupLessonsFilter(
			this.SelectedPlace!.Id,
			skill !== undefined ? skill.Name : '',
			this.CategoryId,
			dateFrom,
			dateTo,
			this.TariffId == 0 ? null : this.TariffId,
			null,
			coachid,
			this.LocationId == 0 ? '' : this.LocationId.toString()
		);

		let filterResult = await this._instructorsService.GetGroupLessonsAsync(filter);

		// Remove disabled date lessons from the filtered result
		// Loop through each result
		await filterResult.Data?.forEach((e, i) => {
			let thisDate = this.DateHandler.Parse(e.Date, 'YYYY-MM-DDTHH:mm:SS').Format('DD.MM.YYYY');
			// If we this date is one of the disabled dates - splice it (removing it from the dataset)
			if (this.Settings.Disableddates.includes(thisDate)) filterResult.Data?.splice(i, 1);
		});

		this.FilterLoading = false;
		this.LessonsLoaded = true;
		if (filterResult.Data !== null) {
			let groups = new Array<InstructorsGroupLesson>();

			filterResult.Data?.forEach((x) => {
				let lesson = groups.find((y) => y.Date == x.Date && y.BeginTime == x.BeginTime && y.EndTime == x.EndTime);
				if (lesson !== undefined) {
					lesson.MaxPersonsCount = lesson.MaxPersonsCount + x.MaxPersonsCount;
					lesson.FreeMembers = lesson.FreeMembers + x.FreeMembers;
				} else {
					groups.push(x);
				}
			});

			this.Lessons = groups;
		}

		return filterResult;
	}

	DatesSet(dates: Array<string>): void {
		this.Dates = dates;
		this.ResetFilterResults();
	}
}

export class WpInstructorsGroupDictionary extends BaseSystemLocalizationDictionary {
	Discipline: string = '';
	DisciplinesLoading: string = '';
	Category: string = '';
	CategoriesLoading: string = '';
	Program: string = '';
	ProgramsLoading: string = '';
	DialogBtnShow: string = '';
	Location: string = '';
	LocationsLoading: string = '';
}
