import Component from 'vue-class-component';
import WpIsdLoyalty from './../../WpLoyalty/WpIsdLoyalty/wp-isd-loyalty';
import { NotificationEventArgs, NotificationTypes, INotificationService } from 'web-platform-core-ui';

@Component({
    template: require("./luzh-loyalty.html")
})
export default class LuzhLoyalty extends WpIsdLoyalty {
    _notificationService!: INotificationService;

    created(): void {
        //
    };

    async mounted(): Promise<void> {
        this._notificationService.NotificationSended.Subscribe(this._notificationCallback);
    };

    beforeDestroy() {
        this._notificationService.NotificationSended.Unsubscribe(this._notificationCallback);
    };

    protected _notificationCallback(sender: any, e: NotificationEventArgs): void {
        if (e.Data.Type == NotificationTypes.System && e.Data.SystemProcessKey == "IsdLoyaltyDataChanged" && window.scrollY != 0) {
            let userDataElement = document.querySelector(".LuzhLoyaltyUserData");
            if (userDataElement) userDataElement.scrollIntoView({ block: 'start' })
        };
    };
};